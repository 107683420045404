import React from 'react';

import { Resources } from 'src/resources';
import { ActionButton, ActionButtonType } from 'src/components/common/flure';

import { useStyles } from './styles';

type Props = {
  onApplyPress: () => void;
  onResetPress: () => void;
  applyDisabled: boolean;
};

const ActionButtonsView = (props: Props) => {
  const classes = useStyles();
  const { onApplyPress, onResetPress, applyDisabled } = props;

  return (
    <div className={classes.buttonsContainer}>
      <ActionButton
        className={classes.applyButton}
        type={ActionButtonType.Default}
        title={Resources.strings.flureFeed.apply}
        onClick={onApplyPress}
        disabled={applyDisabled}
      />
      <ActionButton
        className={classes.resetButton}
        type={ActionButtonType.Stroke}
        title={Resources.strings.flureFeed.reset}
        onClick={onResetPress}
      />
    </div>
  );
};

export const ActionButtons = React.memo(ActionButtonsView);
