import React, { useMemo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import ReactHookFormSelect from 'src/components/common/ReactHookFormSelect';
import {
  operatorsCities,
  operatorsLocation,
  validCommission,
  validFollowBonusCommission,
  validStarterBonusCommission,
} from 'src/services/operator/config';
import { Cities } from 'src/types/operator';

import validationSchema, { OperatorForm } from './schema';
import { ActivateAnimatorFields } from './activate-animator-fields';
import * as S from './styled';

type Props = {
  onSubmit: (formData: OperatorForm) => Promise<void>;
  requestError?: Error;
};

const defaultValues = {
  firstName: '',
  lastName: '',
  commission: 0.01,
  starterBonusCommission: 0.1,
  followBonusCommission: 0.05,
  isReadyForExplicit: false,
  isFreeMember: false,
  city: Cities.Denver,
  freeMemberAnimatorClientId: '',
  animatorCity: '',
  animatorCountry: '',
};

export const OperatorsManagingForm = ({ onSubmit, requestError }: Props) => {
  const { control, handleSubmit, errors, formState, reset, watch, unregister } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { me } = useAuth();
  const realm = me?.realm;
  const isMagnetProduct = realm === Product.Magnet;
  const isOnceProduct = realm === Product.Once;

  const selectItems = useMemo(
    () =>
      validCommission.map((product, i) => (
        <MenuItem key={product} value={validCommission[i]}>
          {product}
        </MenuItem>
      )),
    [],
  );
  const selectItemsStartedBonuses = useMemo(
    () =>
      validStarterBonusCommission.map((commission, i) => (
        <MenuItem key={commission} value={validStarterBonusCommission[i]}>
          {commission}
        </MenuItem>
      )),
    [],
  );
  const selectItemsFollowBonuses = useMemo(
    () =>
      validFollowBonusCommission.map((commission, i) => (
        <MenuItem key={commission} value={validFollowBonusCommission[i]}>
          {commission}
        </MenuItem>
      )),
    [],
  );

  const cityItems = useMemo(() => {
    if (isOnceProduct) {
      return operatorsCities
        .filter((city) => operatorsLocation[city].allowedForUdates)
        .map((city) => (
          <MenuItem key={city} value={city}>
            {operatorsLocation[city].country} - {city}
          </MenuItem>
        ));
    }
    return operatorsCities.map((city, i) => (
      <MenuItem key={city} value={operatorsCities[i]}>
        {operatorsLocation[city].country} - {city}
      </MenuItem>
    ));
  }, [isOnceProduct]);

  useEffect(() => {
    if (formState.isSubmitSuccessful && !requestError) {
      reset();
    }
  }, [formState.isSubmitSuccessful, requestError, reset]);

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.InputWrapper>
        <Controller
          as={TextField}
          name="firstName"
          type="string"
          control={control}
          label="Operator first name"
          error={!!errors.firstName}
          variant="outlined"
          size="small"
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <Controller
          as={TextField}
          name="lastName"
          type="string"
          control={control}
          label="Operator last name"
          error={!!errors.lastName}
          variant="outlined"
          size="small"
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <ReactHookFormSelect
          size="small"
          error={!!errors.commission}
          name="commission"
          label="Select commission"
          control={control}
          variant="outlined"
        >
          {selectItems}
        </ReactHookFormSelect>
      </S.InputWrapper>
      {isMagnetProduct ? (
        <S.InputWrapper>
          <ReactHookFormSelect
            size="small"
            error={!!errors.starterBonusCommission}
            name="starterBonusCommission"
            label="Select starter bonus commission"
            control={control}
            variant="outlined"
          >
            {selectItemsStartedBonuses}
          </ReactHookFormSelect>
        </S.InputWrapper>
      ) : null}
      {isMagnetProduct ? (
        <S.InputWrapper>
          <ReactHookFormSelect
            size="small"
            error={!!errors.followBonusCommission}
            name="followBonusCommission"
            label="Select follow bonus commission"
            control={control}
            variant="outlined"
          >
            {selectItemsFollowBonuses}
          </ReactHookFormSelect>
        </S.InputWrapper>
      ) : null}
      <S.InputWrapper>
        <Controller
          name="isReadyForExplicit"
          control={control}
          error={!!errors.isReadyForExplicit}
          render={({ onChange, value, ...rest }) => (
            <FormControlLabel
              control={<Checkbox {...rest} checked={value} onChange={(e) => onChange(e.target.checked)} />}
              label="is ready for explicit"
            />
          )}
        />
      </S.InputWrapper>

      {isOnceProduct && (
        <ActivateAnimatorFields
          control={control}
          errors={errors}
          watch={watch}
          unregister={unregister}
          cityItems={cityItems}
        />
      )}
      {!isOnceProduct && (
        <S.InputWrapper>
          <ReactHookFormSelect
            error={!!errors.city}
            size="small"
            name="city"
            label="Select City"
            control={control}
            variant="outlined"
          >
            {cityItems}
          </ReactHookFormSelect>
        </S.InputWrapper>
      )}

      <Button type="submit" variant="contained" color="primary" disabled={formState.isSubmitting}>
        Create
      </Button>
    </S.Form>
  );
};
