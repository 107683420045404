import { AxiosResponse } from 'axios';
import moment from 'moment';

import { CommissionsReportDataItem } from 'src/types/report/commissions';
import { round } from 'src/utils/number';
import { ReportCommissionsByOperatorsDto, SingleReportCommission } from './types';

function calculateFullOperatorCommission(operatorItem: SingleReportCommission) {
  const baseOperatorCommission = operatorItem.operatorCommission;
  const unpaidMessagesBonus = operatorItem.unpaidMessagesBonus?.totalBonusValue || 0;
  return round(baseOperatorCommission + unpaidMessagesBonus, 100);
}

function calculateTotalMessagesReceived(operatorItem: SingleReportCommission) {
  const commonRepliesCount = operatorItem.receivedMessages;
  const unpaidMessagesCount = operatorItem?.unpaidMessagesBonus?.bonusesCount || 0;
  return commonRepliesCount + unpaidMessagesCount;
}

const getByOperators = (response: AxiosResponse<ReportCommissionsByOperatorsDto>) => {
  return response.data.operatorCommissions.map((item, i) => {
    const adaptedItem: CommissionsReportDataItem = {
      ...item,
      id: `${item.id}_${item.timestamp || ''}_${i}`,
      operatorId: item.id,
      agencyName: item.agencyName || '-',
      operatorName: item.operatorName || '-',
      hoursOnline: round(item.minutesOnline / 60, 10),
      hoursSinceLastOnline:
        typeof item.minutesSinceLastOnline === 'number' ? round(item.minutesSinceLastOnline / 60, 10) : null,
      prettyDateTime: item.timestamp ? moment(item.timestamp).format('YYYY/MM/DD') : '-',
      timestamp: item.timestamp || null,
      operatorCommission: calculateFullOperatorCommission(item),
      receivedMessages: calculateTotalMessagesReceived(item),
      totalTrapsPenalty: item.trapPenalties?.totalBonusValue || 0,
    };

    return adaptedItem;
  });
};

export const adapter = {
  getByOperators,
};
