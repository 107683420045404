import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '368px',
    height: '73.6px',
    padding: '9px 10px',
    borderRadius: '13px',
    backgroundColor: '#252525',

    display: 'flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
    columnGap: '10px',
  },

  appIcon: {
    width: '42px',
    height: '42px',
    objectFit: 'contain',
  },

  contentContainer: {
    width: '100%',
  },

  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  title: {
    fontFamily: 'SFProText',
    fontSize: '14.5px',
    lineHeight: '20px',
    letterSpacing: '-0.52px',
    fontWeight: 600,
    color: '#fff',
  },

  timeText: {
    fontFamily: 'SFProText',
    fontSize: '11px',
    lineHeight: '18px',
    letterSpacing: '-0.08px',
    fontWeight: 400,
    color: 'rgba(235, 235, 245, 0.6)',
  },

  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    columnGap: '12px',
  },

  subtitle: {
    fontFamily: 'SFProText',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '-0.08px',
    fontWeight: 400,
    color: '#fff',
  },

  pushImage: {
    width: '32px',
    height: '32px',
    borderRadius: '6px',
    objectFit: 'cover',
    marginTop: '1px',
  },
});
