import { Countries, Cities, Commission, BonusCommission } from 'src/types/operator';

type Language = 'en' | 'fr' | 'de' | 'es';
export const DEFAULT_LANGUAGE = 'en';

export const operatorsLocation: Record<
  Cities,
  {
    country: Countries;
    latitude: number;
    longitude: number;
    allowedForUdates: boolean;
    language: Language;
  }
> = {
  Denver: {
    country: 'US',
    latitude: 39.7392,
    longitude: -104.9903,
    allowedForUdates: true,
    language: 'en',
  },
  'New-York': {
    country: 'US',
    latitude: 40.713051,
    longitude: -74.007233,
    allowedForUdates: false,
    language: DEFAULT_LANGUAGE,
  },
  Hamburg: {
    country: 'DE',
    longitude: 9.9937,
    latitude: 53.5511,
    allowedForUdates: true,
    language: 'de',
  },
  London: {
    country: 'UK',
    longitude: -0.118092,
    latitude: 51.509865,
    allowedForUdates: false,
    language: DEFAULT_LANGUAGE,
  },
  Wellington: {
    country: 'NZ',
    longitude: 174.777969,
    latitude: -41.276825,
    allowedForUdates: false,
    language: DEFAULT_LANGUAGE,
  },
  Copenhagen: {
    country: 'DK',
    longitude: 12.568337,
    latitude: 55.676098,
    allowedForUdates: false,
    language: DEFAULT_LANGUAGE,
  },
  Bern: {
    country: 'CH',
    longitude: 7.444608,
    latitude: 46.947922,
    allowedForUdates: false,
    language: DEFAULT_LANGUAGE,
  },
  Paris: {
    country: 'FR',
    longitude: 2.3522219,
    latitude: 48.856614,
    allowedForUdates: true,
    language: 'fr',
  },
  Brussels: {
    country: 'BE',
    longitude: 4.34878,
    latitude: 50.85045,
    allowedForUdates: false,
    language: DEFAULT_LANGUAGE,
  },
  Madrid: {
    country: 'ES',
    latitude: 40.4168,
    longitude: 3.7038,
    allowedForUdates: true,
    language: 'es',
  },
};

export const operatorsCities = Object.keys(operatorsLocation) as Cities[];

export const validCommission: Commission[] = [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07];
export const validStarterBonusCommission: BonusCommission[] = [
  0.01,
  0.02,
  0.03,
  0.04,
  0.05,
  0.06,
  0.07,
  0.08,
  0.09,
  0.1,
];
export const validFollowBonusCommission: BonusCommission[] = [0.01, 0.02, 0.03, 0.04, 0.05];
