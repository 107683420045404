import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import { Layout } from 'src/components/App/views/Layout';
import { ModerationHeader } from 'src/components/Moderation/views/moderation-header';

import { useStyles } from './styles';

type Props = {
  usersInQueue: number;
  moderatedUsersCount: number;
};

type PropsWithoutModerated = {
  usersInQueue: number;
};

export const PageFirstLoadingPlaceholder = (props: Props) => {
  const { usersInQueue, moderatedUsersCount } = props;
  const classes = useStyles();

  return (
    <Layout containerSize="lg">
      <ModerationHeader usersInQueue={usersInQueue} moderatedUsersCount={moderatedUsersCount} />
      <Box className={classes.placeholderContainer}>
        <CircularProgress />
      </Box>
    </Layout>
  );
};

export const NoContentForModerationPlaceholder = (props: Props) => {
  const { usersInQueue, moderatedUsersCount } = props;
  const classes = useStyles();

  return (
    <Layout containerSize="lg">
      <ModerationHeader usersInQueue={usersInQueue} moderatedUsersCount={moderatedUsersCount} />
      <Box className={classes.placeholderContainer}>
        <Typography className={classes.placeholderText}>No content for moderation available.</Typography>
      </Box>
    </Layout>
  );
};

export const LoadingPlaceholder = () => {
  const classes = useStyles();

  return (
    <Box className={classes.placeholderContainer}>
      <CircularProgress />
    </Box>
  );
};

export const NoUndoUsersPlaceholder = () => {
  const classes = useStyles();
  return (
    <Box className={classes.placeholderContainer}>
      <Typography className={classes.placeholderText}>You can’t undo any more</Typography>
    </Box>
  );
};

export const UserNotFoundPlaceholder = (props: PropsWithoutModerated) => {
  const { usersInQueue } = props;
  const classes = useStyles();

  return (
    <Layout containerSize="lg">
      <ModerationHeader usersInQueue={usersInQueue} />
      <Box className={classes.placeholderContainer}>
        <Typography className={classes.placeholderText}>User not found.</Typography>
      </Box>
    </Layout>
  );
};
