import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  input: {
    height: '50px',
    border: '1px solid #000',
    borderRadius: '14px',
    padding: '13px 16px',

    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#101014',
    fontSize: '16px',
    lineHeight: '24px',
    

    '&::placeholder': {
      color: '#76767A',
    },

    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  },

  textArea: {
    height: '189px',
    resize: 'none',
  },
});
