import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { isEmpty, isEqual } from 'lodash';

import { Layout } from 'src/components/App/views/Layout';
import { useChatModerationMessagesStopWords } from 'src/services/flure-chat-moderation/hooks';
import { flureChatModerationRequest } from 'src/network/flure-chat-moderation';

import { ActionButtons, ListType, WordsList } from './components';
import { useStyles } from './styles';

const defaultValue = [] as string[];

export const FlureChatModerationWordsListScreen = () => {
  const { stopWordsState, fetchStopWords } = useChatModerationMessagesStopWords();
  const classes = useStyles();
  const [isUpdating, setIsUpdating] = useState(false);
  const [moderationList, setModerationList] = useState(defaultValue);
  const [scamList, setScamList] = useState(defaultValue);
  const { enqueueSnackbar } = useSnackbar();
  const initialModerationValue = stopWordsState.value?.moderation?.contains || defaultValue;
  const initialScamValue = stopWordsState.value?.scam || defaultValue;
  const buttonsDisabled =
    isUpdating || (isEqual(moderationList, initialModerationValue) && isEqual(scamList, initialScamValue));

  const save = useCallback(async () => {
    try {
      setIsUpdating(true);
      await flureChatModerationRequest.updateMessagesStopWords({
        moderation: {
          contains: moderationList || defaultValue,
          match: moderationList || defaultValue,
        },
        scam: scamList || defaultValue,
      });
      enqueueSnackbar('List updated!', { variant: 'success' });

      await fetchStopWords();
      setIsUpdating(false);
    } catch (e: any) {
      enqueueSnackbar(e.toString() || 'Bad request', { variant: 'error' });
      setIsUpdating(false);
    }
  }, [enqueueSnackbar, fetchStopWords, moderationList, scamList]);

  const reset = useCallback(() => {
    setModerationList(initialModerationValue);
    setScamList(initialScamValue);
  }, [initialModerationValue, initialScamValue]);

  useEffect(() => {
    fetchStopWords();
  }, [fetchStopWords]);

  useEffect(() => {
    if (!isEmpty(initialModerationValue) || !isEmpty(initialScamValue)) {
      setModerationList(initialModerationValue);
      setScamList(initialScamValue);
    }
  }, [initialModerationValue, initialScamValue]);

  return (
    <>
      <Layout containerSize="lg">
        <div className={classes.wrapper}>
          <WordsList listType={ListType.Moderation} listValues={moderationList} setListValues={setModerationList} />
          <WordsList listType={ListType.Scam} listValues={scamList} setListValues={setScamList} />
        </div>
      </Layout>
      <ActionButtons save={save} reset={reset} disabled={buttonsDisabled} />
    </>
  );
};
