export type Operator = {
  operatorId: string;
  operatorDbId: string;
  firstName: string;
  lastName: string;
  agencyName?: string;
  agencyTechName?: string;
  isReadyForExplicit: boolean;
  scheduleType?: string;
};

export type UpdatedOperatorState = Omit<Operator, 'operatorId' | 'operatorDbId' | 'agencyName'>;

export enum Cities {
  Denver = 'Denver',
  NewYork = 'New-York',
  Hamburg = 'Hamburg',
  London = 'London',
  Wellington = 'Wellington',
  Copenhagen = 'Copenhagen',
  Bern = 'Bern',
  Paris = 'Paris',
  Brussels = 'Brussels',
  Madrid = 'Madrid',
}

export type Countries = 'US' | 'DE' | 'UK' | 'NZ' | 'DK' | 'CH' | 'FR' | 'BE' | 'ES';

export type Commission = 0.01 | 0.02 | 0.03 | 0.04 | 0.05 | 0.06 | 0.07;
export type BonusCommission = 0.01 | 0.02 | 0.03 | 0.04 | 0.05 | 0.06 | 0.07 | 0.08 | 0.09 | 0.1;
