import { useCallback } from 'react';
import { useAsyncFn } from 'react-use';
import { useSnackbar } from 'notistack';

import { flurePromoCodesRequest } from 'src/network/flure-promocodes';
import { PromoCodeData } from 'src/network/flure-promocodes/types';

import { useAuth } from '../auth';

export const usePromoCodes = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useAuth();
  const operatorId = me?.id;

  const [promoCodesState, fetchPromoCodes] = useAsyncFn(
    async () => {
      try {
        const result = operatorId ? await flurePromoCodesRequest.getPromoCodesList(operatorId) : [];
        return result;
      } catch (error) {
        enqueueSnackbar('Error occurred, try to reload', { variant: 'error' });
        return null;
      }
    },
    [],
    { loading: false },
  );

  const deletePromoCode = useCallback(
    (id: string, callback: () => void) => {
      if (operatorId) {
        flurePromoCodesRequest
          .deletePromoCode(operatorId, id)
          .then(() => {
            enqueueSnackbar('Promocode deleted', { variant: 'success' });
            callback();
          })
          .catch(() => {
            enqueueSnackbar('Error occurred, try again or reload', { variant: 'error' });
          });
      }
    },
    [enqueueSnackbar, operatorId],
  );

  const addOrUpdatePromoCode = useCallback(
    (promoCodeData: PromoCodeData, callback: () => void) => {
      if (operatorId) {
        flurePromoCodesRequest
          .addOrUpdatePromoCode(operatorId, promoCodeData)
          .then(() => {
            enqueueSnackbar('Success', { variant: 'success' });
            callback();
          })
          .catch(() => {
            enqueueSnackbar('Error occurred, try again or reload', { variant: 'error' });
          });
      } else {
        enqueueSnackbar('Cannot get your user id. Try to reload or re-signin', { variant: 'error' });
      }
    },
    [enqueueSnackbar, operatorId],
  );

  return { promoCodesState, fetchPromoCodes, deletePromoCode, addOrUpdatePromoCode };
};
