import { AxiosResponse } from 'axios';

import { Agency } from 'src/types/agency';
import { AgenciesDto, UserTagsAgencyDto } from './types';

const getAll = (response: AxiosResponse<AgenciesDto>) => {
  return response.data.Agencies.map(
    (agency) =>
      ({
        name: agency.Name,
        techName: agency.TechName,
      } as Agency),
  );
};

const getAgencyName = (response: AxiosResponse<UserTagsAgencyDto>) => {
  return response.data[0];
};

export const adapter = {
  getAll,
  getAgencyName,
};
