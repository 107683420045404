import React, { useCallback, useEffect } from 'react';

import { useNextUserFlure } from 'src/services/verification/hooks';

import { VerificationHeader } from '../VerificationHeader';
import { LoadingOrNoContent, UserInfo, UserMedia, VerificationActionButtons, VerificationMedia } from './components';
import { useUsersCount } from './hooks';
import { useStyles } from './styles';

export const VerificationWidgetFlure: React.FC = () => {
  const { nextUserState, loadNextUser } = useNextUserFlure();
  const { fetchContentCount, handledUsersCount, usersInQueue, updateHandledUsersCount } = useUsersCount();

  const classes = useStyles();

  const onRefreshClick = useCallback(() => {
    fetchContentCount();
    loadNextUser();
  }, [fetchContentCount, loadNextUser]);

  useEffect(() => {
    fetchContentCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!nextUserState.value || nextUserState.loading) {
    return (
      <LoadingOrNoContent
        usersInQueue={usersInQueue}
        handledUsersCount={handledUsersCount}
        isLoading={nextUserState.loading}
        onRefreshClick={onRefreshClick}
      />
    );
  }

  const { age, gender, genderIdentity, media, mediaCount, userId, country, city } = nextUserState.value || {};

  return (
    <div>
      <VerificationHeader usersInQueue={usersInQueue} handledUsersCount={handledUsersCount} />
      <div className={classes.contentContainer}>
        <div>
          <div className={classes.photosContainer}>
            <VerificationMedia userId={userId} />
            <UserMedia userId={userId} media={media} />
          </div>
          <span className={classes.userId}>ID {userId}</span>
        </div>
        <UserInfo age={age} gender={gender} genderIdentity={genderIdentity!} country={country} city={city} />
        <VerificationActionButtons
          userId={userId}
          mediaCount={mediaCount}
          updateHandledUsersCount={updateHandledUsersCount}
          fetchContentCount={fetchContentCount}
          loadNextUser={loadNextUser}
        />
      </div>
    </div>
  );
};
