import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  buttonsContainer: {
    marginTop: '30px',
    display: 'flex',
    columnGap: '32px',
    justifyContent: 'flex-start',
  },

  applyButton: {
    width: '228px',
  },

  resetButton: {
    width: '104px',
  },
});
