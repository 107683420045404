import React, { useEffect, useMemo } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { OperatorForm, OperatorFormKeys } from './schema';

type Props<T extends OperatorFormKeys> = {
  conditionalFieldId: T;
  name: OperatorFormKeys;
  expectedValue?: OperatorForm[T];
  children: React.ReactNode;
  watch: UseFormMethods['watch'];
  unregister: (name: OperatorFormKeys) => void;
};

export const ConditionalField = <T extends OperatorFormKeys>(props: Props<T>) => {
  const { conditionalFieldId, name, expectedValue, children, watch, unregister } = props;
  const watchedValue = watch(conditionalFieldId);
  const hide = useMemo(() => {
    if (expectedValue !== undefined) {
      return watchedValue !== expectedValue;
    }

    return !watchedValue;
  }, [expectedValue, watchedValue]);

  useEffect(() => {
    if (hide) {
      unregister(name);
    }
  }, [name, unregister, hide]);

  if (hide) {
    return null;
  }

  return <>{children}</>;
};
