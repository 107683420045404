import React from 'react';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

type Props = {
  usersInQueue?: number;
  handledUsersCount?: number;
  className?: string;
};

export const VerificationHeader: React.FC<Props> = (props) => {
  const { usersInQueue, handledUsersCount, className } = props;
  const classes = useStyles();

  return (
    <Box className={clsx(classes.topContainer, className)}>
      <Box className={classes.progressContainer}>
        {usersInQueue !== undefined && (
          <Typography className={classes.progressText}>
            👨 <b>{usersInQueue}</b> users in the queue
          </Typography>
        )}
        {handledUsersCount !== undefined && (
          <Typography className={classes.progressText}>
            ✅ You&apos;ve done <b>{handledUsersCount}</b>
          </Typography>
        )}
      </Box>
    </Box>
  );
};
