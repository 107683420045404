import React, { useCallback, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { useReportByBonuses } from 'src/services/report/use-report-by-bonuses';
import { BonusesReportDataItem } from 'src/types/report/bonuses';
import { DefaultReportFormFields, DefaultReportSearchMode } from 'src/types/report/common';
import { ExcelSaverButton } from 'src/components/common/ExcelSaverButton';
import { downloadBonusesReportXlsx, getFileName } from 'src/services/report/bonuses';

import { BonusesReportForm } from './Form';
import { BonusesReportTable } from './Table';
import { ReportLayout } from '../common/ReportLayout';

const initialValues = {
  rangeDate: ':',
};

export const ReportByBonusesScreen = () => {
  const { search } = useLocation();
  const { reportByBonusesState, fetchReportByBonuses } = useReportByBonuses();
  const formData = useRef<DefaultReportFormFields>();

  const submitReportRequest = useCallback(
    (values: DefaultReportFormFields) => {
      formData.current = values;
      fetchReportByBonuses(values).then();
    },
    [fetchReportByBonuses],
  );

  const params: Partial<{
    from: string;
    to: string;
    searchMode: DefaultReportSearchMode;
    operator: string;
  }> = useMemo(() => qs.parse(search.slice(1)), [search]);

  const initialFormValues: Partial<DefaultReportFormFields> = useMemo(() => {
    if (!params.operator) {
      return initialValues;
    }

    return {
      rangeDate: [params.from, params.to].join(':'),
      mode: 'operator',
      operatorId: Number(params.operator),
    };
  }, [params]);

  return (
    <ReportLayout headerCaption="Report by Bonuses">
      <BonusesReportForm
        initialValues={initialFormValues}
        loading={reportByBonusesState.loading}
        onSubmit={submitReportRequest}
      />
      <BonusesReportTable data={reportByBonusesState.value} />
      <ExcelSaverButton<BonusesReportDataItem, DefaultReportFormFields | undefined>
        download={downloadBonusesReportXlsx}
        data={reportByBonusesState.value}
        getFileName={getFileName}
        filterData={formData.current}
      />
    </ReportLayout>
  );
};
