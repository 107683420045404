import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  smallText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    opacity: 0.4,
  },

  link: {
    color: 'black',
  },
});
