import React from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';

import { useStyles } from './styles';

type Props = {
  reset: () => void;
  save: () => void;
  disabled: boolean;
};

export const ActionButtons = (props: Props) => {
  const classes = useStyles();
  const { reset, save, disabled } = props;

  return (
    <Container className={classes.container} maxWidth="lg">
      <div className={classes.buttonsContainer}>
        <Button
          onClick={reset}
          className={clsx(classes.btn, classes.btnDecline)}
          variant="contained"
          disabled={disabled}
        >
          <b>Reset</b>
        </Button>
        <Button
          onClick={save}
          className={clsx(classes.btn, classes.btnApprove)}
          variant="contained"
          disabled={disabled}
        >
          <b>Save</b>
        </Button>
      </div>
    </Container>
  );
};
