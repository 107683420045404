import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  button: {
    borderWidth: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },

  hiddenQr: {
    position: 'absolute',
    display: 'none',
  },
});
