import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    maxWidth: 1440,
    '@media (max-width: 1200px)': {
      columnGap: '10px',
    },
  },

  textContent: { maxWidth: '40%', width: 500 },

  wrapper: {
    display: 'flex',
  }
});
