import { Product } from 'src/types/product';
import { DefaultReportFormFields, DefaultReportSearchMode, Reports } from 'src/types/report/common';
import { BonusesReportDataItem } from 'src/types/report/bonuses';
import { fillExcelDocument } from 'src/utils/excel';
import { downloadFile } from 'src/utils/files';
import { convertBonusesReport } from 'src/services/report/bonuses/utils';

export const downloadBonusesReportXlsx = (payload: BonusesReportDataItem[], fileName: string, realm: Product) => {
  const convertedData = convertBonusesReport(realm, payload);
  const document = fillExcelDocument('Report by bonuses', convertedData);

  downloadFile(document, `${fileName}.xlsx`);
};

export const getFileName = (realm: Product, filter?: DefaultReportFormFields): string => {
  if (!filter) {
    return '';
  }

  const reportName = Reports.bonuses;
  const formattedDateRange = filter.rangeDate.replace(':', '__');

  const reportMapping: Record<DefaultReportSearchMode, string> = {
    agency: 'agency',
    all: 'all',
    'all-without-agency': 'without-agency',
    operator: 'operator',
  };
  const operator = filter.operatorId ? `-${filter.operatorId}` : '';

  return `${realm}-${reportName}-${reportMapping[filter.mode]}${operator}-${formattedDateRange}`;
};
