import React, { useCallback, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import { Operator, UpdatedOperatorState } from 'src/types/operator';
import { Agency } from 'src/types/agency';
import { AgencySelector } from 'src/components/Multichat/views/Agency/Selector';
import { ScheduleSelector } from 'src/components/Multichat/views/Schedule/Selector';
import * as S from '../styled';

type Props = Operator & {
  agencies: Agency[];
  onSave: (operatorId: string, data: UpdatedOperatorState) => void;
};

export const MultichatAgencyOperatorsMappingTableRow = ({
  operatorDbId,
  operatorId,
  firstName,
  lastName,
  agencyTechName,
  isReadyForExplicit,
  scheduleType,
  agencies,
  onSave,
}: Props) => {
  const [formData, setFormData] = useState({
    firstName,
    lastName,
    agencyTechName: agencyTechName || 'null',
    isReadyForExplicit,
    scheduleType,
  });
  const onTextFieldChange = useCallback((e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);
  const onSelectChange = useCallback(
    (fieldName: string) => (value: string) => {
      setFormData((prev) => ({ ...prev, [fieldName]: value }));
    },
    [],
  );
  const onExplicitStatusChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, isReadyForExplicit: event.target.checked }));
  }, []);

  const [editableModeEnabled, setEditableMode] = useState(false);
  const startOperatorEditing = useCallback(() => {
    setEditableMode(true);
    setFormData({ firstName, lastName, agencyTechName: agencyTechName || 'null', isReadyForExplicit, scheduleType });
  }, [agencyTechName, firstName, isReadyForExplicit, lastName, scheduleType]);
  const cancelOperatorEditing = useCallback(() => {
    setEditableMode(false);
  }, []);
  const finishOperatorEditing = useCallback(() => {
    setEditableMode(false);
    onSave(operatorId, formData);
  }, [formData, onSave, operatorId]);

  const selectedAgency = useMemo(() => agencies.find((agency) => agency.techName === formData.agencyTechName), [
    agencies,
    formData.agencyTechName,
  ]);

  const editableRow = useMemo(
    () => (
      <>
        <S.TableBodyCell>{operatorDbId}</S.TableBodyCell>
        <S.TableBodyCell>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            size="small"
            value={formData.firstName}
            name="firstName"
            onChange={onTextFieldChange}
          />
        </S.TableBodyCell>
        <S.TableBodyCell>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            size="small"
            value={formData.lastName}
            name="lastName"
            onChange={onTextFieldChange}
          />
        </S.TableBodyCell>
        <S.TableBodyCell width={60}>
          <Checkbox checked={formData.isReadyForExplicit} onChange={onExplicitStatusChange} />
        </S.TableBodyCell>
        <S.TableBodyCell>
          <ScheduleSelector value={formData.scheduleType} onChange={onSelectChange('scheduleType')} />
        </S.TableBodyCell>
        <S.TableBodyCell>
          <AgencySelector
            agencies={agencies}
            value={formData.agencyTechName}
            onChange={onSelectChange('agencyTechName')}
          />
        </S.TableBodyCell>
        <S.TableBodyCell>
          <Button type="button" variant="contained" color="primary" size="small" onClick={finishOperatorEditing}>
            Save
          </Button>
          {` `}
          <Button type="button" variant="outlined" size="small" onClick={cancelOperatorEditing}>
            Cancel
          </Button>
        </S.TableBodyCell>
      </>
    ),
    [
      agencies,
      cancelOperatorEditing,
      finishOperatorEditing,
      formData.agencyTechName,
      formData.firstName,
      formData.isReadyForExplicit,
      formData.lastName,
      formData.scheduleType,
      onExplicitStatusChange,
      onSelectChange,
      onTextFieldChange,
      operatorDbId,
    ],
  );
  const notEditableRow = useMemo(
    () => (
      <>
        <S.TableBodyCell>{operatorDbId}</S.TableBodyCell>
        <S.TableBodyCell>{firstName || '-'}</S.TableBodyCell>
        <S.TableBodyCell>{lastName || '-'}</S.TableBodyCell>
        <S.TableBodyCell width={60}>{isReadyForExplicit ? 'yes' : 'no'}</S.TableBodyCell>
        <S.TableBodyCell>{scheduleType || '-'}</S.TableBodyCell>
        <S.TableBodyCell>{selectedAgency?.name || '-'}</S.TableBodyCell>
        <S.TableBodyCell>
          <Button type="button" variant="outlined" size="small" onClick={startOperatorEditing}>
            Edit
          </Button>
        </S.TableBodyCell>
      </>
    ),
    [firstName, isReadyForExplicit, lastName, operatorDbId, scheduleType, selectedAgency?.name, startOperatorEditing],
  );

  return <tr>{editableModeEnabled ? editableRow : notEditableRow}</tr>;
};
