import { useSnackbar } from 'notistack';
import { useAsyncFn } from 'react-use';

import { rawModerationRequest } from 'src/network/moderation/raw';
import { Logger, LoggerServices, LoggerMessages } from 'src/infrastructure/loggers/datadog';
import { Product } from 'src/types/product';
import { sessionDataStorage } from 'src/utils/session-storage';
import { useAuth } from 'src/services/auth';
import { RAW } from 'src/types/raw';
import { useEffect } from 'react';

const moderatedUsersCountKey = 'moderated-users-count';

const logError = (
  operatorId: string,
  payload: {
    reason: string;
    userId?: string;
    isUndoAction?: boolean;
    error?: any;
  },
) => {
  Logger.log({
    service: LoggerServices.Moderation,
    message: LoggerMessages.UserForModerationStateIsNull,
    product: Product.RAW,
    payload: {
      message: LoggerMessages.UserForModerationStateIsNull,
      operatorId,
      moderatedUsersCount: Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)),
      ...payload,
    },
  });
};

export function useUserPreset(userId: RAW.User['id']) {
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useAuth();

  const [userPreset, fetchUserPreset] = useAsyncFn(async () => {
    try {
      const result = await rawModerationRequest.getRAWUserPreset(userId);

      return result;
    } catch (e) {
      if (me?.id) {
        logError(me.id, { error: e, reason: 'loadUser crush' });
      }
      enqueueSnackbar('Error while fetching user', { variant: 'error' });
      return null;
    }
  }, [userId]);

  useEffect(() => {
    fetchUserPreset();
  }, [fetchUserPreset]);

  return { userPreset, fetchUserPreset };
}
