import React from 'react';
import Button from '@material-ui/core/Button';
import { CellParams, ColDef } from '@material-ui/data-grid';

import { TableColumnsProps } from '../types';

export function getDefaultTableColumns({ onSentMessageClick }: TableColumnsProps): ColDef[] {
  return [
    { field: 'operatorId', headerName: 'ID', width: 130, sortable: false },
    { field: 'agencyName', headerName: 'Agency name', width: 130, sortable: false },
    { field: 'operatorName', headerName: 'Operator name', width: 180, sortable: false },
    {
      field: 'messages',
      headerName: 'Messages (R/S)',
      description: 'Messages (Received/Sent)',
      width: 125,
      type: 'number',
      sortable: false,
      renderCell: (params: CellParams) => (
        <>
          <Button type="button" size="small" style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }} disabled>
            {params.getValue('receivedMessages')}
          </Button>
          /
          <Button
            type="button"
            size="small"
            style={{ color: '#4A90E2', padding: '0 4px', minWidth: 'auto' }}
            onClick={() => onSentMessageClick(params.getValue('operatorId') as string)}
          >
            {params.getValue('sentMessages')}
          </Button>
        </>
      ),
    },
    {
      field: 'operatorCommission',
      headerName: 'Operator Commission',
      type: 'number',
      width: 160,
      sortable: false,
    },
    {
      field: 'agencyCommission',
      headerName: 'Agency Commission',
      width: 150,
      sortable: false,
    },
    {
      field: 'hoursOnline',
      headerName: 'Online (h)',
      width: 80,
      sortable: false,
    },
    {
      field: 'prettyDateTime',
      headerName: 'Date',
      width: 230,
      sortable: false,
    },
  ];
}
