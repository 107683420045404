import styled from 'styled-components';
import {
  Container as ContainerS,
  Select,
  Typography,
  MenuItem as MenuItemS,
  TextField as TextFieldS,
  Button,
} from '@material-ui/core';

export const DateButton = styled(Button)`
  font-size: 16px;
  margin-right: 10px;
`;

export const Container = styled(ContainerS)`
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  width: 850px;
  margin: 18px 0;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 8px 10px -12px rgba(0, 0, 0, 0.3);
  outline: 1px solid rgba(0, 0, 0, 0.12);
  &:hover {
    box-shadow: 0 16px 20px -12.125px rgba(0, 0, 0, 0.3);
    background-color: #efefff;
  }
  transition: box-shadow 0.6s, background-color 0.5s;
  border-radius: 10px;
`;

export const CategoryText = styled(Typography)`
  margin: 0 5px;
  min-width: 120px;
`;

export const QuestionText = styled(Typography)`
  margin: 0 5px;
`;

export const DateText = styled(Typography)`
  margin-right: 20px;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const CategorySelect = styled(Select)`
  margin: 0 5px;
  min-width: 120px;
  justify-content: center;
`;

export const MenuItem = styled(MenuItemS)`
  text-align: left;
`;

export const TextField = styled(TextFieldS)`
  width: 350px;
  margin-right: 10px;
`;

export const SaveButton = styled(Button)`
  margin-top: 25px;
  margin-left: 25px;
`;
