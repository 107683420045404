const set = <T>(key: string, value: T) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    throw new Error(error);
  }
};

const get = <T>(key: string) => {
  try {
    const jsonValue = localStorage.getItem(key);
    return jsonValue !== null ? (JSON.parse(jsonValue) as T) : null;
  } catch (error) {
    throw new Error(error);
  }
};

const remove = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    throw new Error(error);
  }
};

export const storage = {
  set,
  get,
  remove,
};
