import { makeStyles, Theme } from '@material-ui/core';
import { Product } from 'src/types/product';

export const useStyles = makeStyles<
  Theme,
  {
    realm?: Product;
  }
>({
  actionButton: {
    backgroundColor: 'white',
    fontWeight: 600,
    height: '45px',
    padding: '0px 20px',
    textTransform: 'none',
    borderRadius: '8px',
    boxShadow:
      '0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  warning: {
    color: ({ realm }) => {
      if (realm === Product.Magnet) {
        return '#FF904F';
      }

      if (realm === Product.Flure) {
        return '#878E9C';
      }

      return '#ED654C';
    },
  },
  error: {
    color: ({ realm }) => {
      if (realm === Product.Magnet) {
        return '#FF4747';
      }

      if (realm === Product.Flure) {
        return '#878E9C';
      }

      return '#ED654C';
    },
  },
});
