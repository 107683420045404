import React, { useCallback, useMemo } from 'react';

import { ActionType, ModerationAction, UserForModeration } from 'src/types/moderation';
import { MentalLevel, Gender, MediaState } from 'src/types/user';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import { userCachedPhotoSize } from 'src/components/Moderation/utils/image-source';
import { MagnetActions } from 'src/components/Moderation/views/actions/magnet';
import { UDatesActions } from 'src/components/Moderation/views/actions/UDates';
import { XoXoActions } from 'src/components/Moderation/views/actions/XoXo';

type ReactDispatchType<T> = React.Dispatch<React.SetStateAction<T>>;

type Props = {
  isPressed: (actionType: ActionType, actionValue?: string | undefined) => boolean;
  updatePageWithNewUser: (isUndoAction?: boolean) => void;
  undoAction: (actionType: ActionType) => () => void;
  resetAbout: () => void;
  resetQuestions: () => void;
  resetName: () => void;
  resetOccupation: () => void;
  moderationActions: ModerationAction[];
  setUserMentalLevel?: ReactDispatchType<MentalLevel | undefined>;
  setUserGender: ReactDispatchType<Gender | undefined>;
  setIsSending: ReactDispatchType<boolean>;
  isLoading: boolean;
  sendModerationActions: (actions: ModerationAction[]) => Promise<void>;
  isNoUndoUsers: boolean;
  setMediaState: ReactDispatchType<MediaState>;
  addModerationAction: (actionType: ActionType, actionValue?: string | undefined) => void;
} & Pick<UserForModeration, 'newMediaSource' | 'mainMediaSource' | 'newMediaType'>;

export const Actions = ({
  undoAction,
  addModerationAction,
  resetAbout,
  resetQuestions,
  resetName,
  resetOccupation,
  moderationActions,
  isPressed,
  setUserMentalLevel,
  setUserGender,
  setIsSending,
  newMediaSource,
  mainMediaSource,
  isLoading,
  sendModerationActions,
  setMediaState,
  updatePageWithNewUser,
  isNoUndoUsers,
  newMediaType,
}: Props) => {
  const { me } = useAuth();

  const uDatesProduct = me?.realm === Product.Once;
  const magnetProduct = me?.realm === Product.Magnet;

  const moderatedMedia = useMemo(
    () => (newMediaSource || mainMediaSource)?.replace('.swipe', '').replace(userCachedPhotoSize, ''),
    [newMediaSource, mainMediaSource],
  );

  const undo = useCallback(() => {
    const isUndoAction = true;
    setIsSending(true);
    updatePageWithNewUser(isUndoAction);
    setIsSending(false);
  }, [setIsSending, updatePageWithNewUser]);

  const man = useCallback(() => {
    addModerationAction(ActionType.SetGender, Gender.Male);
    setUserGender(Gender.Male);
  }, [addModerationAction, setUserGender]);

  const woman = useCallback(() => {
    addModerationAction(ActionType.SetGender, Gender.Female);
    setUserGender(Gender.Female);
  }, [addModerationAction, setUserGender]);

  if (uDatesProduct) {
    return (
      <UDatesActions
        moderationActions={moderationActions}
        undo={undo}
        newMediaSource={newMediaSource}
        isNoUndoUsers={isNoUndoUsers}
        isLoading={isLoading}
        addModerationAction={addModerationAction}
        sendModerationActions={sendModerationActions}
        isPressed={isPressed}
        setMediaState={setMediaState}
        man={man}
        woman={woman}
        undoAction={undoAction}
        resetName={resetName}
        moderatedMedia={moderatedMedia}
        resetAbout={resetAbout}
        resetOccupation={resetOccupation}
        newMediaType={newMediaType}
      />
    );
  }

  if (magnetProduct) {
    return (
      <MagnetActions
        isLoading={isLoading}
        undo={undo}
        undoAction={undoAction}
        resetName={resetName}
        resetQuestions={resetQuestions}
        newMediaSource={newMediaSource}
        isNoUndoUsers={isNoUndoUsers}
        moderationActions={moderationActions}
        sendModerationActions={sendModerationActions}
        moderatedMedia={moderatedMedia}
        isPressed={isPressed}
        addModerationAction={addModerationAction}
      />
    );
  }

  return (
    <XoXoActions
      undoAction={undoAction}
      resetAbout={resetAbout}
      resetName={resetName}
      moderatedMedia={moderatedMedia}
      moderationActions={moderationActions}
      setUserMentalLevel={setUserMentalLevel as ReactDispatchType<MentalLevel>}
      newMediaSource={newMediaSource}
      addModerationAction={addModerationAction}
      isLoading={isLoading}
      sendModerationActions={sendModerationActions}
      undo={undo}
      isNoUndoUsers={isNoUndoUsers}
      isPressed={isPressed}
      man={man}
      woman={woman}
    />
  );
};
