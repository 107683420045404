import React, { createContext, useContext } from 'react';

import { AuthUser, LoginFormFields } from 'src/types/auth';
import { useAuthActions } from './use-auth-actions';

type AuthContextType = {
  loading: boolean;
  me: AuthUser | null;
  bootstrap: () => void;
  login: (values: LoginFormFields) => Promise<{ success: boolean }>;
  logout: () => Promise<{ success: boolean }>;
};

const initialValue = {
  me: null,
  loading: true,
  bootstrap: () => null,
  login: () => Promise.resolve({ success: true }),
  logout: () => Promise.resolve({ success: true }),
};

const AuthContext = createContext<AuthContextType>(initialValue);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { loading, me, bootstrap, login, logout } = useAuthActions();

  return <AuthContext.Provider value={{ loading, me, bootstrap, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
