import { Product } from 'src/types/product';

import { TableColumnsProps } from '../types';

import { getDefaultTableColumns } from './default-columns';
import { getUdatesTableColumns } from './udates-columns';
import { getMagnetTableColumns } from './magnet-columns';

type Props = {
  realm?: Product;
} & TableColumnsProps;

export function getTableColumnsByRealm(options: Props) {
  if (!options.realm) {
    return getDefaultTableColumns(options);
  }

  switch (options.realm) {
    case Product.Once:
      return getUdatesTableColumns(options);
    case Product.Magnet:
      return getMagnetTableColumns(options);

    default:
      return getDefaultTableColumns(options);
  }
}
