import { MediaModerationReason } from 'src/network/moderation/types';

export const ReasonMapping = {
  [MediaModerationReason.Scam]: 'Scam',
  [MediaModerationReason.Trash]: 'Trash',
  [MediaModerationReason.EighteenPlus]: '18+',
  [MediaModerationReason.NoUser]: 'No user',
  [MediaModerationReason.SamePhoto]: 'Same Media',
  [MediaModerationReason.RacyPhoto]: 'Racy Media',
  [MediaModerationReason.CelebrityPhoto]: 'Celebrity',
  [MediaModerationReason.AdultPhoto]: 'Adult Media',
  [MediaModerationReason.Approve]: '',
};

export const layoutMaxWidth = 1440;
