import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  doneOverlayContainer: {
    position: 'absolute',
    backgroundColor: '#F2DAF5',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '24px',
  },

  doneSubtitle: {
    textAlign: 'center',
  },
});
