import { CommonReportRequestParams } from 'src/network/report/common/types';
import { DefaultReportFormFields } from 'src/types/report/common';

type BaseReportRequestParams = Omit<CommonReportRequestParams, 'from' | 'to'>;

export function convertBaseFormFieldsToQueryParams(
  fields: DefaultReportFormFields,
  realm: string,
): BaseReportRequestParams {
  return {
    searchMode: fields.mode,
    language: fields.language,
    realm,
  };
}
