import { useState } from 'react';

import { RAW } from 'src/types/raw';

type Props = {
  moderationActions: RAW.ModerationAction[];
  setModerationActions: (action: RAW.ModerationAction[]) => void;
  setModerationAction: (action: RAW.ModerationAction) => void;
};

export const useQuestions = ({ setModerationAction, setModerationActions, moderationActions }: Props) => {
  const [questions, setQuestions] = useState<RAW.UserPreset['profile']['questions']>([]);

  const removeQuestion = (question: RAW.UserPreset['profile']['questions'][number]['question']) => {
    const newQuestions = questions.filter((it) => it.question !== question);
    setQuestions(newQuestions);
    setQuestionsForModeration(newQuestions);
  };

  const setQuestionsForModeration = (newQuestions: RAW.UserPreset['profile']['questions']) => {
    setModerationActions(moderationActions.filter((action) => action.actionType !== 'resetQuestions'));
    setModerationAction({ actionType: 'resetQuestions', actionValue: JSON.stringify(newQuestions) });
  };

  return { questions, setQuestions, removeQuestion };
};
