import { useMemo, useCallback } from 'react';
import { useAsyncFn } from 'react-use';

import { ModerationContent } from 'src/network/moderation/types';
import { moderationRequest, userRequest } from 'src/network';
import { Media, MediaTag, MediaType } from 'src/types/user';
import { UserForModerationFlure, UserForModerationStatus } from 'src/types/moderation';
import { useGetMediaSourceAuthedFullSize } from 'src/components/Moderation/utils/image-source';
import { useAuth } from 'src/services/auth';
import { Logger, LoggerServices, LoggerMessages } from 'src/infrastructure/loggers/datadog';
import { Product } from 'src/types/product';
import { sessionDataStorage } from 'src/utils/session-storage';
import { getUserAge } from 'src/utils/transform';
import { UserPreferences } from 'src/types/user/preferences';

const moderatedUsersCountKey = 'moderated-users-count';

type Data = {
  thumbnailMedia: Media | undefined;
  mainMediaType: MediaType;
  mainMediaSource: string;
  newMediaBaseName?: string;
  newMediaSource?: string;
  newMediaType?: MediaType;
};

class BuildDataForModerationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'BuildDataForModerationError';
  }
}

export const useUserForModeration = () => {
  const { me } = useAuth();
  const getMediaSource = useGetMediaSourceAuthedFullSize();
  const operatorId = useMemo(() => me?.id || '', [me]);

  const buildDataForModeration = useCallback(
    async (userId: string, media: Media[], baseName?: string, mediaType?: string): Promise<Data> => {
      try {
        const thumbnailMedia = media.find((el) => el.tags.includes(MediaTag.Thumbnail));
        const mainMediaType = thumbnailMedia?.mediaType.startsWith('video') ? MediaType.Video : MediaType.Photo;
        const mainMediaSource = thumbnailMedia
          ? getMediaSource(userId, thumbnailMedia.baseName, thumbnailMedia.mediaType, true)
          : '';
        const newMediaSource = baseName && mediaType ? getMediaSource(userId, baseName, mediaType, true) : '';
        const newMediaType = mediaType?.startsWith('video') ? MediaType.Video : MediaType.Photo;

        const dataForModeration = {
          ...(thumbnailMedia && {
            thumbnailMedia: {
              ...thumbnailMedia,
              baseName: thumbnailMedia.baseName,
              mediaType: thumbnailMedia.mediaType,
            },
          }),
          mainMediaType,
          mainMediaSource,
          newMediaBaseName: baseName,
          newMediaSource,
          newMediaType,
        } as Data;

        return dataForModeration;
      } catch (error) {
        throw new BuildDataForModerationError('Failed to build moderation data');
      }
    },
    [getMediaSource],
  );

  const [userForModerationState, fetchUserForModeration] = useAsyncFn(
    async (isUndoAction = false, realm?: Product) => {
      try {
        const request = isUndoAction ? moderationRequest.undoUserModeration : moderationRequest.getModerationContent;
        const moderationContent = operatorId ? await request(operatorId) : null;

        if (moderationContent) {
          const { content } = moderationContent;
          const { id: contentId, userId, baseName, mediaType, userName, userAbout } =
            content || ({} as ModerationContent);

          if (userId) {
            try {
              const user = await userRequest.getById(userId, true);
              const { name, media, gender, birthday, preferences } = user;

              const dataForModeration = await buildDataForModeration(userId, media, baseName, mediaType);

              return {
                userId,
                contentId,
                newUserName: userName,
                newUserAbout: userAbout,
                name,
                about: preferences?.[UserPreferences.Relationship] || '',
                genderIdentity: preferences?.[UserPreferences.GenderIdentity],
                gender,
                userMedia: media,
                status: UserForModerationStatus.Ok,
                age: getUserAge(birthday),
                ...dataForModeration,
              } as UserForModerationFlure;
            } catch (error: any) {
              if (error instanceof BuildDataForModerationError) {
                Logger.log({
                  service: LoggerServices.Moderation,
                  message: error.message,
                  product: realm || '',
                });
                return undefined;
              }

              Logger.log({
                service: LoggerServices.Moderation,
                message: LoggerMessages.GetUserInfoError,
                product: realm || '',
                payload: {
                  userId,
                  operatorId,
                  moderatedUsersCount: Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)),
                  error: error?.response,
                },
              });

              return null;
            }
          } else {
            Logger.log({
              service: LoggerServices.Moderation,
              message: LoggerMessages.UserForModerationStateIsNull,
              product: realm || '',
              payload: {
                message: LoggerMessages.UserForModerationStateIsNull,
                reason: 'No userId',
                userId,
                operatorId,
                moderatedUsersCount: Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)),
              },
            });

            return null;
          }
        } else {
          Logger.log({
            service: LoggerServices.Moderation,
            message: LoggerMessages.UserForModerationStateIsNull,
            product: realm || '',
            payload: {
              message: LoggerMessages.UserForModerationStateIsNull,
              reason: 'No moderation content',
              operatorId,
              moderatedUsersCount: Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)),
            },
          });

          return null;
        }
      } catch (error: any) {
        if (error?.response?.status !== 404) {
          Logger.log({
            service: LoggerServices.Moderation,
            message: LoggerMessages.GetModerationContentError,
            product: realm || '',
            payload: {
              operatorId,
              moderatedUsersCount: Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)),
              isUndoAction,
              error: error?.response,
            },
          });
        }

        if (error?.response?.status === 404) {
          return {
            status: UserForModerationStatus.NoUndoUsers,
          } as UserForModerationFlure;
        }

        return null;
      }
    },
    [],
    { loading: true },
  );

  return { userForModerationState, fetchUserForModeration };
};
