import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignSelf: 'start',
    justifySelf: 'start',

    padding: '6px 16px',
    border: '1px solid #101014',
    borderRadius: '100px',
    transition: 'background-color 0.3s',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  
  defaultCursor: {
    cursor: 'default',
  },

  active: {
    transition: 'background-color 0.3s',
    backgroundColor: '#F2DAF5',
  },
});
