import { Http } from '../http';
import { NewQuestion, Question, QuestionCategoriesDto, QuestionsDto } from './types';
import { categoriesAdapter, questionAdapter } from './adapter';

export const getQuestionCategories = () => {
  return Http.shared()
    .instance.get<QuestionCategoriesDto>('/magnet/dailybuzz/question-categories')
    .then(categoriesAdapter);
};

export const addCategory = (category: string) => {
  return Http.shared().instance.post<{ id: string }>('/magnet/dailybuzz/question-categories', {
    Category: category,
  });
};

export const getQuestions = (from: Date, to: Date, ids: number[]) => {
  return Http.shared()
    .instance.get<QuestionsDto>('/magnet/dailybuzz/questions', {
      params: {
        from: from.toISOString(),
        to: to.toISOString(),
        Ids: ids,
      },
    })
    .then(questionAdapter);
};

export const addQuestion = ({ question, categoryId, date }: NewQuestion) => {
  return Http.shared().instance.post<{ id: string }>('/magnet/dailybuzz/questions', {
    Question: question,
    CategoryId: categoryId,
    Date: date,
  });
};

export const changeQuestion = ({ id, question, categoryId, date }: Question) => {
  return Http.shared().instance.post('/magnet/dailybuzz/questions', {
    Id: id,
    Question: question,
    CategoryId: categoryId,
    Date: date,
  });
};
