import React from 'react';
import { Helmet } from 'react-helmet';

import { clientConfig } from 'src/config/client';

export const Head = () => {
  return (
    <Helmet>
      <title>UL Backoffice {clientConfig.revision}</title>
    </Helmet>
  );
};
