import React, { KeyboardEvent, memo, useCallback, useMemo } from 'react';
import { Box, Button, ButtonGroup } from '@material-ui/core';
import clsx from 'clsx';
import { ActionType, ModerationAction, UserForModeration } from 'src/types/moderation';
import { Gender, MediaState, MentalLevel } from 'src/types/user';
import { LargeTooltip } from 'src/components/Moderation/views/tooltip';
import { Resources } from 'src/resources';

import { useKeyListener } from './useKeyListener';
import { useProductStyles } from './styles';
import {
  OnlyGoBack,
  OnlyUndo,
  OnlyReject,
  OnlyRejectable,
  OnlyResetAbout,
  OnlyResetQuestions,
  OnlyTrash,
  OnlyTrashable,
} from './types';

type ReactDispatchType<T> = React.Dispatch<React.SetStateAction<T>>;

type Props = {
  isLoading: boolean;
  undoAction: (actionType: ActionType) => () => void;
  resetName: () => void;
  resetOccupation?: () => void;
  isNoUndoUsers: boolean;
  moderationActions: ModerationAction[];
  sendModerationActions: (actions: ModerationAction[]) => Promise<void>;
  moderatedMedia: string;
  isPressed: (actionType: ActionType, actionValue?: string | undefined) => boolean;
  addModerationAction: (actionType: ActionType, actionValue?: string | undefined) => void;
  woman?: () => void;
  man?: () => void;
  setUserMentalLevel?: ReactDispatchType<MentalLevel>;
  setMediaState?: ReactDispatchType<MediaState>;
  adultContentCensorable?: boolean;
  noUserCensorable?: boolean;
  unApprovable?: boolean;
  containerStyle?: string;
  primaryButtonStyle: string;
  warningButtonStyle: string;
  undoBackButtonStyle?: string;
  passActionValueForUnapprove?: boolean;
} & (OnlyGoBack | OnlyUndo) &
  (OnlyRejectable | OnlyTrashable) &
  (OnlyResetAbout | OnlyResetQuestions) &
  Pick<UserForModeration, 'newMediaSource' | 'newMediaType'>;

const CommonActionsFC = (props: Props) => {
  const {
    isLoading,
    undo,
    undoAction,
    goBack,
    resetAbout,
    resetQuestions,
    resetName,
    resetOccupation,
    newMediaSource,
    isNoUndoUsers,
    moderationActions,
    sendModerationActions,
    moderatedMedia,
    isPressed,
    man,
    woman,
    addModerationAction,
    setUserMentalLevel,
    setMediaState,
    rejectable,
    shouldSendOnReject = false,
    trashable,
    adultContentCensorable,
    noUserCensorable,
    unApprovable,
    containerStyle,
    primaryButtonStyle,
    warningButtonStyle,
    undoBackButtonStyle,
    newMediaType,
    passActionValueForUnapprove = false,
  } = props;
  const classes = useProductStyles();

  const setAction = useCallback(
    (actionType: ActionType, actionValue?: string) => () => {
      const newAction: ModerationAction = { actionType };

      if (actionValue) {
        newAction.actionValue = actionValue;
      }
      sendModerationActions([...moderationActions, newAction]);
    },
    [sendModerationActions, moderationActions],
  );

  const reject = useCallback(() => {
    if (shouldSendOnReject) {
      sendModerationActions([...moderationActions, { actionType: ActionType.Reject, actionValue: moderatedMedia }]);
    } else {
      addModerationAction(ActionType.Reject, moderatedMedia);
    }
  }, [shouldSendOnReject, sendModerationActions, moderationActions, moderatedMedia, addModerationAction]);

  const trash = useCallback(() => {
    sendModerationActions([...moderationActions, { actionType: ActionType.Trash, actionValue: moderatedMedia }]);
  }, [sendModerationActions, moderatedMedia, moderationActions]);

  const adultContent = useMemo(
    () =>
      adultContentCensorable
        ? () => {
            sendModerationActions([
              ...moderationActions,
              { actionType: ActionType.AdultContent, actionValue: moderatedMedia },
            ]);
          }
        : undefined,
    [sendModerationActions, moderatedMedia, moderationActions, adultContentCensorable],
  );

  const noUser = useMemo(
    () =>
      noUserCensorable
        ? () => {
            sendModerationActions([
              ...moderationActions,
              { actionType: ActionType.NoUser, actionValue: moderatedMedia },
            ]);
          }
        : undefined,
    [sendModerationActions, moderatedMedia, moderationActions, noUserCensorable],
  );

  const unApprove = useCallback(() => {
    const actionValueParam = passActionValueForUnapprove ? { actionValue: moderatedMedia } : undefined;

    sendModerationActions([...moderationActions, { actionType: ActionType.UnApprove, ...actionValueParam }]);
  }, [moderationActions, sendModerationActions, moderatedMedia, passActionValueForUnapprove]);

  const nextProfile = useCallback(() => {
    sendModerationActions([...moderationActions, { actionType: ActionType.Approve, actionValue: moderatedMedia }]);
  }, [moderatedMedia, moderationActions, sendModerationActions]);

  const orange = useCallback(() => {
    if (setUserMentalLevel) {
      addModerationAction(ActionType.SetColor, MentalLevel.Orange);
      setUserMentalLevel(MentalLevel.Orange);
    }
  }, [addModerationAction, setUserMentalLevel]);

  const purple = useCallback(() => {
    if (setUserMentalLevel) {
      addModerationAction(ActionType.SetColor, MentalLevel.Purple);
      setUserMentalLevel(MentalLevel.Purple);
    }
  }, [addModerationAction, setUserMentalLevel]);

  const setPublic = useCallback(() => {
    if (setMediaState) {
      addModerationAction(ActionType.SetPublicPhoto, newMediaSource);
      setMediaState(MediaState.Public);
    }
  }, [addModerationAction, newMediaSource, setMediaState]);

  const setPrivate = useCallback(() => {
    if (setMediaState) {
      addModerationAction(ActionType.SetPrivatePhoto, newMediaSource);
      setMediaState(MediaState.Private);
    }
  }, [addModerationAction, newMediaSource, setMediaState]);

  const getGoBackOrUndo = useCallback(() => {
    return (goBack ? { goBack } : undo && { undo }) as OnlyGoBack | OnlyUndo;
  }, [goBack, undo]);

  const getRejectableOrTrashable = useCallback(() => {
    return (rejectable ? { reject } : trashable && { trash }) as OnlyReject | OnlyTrash;
  }, [reject, rejectable, trash, trashable]);

  const getResetProps = useCallback(() => {
    return (resetAbout ? { resetAbout } : resetQuestions && { resetQuestions }) as OnlyResetAbout | OnlyResetQuestions;
  }, [resetAbout, resetQuestions]);

  useKeyListener({
    undoAction,
    resetName,
    resetOccupation,
    setAction,
    nextProfile,
    man,
    woman,
    setPublic,
    setPrivate,
    unApprove,
    isPressed,
    newMediaSource,
    isLoading,
    isNoUndoUsers,
    adultContent,
    noUser,
    orange,
    purple,
    ...getGoBackOrUndo(),
    ...getRejectableOrTrashable(),
    ...getResetProps(),
  });

  return (
    <Box className={clsx(classes.container, containerStyle)}>
      {goBack && (
        <Button
          variant="contained"
          className={clsx(classes.moderationButton, primaryButtonStyle, undoBackButtonStyle)}
          onClick={goBack}
          disabled={isLoading || isNoUndoUsers}
          onKeyUp={(event) => event.preventDefault()}
        >
          {Resources.strings.moderation.back}
          <br />
          (←/Z)
        </Button>
      )}
      {undo && (
        <Button
          variant="contained"
          className={clsx(classes.moderationButton, primaryButtonStyle, undoBackButtonStyle)}
          onClick={undo}
          disabled={isLoading || isNoUndoUsers}
          onKeyUp={(event) => event.preventDefault()}
        >
          {Resources.strings.moderation.undo}
          <br />
          (←/Z)
        </Button>
      )}
      <LargeTooltip title={Resources.strings.moderation.scamTooltip} arrow>
        <Button
          variant="contained"
          className={clsx(classes.moderationButton, warningButtonStyle)}
          onClick={setAction(ActionType.Scam)}
          disabled={isLoading || isNoUndoUsers}
          component="span"
          onKeyUp={(event: KeyboardEvent<HTMLButtonElement>) => event.preventDefault()}
        >
          Scam
          <br />
          (1)
        </Button>
      </LargeTooltip>
      {rejectable && (
        <Button
          variant="contained"
          className={clsx(classes.moderationButton, warningButtonStyle)}
          onClick={reject}
          disabled={isLoading || isNoUndoUsers || !newMediaSource || !newMediaType || isPressed(ActionType.Reject)}
          onKeyUp={(event) => event.preventDefault()}
        >
          Reject Photo (2)
        </Button>
      )}
      {trashable && (
        <LargeTooltip title={Resources.strings.moderation.trashTooltip} arrow>
          <Button
            variant="contained"
            className={clsx(classes.moderationButton, warningButtonStyle)}
            onClick={trash}
            disabled={isLoading || isNoUndoUsers}
            component="span"
            onKeyUp={(event: KeyboardEvent<HTMLButtonElement>) => event.preventDefault()}
          >
            Trash
            <br />
            (2)
          </Button>
        </LargeTooltip>
      )}
      {adultContentCensorable && (
        <LargeTooltip title={Resources.strings.moderation.eighteenPlusTooltip} arrow>
          <Button
            variant="contained"
            className={clsx(classes.moderationButton, warningButtonStyle)}
            onClick={adultContent}
            disabled={isLoading || isNoUndoUsers}
            component="span"
            onKeyUp={(event: KeyboardEvent<HTMLButtonElement>) => event.preventDefault()}
          >
            18+
            <br />
            (3)
          </Button>
        </LargeTooltip>
      )}
      {noUserCensorable && (
        <LargeTooltip title={Resources.strings.moderation.noUserTooltip} arrow>
          <Button
            variant="contained"
            className={clsx(classes.moderationButton, warningButtonStyle)}
            onClick={noUser}
            disabled={isLoading || isNoUndoUsers}
            component="span"
            onKeyUp={(event: KeyboardEvent<HTMLButtonElement>) => event.preventDefault()}
          >
            No user
            <br />
            (4)
          </Button>
        </LargeTooltip>
      )}
      {unApprovable && (
        <Button
          variant="contained"
          className={clsx(classes.moderationButton, classes.lightOrangeButton, classes.nextButton)}
          onClick={unApprove}
          disabled={isLoading}
          onKeyUp={(event) => event.preventDefault()}
        >
          OK for anim/Next
          <br />
          (D)
        </Button>
      )}
      <Button
        variant="contained"
        className={clsx(classes.moderationButton, primaryButtonStyle, classes.nextButton)}
        onClick={nextProfile}
        disabled={isLoading}
        onKeyUp={(event) => event.preventDefault()}
      >
        {goBack ? Resources.strings.moderation.approveAndGoBack : Resources.strings.moderation.approveAndGoNext}
        <br />
        (Space)
      </Button>
      {man && woman && (
        <ButtonGroup>
          <Button
            variant="contained"
            className={clsx(
              classes.moderationButton,
              isPressed(ActionType.SetGender, Gender.Male) ? classes.manPressed : classes.manUnpressed,
            )}
            onClick={isPressed(ActionType.SetGender, Gender.Male) ? undefined : man}
            disabled={isLoading || isNoUndoUsers}
            onKeyUp={(event) => event.preventDefault()}
          >
            Man
            <br />
            (8)
          </Button>
          <Button
            variant="contained"
            className={clsx(
              classes.moderationButton,
              isPressed(ActionType.SetGender, Gender.Female) ? classes.womanPressed : classes.womanUnpressed,
            )}
            onClick={isPressed(ActionType.SetGender, Gender.Female) ? undefined : woman}
            disabled={isLoading || isNoUndoUsers}
            onKeyUp={(event) => event.preventDefault()}
          >
            Woman
            <br />
            (9)
          </Button>
        </ButtonGroup>
      )}
      {setUserMentalLevel && (
        <ButtonGroup>
          <Button
            variant="contained"
            className={clsx(
              classes.moderationButton,
              isPressed(ActionType.SetColor, MentalLevel.Orange) ? classes.pressed : classes.unpressed,
            )}
            onClick={isPressed(ActionType.SetColor, MentalLevel.Orange) ? undefined : orange}
            disabled={isLoading || isNoUndoUsers}
            onKeyUp={(event) => event.preventDefault()}
          >
            Orange
            <br />
            (0)
          </Button>
          <Button
            variant="contained"
            className={clsx(
              classes.moderationButton,
              isPressed(ActionType.SetColor, MentalLevel.Purple) ? classes.pressed : classes.unpressed,
            )}
            onClick={isPressed(ActionType.SetColor, MentalLevel.Purple) ? undefined : purple}
            disabled={isLoading || isNoUndoUsers}
            onKeyUp={(event) => event.preventDefault()}
          >
            Purple
            <br />
            (-)
          </Button>
        </ButtonGroup>
      )}
      {setMediaState && (
        <ButtonGroup>
          <Button
            variant="contained"
            className={clsx(
              classes.moderationButton,
              isPressed(ActionType.SetPublicPhoto, MediaState.Public) ? classes.pressed : classes.unpressed,
            )}
            onClick={isPressed(ActionType.SetPublicPhoto, MediaState.Public) ? undefined : setPublic}
            disabled={isLoading || isNoUndoUsers}
            onKeyUp={(event) => event.preventDefault()}
          >
            Public
            <br />
            (0)
          </Button>
          <Button
            variant="contained"
            className={clsx(
              classes.moderationButton,
              isPressed(ActionType.SetPrivatePhoto, MediaState.Private) ? classes.pressed : classes.unpressed,
            )}
            onClick={isPressed(ActionType.SetPrivatePhoto, MediaState.Private) ? undefined : setPrivate}
            disabled={isLoading || isNoUndoUsers}
            onKeyUp={(event) => event.preventDefault()}
          >
            Private
            <br />
            (-)
          </Button>
        </ButtonGroup>
      )}
    </Box>
  );
};

export const CommonActions = memo(CommonActionsFC);
