import React from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';

import { RAW } from 'src/types/raw';

import { useStyles } from './styles';

type Props = {
  contentCount?: RAW.ModerationContentCount;
  moderatedUsersCount?: number;
  setContentType: (val: RAW.ContentType, forceUpdateByUserClick?: boolean) => void;
  contentType?: RAW.ContentType;
  isQueueBlocked: boolean;
  setQueueBlock: (val: boolean) => void;
};

export const ModerationHeader = (props: Props) => {
  const { contentCount, moderatedUsersCount, setContentType, contentType, isQueueBlocked, setQueueBlock } = props;
  const classes = useStyles();

  return (
    <Box className={classes.topContainer}>
      <Box className={classes.progressContainer}>
        {contentCount !== undefined && (
          <>
            <Typography
              className={clsx(
                classes.progressText,
                contentType === RAW.ContentType.RAW ? classes.activeText : undefined,
              )}
              onClick={() => {
                setContentType(RAW.ContentType.RAW, true);
              }}
            >
              🌄 RAW queue: <b>{contentCount.rawPhotoCount}</b>
            </Typography>
            <Typography
              className={clsx(
                classes.progressText,
                contentType === RAW.ContentType.Default ? classes.activeText : undefined,
              )}
              onClick={() => {
                setContentType(RAW.ContentType.Default, true);
              }}
            >
              😎 Profile queue: <b>{contentCount.profilePhotoCount}</b>
            </Typography>
          </>
        )}
        {moderatedUsersCount !== undefined && (
          <Typography className={classes.progressText}>
            ✅ You&apos;ve done <b>{moderatedUsersCount}</b>
          </Typography>
        )}
        <label htmlFor="queue">
          <Typography className={classes.checkboxWrapper}>
            <input
              id="queue"
              type="checkbox"
              checked={!isQueueBlocked}
              onChange={(val) => setQueueBlock(!val.target.checked)}
              className={classes.checkbox}
            />
            🔄
          </Typography>
        </label>
      </Box>
    </Box>
  );
};
