import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },

  tagButton: {
    width: '50%',
    height: '22px',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',

    color: 'black',
    fontFamily: 'Poppins',
    fontSize: '9px',
    lineHeight: '14px',
    backgroundColor: 'rgba(247, 247, 247, 1)',

    '&:hover': {
      opacity: 0.8,
    },
  },

  leftTagButton: {
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px',
  },

  rightTagButton: {
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px',
  },

  activeThumbnail: {
    color: 'white',
    backgroundColor: 'black',
  },

  activeSpicy: {
    color: 'black',
    backgroundColor: '#FBA704',
  },
});
