import { clientConfig } from 'src/config/client';

declare global {
  interface Window {
    gmapsready?: () => void;
  }
}
type LocationResponse = { lat: number; lng: number } | { error: string };

const serviceUrl = `https://maps.googleapis.com/maps/api/js?v=3&callback=gmapsready&libraries=places&language=en&key=${clientConfig['google-maps-key']}`;
let geocoder: google.maps.Geocoder;
let loadedResolver: (value: void | PromiseLike<void>) => void | undefined;
let loaded: Promise<void> | undefined;

const getLoadedState = () => {
  if (!loaded) {
    loaded = new Promise<void>((resolve) => {
      loadedResolver = resolve;
    });
    init();
  }

  return loaded;
};

const init = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = serviceUrl;

  document.head.appendChild(script);

  window.gmapsready = function gmapsready() {
    if (loadedResolver) {
      loadedResolver();
    }

    try {
      delete window.gmapsready;
    } catch (e) {
      window.gmapsready = undefined;
    }
  };
};

export function locationHasError(location: LocationResponse): location is { error: string } {
  return (location as { error: string }).error !== undefined;
}

export const getLocation = async ({ city, country }: { city: string; country: string }): Promise<LocationResponse> => {
  await getLoadedState();

  if (!google) {
    return Promise.resolve({ error: 'UNKNOWN_ERROR' });
  }

  if (!geocoder) {
    geocoder = new google.maps.Geocoder();
  }

  return new Promise((resolve) => {
    geocoder.geocode({ address: `${city}, ${country}` }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results) {
        resolve({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      } else {
        resolve({ error: status });
      }
    });
  });
};
