import React, { useMemo } from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

export enum TitleTextSize {
  Small = 'small',
  Large = 'large',
  Medium = 'medium',
}

type Props = {
  text: string;
  size?: TitleTextSize;
  className?: string;
  isHTML?: boolean;
};

const TitleTextView: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { text, size = TitleTextSize.Medium, className: classNameFromProps, isHTML, } = props;

  const textClassName = useMemo(() => {
    const textStyles = [classes.textCommon];

    if (size === TitleTextSize.Small) {
      textStyles.push(classes.smallText);
    }

    if (size === TitleTextSize.Medium) {
      textStyles.push(classes.mediumText);
    }

    if (size === TitleTextSize.Large) {
      textStyles.push(classes.largeText);
    }

    return clsx(...textStyles, classNameFromProps);
  }, [classNameFromProps, classes.largeText, classes.mediumText, classes.smallText, classes.textCommon, size]);

  return isHTML ? (
    // eslint-disable-next-line react/no-danger
    <span className={textClassName} dangerouslySetInnerHTML={{ __html: text }} />
  ) : (
    <span className={textClassName}>{text}</span>
  );
};

export const TitleText = React.memo(TitleTextView);
