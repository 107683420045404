import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  logo: {
    height: '30px',
    width: 'auto',
    display: 'block',
    marginRight: 8,
  },
});
