import React, { useMemo } from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

export enum LabelTextSize {
  Small = 'small',
  Large = 'large',
  Medium = 'medium',
}

export enum LabelTextType {
  Default = 'default',
  Info = 'info',
}

type Props = {
  text: string;
  size?: LabelTextSize;
  type?: LabelTextType;
  className?: string;
  isHTML?: boolean;
};

const LabelTextView: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    text,
    size = LabelTextSize.Medium,
    type = LabelTextType.Default,
    className: classNameFromProps,
    isHTML,
  } = props;

  const textClassName = useMemo(() => {
    const textStyles = [classes.textCommon];

    if (size === LabelTextSize.Small) {
      textStyles.push(classes.smallText);
    }

    if (size === LabelTextSize.Medium) {
      textStyles.push(classes.mediumText);
    }

    if (size === LabelTextSize.Large) {
      textStyles.push(classes.largeText);
    }

    if (type === LabelTextType.Info) {
      textStyles.push(classes.infoText);
    }

    return clsx(...textStyles, classNameFromProps);
  }, [
    classNameFromProps,
    classes.infoText,
    classes.largeText,
    classes.mediumText,
    classes.smallText,
    classes.textCommon,
    size,
    type,
  ]);

  return isHTML ? (
    // eslint-disable-next-line react/no-danger
    <span className={textClassName} dangerouslySetInnerHTML={{ __html: text }} />
  ) : (
    <span className={textClassName}>{text}</span>
  );
};

export const LabelText = React.memo(LabelTextView);
