import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import qs from 'qs';

import { useUserModerationHistory } from 'src/services/moderation/hooks';
import { ModerationHistoryAction } from 'src/network/moderation/types';
import { ActionType, BenefitType, BenefitsActionValue } from 'src/types/moderation';
import { LabelText } from 'src/components/common/flure';
import { Resources } from 'src/resources';

import { useStyles } from './styles';

type Props = {
  userId: string;
};

const getParsedActionValue = (actionValue: string) => {
  let parsedValue: string | BenefitsActionValue = '';

  try {
    parsedValue = JSON.parse(actionValue) as BenefitsActionValue;
  } catch {
    parsedValue = actionValue;
  }

  return parsedValue;
};

const formatHistoryActionType = (actionType: string, parsedValue: string | BenefitsActionValue) => {
  let moderationText;

  if (actionType === ActionType.Benefits) {
    if (typeof parsedValue === 'object' && (parsedValue[BenefitType.Subscription] || parsedValue[BenefitType.Vibes])) {
      const subscription = parsedValue[BenefitType.Subscription];
      const subscriptionDays =
        subscription && typeof subscription === 'string' ? Number(subscription.split(':')[0]) : undefined;
      const vibes = parsedValue[BenefitType.Vibes];

      moderationText =
        subscriptionDays && subscriptionDays > 0
          ? `${subscriptionDays} ${subscriptionDays === 1 ? 'day' : 'days'} Premium`
          : `${vibes} ${vibes === 1 ? 'Vibe' : 'Vibes'} `;
    } else {
      moderationText = actionType;
    }
  } else {
    moderationText = actionType;
  }

  return moderationText;
};

export const BenefitsHistory = React.memo(({ userId }: Props) => {
  const classes = useStyles();
  const { moderationHistoryState, fetchModerationHistory } = useUserModerationHistory();

  useEffect(() => {
    const query = qs.stringify({ includeTypes: [ActionType.Benefits] }, { arrayFormat: 'repeat' });
    fetchModerationHistory(userId, query);
  }, [fetchModerationHistory, userId]);

  const renderModerationHistory = useCallback(() => {
    const { value, loading: isLoadingHistory } = moderationHistoryState;
    const { actions } = value || {};

    return (
      <Box className={classes.historyContainer}>
        <LabelText className={classes.historyTitle} text={Resources.strings.moderation.benefitsHistory} />
        <div>
          {isLoadingHistory && <CircularProgress />}
          {!isLoadingHistory &&
            actions?.map((action: ModerationHistoryAction) => {
              const { actionType, actionValue, createdAt } = action;
              const parsedValue = getParsedActionValue(actionValue);
              const moderationText = formatHistoryActionType(actionType, parsedValue);
              const statusIcon = typeof parsedValue === 'object' && parsedValue[BenefitType.Subscription] ? '🚀' : '🖤';

              return (
                <Typography className={classes.historyItem} key={createdAt}>
                  {statusIcon}&nbsp;&nbsp;{moment(createdAt).format('MMM DD, YYYY • HH:mm')}&nbsp;&nbsp;&nbsp;&nbsp;
                  {moderationText}
                </Typography>
              );
            })}
        </div>
      </Box>
    );
  }, [classes.historyContainer, classes.historyItem, classes.historyTitle, moderationHistoryState]);

  return renderModerationHistory();
});
