import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';

import { Media, MediaTag, MediaType } from 'src/types/user';
import {
  PhotoTransformation,
  flureModerationPhotoSize,
  useGetMediaSourceAuthed,
  useGetMediaSourceAuthedFullSize,
} from 'src/components/Moderation/utils/image-source';
import { ZoomImage } from 'src/components/Verification/views/ZoomImage';
import { Resources } from 'src/resources';

import { useStyles } from './styles';

type UserMediaItem = { baseName: string; mediaSource: string; mediaSourceFullSize: string; isMain: boolean };

type Props = {
  userId: string;
  media: Media[];
};

export const UserMedia: React.FC<Props> = ({ userId, media = [] }) => {
  const classes = useStyles();
  const getMediaSource = useGetMediaSourceAuthed();
  const getMediaSourceFullSize = useGetMediaSourceAuthedFullSize();

  const preparedMedia = useMemo(() => {
    return media
      .reduce<UserMediaItem[]>((acc, it) => {
        const isMain = it.tags.includes(MediaTag.Thumbnail);

        if (isMain) {
          acc.unshift({
            baseName: it.baseName,
            mediaSource: getMediaSource(
              userId,
              it.baseName,
              it.mediaType,
              true,
              flureModerationPhotoSize,
              PhotoTransformation.KeepAspectRatio,
            ),
            mediaSourceFullSize: getMediaSourceFullSize(userId, it.baseName, it.mediaType, true),
            isMain,
          });
        } else {
          acc.push({
            baseName: it.baseName,
            mediaSource: getMediaSource(
              userId,
              it.baseName,
              it.mediaType,
              true,
              flureModerationPhotoSize,
              PhotoTransformation.KeepAspectRatio,
            ),
            mediaSourceFullSize: getMediaSourceFullSize(userId, it.baseName, it.mediaType, true),
            isMain,
          });
        }

        return acc;
      }, [])
      .slice(0, 6);
  }, [getMediaSource, getMediaSourceFullSize, media, userId]);

  const renderMediaItem = useCallback(
    (mediaItem: UserMediaItem) => {
      const { isMain, mediaSource, mediaSourceFullSize, baseName } = mediaItem;

      return (
        <div key={baseName} className={classes.photoContainer}>
          <ZoomImage src={mediaSourceFullSize} mediaType={MediaType.Photo}>
            <img src={mediaSource} className={clsx(classes.photo, isMain && classes.mainPhotoBorder)} alt={baseName} />
          </ZoomImage>
          {isMain && <span className={classes.mediaName}>{Resources.strings.moderation.media.thumbnail}</span>}
        </div>
      );
    },
    [classes.photoContainer, classes.photo, classes.mainPhotoBorder, classes.mediaName],
  );

  return <>{preparedMedia.map((it) => renderMediaItem(it))}</>;
};
