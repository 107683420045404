import React, { memo, useCallback, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { Controller, useForm } from 'react-hook-form';
import { RangeDatePicker } from 'src/components/common/RangeDatePicker';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import { DefaultReportFormFields } from 'src/types/report/common';
import { CommissionsReportFormFields } from 'src/types/report/commissions';
import { MessageStatus, MessagesReportFormFields } from 'src/types/report/messages';

import validationSchema from './schema';
import { SelectWrapper } from './styled';
import { useAvailableAgencies, useAvailableSearchModes } from './hooks';
import { languageDefaultValue, languageItems, statusAllItem, statusItems } from './constants';
import styles from './index.module.css';

type FormGenericProps<TFormType, TValues> = {
  formType: TFormType;
  onSubmit: (values: TValues) => void;
  initialValues?: Partial<TValues>;
  loading?: boolean;
};

type Props =
  | FormGenericProps<'default', DefaultReportFormFields>
  | FormGenericProps<'commissions', CommissionsReportFormFields>
  | FormGenericProps<'messages', MessagesReportFormFields>;

type ReportFormFields = DefaultReportFormFields & CommissionsReportFormFields & MessagesReportFormFields;

const languageMenuElements = languageItems.map(({ value, title }) => (
  <MenuItem key={value} value={value}>
    {title}
  </MenuItem>
));

const statusMenuElements = statusItems.map((value) => (
  <MenuItem key={value} value={value} className={styles.statusSelectValue}>
    {value}
  </MenuItem>
));

const renderSelectedStatusValue = (value: MessageStatus) => {
  return <span className={styles.statusSelectValue}>{value === statusAllItem ? `${value} status` : value}</span>;
};

export const ReportForm = memo((props: Props) => {
  const { onSubmit, formType, initialValues, loading } = props;

  const correctAgencyList = useAvailableAgencies();
  const availableSearchModes = useAvailableSearchModes();

  const { control, handleSubmit, errors, watch, formState } = useForm<ReportFormFields>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const searchModeItems = useMemo(
    () =>
      availableSearchModes.map((mode) => (
        <MenuItem key={mode.value} value={mode.value}>
          {mode.title}
        </MenuItem>
      )),
    [availableSearchModes],
  );

  const handleSelectChange = useCallback(
    <T extends string = string>(saver: (value: T) => void) => (e: React.ChangeEvent<{ name?: string; value: T }>) => {
      saver(e.target.value);
    },
    [],
  );

  const agencyItems = useMemo(
    () =>
      correctAgencyList.map((agency) => (
        <MenuItem key={agency.techName} value={agency.techName}>
          {agency.name}
        </MenuItem>
      )),
    [correctAgencyList],
  );

  const submitDisabled = !formState.isValid;

  const currentSearchMode = watch('mode');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Controller
            name="rangeDate"
            control={control}
            render={({ onChange, value }) => <RangeDatePicker onChange={onChange} value={value} />}
            defaultValue=":"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="mode"
            control={control}
            defaultValue=""
            render={({ value, onChange }) => (
              <SelectWrapper>
                <FormControl fullWidth variant="outlined" error={!!errors.mode}>
                  <Select displayEmpty value={value} onChange={handleSelectChange(onChange)}>
                    <MenuItem value="" key="0" disabled>
                      Select search mode
                    </MenuItem>
                    {searchModeItems}
                  </Select>
                  {!!errors.mode && <FormHelperText>{errors.mode?.message}</FormHelperText>}
                </FormControl>
              </SelectWrapper>
            )}
          />
          {currentSearchMode === 'agency' && (
            <Controller
              name="agencyTechName"
              control={control}
              defaultValue=""
              render={({ value, onChange }) => (
                <SelectWrapper>
                  <FormControl fullWidth variant="outlined" error={!!errors.agencyTechName}>
                    <Select displayEmpty value={value} onChange={handleSelectChange(onChange)}>
                      <MenuItem value="" key="0" disabled>
                        Select agency
                      </MenuItem>
                      {agencyItems}
                    </Select>
                    {!!errors.agencyTechName && <FormHelperText>{errors.agencyTechName?.message}</FormHelperText>}
                  </FormControl>
                </SelectWrapper>
              )}
            />
          )}
          {currentSearchMode === 'operator' ? (
            <Controller
              key="operatorId"
              as={TextField}
              name="operatorId"
              control={control}
              label="Operator Id"
              error={!!errors.operatorId}
              helperText={errors.operatorId?.message}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          ) : (
            <Controller
              key="language"
              name="language"
              control={control}
              defaultValue={languageDefaultValue}
              render={({ value, onChange }) => (
                <SelectWrapper>
                  <FormControl fullWidth variant="outlined" error={!!errors.language}>
                    <Select displayEmpty value={value} onChange={handleSelectChange(onChange)}>
                      <MenuItem value="" key="0" disabled>
                        Select language
                      </MenuItem>
                      {languageMenuElements}
                    </Select>
                    {!!errors.language && <FormHelperText>{errors.language?.message}</FormHelperText>}
                  </FormControl>
                </SelectWrapper>
              )}
            />
          )}
          {formType === 'messages' && (
            <Controller
              name="status"
              control={control}
              defaultValue={statusAllItem}
              render={({ value, onChange }) => (
                <SelectWrapper>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      displayEmpty
                      // @ts-expect-error for TS the value is unknown but we know the exact value
                      renderValue={renderSelectedStatusValue}
                      value={value}
                      onChange={handleSelectChange(onChange)}
                    >
                      <MenuItem value="" key="0" disabled>
                        Select Status
                      </MenuItem>
                      {statusMenuElements}
                    </Select>
                  </FormControl>
                </SelectWrapper>
              )}
            />
          )}
          {formType === 'commissions' && (
            <Controller
              name="groupBy"
              control={control}
              render={({ value, onChange }) => (
                <FormControlLabel
                  control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked ? 'day' : null)} />}
                  label="Split by days"
                />
              )}
            />
          )}
        </Grid>
      </Grid>
      {loading ? (
        <CircularProgress size="2rem" />
      ) : (
        <Button type="submit" variant="contained" color="primary" disabled={submitDisabled}>
          Get report
        </Button>
      )}
    </form>
  );
});
