import { MediaTag, MediaType, User, UserDto } from 'src/types/user';
import { getMediaSourceFullSize } from 'src/components/Moderation/utils/image-source';
import { UserPreferences } from 'src/types/user/preferences';
import { getUserAge } from 'src/utils/transform';

export const flureUserAdapter = (apiUser: UserDto, token?: string) => {
  const { id, name, tags, media, gender, birthday, preferences } = apiUser;

  const thumbnailMedia = media.find((el) => el.tags.includes(MediaTag.Thumbnail));
  const thumbnail = thumbnailMedia?.baseName;
  const thumbnailType = thumbnailMedia?.mediaType.startsWith('video') ? MediaType.Video : MediaType.Photo;
  const thumbnailSource = thumbnailMedia
    ? getMediaSourceFullSize(id, thumbnailMedia.baseName, thumbnailMedia.mediaType, token)
    : '';

  const desiredRelationship = preferences ? preferences[UserPreferences.Relationship] || '' : '';
  const genderIdentity = preferences ? preferences[UserPreferences.GenderIdentity] : undefined;

  const user: User = {
    id,
    name: name?.trim() || '',
    about: desiredRelationship,
    genderIdentity,
    gender,
    tags,
    media,
    thumbnail,
    thumbnailType,
    thumbnailSource,
    birthday,
    age: getUserAge(birthday || ''),
  };

  return user;
};
