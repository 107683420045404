import { MediaTag, MediaType, User, UserDto } from 'src/types/user';
import { getMediaSource } from 'src/components/Moderation/utils/image-source';

export const xoxoUserAdapter = (apiUser: UserDto) => {
  const { id, name, about, tags, mentalAge, media, gender, birthday } = apiUser;

  const thumbnailMedia = media.find((item) => item.tags?.includes(MediaTag.Thumbnail)) || media?.[0];
  const thumbnail = thumbnailMedia?.baseName;
  const thumbnailType = thumbnailMedia?.mediaType?.startsWith('image') ? MediaType.Photo : MediaType.Video;

  const thumbnailSource = thumbnailMedia ? getMediaSource(id, thumbnail, thumbnailMedia?.mediaType) : '';

  const user: User = {
    id,
    name: name?.trim() || '',
    about,
    gender,
    tags,
    mentalAge,
    media,
    thumbnail,
    thumbnailType,
    thumbnailSource,
    birthday,
  };

  return user;
};
