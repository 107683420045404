import { makeStyles } from '@material-ui/core';

import { RAW_PHOTO_HEIGHT, RAW_PHOTO_WIDTH } from '../raw-photo/styles';

export const PROFILE_PHOTO_SIZE = 190;

export const useStyles = makeStyles({
  profileImage: {
    position: 'relative',
    width: PROFILE_PHOTO_SIZE,
    height: PROFILE_PHOTO_SIZE,
    objectFit: 'cover',
    padding: 0,
    backgroundColor: '#e6e6e6'
  },

  rawImage: {
    position: 'relative',
    width: RAW_PHOTO_WIDTH,
    height: RAW_PHOTO_HEIGHT,
    objectFit: 'cover',
    padding: 0,
    backgroundColor: '#e6e6e6'
  },
});
