import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const CardContainer = styled.div`
  box-shadow: 0 8px 10px -12px rgba(0, 0, 0, 0.3);
  outline: 1px solid rgba(0, 0, 0, 0.12);
  &:hover {
    box-shadow: 0 16px 25px -12.125px rgba(0, 0, 0, 0.3);
  }
  transition: box-shadow 0.6s;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const LoaderContainer = styled.div`
  margin-left: 20px;
  display: flex;
`;

export const CircularProgressStyled = styled(CircularProgress)`
  margin-left: 10px;
`;

export const ErrorContainer = styled.div`
  position: fixed;
  min-width: 250px;
  max-width: 500px;
  right: 10px;
  top: 80px;
`;
