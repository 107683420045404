import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  chip: {
    height: '48px',
    borderRadius: '24px',
    marginInline: '6px',
    paddingLeft: '6px',
    paddingRight: '6px',
    fontSize: '20px',
    color: '#FFFFFF',
    backgroundColor: '#FF904F',
    '&:hover': {
      backgroundColor: '#FF904F',
      opacity: 0.8,
    },
  },

  label: {
    fontWeight: 700,
    paddingLeft: '6px',
    paddingRight: '6px',
  },

  deleteIcon: {
    color: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF',
      opacity: 0.8,
    },
  },
});
