import React from 'react';

import { RAW } from 'src/types/raw';

import { ImageWithLoggerWithRef } from '../image-with-logger';
import { FullSizePhotoModal } from './modal';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  sources: string[];
  mediaType: RAW.MediaType;
};

export const FullSizeMedia = ({ isOpen, setIsOpen, sources, mediaType }: Props) => (
  <FullSizePhotoModal setIsOpen={setIsOpen} isOpen={isOpen}>
    <div style={{ display: 'flex', gap: 20 }}>
      {sources.map((source) => (
        <React.Fragment key={source}>
          {mediaType === RAW.MediaType.Video ? <></> : <ImageWithLoggerWithRef isFullSize src={source} />}
        </React.Fragment>
      ))}
    </div>
  </FullSizePhotoModal>
);
