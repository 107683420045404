import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { rawModerationRequest } from 'src/network/moderation/raw';
import { RAW } from 'src/types/raw';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';
import { Logger, LoggerMessages, LoggerServices } from 'src/infrastructure/loggers/datadog';
import { Product } from 'src/types/product';

type Props = {
  contentType?: RAW.ContentType;
  startTime: React.MutableRefObject<number>;
  operatorId: string | undefined;
  contentId?: number;
  isNoUndoUsers?: boolean;
  sendModerationActionsSuccessFlow?: (isRevert: boolean) => Promise<void> | void;
  updatePageWithNewUser?: () => Promise<void>;
  setIsSending: (val: boolean) => void;
  userId?: string;
  moderatedUsersCount?: number;
};

export const MaxOnScreenPresenceTime = 2 * 60 * 1000; // 2 minutes

export const useSendModerationActions = ({
  contentType,
  startTime,
  operatorId,
  contentId,
  isNoUndoUsers,
  sendModerationActionsSuccessFlow,
  updatePageWithNewUser,
  setIsSending,
  userId,
  moderatedUsersCount,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const sendModerationActions = useCallback(
    async (actions: RAW.ModerationAction[]) => {
      const presenceOnScreen = Date.now() - startTime.current;

      const formattedActions = actions.map((action) => {
        if (action.actionType === RAW.Action.Approve) {
          return {
            actionType:
              contentType === RAW.ContentType.Default
                ? RAW.BackAction.approveProfilePhoto
                : RAW.BackAction.approveRawPhoto,
            actionValue: action.actionValue,
          };
        }

        if (action.actionType === RAW.Action.Reject) {
          return {
            actionType:
              contentType === RAW.ContentType.Default
                ? RAW.BackAction.rejectProfilePhoto
                : RAW.BackAction.rejectRawPhoto,
            actionValue: action.actionValue,
            reason: action.reason,
          };
        }

        if (action.actionType === RAW.Action.ScamUser) {
          return {
            actionType:RAW.BackAction.scamUser,
            actionValue: action.actionValue,
            reason: action.reason,
          };
        }

        return action;
      });

      if (presenceOnScreen >= MaxOnScreenPresenceTime) {
        enqueueSnackbar('The wait was too long, please reload your screen', { variant: 'error' });
        return;
      }

      if (!operatorId) {
        enqueueSnackbar('Cannot get your user id. Try to reload or re-signin', { variant: 'error' });
        return;
      }

      if (!isNoUndoUsers && actions.length > 0) {
        const handleSendActions = async () => {
          setIsSending(true);

          try {
            let response;
            if (contentId) {
              response = await rawModerationRequest.sendRAWModerationActions(operatorId, contentId, formattedActions);
            } else if (userId) {
              response = await rawModerationRequest.sendRAWModerationActionsForUser(
                operatorId,
                userId,
                formattedActions,
              );
            }

            ActionsLogger.log(LoggerMessages.SendUserModerationActions, {
              actions: formattedActions,
              product: Product.RAW,
              operatorId,
              contentId,
              userId,
              response,
            });
          } catch (error: any) {
            Logger.log({
              service: LoggerServices.Moderation,
              message: LoggerMessages.SendModerationActionsError,
              product: Product.RAW,
              payload: {
                contentId,
                operatorId,
                moderatedUsersCount,
                error: error?.response,
              },
            });
          } finally {
            if (sendModerationActionsSuccessFlow) {
              await sendModerationActionsSuccessFlow(false);
            }
            setIsSending(false);
          }
        };

        handleSendActions();
      } else {
        if (updatePageWithNewUser) {
          await updatePageWithNewUser();
        }
        setIsSending(false);
      }
    },
    [
      startTime,
      operatorId,
      contentId,
      isNoUndoUsers,
      contentType,
      enqueueSnackbar,
      sendModerationActionsSuccessFlow,
      updatePageWithNewUser,
      setIsSending,
      userId,
      moderatedUsersCount,
    ],
  );

  return sendModerationActions;
};
