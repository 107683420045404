import { Product } from 'src/types/product';
import { BonusesReportDataItem } from 'src/types/report/bonuses';

type ColDef = {
  headerName: string;
  field: string;
  width: number;
  sortable: boolean;
};

type ColumnsDefinitionType = {
  agencyName: ColDef;
  allReplies: ColDef;
  bonusCount: ColDef;
  bonusType: ColDef;
  bonusValue: ColDef;
  agencyBonusValue: ColDef;
  operatorBonusValue: ColDef;
  operatorId: ColDef;
  operatorName: ColDef;
  prettyDateTime: ColDef;
  replyRate: ColDef;
  weekendReplies: ColDef;
};

const columns: ColumnsDefinitionType = {
  operatorId: {
    field: 'operatorId',
    headerName: 'Operator',
    width: 125,
    sortable: false,
  },
  operatorName: {
    field: 'operatorName',
    headerName: 'Operator name',
    width: 170,
    sortable: false,
  },
  agencyName: {
    field: 'agencyName',
    headerName: 'Agency name',
    width: 170,
    sortable: false,
  },
  bonusValue: {
    field: 'bonusValue',
    headerName: 'Bonus Value $',
    width: 115,
    sortable: false,
  },
  operatorBonusValue: {
    field: 'bonusValue',
    headerName: 'Operator Bonus Value $',
    width: 170,
    sortable: false,
  },
  agencyBonusValue: {
    field: 'agencyBonusValue',
    headerName: 'Agency Bonus Value $',
    width: 160,
    sortable: false,
  },
  bonusType: {
    field: 'bonusType',
    headerName: 'Bonus Type',
    width: 160,
    sortable: false,
  },
  bonusCount: {
    field: 'bonusCount',
    headerName: 'Bonus Count',
    width: 115,
    sortable: false,
  },
  prettyDateTime: {
    field: 'prettyDateTime',
    headerName: 'Date',
    width: 135,
    sortable: false,
  },
  allReplies: {
    field: 'allReplies',
    headerName: 'All Replies',
    width: 90,
    sortable: false,
  },
  weekendReplies: {
    field: 'weekendReplies',
    headerName: 'Weekend Replies',
    width: 130,
    sortable: false,
  },
  replyRate: {
    field: 'replyRate',
    headerName: 'Reply Rate',
    width: 135,
    sortable: false,
  },
};

type OmittedDataItem = Omit<BonusesReportDataItem, 'id' | 'timestamp'>;

export const getProductSpecificColumns = (realm?: Product): ColDef[] => [
  columns.operatorId,
  columns.operatorName,
  columns.agencyName,
  ...(realm === Product.Magnet ? [columns.operatorBonusValue] : [columns.bonusValue]),
  ...(realm === Product.Magnet ? [columns.agencyBonusValue] : []),
  ...(realm === Product.Magnet ? [columns.bonusCount] : []),
  columns.bonusType,
  columns.prettyDateTime,
  ...(realm !== Product.Magnet ? [columns.allReplies] : []),
  ...(realm !== Product.Magnet ? [columns.weekendReplies] : []),
  ...(realm !== Product.Magnet ? [columns.replyRate] : []),
];

export const convertBonusesReport = (realm: Product, json: BonusesReportDataItem[]): string[][] => {
  const fieldIds = getProductSpecificColumns(realm).map((column) => column.field as keyof OmittedDataItem);
  const jsonToItemsMapper = (item: OmittedDataItem) => fieldIds.map((fieldId) => item[fieldId]);

  return [getProductSpecificColumns(realm).map((column) => column.headerName || 'N/A'), ...json.map(jsonToItemsMapper)];
};
