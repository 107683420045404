import React, { useCallback, useMemo } from 'react';
import Button from '@material-ui/core/Button';

import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';

type Props<ReportDataItem, FilterData> = {
  data?: ReportDataItem[];
  download: (payload: ReportDataItem[], fileName: string, realm: Product) => void;
  filterData: FilterData;
  getFileName: (realm: Product, filterData: FilterData) => string;
};

export const ExcelSaverButton = <ReportDataItem, FilterData>({
  data,
  download,
  getFileName,
  filterData,
}: Props<ReportDataItem, FilterData>) => {
  const { me } = useAuth();

  const disabled = useMemo(() => {
    return typeof data === 'undefined' || data?.length === 0;
  }, [data]);

  const handleClick = useCallback(() => {
    if (!data || !me?.realm) {
      return;
    }

    download(data, getFileName(me.realm, filterData), me?.realm);
  }, [data, download, filterData, getFileName, me?.realm]);

  return (
    <Button color="primary" variant="contained" disabled={disabled} onClick={handleClick}>
      Export to XLSX
    </Button>
  );
};
