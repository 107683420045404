import React, { useMemo } from 'react';
import { CellParams, ColDef, DataGrid } from '@material-ui/data-grid';
import { useAuth } from 'src/services/auth';
import { getProductSpecificColumns } from 'src/services/report/bonuses/utils';
import { BonusesReportDataItem } from 'src/types/report/bonuses';
import { Tooltip } from 'src/components/Report/common/Tooltip';
import { TableStretcher, TableMargins, Alert } from 'src/components/Report/common/Table/styled';
import 'src/components/Report/common/Table/styles.css';

type Props = {
  data?: BonusesReportDataItem[];
};

const maxCount = 1000;

export const BonusesReportTable = ({ data = [] }: Props) => {
  const { me } = useAuth();
  const rows = useMemo(() => data.slice(0, maxCount), [data]);

  const columns = useMemo(
    () =>
      getProductSpecificColumns(me?.realm).map((column: ColDef) => ({
        ...column,
        renderCell: (params: CellParams) => <Tooltip value={params.value as string} />,
      })),
    [me?.realm],
  );

  const showOverflowWarning = data.length > maxCount;

  return (
    <TableMargins>
      {showOverflowWarning && (
        <Alert>
          <b>{maxCount}</b> items are displayed. Total: <b>{data.length}</b>
        </Alert>
      )}
      <TableStretcher>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={25}
          headerHeight={30}
          rowHeight={30}
          disableColumnMenu
          disableColumnSelector
          disableColumnFilter
          disableColumnResize
          disableColumnReorder
          disableSelectionOnClick
        />
      </TableStretcher>
    </TableMargins>
  );
};
