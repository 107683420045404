import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Close, EmojiEmotionsRounded } from '@material-ui/icons';
import DatePicker from 'react-datepicker';
import { add } from 'date-fns';
import EmojiPicker from 'emoji-picker-react';

import { dayStartToIsoFormat } from 'src/utils/day-start-to-iso-format';
import { Question } from 'src/network/dailybuzz/types';

import 'react-datepicker/dist/react-datepicker.css';
import { sliceCategory } from '../utils';
import * as S from './styled';

type Props = {
  question: Question;
  closeCard: () => void;
  categories?: Record<number, string>;
  saveQuestion: (question: Question) => void;
};

const minDate = add(new Date(), { days: 1 });

const CustomInput = forwardRef<HTMLButtonElement, { value?: string; onClick?: () => void }>(
  ({ value, onClick }, ref) => (
    <S.DateButton variant="outlined" color="primary" onClick={onClick} ref={ref}>
      {value}
    </S.DateButton>
  ),
);

export const OpenedCard = ({ question, categories, closeCard, saveQuestion }: Props) => {
  const [newCategory, setNewCategory] = useState(categories?.[question.categoryId]);
  const [newText, setNewText] = useState(question.question);

  const [newDate, setNewDate] = useState(new Date(question.date));

  const questionWasSet = useRef(false);
  useEffect(() => {
    if (question && !questionWasSet.current) {
      setNewText(question.question);
      setNewDate(new Date(question.date));
      questionWasSet.current = true;
    }
  }, [question]);

  const newCategoryWasSet = useRef(false);
  useEffect(() => {
    if (categories && question && !newCategoryWasSet.current) {
      setNewCategory(categories?.[question.categoryId]);
      newCategoryWasSet.current = true;
    }
  }, [categories, question]);

  const [emojiPickerOpened, setEmojiPickerOpened] = useState(false);
  const emojiRef = React.useRef(null);

  const canBeSaved = useMemo(() => {
    return newCategory && newText.trim() && newDate;
  }, [newCategory, newText, newDate]);

  const sortedCategories = useMemo(() => {
    return Object.entries(categories || {}).sort((a, b) => (a[1] > b[1] ? 1 : -1));
  }, [categories]);

  const onTextChange = useCallback(
    (text: string) => {
      if (text.length <= 80) {
        setNewText(text);
      }
    },
    [setNewText],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onTextChange(event.target.value);
    },
    [onTextChange],
  );

  const handleChangeCategory = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setNewCategory(event.target.value as string);
  };

  const openEmojiPicker = useCallback(() => setEmojiPickerOpened(true), []);
  const closeEmojiPicker = useCallback(() => setEmojiPickerOpened(false), []);

  const onEmojiClick = useCallback(
    (emoji) => {
      onTextChange(newText + emoji.emoji);
    },
    [onTextChange, newText],
  );

  const onSave = useCallback(() => {
    const [categoryId] = Object.entries(categories || {})?.find(([, category]) => category === newCategory) || [];

    if (categoryId) {
      const beginningOfDay = dayStartToIsoFormat(newDate);
      saveQuestion({ categoryId: Number(categoryId), question: newText, id: question.id, date: beginningOfDay });
    }
  }, [categories, newCategory, newText, question, saveQuestion, newDate]);

  const onDayClick = useCallback((day) => {
    setNewDate(day);
  }, []);

  return (
    <S.Card key={question.id}>
      <S.Container>
        <S.FlexCenter>
          <S.CategorySelect value={newCategory} label="Category" autoWidth onChange={handleChangeCategory}>
            {sortedCategories?.map(([, currCategory]) => (
              <S.MenuItem key={currCategory} value={currCategory}>
                {sliceCategory(currCategory)}
              </S.MenuItem>
            ))}
          </S.CategorySelect>
          <S.TextField multiline rowsMax={3} value={newText} onChange={handleChange} />
          <IconButton onClick={openEmojiPicker}>
            <EmojiEmotionsRounded ref={emojiRef} color="primary" />
          </IconButton>
          <Popover
            open={emojiPickerOpened}
            anchorEl={emojiRef.current}
            onClose={closeEmojiPicker}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <EmojiPicker onEmojiClick={onEmojiClick} />
          </Popover>
          <Typography>{`${newText.length}/80`}</Typography>
        </S.FlexCenter>
        <S.FlexCenter>
          <DatePicker
            customInput={<CustomInput />}
            minDate={minDate}
            dateFormat="dd/MM/yyyy"
            selected={newDate}
            onChange={onDayClick}
          />
          <IconButton size="small" onClick={closeCard}>
            <Close color="primary" />
          </IconButton>
        </S.FlexCenter>
      </S.Container>
      <S.SaveButton disabled={!canBeSaved} onClick={onSave} color="primary" variant="outlined">
        Save
      </S.SaveButton>
    </S.Card>
  );
};
