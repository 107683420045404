import { LocationWithUsersCount } from 'src/network/flure-feed/types';
import { Option } from 'src/components/common/flure';

export type LocationOption = Option & {
  country?: string;
};

export const locationsFormatter = (locations: LocationWithUsersCount[]): LocationOption[] => {
  return locations.map((location) => {
    const { city, country, count } = location;
    const text = [country, city].filter(Boolean).join(', ') + (count ? ` (${count})` : '');

    return {
      text,
      value: city,
      country,
    };
  });
};
