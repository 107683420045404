import React from 'react';
import { CellParams } from '@material-ui/data-grid';
import { KPIReportDataItem } from 'src/types/report/kpi';
import { roundCellValue } from './roundCellValue';

export const columns = [
  {
    field: 'operatorId',
    headerName: 'ID',
    width: 120,
  },
  {
    field: 'agencyName',
    headerName: 'Agency name',
    width: 120,
  },
  {
    field: 'operatorName',
    headerName: 'Operator name',
    width: 180,
  },
  {
    field: 'operatorCommission',
    headerName: 'Op. Commission',
    description: 'Replies / Follow Up Replies / Viewed Photos / Gifts Reply / Blue Chat Bonus',
    width: 270,
    sortable: false,
    renderCell: (params: CellParams) => {
      const replyBonusValue = (params.getValue('replyBonus') as KPIReportDataItem['replyBonus'])?.totalBonusValue || 0;
      const followUpRepliesValue =
        (params.getValue('answeredFollowUpBonus') as KPIReportDataItem['answeredFollowUpBonus'])?.totalBonusValue || 0;
      const mediaViewBonusValue =
        (params.getValue('mediaViewBonus') as KPIReportDataItem['mediaViewBonus'])?.totalBonusValue || 0;
      const giftsReplyValue =
        (params.getValue('giftsReplyBonus') as KPIReportDataItem['giftsReplyBonus'])?.totalBonusValue || 0;
      const answeredUnpaidBonusValue =
        (params.getValue('answeredUnpaidBonus') as KPIReportDataItem['answeredUnpaidBonus'])?.totalBonusValue || 0;

      return (
        <>
          <span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>{replyBonusValue}</span>/
          <span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>{followUpRepliesValue}</span>/
          <span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>{mediaViewBonusValue}</span>/
          <span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>{giftsReplyValue}</span>/
          <span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>{answeredUnpaidBonusValue}</span>
        </>
      );
    },
  },
  {
    field: 'agencyCommission',
    headerName: 'Ag. Commission',
    width: 130,
  },
  {
    field: 'fine',
    headerName: 'Fine',
    width: 70,
    renderCell: (params: CellParams) => <>{roundCellValue(params.getValue('fine'), 2)}</>,
  },
  {
    field: 'totalFine',
    headerName: 'Total Fine',
    width: 70,
    renderCell: (params: CellParams) => <>{roundCellValue(params.getValue('totalFine'), 2)}</>,
  },
  {
    field: 'bonus',
    headerName: 'Bonus',
    width: 70,
    renderCell: (params: CellParams) => <>{roundCellValue(params.getValue('bonus'), 2)}</>,
  },
  {
    field: 'totalBonus',
    headerName: 'Total Bonus',
    width: 70,
    renderCell: (params: CellParams) => <>{roundCellValue(params.getValue('totalBonus'), 2)}</>,
  },
  {
    field: 'kpiIndicatorsSummary',
    headerName: 'Rating',
    width: 70,
  },
  {
    field: 'sumTextsSent',
    headerName: 'Text Sent',
    width: 80,
  },
  {
    field: 'sumGiftsSent',
    headerName: 'VG Sent',
  },
  {
    field: 'sumPhotosSent',
    headerName: 'Photos Sent',
  },
  {
    field: 'textReplyRate',
    headerName: '% Text Ans',
    renderCell: (params: CellParams) => <>{`${roundCellValue(params.getValue('textReplyRate'), 1)}%`}</>,
  },
  {
    field: 'giftReplyRate',
    headerName: '% VG Ans',
    renderCell: (params: CellParams) => <>{`${roundCellValue(params.getValue('giftReplyRate'), 1)}%`}</>,
    width: 110,
  },
  {
    field: 'photoReplyRate',
    headerName: '% Photos Ans',
    renderCell: (params: CellParams) => <>{`${roundCellValue(params.getValue('photoReplyRate'), 1)}%`}</>,
    width: 110,
  },
  {
    field: 'quickRepliesReplyRate',
    headerName: '% Msgs replied in 1 min',
    renderCell: (params: CellParams) => <>{`${roundCellValue(params.getValue('quickRepliesReplyRate'), 1)}%`}</>,
    width: 180,
  },
  {
    field: 'monthNumber',
    headerName: 'Month',
    width: 70,
  },
  {
    field: 'weekNumber',
    headerName: 'Week',
    width: 70,
  },
];
