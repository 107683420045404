import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

export const SelectWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;
