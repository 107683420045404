import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { useAutoAnimate } from '@formkit/auto-animate/react';

import { Layout } from 'src/components/App/views/Layout';
import { useUser } from 'src/services/user/hooks';
import { Http } from 'src/network/http';
import { MediaTag } from 'src/types/user';

import {
  Circular,
  Image,
  ImageTitle,
  InputS,
  LoadingPhotos,
  SearchContainer,
  TextFieldS,
  UploadingBox,
} from '../styled';
import { useUserMedia } from '../hooks';

export const getUrl = (id: string, basename: string) => {
  return `/users/${id}/photos/private/${basename}`;
};

export const AnimatorsScreen = () => {
  const [inputAnimatorId, setInputAnimatorId] = useState('');
  const [animatorId, setAnimatorId] = useState('');

  const [files, setFiles] = useState<FileList | null>(null);
  const [uploading, setUploading] = useState(false);

  const { userState, fetchUser } = useUser();

  const { userMedia, isMediaLoading } = useUserMedia(userState?.value, [
    MediaTag.Chat,
    MediaTag.Thumbnail,
    MediaTag.Hidden,
  ]);

  const [headerRef] = useAutoAnimate();
  const [photosRef] = useAutoAnimate();

  const userMediaView = useMemo(() => {
    return (
      <Grid container spacing={2}>
        {userMedia.map(({ url, tags }, i) => {
          let title = '';
          if (tags.includes(MediaTag.Hidden)) {
            title = 'Profile';
          }
          if (tags.includes(MediaTag.Thumbnail)) {
            title = 'Avatar';
          }
          return (
            <Grid style={{ position: 'relative' }} key={String(i)} item>
              {title && <ImageTitle>{title}</ImageTitle>}
              <Image src={url} alt="preloaded image" />
            </Grid>
          );
        })}
      </Grid>
    );
  }, [userMedia]);

  const onSearch = useCallback(() => {
    fetchUser(inputAnimatorId).then();
    setAnimatorId(inputAnimatorId);
  }, [inputAnimatorId, fetchUser]);

  const onUpload = useCallback(() => {
    if (!files) {
      return;
    }

    new Promise((resolve) => {
      setUploading(true);
      Array.from(files).forEach(async (file, i, arr) => {
        const formData = new FormData();
        formData.append('image', file, file.name);

        const response = await Http.shared().instance.post(`/users/${animatorId}/photos`, formData);
        const basename = response.data.basename || response.data[0];
        await Http.shared().instance.put(`/users/${animatorId}/photos/${basename}/tags/chat`);

        if (i === arr.length - 1) {
          resolve(undefined);
          setUploading(false);
        }
      });
    }).then(() => onSearch());
  }, [onSearch, animatorId, files]);

  return (
    <Layout>
      <Box paddingLeft={1} display="flex" alignItems="center">
        <Typography component="h1" variant="h5">
          Preload images
        </Typography>
        <ImageIcon color="primary" fontSize="large" />
      </Box>
      <SearchContainer ref={headerRef}>
        <TextFieldS
          focused
          size="small"
          placeholder="Animator's ID"
          value={inputAnimatorId}
          onChange={(e) => setInputAnimatorId(e.target.value)}
          variant="outlined"
        />
        <Button size="medium" color="primary" onClick={onSearch} variant="outlined">
          Search
        </Button>
        {userState.value && userMedia && (
          <InputS multiple accept="image/*" onChange={(e) => setFiles(e.target.files)} type="file" />
        )}
        {files && (
          <Button onClick={onUpload} size="medium" color="primary" variant="outlined">
            Upload
          </Button>
        )}
        {uploading && (
          <UploadingBox>
            <Typography>Uploading...</Typography>
            <Circular size="2rem" />
          </UploadingBox>
        )}
      </SearchContainer>
      <LoadingPhotos>
        {userState.value && <Typography>name: {userState.value?.name}</Typography>}
        {isMediaLoading && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography>Loading photos...</Typography>
            <Circular size="2rem" />
          </Box>
        )}
      </LoadingPhotos>
      <div ref={photosRef}>{userMediaView}</div>
    </Layout>
  );
};
