import { Http } from 'src/network/http';

type UserRolesDto = string[];

const getAll = (ownerId: string) => {
  return Http.shared()
    .instance.get<UserRolesDto>(`/users/${ownerId}/roles`)
    .then((response) => response.data as UserRolesDto);
};

export const userRolesRequest = {
  getAll,
};
