import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  verificationMedia: {
    minWidth: '100px',
    width: '100px',
    marginRight: '32px',
  },

  mediaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginInline: '8px',
    position: 'relative',
  },
});
