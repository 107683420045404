import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: fit-content;
`;
