import React, { memo } from 'react';

import { ActionType, ModerationAction, UserForModeration } from 'src/types/moderation';
import { CommonActions } from 'src/components/Moderation/views/actions/common';
import { OnlyGoBack, OnlyUndo } from 'src/components/Moderation/views/actions/common/types';
import { useStyles } from './styles';

type Props = {
  undoAction: (actionType: ActionType) => () => void;
  resetName: () => void;
  resetAbout: () => void;
  isNoUndoUsers: boolean;
  isPressed: (actionType: ActionType, actionValue?: string | undefined) => boolean;
  addModerationAction: (actionType: ActionType, actionValue?: string | undefined) => void;
  moderationActions: ModerationAction[];
  sendModerationActions: (actions: ModerationAction[]) => Promise<void>;
  moderatedMedia: string;
  isLoading: boolean;
} & (OnlyGoBack | OnlyUndo) &
  Pick<UserForModeration, 'newMediaSource' | 'newMediaType'>;

const FlureActionsFC = (props: Props) => {
  const classes = useStyles();

  return (
    <CommonActions
      {...props}
      rejectable
      shouldSendOnReject={false}
      passActionValueForUnapprove
      containerStyle={classes.container}
      primaryButtonStyle={classes.primaryButton}
      warningButtonStyle={classes.warningButton}
      undoBackButtonStyle={classes.undoBackButton}
    />
  );
};

export const FlureActions = memo(FlureActionsFC);
