import { Http } from 'src/network/http';
import { MessagesReportDto, GetReportByMessagesRequestParams } from './types';
import { adapter } from './adapter';

const get = (params: GetReportByMessagesRequestParams) => {
  return Http.shared()
    .instance.get<MessagesReportDto>(`/reports/messages/operators`, { params })
    .then(adapter.get);
};

export const messagesReportsRequest = {
  get,
};
