import { useAsyncFn } from 'react-use';
import { flureFeedRequest } from 'src/network/flure-feed';
import { useAuth } from 'src/services/auth';

export const useLocationsWithUsersCount = () => {
  const { me } = useAuth();
  const operatorId = me?.id;

  const [locations, fetchLocations] = useAsyncFn(
    async (query: string) => {
      try {
        if (operatorId) {
          const result = await flureFeedRequest.getLocationsWithUsersCount(operatorId, query);
          return result;
        }

        return null;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return {
    locations: locations.value,
    locationsLoading: locations.loading,
    fetchLocations,
  };
};
