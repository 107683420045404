import React from 'react';

import { LabelText } from 'src/components/common/flure';

import appIcon from './app-icon.png';
// import pushImageExample from './push-image-example.png';
import { useStyles } from './styles';

type Props = {
  title: string;
  subtitle: string;
  imageSource?: string;
};

const PushPreviewView: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { title, subtitle, imageSource } = props;

  return (
    <div className={classes.container}>
      <img className={classes.appIcon} src={appIcon} alt="icon" />
      <div className={classes.contentContainer}>
        <div className={classes.topContainer}>
          <LabelText className={classes.title} text={title} />
          <LabelText className={classes.timeText} text="now" />
        </div>
        <div className={classes.bottomContainer}>
          <LabelText className={classes.subtitle} text={subtitle} />
          {imageSource ? (
            <img className={classes.pushImage} src={imageSource} alt="pushImage" />
          ) : (
            <div className={classes.pushImage} />
          )}
        </div>
      </div>
    </div>
  );
};

export const PushPreview = React.memo(PushPreviewView);
