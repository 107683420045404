import { useAsyncFn } from 'react-use';

import { rawModerationRequest } from 'src/network/moderation/raw';

export const useModerationContentCount = () => {
  const [contentCountState, fetchContentCount] = useAsyncFn(
    async () => {
      try {
        const result = await rawModerationRequest.getRAWModerationContentCount();
        return result;
      } catch (error) {
        return undefined;
      }
    },
    [],
    { loading: true },
  );

  return {
    contentCountState,
    fetchContentCount,
  };
};
