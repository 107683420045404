import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import { Resources } from 'src/resources';
import { TitleText, TitleTextSize } from 'src/components/common/flure';
import { ImageUploadInput, ImageUploadInputSize, PhonePreview, TextInput } from 'src/components/FlureChat/components';

import { useStyles } from './styles';
import { FieldWrapper } from '../field-wrapper';
import { ButtonWithAction } from '../button-with-action';
import { TextStylingHints } from '../text-styling-hints';
import { MessageComponents } from '../../types';

type Props = {
  selectedGenders?: string[];
  setSelectedGenders?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCountries?: string[];
  setSelectedCountries?: React.Dispatch<React.SetStateAction<string[]>>;
  messageTextInitialValue?: string;
  messageText: string | undefined;
  setMessageText: React.Dispatch<React.SetStateAction<string | undefined>>;
  messageImage: string | undefined;
  setMessageImage: React.Dispatch<React.SetStateAction<string | undefined>>;
  setMessageImageFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  messageImagePreview: string | undefined;
  setMessageImagePreview: React.Dispatch<React.SetStateAction<string | undefined>>;
  buttonTitle: string;
  setButtonTitle: React.Dispatch<React.SetStateAction<string>>;
  selectedButtonUrl: string;
  setSelectedButtonUrl: React.Dispatch<React.SetStateAction<string>>;
  messageComponents: MessageComponents[];
  setMessageComponents: React.Dispatch<React.SetStateAction<MessageComponents[]>>;
  messageComponentEnabled: Record<MessageComponents, boolean>;
  setMessageComponentEnabled: React.Dispatch<React.SetStateAction<Record<MessageComponents, boolean>>>;
};

const reorder = (list: MessageComponents[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  background: isDragging ? '#F4F4F4' : 'white',
  borderRadius: '16px',
  margin: '0 0 24px 0',
  ...draggableStyle,
});

const MessageWithPreviewView: React.FC<Props> = (props) => {
  const {
    messageTextInitialValue,
    messageText,
    setMessageText,
    messageImage,
    setMessageImage,
    setMessageImageFile,
    messageImagePreview,
    setMessageImagePreview,
    selectedButtonUrl,
    setSelectedButtonUrl,
    buttonTitle,
    setButtonTitle,
    messageComponents,
    setMessageComponents,
    messageComponentEnabled,
    setMessageComponentEnabled,
  } = props;
  const classes = useStyles();

  const setTextComponentEnabled = useCallback(
    (enabled: boolean) => {
      setMessageComponentEnabled({
        ...messageComponentEnabled,
        [MessageComponents.Text]: enabled,
      });
    },
    [messageComponentEnabled, setMessageComponentEnabled],
  );

  const setPhotoComponentEnabled = useCallback(
    (enabled: boolean) => {
      setMessageComponentEnabled({
        ...messageComponentEnabled,
        [MessageComponents.Photo]: enabled,
      });
    },
    [messageComponentEnabled, setMessageComponentEnabled],
  );

  const setButtonComponentEnabled = useCallback(
    (enabled: boolean) => {
      setMessageComponentEnabled({
        ...messageComponentEnabled,
        [MessageComponents.Button]: enabled,
      });
    },
    [messageComponentEnabled, setMessageComponentEnabled],
  );

  const onMessageTextChange = useCallback(
    (event) => {
      setMessageText(event.target.value);
      setTextComponentEnabled(!!event.target.value);
    },
    [setMessageText, setTextComponentEnabled],
  );

  const onMessageImageChange = useCallback(
    (source: string | undefined, file?: File) => {
      setMessageImage(source);
      setMessageImageFile(file);
      setPhotoComponentEnabled(!!source);
    },
    [setMessageImage, setMessageImageFile, setPhotoComponentEnabled],
  );

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const items = reorder(messageComponents, result.source.index, result.destination.index);
      setMessageComponents(items);
    },
    [messageComponents, setMessageComponents],
  );

  return (
    <div className={classes.messageBlockContainer}>
      <div className={classes.leftContainer}>
        <div className={classes.messageContentContainer}>
          <TitleText
            className={classes.subtitle}
            text={Resources.strings.flureChat.messageTitle}
            size={TitleTextSize.Medium}
          />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div className={classes.inputsContainer} ref={provided.innerRef} {...provided.droppableProps}>
                  {messageComponents.map((it, index) => {
                    return (
                      <Draggable key={it} draggableId={it} index={index}>
                        {(drProvided, drSnapshot) => (
                          <div
                            ref={drProvided.innerRef}
                            {...drProvided.draggableProps}
                            {...drProvided.dragHandleProps}
                            style={getItemStyle(drSnapshot.isDragging, drProvided.draggableProps.style)}
                          >
                            {it === MessageComponents.Text && (
                              <FieldWrapper
                                title={Resources.strings.flureChat.message}
                                enabled={messageComponentEnabled[MessageComponents.Text]}
                                setEnabled={setTextComponentEnabled}
                              >
                                <TextInput
                                  className={classes.messageInput}
                                  initialValue={messageTextInitialValue}
                                  value={messageText}
                                  placeholder={Resources.strings.flureChat.messageInputPlaceholder}
                                  onChange={onMessageTextChange}
                                  multiline
                                />
                              </FieldWrapper>
                            )}
                            {it === MessageComponents.Photo && (
                              <FieldWrapper
                                title={Resources.strings.flureChat.photo}
                                enabled={messageComponentEnabled[MessageComponents.Photo]}
                                setEnabled={setPhotoComponentEnabled}
                              >
                                <ImageUploadInput
                                  className={classes.messageInput}
                                  size={ImageUploadInputSize.Large}
                                  onChange={onMessageImageChange}
                                  imagePreview={messageImagePreview}
                                  setImagePreview={setMessageImagePreview}
                                />
                              </FieldWrapper>
                            )}
                            {it === MessageComponents.Button && (
                              <FieldWrapper
                                title={Resources.strings.flureChat.button}
                                enabled={messageComponentEnabled[MessageComponents.Button]}
                                setEnabled={setButtonComponentEnabled}
                              >
                                <ButtonWithAction
                                  enabled={messageComponentEnabled[MessageComponents.Button]}
                                  buttonTitle={buttonTitle}
                                  setButtonTitle={setButtonTitle}
                                  selectedAction={selectedButtonUrl}
                                  setSelectedAction={setSelectedButtonUrl}
                                />
                              </FieldWrapper>
                            )}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>

      <div className={classes.rightContainer}>
        <PhonePreview
          messages={[
            {
              text: messageComponentEnabled[MessageComponents.Text] ? messageText : '',
              reference: messageComponentEnabled[MessageComponents.Photo] ? messageImage : '',
              meta: {
                buttonTitle: messageComponentEnabled[MessageComponents.Button] && selectedButtonUrl ? buttonTitle : '',
                componentsOrder: messageComponents,
              },
            },
          ]}
          withHeader
        />
       <TextStylingHints />
      </div>
    </div>
  );
};

export const MessageWithPreview = React.memo(MessageWithPreviewView);
