import React, { useMemo } from 'react';

import { Selector } from 'src/components/common/Selector';

type Props = {
  value?: string;
  onChange: (value: string) => void;
};

const configMap = {
  FlexibleSchedule: 'Flexible schedule',
  TwoInTwo: '2/2',
  Agency: 'Agency',
};
const scheduleKeys = Object.keys(configMap) as (keyof typeof configMap)[];

export const ScheduleSelector = ({ value, onChange }: Props) => {
  const values = useMemo(
    () => scheduleKeys.map((scheduleKey) => ({ key: scheduleKey, value: configMap[scheduleKey] })),
    [],
  );

  return <Selector variants={values} onChange={onChange} emptyPlaceholder="Without schedule" value={value} />;
};
