import { Http } from 'src/network/http';
import { adapter } from './adapter';
import { GetActivityReportRequestParams, ActivityReportDTO } from './types';

const get = (params: GetActivityReportRequestParams) => {
  return Http.shared()
    .instance.get<ActivityReportDTO>(`/reports/operators/activities`, { params })
    .then(adapter.get);
};

export const activityReportRequest = {
  get,
};
