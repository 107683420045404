import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { userRequest } from 'src/network';
import { questionsRequest } from 'src/network/user/questions';
import { udatesUserAdapter } from 'src/network/user/udates-user-adapter';
import { flureUserAdapter } from 'src/network/user/flure-user-adapter';
import { xoxoUserAdapter } from 'src/network/user/xoxo-user-adapter';
import { magnetUserAdapter } from 'src/network/user/magnet-user-adapter';
import { Product } from 'src/types/product';
import { useAuth } from 'src/services/auth';

export const useUser = () => {
  const [userState, fetchUser] = useAsyncFn(
    async (userId: string) => {
      try {
        const result = await userRequest.getById(userId, true);
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return { userState, fetchUser };
};

export const useFormattedUser = () => {
  const { me } = useAuth();
  const [userState, fetchFormattedUser] = useAsyncFn(
    async (userId: string, product: Product | undefined) => {
      try {
        if (!userId) {
          return null;
        }
        const result = await userRequest.getById(userId, true);

        if (product === Product.Once) {
          const { data } = await userRequest.getPhotoTags(userId);

          return result ? udatesUserAdapter(result, data) : null;
        }

        if (product === Product.Flure) {
          return result ? flureUserAdapter(result, me?.token) : null;
        }

        if (product === Product.Magnet) {
          const preferences = await userRequest.getUserPreferences(userId);
          const { traits } = await userRequest.getUserTraits(userId);
          const turnOnsData = await userRequest.getUserTurnOns(userId);
          return result
            ? magnetUserAdapter(result, traits, turnOnsData['turn-ons'], preferences?.['looking-for'])
            : null;
        }

        return result ? xoxoUserAdapter(result) : null;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return { userState, fetchFormattedUser };
};

export const useMagnetPromptsConfig = () => {
  const { me } = useAuth();
  const [magnetUserPromptsState, fetchMagnetUserPromptsState] = useAsyncFn(
    async () => {
      const response = await questionsRequest.getAll();
      const questions = response?.onboardSets?.[0]?.questions || null;
      return questions;
    },
    [],
    { loading: true },
  );

  useEffect(() => {
    if (me?.realm === Product.Magnet) {
      fetchMagnetUserPromptsState();
    }
  }, [fetchMagnetUserPromptsState, me?.realm]);

  return magnetUserPromptsState;
};
