import React from 'react';

import { ReasonMapping } from 'src/constants';
import { MediaModerationReason } from 'src/network/moderation/types';

import { useStyles } from './styles';

type Props = {
  reason: MediaModerationReason;
};

export function Label({ reason }: Props) {
  const classes = useStyles();

  return <span className={classes.label}>{ReasonMapping[reason]}</span>;
}
