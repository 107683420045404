import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import validationSchema from './schema';
import * as S from './styled';

type Props = {
  story: string;
  onSubmit: (story: string) => void;
};

export const LifeStoryEditor = ({ story, onSubmit: onSubmitBase }: Props) => {
  const { control, handleSubmit, errors } = useForm<{ story: string }>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      story,
    },
  });

  const onSubmit = async (values: { story: string }) => {
    onSubmitBase(values.story);
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.FormInner>
        <S.InputWrapper>
          <Controller
            as={TextField}
            name="story"
            control={control}
            label="Animator life story"
            placeholder="Story"
            multiline
            error={!!errors.story}
            variant="outlined"
            size="small"
            fullWidth
          />
        </S.InputWrapper>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Save
        </Button>
      </S.FormInner>
    </S.Form>
  );
};
