import { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/core';
import { Product } from 'src/types/product';
import { useAuth } from 'src/services/auth';

const modal = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContent: CSSProperties = {
  backgroundColor: 'white',
  alignSelf: 'center',
  padding: '32px 24px 14px 24px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
};

const button: CSSProperties = {
  height: '50px',
  fontWeight: 700,
  boxShadow: 'none',
  color: '#FFFFFF',
};

const useCommonStyles = makeStyles({
  modal,
  modalContent: {
    ...modalContent,
    width: '334px',
    height: '260px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '54px',
    maxWidth: '200px',
    lineHeight: '45px',
    fontVariant: 'small-caps',
    textTransform: 'lowercase',
    color: '#ED654C',
    marginBottom: '20px',
  },
  button: {
    ...button,
    textTransform: 'uppercase',
    fontSize: '17px',
    borderRadius: '8px',
  },
  block: {
    backgroundColor: '#ED654C',
    marginBottom: '12px',
  },
  cancel: {
    backgroundColor: '#E6E7EC',
    color: '#000000',
  },
});

const useMagnetStyles = makeStyles({
  modal,
  modalContent: {
    ...modalContent,
    width: '435px',
    height: '260px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '33px',
    fontVariant: 'normal',
    textTransform: 'none',
    textAlign: 'center',
    color: '#6C20B9',
    marginBottom: '30px',
  },
  button: {
    ...button,
    textTransform: 'none',
    fontSize: '20px',
    borderRadius: '30px',
  },
  block: {
    backgroundColor: '#FF4747',
    marginBottom: '15px',
  },
  cancel: {
    backgroundColor: '#C3C3C3',
  },
});

export const useProductStyles = () => {
  const { me } = useAuth();
  const magnetClasses = useMagnetStyles();
  const commonClasses = useCommonStyles();

  return (me?.realm === Product.Magnet && magnetClasses) || commonClasses;
};
