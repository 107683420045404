import parseISO from 'date-fns/parseISO';
import intervalToDuration from 'date-fns/intervalToDuration';
import { format, subYears } from 'date-fns';

export const getUserAge = (birthday: string): number => {
  if (!birthday) {
    return 0;
  }

  const birthdayParsed = parseISO(birthday);
  const { years = 0 } = intervalToDuration({ start: birthdayParsed, end: new Date() });

  return years;
};

export const birthdayFormat = "yyyy-MM-dd'T00:00:00Z'";

export const getUserBirthday = (age: number): string => {
  if (!age) {
    return '';
  }

  return format(subYears(new Date(), age), birthdayFormat);
};
