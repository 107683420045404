import { AxiosResponse } from 'axios';

import { Http } from 'src/network/http';

import { ApiGetPromoCodes, PromoCodeData, PromoCodeType } from './types';
import { apiPromoCodesAdapter, dataToApiPromoCodeAdapter } from './adapters';

const getPromoCodesList = (operatorId: string, type: PromoCodeType = PromoCodeType.Many) =>
  Http.shared()
    .instance.get(`benefits/${operatorId}/promocode/${type}`)
    .then((response: AxiosResponse<ApiGetPromoCodes>) => apiPromoCodesAdapter(response.data?.promocodes || []));

const addOrUpdatePromoCode = (
  operatorId: string,
  promoCodeData: PromoCodeData,
  type: PromoCodeType = PromoCodeType.Many,
) => Http.shared().instance.put(`benefits/${operatorId}/promocode/${type}`, dataToApiPromoCodeAdapter(promoCodeData));

const deletePromoCode = (operatorId: string, promoCodeId: string) =>
  Http.shared().instance.delete(`benefits/${operatorId}/promocode/${promoCodeId}/delete`);

export const flurePromoCodesRequest = {
  getPromoCodesList,
  addOrUpdatePromoCode,
  deletePromoCode,
};
