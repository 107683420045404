import styled from 'styled-components';

export const TableMargins = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const TableStretcher = styled.div`
  height: 400px;
  width: 100%;
`;

export const Alert = styled.p`
  color: rgb(0, 0, 0);
  background-color: rgb(255, 177, 177);
  padding: 6px 16px;
`;
