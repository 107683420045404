import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { operatorRequest } from 'src/network';
import { Operator, UpdatedOperatorState } from 'src/types/operator';
import { getRealm } from 'src/utils/realm';
import { useAuth } from 'src/services/auth';

export const useOperators = () => {
  const [operators, setOperators] = useState<Operator[]>([]);
  const { me } = useAuth();
  const realm = useMemo(() => getRealm(me?.realm), [me]);
  const [operatorsState, fetchOperators] = useAsyncFn(
    async () => {
      const result = await operatorRequest.getAll(realm);
      setOperators(result);
      return result;
    },
    [],
    { loading: true },
  );

  const updateOperator = useCallback(
    async (operatorId: string, data: UpdatedOperatorState) => {
      const preparedData = {
        ...data,
        agencyTechName: !data.agencyTechName || data.agencyTechName === 'null' ? null : data.agencyTechName,
        scheduleType: !data.scheduleType || data.scheduleType === 'null' ? null : data.scheduleType,
      };

      await operatorRequest.update(operatorId, { ...preparedData, realm });

      await fetchOperators();
    },
    [fetchOperators, realm],
  );

  useEffect(() => {
    fetchOperators().then();
  }, [fetchOperators]);

  return { operatorsState, operators, updateOperator };
};
