export const CHANGE_CONTENT_TYPE = 'CHANGE_CONTENT_TYPE' as const;
export const SET_MODERATION_ACTION = 'SET_MODERATION_ACTION' as const;
export const SET_MODERATION_ACTIONS = 'SET_MODERATION_ACTIONS' as const;
export const RESET_MODERATION_ACTIONS = 'RESET_MODERATION_ACTIONS' as const;
export const SET_IS_SENDING = 'SET_IS_SENDING' as const;
export const SET_MODERATED_COUNT = 'SET_MODERATED_COUNT' as const;

export const LOAD_QUESTION_CONFIG_PENDING = 'LOAD_QUESTION_CONFIG_PENDING' as const;
export const LOAD_QUESTION_CONFIG_FULFILLED = 'LOAD_QUESTION_CONFIG_FULFILLED' as const;
export const LOAD_QUESTION_CONFIG_REJECTED = 'LOAD_QUESTION_CONFIG_REJECTED' as const;

export const TOGGLE_BLOCK_MODAL_STATE = 'TOGGLE_BLOCK_MODAL_STATE' as const;

export const SET_QUEUE_BLOCK = 'SET_QUEUE_BLOCK' as const;
