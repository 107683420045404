import React from 'react';

import { ActionType } from 'src/types/moderation';
import { RAW } from 'src/types/raw';
import { convertUtcDateToLocalDate } from 'src/utils/raw/date-utc-to-local-date';
import { ActionsHistoryLabel } from 'src/components/Moderation/screens/AdminUserModeration/raw/components/action-history-label';

type Props = {
  declined: boolean;
  unlisted: boolean;
  photo: RAW.ModerationMediaPhoto;
};

export function TooltipContent(props: Props) {
  const { declined, unlisted, photo } = props;

  const actionTypes: ActionType[] = declined ? [(photo as RAW.DeclinedPhoto).reason as unknown as ActionType] : [ActionType.Approve];

  return (
    <>
      <ActionsHistoryLabel date={convertUtcDateToLocalDate(photo.moderatedAt)} actionTypes={actionTypes} initiator={photo.initiator} />
      {unlisted ? (
        <div>The photo is no longer listed. It will be automatically deleted soon</div>
      ) : null}
    </>
  )
}
