import { useAsyncFn } from 'react-use';
import { add, sub } from 'date-fns';

import { addCategory, addQuestion, changeQuestion, getQuestionCategories, getQuestions } from 'src/network/dailybuzz';
import { NewQuestion, Question } from 'src/network/dailybuzz/types';

export const useQuestions = (incoming: boolean) => {
  return useAsyncFn(async () => {
    let from = sub(new Date(), { years: 1 });
    let to = new Date();

    if (incoming) {
      from = add(new Date(), { days: 1 });
      to = add(new Date(), { years: 1 });
    }

    return getQuestions(from, to, []);
  }, [incoming]);
};

export const useQuestionsCategories = () => {
  return useAsyncFn(async () => {
    return getQuestionCategories();
  }, []);
};

export const useSaveQuestion = () => {
  return (question: Question | NewQuestion) => {
    return 'id' in question ? changeQuestion(question) : addQuestion(question);
  };
};

export const useAddCategory = () => {
  return useAsyncFn(async (category: string) => {
    return addCategory(category);
  }, []);
};
