import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Layout } from 'src/components/App/views/Layout';
import { Button, Grid } from '@material-ui/core';

import { useAddCategory, useQuestionsCategories } from '../hooks';

import * as S from './styled';

export const CategoriesScreen = () => {
  const [text, setText] = useState('');
  const [categories, fetchCategories] = useQuestionsCategories();
  const [, addCategories] = useAddCategory();

  useEffect(() => {
    fetchCategories().then();
  }, [fetchCategories]);

  const sortedCategories = useMemo(() => {
    return Object.entries(categories.value || {}).sort((a, b) => (a[1] > b[1] ? 1 : -1));
  }, [categories]);

  const canAdd = useMemo(() => text.trim().length > 0, [text]);

  const onAddCategory = useCallback(async () => {
    await addCategories(text.trim());
    await fetchCategories();
    setText('');
  }, [addCategories, fetchCategories, text]);

  const onTextChange = useCallback((newText: string) => {
    if (newText.length <= 50) {
      setText(newText);
    }
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onTextChange(event.target.value);
    },
    [onTextChange],
  );

  return (
    <Layout>
      <S.TextFieldS placeholder="Type new category" value={text} onChange={handleChange} />
      <S.Counter>{`${text.length}/50`}</S.Counter>
      <Button disabled={!canAdd} variant="outlined" color="primary" size="small" onClick={onAddCategory}>
        Add
      </Button>
      <S.GridS container spacing={1}>
        {sortedCategories.map(([id, category]) => (
          <Grid item key={id}>
            <S.TypographyS align="left" key={id}>
              {category}
            </S.TypographyS>
          </Grid>
        ))}
      </S.GridS>
    </Layout>
  );
};
