import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  messagesContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    rowGap: '10px',
  },

  messageContainer: {
    display: 'flex',
    columnGap: '10px',
    color: 'rgba(0, 0, 0, 0.3)',
  },

  activeMessage: {
    color: 'black',
  },

  mediumText: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '27px',
  },

  redText: {
    color: 'red',
  },

  loader: {
    display: 'flex',
  },
});
