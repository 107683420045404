import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '90px',
    paddingTop: '10px',
  },

  middleContainer: {
    width: '375px',
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
  },

  rightContainer: {
    width: '397px',
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
  },
});
