import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from 'src/components/App/views/Layout';
import { useAsyncFn } from 'react-use';
import generator from 'generate-password';
import { nanoid } from 'nanoid';
import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import random from 'lodash/random';

import { animatorRequest, authRequest } from 'src/network';
import { useFormattedUser } from 'src/services/user/hooks';
import { Product } from 'src/types/product';
import { MediaType, QuestionItem, User as UserType } from 'src/types/user';
import { getRandomIndexes } from 'src/utils/random-indexes';
import { Http } from 'src/network/http';

import { useSnackbar } from 'notistack';
import { IdTitle, SearchContainer, TextFieldS, UserProfileContainer } from '../styled';
import { User } from './users';

const emptyUser = {
  id: '',
  name: '',
  about: '',
  thumbnailType: MediaType.Photo,
  thumbnailSource: '',
  city: '',
  country: '',
  birthday: undefined,
  'looking-for': '',
  interests: [],
  media: [],
} as UserType;

const emailRegex = /^\S+@\S+\.\S+$/;

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

export const ProfileSettingsScreen = () => {
  const [mode, setMode] = useState<'edit' | 'create'>('edit');

  const { enqueueSnackbar } = useSnackbar();

  const [animatorId, setAnimatorId] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [createdUser, setCreatedUser] = useState<UserType | null>();

  const { userState, fetchFormattedUser } = useFormattedUser();

  const [questionsPool, fetchQuestionsPool] = useAsyncFn(async () => {
    const response = await Http.shared().instance.get('/magnet/questions/config');
    return response.data.onboardSets[0].questions as string[];
  });

  const [interestsPool, fetchInterests] = useAsyncFn(async () => {
    const response = await Http.shared().instance.get<Record<string, string>>('dict/interests');
    return response.data;
  });

  const [lookingForPool, fetchLookingFor] = useAsyncFn(async () => {
    const response = await Http.shared().instance.get<Record<string, string>>('dict/looking-for');
    return response.data;
  });

  const [traitsPool, fetchTraits] = useAsyncFn(async () => {
    const response = await Http.shared().instance.get<Record<string, string>>('dict/traits');
    return response.data;
  });

  const [turnOnsPool, fetchTurnOns] = useAsyncFn(async () => {
    const response = await Http.shared().instance.get<Record<string, string>>('dict/turn-ons');
    return response.data;
  });

  useEffect(() => {
    fetchQuestionsPool().then();
    fetchLookingFor().then();
    fetchTurnOns().then();
    fetchTraits().then();
    fetchInterests().then();
  }, [fetchQuestionsPool, fetchLookingFor, fetchTurnOns, fetchTraits, fetchInterests]);

  const [headerRef] = useAutoAnimate();
  const [profileContainerRef] = useAutoAnimate();

  const handleAnimatorIdChange = useCallback((event) => {
    setAnimatorId(event.target.value);
  }, []);

  const handleModeChange = useCallback((event) => {
    const modeValue = event.target.value;
    setMode(modeValue);

    if (modeValue === 'edit') {
      setEmail('');
      setPassword('');
      setCreatedUser(null);
    }
  }, []);

  const handleEmailChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const handlePasswordChange = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const onSearch = useCallback(() => {
    fetchFormattedUser(animatorId, Product.Magnet).then();
  }, [fetchFormattedUser, animatorId]);

  const onSaveEnd = useCallback(() => {
    onSearch();
    const phrase = mode === 'edit' ? 'updated' : 'created';
    enqueueSnackbar(`User ${phrase}`, { variant: 'success' });

    if (mode === 'create') {
      setCreatedUser(null);
      setEmail('');
      setPassword('');
    }
  }, [onSearch, enqueueSnackbar, mode]);

  const onUserCreate = useCallback(async () => {
    const response = await authRequest.register({ email, password });
    const { id } = response.data;

    await animatorRequest.activate(id, { city: 'Denver', country: 'US', realm: 'magnet' });

    let questions: Array<QuestionItem> = [];
    if (questionsPool.value) {
      questions = getRandomIndexes(questionsPool.value, 4).map((el) => ({ question: String(el), answer: '' }));
    }

    if (lookingForPool.value) {
      const entries = getEntries(lookingForPool.value);
      const [key] = entries[random(0, entries.length - 1)];
      emptyUser.lookingFor = key;
    }

    if (interestsPool.value) {
      const entries = getEntries(interestsPool.value);
      const indexes = getRandomIndexes(entries, random(3, 8));
      emptyUser.interests = [];
      indexes.forEach((el) => {
        const [key] = entries[el];
        emptyUser.interests?.push(key);
      });
    }

    if (traitsPool.value) {
      const entries = getEntries(traitsPool.value);
      const indexes = getRandomIndexes(entries, random(3, 8));
      emptyUser.traits = [];
      indexes.forEach((el) => {
        const [key] = entries[el];
        emptyUser.traits?.push(key);
      });
    }

    if (turnOnsPool.value) {
      const entries = getEntries(turnOnsPool.value);
      const indexes = getRandomIndexes(entries, random(3, 8));
      emptyUser.turnOns = [];
      indexes.forEach((el) => {
        const [key] = entries[el];
        emptyUser.turnOns?.push(key);
      });
    }

    if (interestsPool.value) {
      const entries = getEntries(interestsPool.value);
      const indexes = getRandomIndexes(entries, random(3, 8));
      emptyUser.interests = [];
      indexes.forEach((el) => {
        const [key] = entries[el];
        emptyUser.interests?.push(key);
      });
    }

    const newUser = { ...emptyUser, questions, id };
    setCreatedUser(newUser);
  }, [
    email,
    password,
    questionsPool.value,
    lookingForPool.value,
    interestsPool.value,
    traitsPool.value,
    turnOnsPool.value,
  ]);

  const onRandomize = useCallback(() => {
    const randomEmail = `animator_${nanoid(8)}@magnetapp.co`;
    const randomPassword = generator.generate({
      length: 8,
      numbers: true,
    });
    setEmail(randomEmail);
    setPassword(randomPassword);
  }, []);

  const emailError = email.trim().length > 0 && !emailRegex.test(email);
  const passwordError = password.trim().length > 0 && password.length < 6;

  return (
    <Layout>
      <Box paddingLeft={1} display="flex" alignItems="center">
        <Typography component="h1" variant="h5">
          Profile
        </Typography>
        <AccountCircle color="primary" fontSize="large" />
        <RadioGroup
          style={{ marginLeft: '16px', flexDirection: 'row' }}
          aria-label="mode"
          name="mode"
          value={mode}
          onChange={handleModeChange}
        >
          <FormControlLabel value="edit" control={<Radio />} label="Edit" />
          <FormControlLabel value="create" control={<Radio />} label="Create" />
        </RadioGroup>
      </Box>
      <div ref={headerRef}>
        {mode === 'edit' && (
          <SearchContainer>
            <TextFieldS
              focused
              size="small"
              placeholder="Animator's ID"
              value={animatorId}
              onChange={handleAnimatorIdChange}
              variant="outlined"
            />
            <Button
              disabled={animatorId.trim().length === 0}
              size="medium"
              color="primary"
              onClick={onSearch}
              variant="outlined"
            >
              Search
            </Button>
          </SearchContainer>
        )}
        {mode === 'create' && (
          <SearchContainer>
            <TextFieldS
              focused
              size="small"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              variant="outlined"
              error={emailError}
            />
            <TextFieldS
              focused
              size="small"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              variant="outlined"
              error={passwordError}
            />
            <Button
              style={{ marginRight: '16px' }}
              disabled={emailError || passwordError || email.trim().length === 0 || password.trim().length === 0}
              size="medium"
              color="primary"
              onClick={onUserCreate}
              variant="outlined"
            >
              Sign up
            </Button>
            <Button size="medium" color="primary" onClick={onRandomize} variant="outlined">
              Randomize
            </Button>
          </SearchContainer>
        )}
        {mode === 'create' && createdUser?.id && (
          <IdTitle variant="body2" color="textSecondary" align="left">
            ID: {createdUser?.id}
          </IdTitle>
        )}
      </div>
      {questionsPool.value && interestsPool.value && lookingForPool.value && traitsPool.value && turnOnsPool.value ? (
        <UserProfileContainer ref={profileContainerRef}>
          {userState.value && !userState.loading && mode === 'edit' && (
            <User
              mode={mode}
              onSaveEnd={onSaveEnd}
              user={userState.value}
              questionsPool={questionsPool.value}
              lookingForPool={lookingForPool.value}
              traitsPool={traitsPool.value}
              turnOnsPool={turnOnsPool.value}
              interestsPool={interestsPool.value}
            />
          )}
          {createdUser && mode === 'create' && (
            <User
              mode={mode}
              onSaveEnd={onSaveEnd}
              user={createdUser}
              questionsPool={questionsPool.value}
              lookingForPool={lookingForPool.value}
              traitsPool={traitsPool.value}
              turnOnsPool={turnOnsPool.value}
              interestsPool={interestsPool.value}
            />
          )}
        </UserProfileContainer>
      ) : (
        <></>
      )}
    </Layout>
  );
};
