import { fillExcelDocument } from 'src/utils/excel';
import { downloadFile } from 'src/utils/files';
import { KPIReportDataItem } from 'src/types/report/kpi';
import { Reports, DefaultReportSearchMode, DefaultReportFormFields } from 'src/types/report/common';
import { Product } from '../../types/product';

const convertKPIReport = (json: KPIReportDataItem[]) => {
  return [
    [
      'ID',
      'Agency name',
      'Operator name',
      'Op. Commission (Replies / Follow Up Replies / Viewed Photos / Gifts Reply / Blue Chat Bonus)',
      'Ag. Commission',
      'Fine',
      'Total Fine',
      'Bonus',
      'Total Bonus',
      'Rating',
      'Text Sent',
      'VG Sent',
      'Photos Sent',
      '% Text Ans',
      '% VG Ans',
      '% Photos Ans',
      '% Msgs replied in 1 min',
      'Month',
      'Week',
    ],
    ...json.map((item) => [
      item.operatorId,
      item.agencyName,
      item.operatorName,
      `${item.replyBonus.totalBonusValue || 0} / ${item.answeredFollowUpBonus.totalBonusValue || 0} / ${
        item.mediaViewBonus.totalBonusValue || 0
      } / ${item.giftsReplyBonus.totalBonusValue || 0} / ${item.answeredUnpaidBonus.totalBonusValue || 0}`,
      item.agencyCommission,
      item.fine,
      item.totalFine,
      item.bonus,
      item.totalBonus,
      item.kpiIndicatorsSummary,
      item.sumTextsSent,
      item.sumGiftsSent,
      item.sumPhotosSent,
      `${item.textReplyRate}%`,
      `${item.giftReplyRate}%`,
      `${item.photoReplyRate}%`,
      `${item.quickRepliesReplyRate}%`,
      item.monthNumber,
      item.weekNumber,
    ]),
  ];
};

export const downloadKPIReportXlsx = (payload: KPIReportDataItem[], fileName: string) => {
  const convertedData = convertKPIReport(payload);
  const document = fillExcelDocument('Report by KPI', convertedData);

  downloadFile(document, `${fileName}.xlsx`);
};

export const getFileName = (realm: Product, filter?: DefaultReportFormFields): string => {
  if (!filter) {
    return 'defaultName';
  }

  const reportName = Reports.kpi;
  const formattedDateRange = filter.rangeDate.replace(':', '__');

  const reportMapping: Record<DefaultReportSearchMode, string> = {
    agency: 'agency',
    all: 'all',
    'all-without-agency': 'without-agency',
    operator: 'operator',
  };
  const operator = filter.operatorId ? `-${filter.operatorId}` : '';
  const agency = filter.agencyTechName ? `-${filter.agencyTechName}` : '';

  return `${realm}-${reportName}-${reportMapping[filter.mode]}${agency}${operator}-${formattedDateRange}`;
};
