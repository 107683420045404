import { useCallback } from 'react';

import { ActionType, UserForModeration } from 'src/types/moderation';
import { MentalLevel, Gender } from 'src/types/user';
import { Key, KeyTitlesMapper, useKeyboardEventListener } from 'src/components/Moderation/utils/keyboard';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';

import { OnlyGoBack, OnlyReject, OnlyResetAbout, OnlyResetQuestions, OnlyTrash, OnlyUndo } from './types';

type Props = {
  isNoUndoUsers: boolean;
  setAction: (actionType: ActionType, actionValue?: string | undefined) => () => void;
  isPressed: (actionType: ActionType, actionValue?: string | undefined) => boolean;
  undoAction: (actionType: ActionType) => () => void;
  nextProfile: () => void;
  resetName: () => void;
  resetOccupation?: () => void;
  isLoading: boolean;
  man?: () => void;
  woman?: () => void;
  adultContent?: () => void;
  noUser?: () => void;
  unApprove?: () => void;
  orange?: () => void;
  purple?: () => void;
  setPublic?: () => void;
  setPrivate?: () => void;
} & (OnlyGoBack | OnlyUndo) &
  (OnlyReject | OnlyTrash) &
  (OnlyResetAbout | OnlyResetQuestions) &
  Pick<UserForModeration, 'newMediaSource'>;

export const useKeyListener = (props: Props) => {
  const {
    newMediaSource,
    undoAction,
    setAction,
    undo,
    goBack,
    nextProfile,
    man,
    woman,
    resetAbout,
    resetOccupation,
    resetQuestions,
    isPressed,
    resetName,
    isLoading,
    isNoUndoUsers,
    trash,
    reject,
    adultContent,
    unApprove,
    noUser,
    orange,
    purple,
    setPublic,
    setPrivate,
  } = props;
  const keyPressListener = useCallback(
    (event: KeyboardEvent) => {
      if (!event.defaultPrevented) {
        if ((isLoading || isNoUndoUsers) && event.keyCode !== Key.Space) {
          return undefined;
        }
        const pressedActionType = isPressed(ActionType.ResetAbout)
          ? ActionType.ResetAbout
          : isPressed(ActionType.ResetQuestions) && ActionType.ResetQuestions;

        ActionsLogger.addKeyCode(KeyTitlesMapper[event.keyCode]);

        switch (event.keyCode) {
          case Key.J:
            if (isPressed(ActionType.ResetName)) {
              undoAction(ActionType.ResetName)();
            } else {
              resetName();
            }

            break;
          case Key.F:
            if (pressedActionType) {
              undoAction(pressedActionType)();
            } else {
              if (resetAbout) {
                resetAbout();
              }
              if (resetQuestions) {
                resetQuestions();
              }
            }
            break;
          case Key.W:
            if (isPressed(ActionType.ResetOccupation)) {
              undoAction(ActionType.ResetOccupation)();
            } else if (resetOccupation) {
              resetOccupation();
            }

            break;
          case Key.Z:
          case Key.Backspace:
            if (undo) {
              undo();
            }
            if (goBack) {
              goBack();
            }
            break;
          case Key.One:
          case Key.Numpad1:
            setAction(ActionType.Scam)();
            break;
          case Key.Two:
          case Key.Numpad2:
            if (trash) {
              trash();
            }
            if (reject) {
              reject();
            }
            break;
          case Key.Three:
          case Key.Numpad3:
            if (adultContent) {
              adultContent();
            }
            break;
          case Key.Four:
          case Key.Numpad4:
            if (noUser) {
              noUser();
            }
            break;
          case Key.D:
            if (unApprove) {
              unApprove();
            }
            break;
          case Key.Space:
            if (!isLoading) {
              nextProfile();
            }
            break;
          case Key.Eight:
          case Key.Numpad8:
            if (man && !isPressed(ActionType.SetGender, Gender.Male)) {
              man();
            }
            break;
          case Key.Nine:
          case Key.Numpad9: {
            if (woman && !isPressed(ActionType.SetGender, Gender.Female)) {
              woman();
            }
            break;
          }
          case Key.Zero:
          case Key.Numpad0:
            if (orange && !isPressed(ActionType.SetColor, MentalLevel.Orange)) {
              orange();
            }
            if (setPublic && !isPressed(ActionType.SetPublicPhoto, newMediaSource)) {
              setPublic();
            }
            break;
          case Key.Dash:
            if (purple && !isPressed(ActionType.SetColor, MentalLevel.Purple)) {
              purple();
            }
            if (setPrivate && !isPressed(ActionType.SetPrivatePhoto, newMediaSource)) {
              setPrivate();
            }
            break;
          default:
            break;
        }
      }
      return undefined;
    },
    [
      isNoUndoUsers,
      isLoading,
      isPressed,
      undo,
      undoAction,
      goBack,
      setAction,
      trash,
      reject,
      adultContent,
      noUser,
      unApprove,
      orange,
      setPublic,
      newMediaSource,
      purple,
      setPrivate,
      resetName,
      resetAbout,
      resetQuestions,
      nextProfile,
      man,
      woman,
      resetOccupation,
    ],
  );

  useKeyboardEventListener(keyPressListener);
};
