import React, { useCallback, useMemo } from 'react';

import { ActionType, ModerationAction, UserForModeration } from 'src/types/moderation';
import { MentalLevel, Gender, MediaState } from 'src/types/user';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import { userCachedPhotoSize } from 'src/components/Moderation/utils/image-source';
import { MagnetActions } from 'src/components/Moderation/views/actions/magnet';
import { UDatesActions } from 'src/components/Moderation/views/actions/UDates';
import { XoXoActions } from 'src/components/Moderation/views/actions/XoXo';
import { FlureActions } from 'src/components/Moderation/views/actions/Flure';

type ReactDispatchType<T> = React.Dispatch<React.SetStateAction<T>>;

type Props = {
  isPressed: (actionType: ActionType, actionValue?: string | undefined) => boolean;
  goBack: () => void;
  undoAction: (actionType: ActionType) => () => void;
  resetAbout: () => void;
  resetQuestions: () => void;
  resetName: () => void;
  resetOccupation: () => void;
  moderationActions: ModerationAction[];
  setUserMentalLevel: ReactDispatchType<MentalLevel | undefined>;
  setUserGender: ReactDispatchType<Gender | undefined>;
  isLoading: boolean;
  sendModerationActions: (actions: ModerationAction[]) => Promise<void>;
  isNoUndoUsers: boolean;
  setMediaState: ReactDispatchType<MediaState>;
  addModerationAction: (actionType: ActionType, actionValue?: string | undefined) => void;
} & Pick<UserForModeration, 'newMediaSource' | 'newMediaType' | 'mainMediaSource' | 'newMediaBaseName'>;

export const Actions = ({
  goBack,
  undoAction,
  addModerationAction,
  resetAbout,
  resetQuestions,
  resetName,
  moderationActions,
  isPressed,
  setUserMentalLevel,
  setUserGender,
  newMediaSource,
  newMediaType,
  mainMediaSource,
  isLoading,
  sendModerationActions,
  isNoUndoUsers,
  setMediaState,
  resetOccupation,
}: Props) => {
  const { me } = useAuth();

  const uDatesProduct = me?.realm === Product.Once;
  const flureProduct = me?.realm === Product.Flure;
  const magnetProduct = me?.realm === Product.Magnet;

  const moderatedMedia = useMemo(
    () => (newMediaSource || mainMediaSource)?.replace('.swipe', '').replace(userCachedPhotoSize, ''),
    [newMediaSource, mainMediaSource],
  );

  const man = useCallback(() => {
    addModerationAction(ActionType.SetGender, Gender.Male);
    setUserGender(Gender.Male);
  }, [addModerationAction, setUserGender]);

  const woman = useCallback(() => {
    addModerationAction(ActionType.SetGender, Gender.Female);
    setUserGender(Gender.Female);
  }, [addModerationAction, setUserGender]);

  const setAction = useCallback(
    (actionType: ActionType, actionValue?: string) => () => {
      const newAction: ModerationAction = { actionType };

      if (actionValue) {
        newAction.actionValue = actionValue;
      }
      sendModerationActions([...moderationActions, newAction]);
    },
    [sendModerationActions, moderationActions],
  );

  const nextProfile = useCallback(() => {
    sendModerationActions([...moderationActions, { actionType: ActionType.Approve }]);
  }, [moderationActions, sendModerationActions]);

  if (uDatesProduct) {
    return (
      <UDatesActions
        {...{
          moderationActions,
          goBack,
          newMediaSource,
          newMediaType,
          isNoUndoUsers,
          setAction,
          isLoading,
          addModerationAction,
          sendModerationActions,
          isPressed,
          setMediaState,
          nextProfile,
          man,
          woman,
          undoAction,
          resetName,
          moderatedMedia,
          resetAbout,
          resetOccupation,
        }}
      />
    );
  }

  if (magnetProduct) {
    return (
      <MagnetActions
        {...{
          isLoading,
          goBack,
          undoAction,
          resetName,
          resetQuestions,
          newMediaSource,
          isNoUndoUsers,
          moderationActions,
          sendModerationActions,
          moderatedMedia,
          isPressed,
          addModerationAction,
        }}
      />
    );
  }

  if (flureProduct) {
    return (
      <FlureActions
        moderationActions={moderationActions}
        newMediaSource={newMediaSource}
        isNoUndoUsers={isNoUndoUsers}
        isLoading={isLoading}
        addModerationAction={addModerationAction}
        sendModerationActions={sendModerationActions}
        isPressed={isPressed}
        undoAction={undoAction}
        resetName={resetName}
        moderatedMedia={moderatedMedia}
        resetAbout={resetAbout}
        newMediaType={newMediaType}
        goBack={goBack}
      />
    );
  }

  return (
    <XoXoActions
      {...{
        undoAction,
        goBack,
        resetAbout,
        resetName,
        moderatedMedia,
        moderationActions,
        setUserMentalLevel: setUserMentalLevel as ReactDispatchType<MentalLevel>,
        newMediaSource,
        addModerationAction,
        isLoading,
        sendModerationActions,
        isNoUndoUsers,
        isPressed,
        man,
        woman,
      }}
    />
  );
};
