import React, { ChangeEvent, SetStateAction, useState } from 'react';
import { uniq } from 'lodash';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import SwapVertOutlinedIcon from '@material-ui/icons/SwapVertOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';

import { LabelText, TitleText } from 'src/components/common/flure';

import { useStyles } from './styles';

export enum ListType {
  Moderation = 'moderation',
  Scam = 'scam',
}

enum AddMultipleMode {
  Concat = 'concat',
  Replace = 'replace',
}

type Props = {
  listType: ListType;
  listValues: string[];
  setListValues: React.Dispatch<SetStateAction<string[]>>;
};

export const WordsList = React.memo(({ listType, listValues, setListValues }: Props) => {
  const classes = useStyles();
  const [editableId, setEditableId] = useState('');
  const [currentText, setCurrentText] = useState('');
  const [addMultipleMode, setAddMultipleMode] = useState(AddMultipleMode.Concat);
  const [showTextArea, setShowTextArea] = useState(false);
  const [textAreaText, setTextAreaText] = useState('');
  const title = listType === ListType.Moderation ? 'Moderation Words' : 'Scam Words';

  const deleteWord = (word: string) => {
    setListValues((list) => list.filter((it) => it !== word));
  };

  const copyListValues = () => {
    copy(listValues.join('\n'));
  };

  const addNewWord = () => {
    setListValues((it) => [...it, '']);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentText(event.target.value);
  };

  const addMultiple = (mode: AddMultipleMode) => {
    setAddMultipleMode(mode);
    setShowTextArea(true);
  };

  const onTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaText(event.target.value);
  };

  const onTextAreaApply = () => {
    const newWords = textAreaText.split('\n').reduce<string[]>((acc, it) => {
      if (it) {
        acc.push(it.trim());
      }

      return acc;
    }, []);

    if (addMultipleMode === AddMultipleMode.Concat) {
      setListValues((it) => uniq([...it, ...newWords]));
    } else {
      setListValues(uniq(newWords));
    }
    setTextAreaText('');
    setShowTextArea(false);
  };

  const onTextAreaDelete = () => {
    setTextAreaText('');
    setShowTextArea(false);
  };

  const onFocus = (id: string, initialValue: string) => {
    setCurrentText(initialValue);
    setEditableId(id);
  };

  const onBlur = (initialValue: string) => {
    setListValues((it) => uniq(it.map((value) => (value === initialValue ? currentText : value))));
    setEditableId('');
    setCurrentText('');
  };

  return (
    <div className={classes.columnContainer}>
      <TitleText text={title} />
      <div className={classes.wordsContainer}>
        {listValues.map((it, index) => {
          const id = `${listType}_${it}` || `${listType}_${index}`;

          return (
            <div key={id} className={classes.wordRow}>
              <LabelText className={classes.wordNumber} text={String(index + 1)} />
              <input
                className={classes.wordInput}
                value={id === editableId ? currentText : it}
                onChange={onInputChange}
                onFocus={() => onFocus(id, it)}
                onBlur={() => onBlur(it)}
              />
              <button className={classes.actionButton} type="button" onClick={() => copy(it)}>
                <FileCopyOutlinedIcon />
              </button>
              <button
                className={clsx(classes.actionButton, classes.deleteButton)}
                type="button"
                onClick={() => deleteWord(it)}
              >
                <DeleteOutlineIcon />
              </button>
            </div>
          );
        })}
        {showTextArea && (
          <div className={clsx(classes.wordRow, classes.alignEnd)}>
            <TextareaAutosize className={classes.textArea} value={textAreaText} onChange={onTextAreaChange} />
            <button className={clsx(classes.actionButton, classes.applyButton)} type="button" onClick={onTextAreaApply}>
              <DoneOutlinedIcon />
            </button>
            <button
              className={clsx(classes.actionButton, classes.deleteButton)}
              type="button"
              onClick={onTextAreaDelete}
            >
              <DeleteOutlineIcon />
            </button>
          </div>
        )}
        <div className={classes.buttonsContainer}>
          <button
            className={classes.actionButton}
            type="button"
            onClick={addNewWord}
            disabled={listValues.length > 0 && !listValues[listValues.length - 1] && !currentText}
          >
            <AddBoxOutlinedIcon />
          </button>
          <button className={classes.actionButton} type="button" onClick={() => addMultiple(AddMultipleMode.Concat)}>
            <LibraryAddOutlinedIcon />
          </button>
          <button className={classes.actionButton} type="button" onClick={() => addMultiple(AddMultipleMode.Replace)}>
            <SwapVertOutlinedIcon />
          </button>
          <button className={classes.actionButton} type="button" onClick={copyListValues}>
            <FileCopyOutlinedIcon />
          </button>
        </div>
      </div>
      <TitleText text={title} />
    </div>
  );
});
