import { Box } from '@material-ui/core';
import React from 'react';

import { useModerationMedia } from 'src/services/moderation/raw/hooks';
import { RAW } from 'src/types/raw';

import { Link } from 'react-router-dom';
import { PhotoWrapper } from './photo-wrapper';

import { useStyles } from './styles';

type Props = {
  userId: RAW.User['id'];
};

export const Media = ({ userId }: Props) => {
  const classes = useStyles();

  const media = useModerationMedia(userId);

  const separator = <Box className={classes.separator} />;

  return media.value ? (
    <Box>
      <Box className={classes.title}>😎 Profile photos</Box>
      <Box className={classes.profilePhotos}>
        {media.value.approvedProfilePhotos.map((photo) => (
          <Link to={`/moderation/user/${userId}/${photo.baseName}/${photo.type}`}>
            <PhotoWrapper key={photo.baseName} photo={photo} userId={userId} />
          </Link>
        ))}
      </Box>
      {separator}
      <Box className={classes.title}>🎆 RAW photos</Box>

      <Box className={classes.rawPhotos}>
        {media.value.approvedRawPhotos.map((photo) => (
          <Link to={`/moderation/user/${userId}/${photo.id}/${photo.type}`}>
            <PhotoWrapper key={photo.id} photo={photo} userId={userId} />
          </Link>
        ))}
      </Box>
      {separator}
      <Box className={classes.title}>❌ Declined</Box>
      <Box className={classes.rawPhotos}>
        {media.value.declinedPhotos.map((photo) => (
          <Link
            to={`/moderation/user/${userId}/${photo.type === RAW.ContentType.Default ? photo.baseName : photo.id}/${
              photo.type
            }`}
          >
            <PhotoWrapper
              key={photo.type === RAW.ContentType.Default ? photo.baseName : photo.id}
              photo={photo}
              userId={userId}
            />
          </Link>
        ))}
      </Box>
    </Box>
  ) : (
    <Box>No media</Box>
  );
};
