export const LoggerServices = {
  Moderation: 'moderation',
  ModerationActionsLogger: 'moderation-actions-logger',
};

export const LoggerMessages = {
  ImageLoadDuration: 'image-load-duration',
  LoadImageError: 'load-image-error',
  VideoLoadDuration: 'video-load-duration',
  VerificationImageLoadDuration: 'verification-image-load-duration',
  SendModerationActionsError: 'send-moderation-actions-error',
  UpdateUserThumbnailError: 'update-user-thumbnail-error',
  UserForModerationStateIsNull: 'user-for-moderation-state-is-null',
  MediaForModerationStateIsNull: 'media-for-moderation-state-is-null',
  GetUserInfoError: 'get-user-info-error',
  GetModerationContentError: 'get-moderation-content-error',
  SendUserModerationActions: 'send-user-moderation-actions',
  SendAdminUserModerationActions: 'send-admin-user-moderation-actions',
  SendAdminContentModerationActions: 'send-admin-content-moderation-actions',
};

export const RAWLoggerMessages = {
  CouldNotFindSourceForImage: 'could-not-find-source-for-image',
};

export const MaxMediaLoadDurationBeforeLog = 3000;
