import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { useNextUser, useWaitingListCount } from 'src/services/waiting-list/hooks';
import { useAuth } from 'src/services/auth';
import { sessionDataStorage } from 'src/utils/session-storage';
import { VerificationContentCountResponse } from 'src/network/verification/types';
import { Layout } from 'src/components/App/views/Layout';
import { waitingListRequest } from 'src/network/waiting-list';
import { WaitingListActionType } from 'src/types/waiting-list';
import { moderationRequest } from 'src/network';
import { noop } from 'src/utils/functions';
import { ActionType } from 'src/types/moderation';
import { formatCountryAndCity } from 'src/utils/format-country-and-city';
import { formatGenderIdentityGenderAndAge } from 'src/utils/format-gender-and-age';

import { ActionButton } from '../Moderation/views/action-button';
import { BlockUserModal } from '../Moderation/views/block-user-modal';
import { PrevUserId, UserInterests, WaitingListHeader } from './components';
import { useStyles } from './styles';
import { useKeyListener } from './hooks';
import { MediaContent } from '../Moderation/views/flure';
import { useCommonModerationActions } from '../Moderation/hooks/flure';

const waitingListHandledUsersCountKey = 'waiting-list-handled-users-count';

export const WaitingListScreen: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [prevUserId, setPrevUserId] = useState<string | undefined>();
  const { nextUserState, loadNextUser } = useNextUser();
  const { contentCountState, fetchContentCount } = useWaitingListCount();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useAuth();
  const operatorId = me?.id || '';
  const [handledUsersCount, setHandledUsersCount] = useState<number>(
    Number(sessionDataStorage.get(`${waitingListHandledUsersCountKey}_${operatorId}`)),
  );
  const { count: usersInQueue = 0 } = useMemo(
    () => contentCountState?.value || ({} as VerificationContentCountResponse),
    [contentCountState?.value],
  );

  const submit = useCallback(async (userId: string, action: WaitingListActionType, msg: string) => {
    try {
      await waitingListRequest.sendAction(operatorId, userId, action);
      enqueueSnackbar(msg, { variant: 'success' });
      updateHandledUsersCount();
      fetchContentCount();
      setPrevUserId(userId);
      await loadNextUser();
    } catch (e) {
      enqueueSnackbar(e.toString() || 'Bad request', { variant: 'error' });
    }
  }, []); // eslint-disable-line

  const {
    gender,
    age,
    media = [],
    name,
    desiredRelationship = '-',
    userId,
    interests,
    country,
    city,
    declinedCount = 0,
    genderIdentity,
  } = nextUserState.value || {};

  const { mediaTags, setMediaTags, updateMediaTags, checkIfNeedUpdateMediaTags } = useCommonModerationActions({
    userId: userId!,
    userMedia: media,
  });

  const approveAction = useCallback(() => {
    if (userId) {
      submit(userId, WaitingListActionType.Approve, 'User Approved');

      if (checkIfNeedUpdateMediaTags([{ actionType: ActionType.Approve }])) {
        updateMediaTags(false);
      }
    }
  }, [userId, submit, checkIfNeedUpdateMediaTags, updateMediaTags]);

  const declineAction = useCallback(() => {
    if (userId) {
      submit(userId, WaitingListActionType.Decline, 'User Declined');

      if (checkIfNeedUpdateMediaTags([{ actionType: ActionType.Reject }])) {
        updateMediaTags(false);
      }
    }
  }, [userId, submit, checkIfNeedUpdateMediaTags, updateMediaTags]);

  const updateHandledUsersCount = useCallback(() => {
    setHandledUsersCount((prevHandledUsersCount: number) => {
      const newHandledUsersCount = Number(prevHandledUsersCount) + 1;
      sessionDataStorage.set(`${waitingListHandledUsersCountKey}_${operatorId}`, newHandledUsersCount);
      return newHandledUsersCount;
    });
  }, [operatorId]);

  const onRefreshClick = useCallback(() => {
    fetchContentCount();
    loadNextUser();
  }, [fetchContentCount, loadNextUser]);

  const toggleModal = useCallback(() => {
    setIsModalOpen((modalOpen) => !modalOpen);
  }, []);

  const blockProfile = useCallback(() => {
    toggleModal();

    moderationRequest
      .sendAdminModerationActions(operatorId, nextUserState.value!.userId, [{ actionType: ActionType.Delete }])
      .then(() => {
        enqueueSnackbar('User successfully deleted', { variant: 'success' });
        updateHandledUsersCount();
        fetchContentCount();
        loadNextUser();
      })
      .catch(noop);
  }, [
    toggleModal,
    operatorId,
    nextUserState.value,
    loadNextUser,
    enqueueSnackbar,
    updateHandledUsersCount,
    fetchContentCount,
  ]);

  useEffect(() => {
    fetchContentCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useKeyListener({
    approveAction,
    declineAction,
  });

  if (!nextUserState.value || nextUserState.loading) {
    return (
      <Layout containerSize="xl">
        <WaitingListHeader usersInQueue={usersInQueue} handledUsersCount={handledUsersCount} />
        <div className={classes.buttonsContainer}>
          <Typography variant="h4">{nextUserState.loading ? 'Loading...' : 'No data available'}</Typography>
          {!nextUserState.loading && (
            <Box ml={2}>
              <Button onClick={onRefreshClick} color="inherit" variant="outlined" startIcon={<RefreshIcon />}>
                Refresh
              </Button>
            </Box>
          )}
          {!!prevUserId && <PrevUserId className={classes.preUserIdLoading} userId={prevUserId} />}
        </div>
      </Layout>
    );
  }

  return (
    <Layout containerSize="lg">
      <BlockUserModal isOpen={isModalOpen} onBlock={blockProfile} onCancel={toggleModal} />
      <WaitingListHeader className={classes.header} usersInQueue={usersInQueue} handledUsersCount={handledUsersCount} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.leftContainer}>
            <div className={classes.infoContainer}>
              <span className={classes.fieldName}>Name</span>
              <span className={classes.userName}>
                {name}, {formatGenderIdentityGenderAndAge(genderIdentity, gender, age, false)}
              </span>
              <span className={classes.fieldName}>Looking For</span>
              <span className={classes.userLookingFor}>{desiredRelationship}</span>
              <span className={classes.fieldName}>Interests</span>
              <span className={classes.userFieldContainer}>
                <UserInterests interests={interests} />
              </span>
              <span className={classes.fieldName}>Location</span>
              <span className={classes.userFieldContainer}>
                <span className={classes.location}>{formatCountryAndCity(country, city)}</span>
              </span>
            </div>
            <div>
              <ActionButton onClick={toggleModal} title="Delete profile" />
            </div>
          </div>

          <MediaContent
            userId={userId!}
            userMedia={media}
            showHiddenMedia
            declinedCount={declinedCount}
            mediaTags={mediaTags}
            setMediaTags={setMediaTags}
          />
        </div>

        <div className={classes.buttonsContainer}>
          {!!prevUserId && <PrevUserId className={classes.prevUserId} userId={prevUserId!} />}
          <Button onClick={declineAction} className={clsx(classes.btn, classes.btnDecline)} variant="contained">
            Decline
            <br />
            (V)
          </Button>
          <Button onClick={approveAction} className={clsx(classes.btn, classes.btnApprove)} variant="contained">
            Approve
            <br />
            (N)
          </Button>
        </div>
      </div>
    </Layout>
  );
};
