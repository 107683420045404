import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { AnimatorActivateFormFields } from 'src/types/animator';
import validationSchema from './schema';
import * as S from './styled';

type Props = {
  onSubmit: (values: AnimatorActivateFormFields) => void;
};

export const AnimatorActivateFormWithTextFields = ({ onSubmit }: Props) => {
  const { control, handleSubmit, errors } = useForm<AnimatorActivateFormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      city: 'Denver',
      country: 'US',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Controller
            as={TextField}
            name="city"
            type="string"
            control={control}
            label="City"
            error={!!errors.city}
            helperText={errors.city?.message}
            fullWidth
            variant="outlined"
            size="small"
            margin="normal"
          />
        </Grid>
        <Grid item xs={5}>
          <Controller
            as={TextField}
            name="country"
            type="string"
            control={control}
            label="Country (abbreviation)"
            error={!!errors.country}
            helperText={errors.country?.message}
            fullWidth
            variant="outlined"
            size="small"
            margin="normal"
          />
        </Grid>
        <Grid item xs={2}>
          <S.SubmitButtonWrapper>
            <Button type="submit" variant="contained" color="primary">
              Activate
            </Button>
          </S.SubmitButtonWrapper>
        </Grid>
      </Grid>
    </form>
  );
};
