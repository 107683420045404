import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  margin-bottom: 20px;
  background: #e9edff;
  border-radius: 5px;
  padding: 20px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 20px;
`;
