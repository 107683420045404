import React from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { useAuth } from 'src/services/auth';
import { useStyles } from './styles';

export type ActionButtonProps = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  color?: 'warning' | 'error';
};

export const ActionButton = (props: ActionButtonProps) => {
  const { me } = useAuth();
  const classes = useStyles({ realm: me?.realm });
  const { title, onClick, disabled, color = 'warning' } = props;

  return (
    <Button className={clsx(classes.actionButton, classes[color])} onClick={onClick} disabled={disabled}>
      {title}
    </Button>
  );
};
