import React, { memo } from 'react';

import { ActionType, ModerationAction, UserForModeration } from 'src/types/moderation';
import { MentalLevel } from 'src/types/user';
import { CommonActions } from 'src/components/Moderation/views/actions/common';
import { OnlyGoBack, OnlyUndo } from 'src/components/Moderation/views/actions/common/types';
import { useStyles } from './styles';

type ReactDispatchType<T> = React.Dispatch<React.SetStateAction<T>>;

type Props = {
  undoAction: (actionType: ActionType) => () => void;
  resetAbout: () => void;
  resetName: () => void;
  moderationActions: ModerationAction[];
  setUserMentalLevel?: ReactDispatchType<MentalLevel>;
  addModerationAction: (actionType: ActionType, actionValue?: string | undefined) => void;
  isLoading: boolean;
  sendModerationActions: (actions: ModerationAction[]) => Promise<void>;
  woman: () => void;
  man: () => void;
  isPressed: (actionType: ActionType, actionValue?: string | undefined) => boolean;
  isNoUndoUsers: boolean;
  moderatedMedia: string;
} & (OnlyGoBack | OnlyUndo) &
  Pick<UserForModeration, 'newMediaSource'>;

const XoXoActionsFC = (props: Props) => {
  const classes = useStyles();

  return (
    <CommonActions
      {...props}
      trashable
      adultContentCensorable
      noUserCensorable
      primaryButtonStyle={classes.primaryButton}
      warningButtonStyle={classes.warningButton}
    />
  );
};

export const XoXoActions = memo(XoXoActionsFC);
