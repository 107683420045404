import React from 'react';
import { useSnackbar } from 'notistack';

import { useStore } from 'src/context/raw/moderation/hooks';
import { rawModerationRequest } from 'src/network/moderation/raw';

import { ConfirmationModal } from './confirmation-modal';

type Props = {
  operatorId: string;
  actorId: string;
  moderatedMedia?: string;
  setIsSending: (val: boolean) => void;
  updatePageWithNewUser?: () => Promise<void>;
};

export const BlockUserModal = ({ operatorId, actorId, moderatedMedia, updatePageWithNewUser, setIsSending }: Props) => {
  const { isBlockModalOpen, toggleBlockModal } = useStore(operatorId);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <ConfirmationModal
      isOpen={isBlockModalOpen}
      onConfirm={async () => {
        try {
          toggleBlockModal(false);
          setIsSending(true);
          await rawModerationRequest.sendRAWModerationActionsForUser(operatorId, actorId, [
            { actionType: 'blockUser', actionValue: moderatedMedia },
          ]);
          if (updatePageWithNewUser) {
            await updatePageWithNewUser();
          }
          enqueueSnackbar('User successfully deleted', { variant: 'success' });
        } catch (error) {
          enqueueSnackbar('Some error happened. Contact with DEV team', { variant: 'error' });
        } finally {
          setIsSending(false);
        }
      }}
      onReject={() => {
        toggleBlockModal(false);
      }}
    />
  );
};
