import { Http } from 'src/network/http';
import { sideLimit } from 'src/utils/raw';

const rawHost = 'https://api.raw.app';
const cfHost = 'https://rawapp.sdvcloud.net';

const getUserPhoto = (userId: string, basename: string) => {
  const host = Http.shared().mediaHost;

  const formattedHost = host === rawHost ? cfHost : host;

  return basename ? `${formattedHost}/users/${userId}/photos/${basename}${sideLimit.ByWidth}` : '';
};

export const getRAWPhoto = (userId: string, partitionId: string) => {
  const host = Http.shared().mediaHost;
  const formattedHost = host === rawHost ? cfHost : host;

  return partitionId ? `${formattedHost}/users/${userId}/photos/raw/partitions/${partitionId}${sideLimit.ByWidth}` : '';
};

export const userRAWRequest = { getUserPhoto, getRAWPhoto };
