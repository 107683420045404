import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ModerationAction } from 'src/types/moderation';
import { User } from 'src/types/user';
import { moderationRequest } from 'src/network';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import { useModerationLogger, useModerationNotifications } from 'src/components/Moderation/hooks/flure';

type Props = {
  userId: string;
  fetchFormattedUser: (userId: string, product: Product | undefined) => Promise<User | null>;
  checkIfNeedUpdateMediaTags: (actions: ModerationAction[]) => boolean;
  updateMediaTags: () => void;
};

export const useModerationActions = (props: Props) => {
  const { userId, fetchFormattedUser, checkIfNeedUpdateMediaTags, updateMediaTags } = props;
  const [isSending, setIsSending] = useState(false);
  const history = useHistory();
  const { logSendAdminModerationActions, logSendModerationActionsError } = useModerationLogger();
  const { showNoIdErrorNotification, showSendActionsErrorNotification } = useModerationNotifications();
  const { me } = useAuth();
  const operatorId = me?.id;

  const loadData = useCallback(() => {
    fetchFormattedUser(userId, me?.realm);
  }, [fetchFormattedUser, me?.realm, userId]);

  const goBack = useCallback(() => {
    history.replace(`/moderation/user/${userId}`);
  }, [history, userId]);

  const sendModerationActions = useCallback(
    async (actions: ModerationAction[]) => {
      if (operatorId) {
        if (actions.length > 0) {
          setIsSending(true);

          try {
            const response = await moderationRequest.sendAdminModerationActions(operatorId, userId, actions);
            logSendAdminModerationActions({ actions, userId, response });

            if (checkIfNeedUpdateMediaTags(actions)) {
              updateMediaTags();
            }
          } catch (error) {
            logSendModerationActionsError({ error });
            showSendActionsErrorNotification(error);
          } finally {
            setIsSending(false);
            goBack();
          }
        }
      } else {
        showNoIdErrorNotification();
      }
    },
    [
      operatorId,
      userId,
      logSendAdminModerationActions,
      checkIfNeedUpdateMediaTags,
      updateMediaTags,
      logSendModerationActionsError,
      showSendActionsErrorNotification,
      goBack,
      showNoIdErrorNotification,
    ],
  );

  return {
    isSending,
    loadData,
    sendModerationActions,
    goBack,
  };
};
