import { Http } from 'src/network/http';
import { RAW } from 'src/types/raw';

import { adapter } from '../adapter';

const sendRAWModerationActionsForUser = async (
  operatorId: string,
  actorId: string,
  actions: RAW.BackModerationAction[],
) => {
  return Http.shared().instance.post<RAW.BackModerationAction>(
    `/moderation/${operatorId}/raw/user/${actorId}/actions`,
    { actions },
  );
};

const sendRAWModerationActions = async (operatorId: string, contentId: number, actions: RAW.BackModerationAction[]) => {
  return Http.shared().instance.post<RAW.BackModerationAction>(
    `/moderation/${operatorId}/raw/content/${contentId}/actions`,
    { actions },
  );
};

const getRAWModerationContent = <T extends RAW.ModerationContent>(operatorId: string, contentType: RAW.ContentType) => {
  return Http.shared()
    .instance.get<T>(`/moderation/${operatorId}/raw/content`, { params: { type: contentType } })
    .then(adapter.getModerationContent);
};

const getRAWModerationSearch = (searchValue: string) => {
  return Http.shared()
    .instance.get<RAW.ModerationSearch>(`/moderation/raw/search/${searchValue}`)
    .then(adapter.getResponseData);
};

const getRAWModerationMediaById = (userId: string) => {
  return Http.shared()
    .instance.get<RAW.ModerationMediaResponse>(`/moderation/${userId}/raw/media`)
    .then(adapter.getModerationMediaById);
};

const getRAWModerationActionsById = (userId: string) => {
  return Http.shared()
    .instance.get<RAW.ModerationActions>(`/moderation/${userId}/raw/actions`)
    .then(adapter.getModerationActionsById);
};

const getRAWModerationContentCount = () => {
  return Http.shared()
    .instance.get<RAW.ModerationContentCount>(`/moderation/raw/content/count`)
    .then(adapter.getModerationContentCount);
};

const getRAWUserModerationHistory = (userId: string) => {
  return Http.shared()
    .instance.get<{ actions: RAW.ModerationAction[] }>(`/moderation/${userId}/raw/content/actions`)
    .then(adapter.getResponseData);
};

const undoRAWUserModeration = <T extends RAW.ModerationContent>(operatorId: string) => {
  return Http.shared().instance.put<T>(`/moderation/${operatorId}/raw/content/undo`).then(adapter.getModerationContent);
};

const getRAWUserPreset = <T extends RAW.UserPreset>(userId: string) => {
  return Http.shared().instance.get<T>(`/users/${userId}/raw/presets/main`).then(adapter.getResponseData);
};

const getProfilePhotos = <T extends RAW.ProfilePhotos>(userId: string) => {
  return Http.shared().instance.get<T>(`/users/${userId}/photos`).then(adapter.getResponseData);
};

const getAutoModerationContent = (moderationType: RAW.AutoModerationContentType, omit: number, select: number) => {
  return Http.shared()
    .instance.get<RAW.AutoModerationContentResponse>(`/moderation/raw/content/actions/auto`, {
      params: { type: moderationType, omit, select },
    })
    .then(adapter.getModerationContent);
};

export const rawModerationRequest = {
  getRAWModerationContentCount,
  getRAWUserModerationHistory,
  sendRAWModerationActions,
  undoRAWUserModeration,
  getRAWModerationContent,
  getRAWModerationSearch,
  getRAWModerationActionsById,
  getRAWModerationMediaById,
  getRAWUserPreset,
  sendRAWModerationActionsForUser,
  getAutoModerationContent,
  getProfilePhotos,
};
