import qs from 'qs';
import { isEmpty } from 'lodash';

import { Gender } from 'src/types/user';
import {
  GenderIdentityPreferences,
  LookingForPreferences,
  RelationshipGoalsPreferences,
  RelationshipPreferencePreferences,
  SexualOrientationPreferences,
} from 'src/types/user/preferences';
import { Filter, FilterLocation, FilterRange, FiltersState, SearchParam } from 'src/network/flure-feed/types';
import {
  FilterPartner,
  MAX_AGE,
  MAX_AGE_IN_SEARCH,
  MAX_HEIGHT,
  MIN_AGE,
  MIN_HEIGHT,
} from 'src/components/FlureFeed/constants';

export const filtersToSearchQuery = (filters: FiltersState, userGenderIdentity: GenderIdentityPreferences): string => {
  const userGenderAtBirth = userGenderIdentity === GenderIdentityPreferences.Woman ? Gender.Female : Gender.Male;
  const isNotManOrWomanIdentity =
    userGenderIdentity !== GenderIdentityPreferences.Man && userGenderIdentity !== GenderIdentityPreferences.Woman;
  const isMale = userGenderAtBirth === Gender.Male;
  const lookingForFromFilters = filters[Filter.LookingFor] || [];
  const allSexualOrientations = Object.values(SexualOrientationPreferences);

  let genderIdentities = [] as GenderIdentityPreferences[];
  let genderAtBirth = [] as Gender[];
  let sexualOrientationsMale = [] as SexualOrientationPreferences[];
  let sexualOrientationsFemale = [] as SexualOrientationPreferences[];
  let hasPartner = [] as FilterPartner[];
  let partnerGenderIdentityMan = [] as GenderIdentityPreferences[];
  let partnerGenderIdentityWoman = [] as GenderIdentityPreferences[];

  const filteredFemaleOrientations = allSexualOrientations.filter((so) => {
    if (isMale) {
      return so !== SexualOrientationPreferences.GayLesbian;
    }
    return so !== SexualOrientationPreferences.Straight;
  });

  const filteredMaleOrientations = allSexualOrientations.filter((so) => {
    if (isMale) {
      return so !== SexualOrientationPreferences.Straight;
    }
    return so !== SexualOrientationPreferences.GayLesbian;
  });

  lookingForFromFilters.forEach((it) => {
    let orientationsMale = [] as SexualOrientationPreferences[];
    let orientationsFemale = [] as SexualOrientationPreferences[];

    switch (it) {
      case LookingForPreferences.Man:
        genderAtBirth.push(Gender.Male);
        genderIdentities.push(GenderIdentityPreferences.Man);
        hasPartner.push(FilterPartner.true, FilterPartner.false);
        orientationsMale = isNotManOrWomanIdentity ? allSexualOrientations : filteredMaleOrientations;
        break;
      case LookingForPreferences.Woman:
        genderAtBirth.push(Gender.Female);
        genderIdentities.push(GenderIdentityPreferences.Woman);
        hasPartner.push(FilterPartner.true, FilterPartner.false);
        orientationsFemale = isNotManOrWomanIdentity ? allSexualOrientations : filteredFemaleOrientations;
        break;
      case LookingForPreferences.WomanMan:
        genderAtBirth.push(Gender.Female, Gender.Male);
        genderIdentities.push(GenderIdentityPreferences.Woman, GenderIdentityPreferences.Man);
        hasPartner.push(FilterPartner.true);
        partnerGenderIdentityWoman.push(GenderIdentityPreferences.Man);
        partnerGenderIdentityMan.push(GenderIdentityPreferences.Woman);
        orientationsMale = isNotManOrWomanIdentity ? allSexualOrientations : filteredMaleOrientations;
        orientationsFemale = isNotManOrWomanIdentity ? allSexualOrientations : filteredFemaleOrientations;
        break;
      case LookingForPreferences.WomanWoman:
        genderAtBirth.push(Gender.Female);
        genderIdentities.push(GenderIdentityPreferences.Woman);
        hasPartner.push(FilterPartner.true);
        partnerGenderIdentityWoman.push(GenderIdentityPreferences.Woman);
        orientationsFemale = isNotManOrWomanIdentity ? allSexualOrientations : filteredFemaleOrientations;
        break;
      case LookingForPreferences.ManMan:
        genderAtBirth.push(Gender.Male);
        genderIdentities.push(GenderIdentityPreferences.Man);
        hasPartner.push(FilterPartner.true);
        partnerGenderIdentityMan.push(GenderIdentityPreferences.Man);
        orientationsMale = isNotManOrWomanIdentity ? allSexualOrientations : filteredMaleOrientations;
        break;
      default:
        genderAtBirth.push(Gender.Female, Gender.Male);
        genderIdentities.push(it as GenderIdentityPreferences);
        hasPartner.push(FilterPartner.true, FilterPartner.false);
        orientationsMale = allSexualOrientations;
        orientationsFemale = allSexualOrientations;
        break;
    }

    sexualOrientationsMale = Array.from(new Set([...sexualOrientationsMale, ...orientationsMale]));
    sexualOrientationsFemale = Array.from(new Set([...sexualOrientationsFemale, ...orientationsFemale]));
  });

  hasPartner = Array.from(new Set(hasPartner));
  genderIdentities = Array.from(new Set(genderIdentities));
  genderAtBirth = Array.from(new Set(genderAtBirth));
  partnerGenderIdentityMan = Array.from(new Set(partnerGenderIdentityMan));
  partnerGenderIdentityWoman = Array.from(new Set(partnerGenderIdentityWoman));
  const ageFilter = filters[Filter.Age] || { min: MIN_AGE, max: MAX_AGE };
  const ageRangeInSearch = ageFilter?.max === MAX_AGE ? { min: ageFilter.min, max: MAX_AGE_IN_SEARCH } : ageFilter;

  const searchParams: Partial<
    Record<SearchParam, FilterRange | FilterPartner[] | string[] | string | GenderIdentityPreferences[] | boolean>
  > = {
    [SearchParam.isOperator]: true,
    [SearchParam.Age]: ageRangeInSearch,
    [SearchParam.Height]: filters[Filter.Height],
    [SearchParam.VirtualLocation]: filters[Filter.Location],
    [SearchParam.RelationshipPreferences]: filters[Filter.RelationshipPreference],
    [SearchParam.RelationshipGoals]: filters[Filter.RelationshipGoals],
    [SearchParam.GenderIdentity]: genderIdentities,
    [SearchParam.GenderAtBirth]: genderAtBirth,
    [SearchParam.SexualOrientationMale]: sexualOrientationsMale,
    [SearchParam.SexualOrientationFemale]: sexualOrientationsFemale,
    ...(!isEmpty(hasPartner) && { [SearchParam.Partner]: hasPartner }),
    ...(!isEmpty(partnerGenderIdentityMan) && { [SearchParam.PartnerGenderIdentityMan]: partnerGenderIdentityMan }),
    ...(!isEmpty(partnerGenderIdentityWoman) && {
      [SearchParam.PartnerGenderIdentityWoman]: partnerGenderIdentityWoman,
    }),
  };

  const queryFromSearchParams = qs.stringify(searchParams, { arrayFormat: 'comma' });

  return queryFromSearchParams;
};

export const allFiltersSearchQuery = () => {
  const allSexualOrientations = Object.values(SexualOrientationPreferences);
  const allGenderIdentities = Object.values(GenderIdentityPreferences);

  const searchParams: Partial<
    Record<SearchParam, FilterRange | FilterPartner[] | string[] | string | GenderIdentityPreferences[] | boolean>
  > = {
    [SearchParam.isOperator]: true,
    [SearchParam.Age]: { min: MIN_AGE, max: MAX_AGE_IN_SEARCH },
    [SearchParam.Height]: { min: MIN_HEIGHT, max: MAX_HEIGHT },
    [SearchParam.VirtualLocation]: FilterLocation.Virtual,
    [SearchParam.RelationshipPreferences]: Object.values(RelationshipPreferencePreferences),
    [SearchParam.RelationshipGoals]: Object.values(RelationshipGoalsPreferences),
    [SearchParam.GenderIdentity]: allGenderIdentities,
    [SearchParam.GenderAtBirth]: [Gender.Male, Gender.Female],
    [SearchParam.SexualOrientationMale]: allSexualOrientations,
    [SearchParam.SexualOrientationFemale]: allSexualOrientations,
    [SearchParam.Partner]: [FilterPartner.false, FilterPartner.true],
    [SearchParam.PartnerGenderIdentityMan]: allGenderIdentities,
    [SearchParam.PartnerGenderIdentityWoman]: allGenderIdentities,
  };

  const queryFromSearchParams = qs.stringify(searchParams, { arrayFormat: 'comma' });

  return queryFromSearchParams;
};
