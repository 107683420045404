import { useCallback } from 'react';

import { Key, KeyTitlesMapper, useKeyboardEventListener } from 'src/components/Moderation/utils/keyboard';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';

type Props = {
  approveAction: () => void;
  declineAction: () => void;
};
export const useKeyListener = ({ approveAction, declineAction }: Props) => {
  const keyPressListener = useCallback(
    (event: KeyboardEvent) => {
      if (!event.defaultPrevented) {
        ActionsLogger.addKeyCode(KeyTitlesMapper[event.keyCode]);

        switch (event.keyCode) {
          case Key.N:
            approveAction();
            break;
          case Key.V:
            declineAction();
            break;
          default:
            break;
        }
      }
      return undefined;
    },
    [approveAction, declineAction],
  );

  useKeyboardEventListener(keyPressListener);
};
