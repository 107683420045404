import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<
  Theme,
  {
    topLabelColor?: string;
  }
>({
  videoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  videoWithLabelContainer: {
    display: 'inline-block',
    position: 'relative',
    height: '100%',
  },

  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  celebrityContainer: {
    position: 'absolute',
    maxWidth: '90%',
    left: 0,
    right: 0,
    margin: 'auto',
    bottom: '10px',
    padding: '4px 8px',
    borderRadius: '8px',
    backgroundColor: 'rgb(136, 63, 255)',
  },

  celebrityName: {
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
    color: '#fff',
  },

  topLabelContainer: {
    position: 'absolute',
    maxWidth: '90%',
    right: '4px',
    top: '4px',
    padding: '4px 8px',
    borderRadius: '8px',
    backgroundColor: (props) => (props.topLabelColor ? props.topLabelColor : '#ED654C'),
  },

  topLabelText: {
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
    color: '#fff',
  },
});
