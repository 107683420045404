import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserForModerationFlure } from 'src/types/moderation';

type Props = {
  contentId: number;
  userId: string;
  userForModerationStateValue: UserForModerationFlure | null | undefined;
  loadData: () => void;
  fetchContentCount: () => void;
};

export const useBackgroundTasks = (props: Props) => {
  const { loadData, contentId, userId, userForModerationStateValue, fetchContentCount } = props;
  const history = useHistory();

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (contentId && userId) {
      history.push(`/moderation/content?id=${userId}`);
    }
  }, [contentId, history, userId]);

  useEffect(() => {
    if (userForModerationStateValue === null) {
      history.push(`/moderation/content`);
    }
  }, [userForModerationStateValue, history]);

  useEffect(() => {
    fetchContentCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
