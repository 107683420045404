import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexBasis: '100%',
  },

  wrapper: {
    height: '100vh',
  },

  placeholderContainer: {
    height: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  placeholderText: {
    fontWeight: 'bold',
    fontSize: '40px',
    color: '#020D3D',
    opacity: 0.5,
    textAlign: 'center',
  },
});
