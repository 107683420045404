import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-size: 24px;
  margin-right: 16px;
`;

export const ListItem = styled.li`
  & + & {
    margin-top: 6px;
  }
`;

export const ListItemInner = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  background: #ddd;
  color: #000;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 3px;
  padding: 4px 8px;
  margin-left: 8px;

  &:hover {
    opacity: 0.7;
  }
`;

export const SearchedListItem = styled.div`
  margin-top: 6px;
`;
