import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, debounce } from '@material-ui/core';
import { usePrevious } from 'react-use';

import { useStyles, withTextFieldStyles } from './styles';
import { Option } from '../bubble-checkboxes-list';

type Props = {
  options: Option[];
  defaultValue?: Option;
  value?: Option;
  disableClearable?: boolean;
  onChange?: (value: Option | null) => void;
  searchLocations: (substring?: string) => void;
};

const getOptionLabel = (option: Option) => option.text;

const CustomTextField = withTextFieldStyles(TextField);

const AutocompleteSearchableInputView = (props: Props) => {
  const { defaultValue = null, options, disableClearable = true, onChange, searchLocations } = props;
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);

  const [inputValue, setInputValue] = useState(defaultValue?.text || '');
  const previousInputValue = usePrevious(value?.text || '');

  const handleChange = useCallback(
    (_: React.ChangeEvent<{}>, selectedOption: Option | null) => {
      if (onChange) {
        onChange(selectedOption);
        setValue(selectedOption);
      }
    },
    [onChange],
  );

  const fetch = useMemo(
    () =>
      debounce((input: string) => {
        searchLocations(input);
      }, 400),
    [searchLocations],
  );

  useEffect(() => {
    if (inputValue !== previousInputValue && inputValue !== value?.text) {
      fetch(inputValue);
    }
  }, [value, inputValue, fetch, previousInputValue]);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      className={classes.fieldStyle}
      renderInput={(params) => <CustomTextField {...params} variant="outlined" />}
      onChange={handleChange}
      value={value}
      defaultValue={defaultValue}
      disableClearable={disableClearable}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      noOptionsText="No locations"
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
};

export const AutocompleteSearchableInput = React.memo(AutocompleteSearchableInputView);
