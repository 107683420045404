import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  label: {
    fontSize: 14,
    lineHeight: '17px',
    fontWeight: 600,
    color: '#FFFFFF',
    borderRadius: 8,
    padding: '8px 4px',
    backgroundColor: '#FF3B30',
    position: 'absolute',
    bottom: 4,
    right: 4,
  },
});
