export enum UserPreferences {
  GenderIdentity = 'gender-identity',
  SexualOrientation = 'sexual-orientation',
  SexualIdentity = 'sexual-identity',
  RelationshipPreference = 'relationship-preference',
  Partner = 'partner',
  Desires = 'desires',
  LookingFor = 'looking-for',
  Relationship = 'desired-relationship',
  RelationshipGoals = 'relationship-goals',
}

export enum LookingForPreferences {
  Woman = 'woman',
  Man = 'man',
  WomanMan = 'woman-man',
  WomanWoman = 'woman-woman',
  ManMan = 'man-man',
}

export enum SexualOrientationPreferences {
  Straight = 'straight',
  GayLesbian = 'gay-lesbian',
  Bisexual = 'bisexual',
  Heteroflexible = 'heteroflexible',
  Homoflexible = 'homoflexible',
  Pansexual = 'pansexual',
  Fluid = 'fluid',
  Questioning = 'questioning',
}

export enum GenderIdentityPreferences {
  Man = 'man',
  Woman = 'woman',
  NonBinary = 'non-binary',
  Agender = 'agender',
  Androgynous = 'androgynous',
  Bigender = 'bigender',
  GenderFluid = 'gender-fluid',
  GenderNonconforming = 'gender-nonconforming',
  GenderQueer = 'genderqueer',
  GenderQuestioning = 'gender-questioning',
  Intersex = 'intersex',
}

export enum RelationshipPreferencePreferences {
  Monoamorous = 'monoamorous',
  Polyamorous = 'polyamorous',
  Ambiamorous = 'ambiamorous',
}

export enum RelationshipGoalsPreferences {
  LongTerm = 'long-term',
  ShortTerm = 'short-term',
  VirtualRomance = 'virtual-romance',
  FriendsWithBenefits = 'friends-with-benefits',
  Hookup = 'hookup',
  Friendship = 'friendship',
  FutureMarriage = 'future-marriage',
}

export type ApiUserPreferences = {
  [UserPreferences.GenderIdentity]: GenderIdentityPreferences;
  [UserPreferences.SexualOrientation]: SexualOrientationPreferences;
  [UserPreferences.SexualIdentity]: string[];
  [UserPreferences.RelationshipPreference]: string;
  [UserPreferences.Partner]: boolean;
  [UserPreferences.Desires]: string[];
  [UserPreferences.LookingFor]: LookingForPreferences[];
  [UserPreferences.Relationship]: string;
};
