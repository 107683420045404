import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const button = {
  color: 'white',
  fontSize: '16px',
  textTransform: 'none',
  height: '64px',
  width: '100%',
  borderRadius: '8px',
  fontFamily: 'Poppins',
  '&:hover': {
    opacity: 0.8,
  },
} as CSSProperties;

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '426px',
    height: 'calc(100vh - 150px)',
    margin: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  button: {
    ...button,
    marginBottom: '20px',
    backgroundColor: '#EF7BF8',
  },

  findFieldWrapper: {
    borderRadius: '8px',
    flexDirection: 'row',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    borderWidth: '1px',
    borderColor: '#EF7BF8',
    borderStyle: 'solid',
    marginTop: '20px',
    width: '100%',
  },

  findField: {
    padding: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  buttonSearch: {
    ...button,
    marginTop: '20px',
    backgroundColor: '#FFFFFF',
    borderWidth: '1px',
    borderColor: '#EF7BF8',
    borderStyle: 'solid',
    color: '#000000',
  },

  findButton: {
    ...button,
    backgroundColor: '#EF7BF8',
    width: '96px',
    borderRadius: '0px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },

  textInput: {
    fontSize: 16,
    outlineStyle: 'none',
  },
});
