import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useAsync } from 'react-use';

import { rawModerationRequest } from 'src/network/moderation/raw';
import { RAW } from 'src/types/raw';

import { useStyles } from './styles';
import { ActionsHistoryLabel } from '../action-history-label';

type Props = {
  userId: RAW.User['id'];
};

export const ActionsHistory = ({ userId }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const actions = useAsync(async () => {
    try {
      const result = await rawModerationRequest.getRAWModerationActionsById(userId);

      return result;
    } catch (e) {
      enqueueSnackbar('Error while fetching actions', { variant: 'error' });
      return null;
    }
  }, [userId]);

  return actions.value?.length ? (
    <Box>
      {actions.value.map((action, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box className={classes.item} key={`${action.createdAt}-${i}`}>
          <ActionsHistoryLabel
            boldInitiatorRole
            date={action.createdAt}
            actionTypes={action.actionTypes}
            initiator={action.initiator}
          />
        </Box>
      ))}
    </Box>
  ) : (
    <Box>No actions</Box>
  );
};
