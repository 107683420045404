import { Http } from 'src/network/http';
import { GetReportByBonusesRequestParams, BonusesReportDto } from './types';
import { adapter } from './adapter';

const get = (params: GetReportByBonusesRequestParams) => {
  return Http.shared()
    .instance.get<BonusesReportDto>(`/reports/bonuses/operators`, { params })
    .then(adapter.get);
};

export const bonusesReportsRequest = {
  get,
};
