import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;

export const InputWrapper = styled.div`
  width: 303px;
  margin-bottom: 20px;
`;

export const FormInner = styled.span`
  padding: 20px 0;
  background: #f1f1f1;
  border-radius: 10px;
`;
