import { Datadog } from './datadog';
import { Level, Log } from './types';

export class Logger {
  private static action(level: Level, payload: Log) {
    try {
      const { service, message, product } = payload;

      const data = {
        service,
        message,
        product,
        ...(payload.payload && { payload: payload.payload }),
      };

      Datadog.log(level, data);

      // eslint-disable-next-line no-console
      console[level](`[${payload.service}]`, payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  static log(payload: Log) {
    Logger.action(Level.debug, payload);
  }
}
