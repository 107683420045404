import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
  border-collapse: collapse;
`;

export const TableHeadCell = styled.th`
  font-weight: bold;
  padding: 5px;
  background: #efefef;
  border: 1px solid #dddddd;
  width: 20%;
`;

export const TableBodyCell = styled.td`
  border: 1px solid #dddddd;
  padding: 5px;
  height: 51px;
`;
