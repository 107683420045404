import { makeStyles, Theme } from '@material-ui/core';
import { Product } from 'src/types/product';

export const useStyles = makeStyles<
  Theme,
  {
    realm?: Product;
  }
>({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: ({ realm }) => (realm === Product.Flure ? undefined : '70vh'),
    minHeight: ({ realm }) => (realm === Product.Flure ? '550px' : undefined),
    padding: ({ realm }) => (realm === Product.Flure ? '0px 0px 40px' : '3vh 0'),
  },

  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    paddingRight: '10%',
  },

  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',
  },

  mediasContainer: {
    width: '100%',
    height: '60vh',
    padding: '24px 16px',
    background: 'white',
    boxShadow:
      '0px 18.6951px 24.9268px rgba(0, 0, 0, 0.04), 0px 12.4634px 18.6951px rgba(0, 0, 0, 0.04), 0px 3.11585px 6.23171px rgba(0, 0, 0, 0.04), 0px 0px 0.778964px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
  },

  mediaContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginInline: '8px',
    position: 'relative',
  },

  mediaName: {
    fontWeight: 'bold',
    fontSize: '18px',
    display: 'flex',
    textAlign: 'center',
    color: ({ realm }) => (realm === Product.Magnet ? '#7E1BE3' : '#020D3D'),
    marginBottom: '16px',
  },

  media: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',

    filter:
      'drop-shadow(0 -7px 0 transparent) drop-shadow(0 7px 0 transparent) drop-shadow(-7px 0 0 transparent) drop-shadow(7px 0 0 transparent)',
  },

  uDatesMedia: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
    cursor: 'pointer',

    filter:
      'drop-shadow(0 -7px 0 transparent) drop-shadow(0 7px 0 transparent) drop-shadow(-7px 0 0 transparent) drop-shadow(7px 0 0 transparent)',
  },

  mediaBorderBlue: {
    filter:
      'drop-shadow(0 -7px 0 #985EF8) drop-shadow(0 7px 0 #985EF8) drop-shadow(-7px 0 0 #985EF8) drop-shadow(7px 0 0 #985EF8)',
    cursor: 'pointer',
  },

  mediaBorderRed: {
    filter:
      'drop-shadow(0 -7px 0 #F31D34) drop-shadow(0 7px 0 #F31D34) drop-shadow(-7px 0 0 #F31D34) drop-shadow(7px 0 0 #F31D34)',
    cursor: 'pointer',
  },

  fieldContainer: {
    marginBottom: '5vh',
    paddingLeft: '32px',

    borderLeftWidth: '7px',
    borderLeftColor: 'transparent',
    borderLeftStyle: 'solid',
  },

  nameWithPhotoContainer: {
    display: 'flex',
  },

  fieldChangedBlue: {
    borderLeftColor: '#985EF8',
  },

  fieldChangedRed: {
    borderLeftColor: '#F31D34',
  },

  name: {
    fontWeight: 'bold',
    fontSize: '40px',
    color: ({ realm }) => (realm === Product.Magnet ? '#7E1BE3' : '#020D3D'),

    marginBottom: '16px',
  },

  bio: {
    marginBottom: '16px',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#020D3D',
    opacity: 0.5,
    overflowY: 'auto',
    height: '20vh',
  },

  occupation: {
    marginBottom: '16px',
    fontSize: '30px',
    lineHeight: '36px',
    color: '#020D3D',
    opacity: 0.5,
    overflow: 'scroll',
  },

  topContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  logo: {
    height: '44px',
    width: 'auto',
    resizeMode: 'contain',
  },

  progressContainer: {
    display: 'flex',
  },

  progressText: {
    fontSize: '18px',
    color: '#020D3D',
    marginRight: '40px',
  },

  bottomContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1vh 0',
  },

  moderationButton: {
    color: 'white',
    textTransform: 'none',
    backgroundColor: '#40A3FF',
    borderRadius: '8px',
    marginInline: '2px',

    '@media (min-width: 1220px)': {
      minWidth: '100px',
      height: '78px',
      fontSize: '20px',
    },

    height: '60px',
    fontSize: '14px',
    minwidth: '60px',
  },
  blueButton: {
    backgroundColor: '#40A3FF',
    '&:hover': {
      backgroundColor: '#40A3FF',
      opacity: 0.8,
    },
  },
  orangeButton: {
    backgroundColor: '#ED654C',
    '&:hover': {
      backgroundColor: '#ED654C',
      opacity: 0.8,
    },
  },

  orangeButtonPressed: {
    backgroundColor: '#ab280f',
    '&:hover': {
      backgroundColor: '#ab280f',
      opacity: 0.8,
    },
  },

  blueButtonPressed: {
    backgroundColor: '#2DE440',
    '&:hover': {
      backgroundColor: '#2DE440',
      opacity: 0.8,
    },
  },

  purpleButton: {
    backgroundColor: '#883FFF',
    '&:hover': {
      backgroundColor: '#883FFF',
      opacity: 0.8,
    },
  },

  nextButton: {
    width: '246px',
  },

  placeholderContainer: {
    height: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  placeholderText: {
    fontWeight: 'bold',
    fontSize: '40px',
    color: '#020D3D',
    opacity: 0.5,
    textAlign: 'center',
  },

  userIdContainer: {
    marginTop: '20px',
    color: '#020D3D',
    opacity: 0.5,
    fontSize: '16px',
    lineHeight: '19px',
    alignSelf: 'flex-start',
  },

  genderText: {
    fontSize: '25px',
    fontWeight: 'bold',
    position: 'absolute',
    bottom: '-75px',
  },

  rejectPressed: {
    opacity: '20%',
  },

  cursorPointer: {
    cursor: 'pointer',
  },
});
