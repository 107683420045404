import { isNil } from 'lodash';
import { Gender } from 'src/types/user';
import { SexualOrientationPreferences } from 'src/types/user/preferences';
import { partnerDict, sexualOrientationDict } from 'src/components/FlureFeed/constants';

import { kmsToMiles } from './distance-converter';

type Params = {
  age: number | undefined;
  sexualOrientation: SexualOrientationPreferences | undefined;
  gender: Gender | undefined;
  hasPartner: boolean | undefined;
};

export const formatUserFields = ({ age, sexualOrientation, gender, hasPartner }: Params) => {
  let orientationString = '';

  if (sexualOrientation && gender) {
    orientationString =
      sexualOrientation === SexualOrientationPreferences.GayLesbian
        ? sexualOrientationDict[`${sexualOrientation}-${gender}`]
        : sexualOrientationDict[sexualOrientation];
  }

  const fields = [String(age), orientationString, partnerDict[String(hasPartner)]].filter(Boolean);

  if (fields.length > 0) {
    return fields.join(' · ');
  }

  return '';
};

export const formatDistance = (distance: number | undefined) => {
  if (!isNil(distance)) {
    return `${kmsToMiles(distance)} mi away`;
  }

  return '';
};
