import React from 'react';
import clsx from 'clsx';

import { PromoCodeField, fieldToTitleMap, fieldToTooltipTextMap } from 'src/components/LocalEvents/types-and-constants';

import { useStyles } from './styles';

export const TableTh = React.memo(({ field }: { field: PromoCodeField }) => {
  const classes = useStyles();
  return (
    <th
      className={clsx(
        field === PromoCodeField.id && classes.mediumCell,
        field === PromoCodeField.subTTL && classes.smallCell,
        field === PromoCodeField.qr && classes.smallCell,
        field === PromoCodeField.activeFrom && classes.dateCell,
        field === PromoCodeField.activeUntil && classes.dateCell,
      )}
      title={fieldToTooltipTextMap[field]}
    >
      {fieldToTitleMap[field]}
    </th>
  );
});
