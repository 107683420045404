import { useAsyncFn } from 'react-use';
import moment from 'moment';

import { reportRequest } from 'src/network';
import { GetReportByBonusesRequestParams } from 'src/network/report/bonuses/types';
import { useAuth } from 'src/services/auth';
import { getRealm, BackendAlias } from 'src/utils/realm';
import { DefaultReportFormFields } from 'src/types/report/common';

function convertFormFieldsToQueryParams(
  fields: DefaultReportFormFields,
  realm: BackendAlias,
): GetReportByBonusesRequestParams {
  const [from, to] = fields.rangeDate.split(':');

  const params: GetReportByBonusesRequestParams = {
    searchMode: fields.mode,
    operator: fields.operatorId ? String(fields.operatorId) : null,
    realm,
    from,
    to: moment(to).add(1, 'day').format('YYYY-MM-DD'),
    agency: fields.agencyTechName ? String(fields.agencyTechName) : undefined,
  };

  return params;
}

export const useReportByBonuses = () => {
  const { me } = useAuth();

  const realm = getRealm(me?.realm);

  const [reportByBonusesState, fetchReportByBonuses] = useAsyncFn(
    async (params: DefaultReportFormFields) => {
      const queryParams = convertFormFieldsToQueryParams(params, realm);
      return reportRequest.bonuses.get(queryParams);
    },
    [realm],
  );

  return { reportByBonusesState, fetchReportByBonuses };
};
