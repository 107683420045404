import { useCallback, useMemo, useState } from 'react';

import { useAuth } from 'src/services/auth';
import { sessionDataStorage } from 'src/utils/session-storage';
import { VerificationContentCountResponse } from 'src/network/verification/types';
import { useVerificationContentCount } from 'src/services/verification/hooks';

const verificationHandledUsersCountKey = 'verification-handled-users-count';

export const useUsersCount = () => {
  const { contentCountState, fetchContentCount } = useVerificationContentCount();
  const { me } = useAuth();
  const operatorId = me?.id || '';
  const [handledUsersCount, setHandledUsersCount] = useState<number>(
    Number(sessionDataStorage.get(`${verificationHandledUsersCountKey}_${operatorId}`)),
  );
  const { count: usersInQueue = 0 } = useMemo(
    () => contentCountState?.value || ({} as VerificationContentCountResponse),
    [contentCountState?.value],
  );

  const updateHandledUsersCount = useCallback(() => {
    setHandledUsersCount((prevHandledUsersCount: number) => {
      const newHandledUsersCount = Number(prevHandledUsersCount) + 1;
      sessionDataStorage.set(`${verificationHandledUsersCountKey}_${operatorId}`, newHandledUsersCount);
      return newHandledUsersCount;
    });
  }, [operatorId]);

  return {
    fetchContentCount,
    handledUsersCount,
    usersInQueue,
    updateHandledUsersCount,
  };
};
