import React from 'react';
import { useAsyncFn } from 'react-use';
import generator from 'generate-password';
import { nanoid } from 'nanoid';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';

import { Product } from 'src/types/product';
import { Cities, Countries } from 'src/types/operator';
import { UserDto } from 'src/types/user';
import { operatorRequest, authRequest, userRequest } from 'src/network';
import { operatorsLocation, DEFAULT_LANGUAGE } from 'src/services/operator/config';
import { getRealm } from 'src/utils/realm';
import { OperatorForm } from 'src/components/Multichat/OperatorsManaging/CreationForm/schema';
import { useAuth } from 'src/services/auth';
import { getLocation, locationHasError } from 'src/network/location';

export const useManagingNewOperator = () => {
  const { me } = useAuth();
  const realm = getRealm(me?.realm);
  const isMagnetProduct = realm === Product.Magnet;
  const isOnceProduct = me?.realm === Product.Once;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [operatorsData, manageOperator] = useAsyncFn(
    async (values: OperatorForm) => {
      const registerData = {
        email: `atolive1+${nanoid(6)}@gmail.com`,
        password: generator.generate({
          length: 10,
          numbers: true,
          symbols: true,
        }),
      };
      try {
        const { data } = await authRequest.register(registerData);
        const { country, latitude, longitude, language } = operatorsLocation[values.city];
        let location = {
          country,
          latitude,
          longitude,
          city: values.city,
          languages: [language],
        };

        if (values.isFreeMember && values.freeMemberAnimatorClientId) {
          const animatorData: Partial<UserDto> & { error?: string } = await userRequest
            .getById(values.freeMemberAnimatorClientId, true)
            .catch(() => ({ error: 'Wrong Animator ID' }));

          if (animatorData.error) {
            throw new Error(animatorData.error);
          }
        }

        if (values.isFreeMember && values.animatorCity && values.animatorCountry) {
          const locationData = await getLocation({
            city: values.animatorCity,
            country: values.animatorCountry,
          });

          if (locationHasError(locationData)) {
            throw new Error(
              `Wrong location data for city: ${values.animatorCity}, country: ${values.animatorCountry}. Error status: ${locationData.error}`,
            );
          }

          location = {
            city: values.animatorCity as Cities,
            country: values.animatorCountry as Countries,
            latitude,
            longitude,
            languages: [DEFAULT_LANGUAGE],
          };
        }

        const updateDTO = {
          name: 'Op1',
          birthday: '1995-04-01T00:00:00.000Z', // mocked birthday. should be just more than 18 years old
          city: location.city,
          gender: 'mal',
          country: location.country,
          latitude: location.latitude,
          longitude: location.longitude,
          languages: isOnceProduct ? location.languages : undefined,
        };

        await userRequest.update(data.id, updateDTO);

        await operatorRequest.activate(data.id, {
          firstName: values.firstName,
          realm,
          lastName: values.lastName,
          commission: values.commission,
          isReadyForExplicit: values.isReadyForExplicit,
          city: location.city,
          bonuses: isMagnetProduct
            ? [
                {
                  bonus: 'starter_bonus',
                  value: values.starterBonusCommission,
                },
                {
                  bonus: 'follow_bonus',
                  value: values.followBonusCommission,
                },
              ]
            : undefined,
          country: location.country,
          isFreeMember: values.isFreeMember,
          freeMemberAnimatorClientId: values.freeMemberAnimatorClientId || undefined,
        });
        enqueueSnackbar(
          `Operator successfully created \n id: ${data.id} \n email: ${registerData.email}  \n password: ${registerData.password}`,
          {
            variant: 'success',
            persist: true,
            style: { whiteSpace: 'pre-line' },
            action: () => (
              <Button style={{ color: 'white' }} onClick={() => closeSnackbar()}>
                Close
              </Button>
            ),
          },
        );
      } catch (error: unknown) {
        if (error instanceof Error || typeof error === 'string') {
          enqueueSnackbar(error.toString() || 'Bad request', { variant: 'error' });
          throw new Error(error.toString());
        }
      }
    },
    [],
    { loading: true },
  );

  return { operatorsData, manageOperator };
};
