import React from 'react';
import { Controller, DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import ReactHookFormSelect from 'src/components/common/ReactHookFormSelect';

import { OperatorForm } from './schema';
import { ConditionalField } from './conditional-field';
import * as S from './styled';

type Props = {
  control: UseFormMethods['control'];
  errors: DeepMap<OperatorForm, FieldError>;
  cityItems: JSX.Element[];
} & Pick<React.ComponentProps<typeof ConditionalField>, 'unregister' | 'watch'>;

export const ActivateAnimatorFields = (props: Props) => {
  const { control, errors, cityItems, watch, unregister } = props;

  return (
    <>
      <S.InputWrapper>
        <Controller
          name="isFreeMember"
          control={control}
          error={!!errors.isFreeMember}
          render={({ onChange, value, ...rest }) => (
            <FormControlLabel
              control={<Checkbox {...rest} checked={value} onChange={(e) => onChange(e.target.checked)} />}
              label="free member"
            />
          )}
        />
      </S.InputWrapper>

      <ConditionalField
        name="freeMemberAnimatorClientId"
        conditionalFieldId="isFreeMember"
        watch={watch}
        unregister={unregister}
      >
        <S.InputWrapper>
          <Controller
            as={TextField}
            name="freeMemberAnimatorClientId"
            type="string"
            control={control}
            label="Animator ID (SiteID)"
            error={!!errors.freeMemberAnimatorClientId}
            helperText={errors.freeMemberAnimatorClientId?.message}
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
          />
        </S.InputWrapper>
      </ConditionalField>

      <ConditionalField name="animatorCity" conditionalFieldId="isFreeMember" watch={watch} unregister={unregister}>
        <S.InputWrapper>
          <Controller
            as={TextField}
            name="animatorCity"
            type="string"
            control={control}
            label="Animator City"
            error={!!errors.animatorCity}
            helperText={errors.animatorCity?.message}
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
          />
        </S.InputWrapper>
      </ConditionalField>

      <ConditionalField name="animatorCountry" conditionalFieldId="isFreeMember" watch={watch} unregister={unregister}>
        <S.InputWrapper>
          <Controller
            as={TextField}
            name="animatorCountry"
            type="string"
            control={control}
            label="Animator Country (abbreviation)"
            error={!!errors.animatorCountry}
            helperText={errors.animatorCountry?.message}
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
          />
        </S.InputWrapper>
      </ConditionalField>
      <ConditionalField
        name="city"
        conditionalFieldId="isFreeMember"
        expectedValue={false}
        watch={watch}
        unregister={unregister}
      >
        <S.InputWrapper>
          <ReactHookFormSelect
            error={!!errors.city}
            size="small"
            name="city"
            label="Select City"
            control={control}
            variant="outlined"
          >
            {cityItems}
          </ReactHookFormSelect>
        </S.InputWrapper>
      </ConditionalField>
    </>
  );
};
