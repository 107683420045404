import React, { useCallback, useRef } from 'react';
import { DefaultReportFormFields } from 'src/types/report/common';
import { useReportByActivity } from 'src/services/report/use-report-by-activity';
import { ActivityReportDataItem } from 'src/types/report/activity';
import { ExcelSaverButton } from 'src/components/common/ExcelSaverButton';
import { getFileName, downloadActivityReportXlsx } from 'src/services/report/activity';
import { ReportLayout } from '../common/ReportLayout';
import { ReportForm } from '../common/Form';
import { ActivityReportTable } from './Table';

export const ReportByActivityScreen = () => {
  const formData = useRef<DefaultReportFormFields>();

  const { reportByActivityState, fetchReportByActivity } = useReportByActivity();

  const submitReportRequest = useCallback(
    (values: DefaultReportFormFields) => {
      formData.current = values;
      return fetchReportByActivity(values);
    },
    [fetchReportByActivity],
  );

  return (
    <ReportLayout headerCaption="Report by activity">
      <ReportForm formType="default" onSubmit={submitReportRequest} />
      <ActivityReportTable data={reportByActivityState.value} />
      <ExcelSaverButton<ActivityReportDataItem, DefaultReportFormFields | undefined>
        data={reportByActivityState.value}
        download={downloadActivityReportXlsx}
        getFileName={getFileName}
        filterData={formData.current}
      />
    </ReportLayout>
  );
};
