import React, { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputLabel, MenuItem, Select, Button, Grid, FormControl, FormHelperText } from '@material-ui/core';

import { AnimatorActivateFormFields } from 'src/types/animator';
import validationSchema from './schema';
import * as S from './styled';

type Props = {
  onSubmit: (values: AnimatorActivateFormFields) => void;
};

// keys should be iso-3166-country-codes
const locations = {
  US: 'Denver',
  FR: 'Paris',
  ES: 'Madrid',
};

export const AnimatorActivateFormWithSelect = ({ onSubmit }: Props) => {
  const { control, handleSubmit, errors, setValue } = useForm<AnimatorActivateFormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      city: 'Denver',
      country: 'US',
    },
  });

  const countriesItems = useMemo(
    () =>
      Object.keys(locations).map((country) => (
        <MenuItem key={country} value={country}>
          {country}
        </MenuItem>
      )),
    [],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Controller
            render={({ value }) => (
              <FormControl size="small" variant="outlined" fullWidth margin="normal">
                <InputLabel id="countriesLabel">Country (abbreviation)</InputLabel>
                <Select
                  label="Country (abbreviation)"
                  labelId="countriesLabel"
                  aria-describedby="country-helper-text"
                  value={value}
                  onChange={(event) => {
                    const newVal = event.target.value;
                    setValue('country', newVal);
                    setValue('city', locations[newVal as keyof typeof locations]);
                  }}
                  error={!!errors.country}
                >
                  {countriesItems}
                </Select>
                {!!errors.country && <FormHelperText>{errors.country?.message}</FormHelperText>}
              </FormControl>
            )}
            name="country"
            type="string"
            control={control}
          />
        </Grid>
        <Grid item xs={5}>
          <Controller
            as={TextField}
            name="city"
            type="string"
            control={control}
            label="City"
            error={!!errors.city}
            helperText={errors.city?.message}
            fullWidth
            variant="outlined"
            size="small"
            margin="normal"
          />
        </Grid>
        <Grid item xs={2}>
          <S.SubmitButtonWrapper>
            <Button type="submit" variant="contained" color="primary">
              Activate
            </Button>
          </S.SubmitButtonWrapper>
        </Grid>
      </Grid>
    </form>
  );
};
