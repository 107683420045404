import React from 'react';
import MaterialUITooltip from '@material-ui/core/Tooltip';

type Props = {
  value?: string;
};

export const Tooltip = (props: Props) => {
  const { value = '' } = props;

  return (
    <MaterialUITooltip title={value}>
      <span>{value}</span>
    </MaterialUITooltip>
  );
};
