import { AxiosResponse } from 'axios';
import { KPIReportDataItem } from 'src/types/report/kpi';
import { KPIReportDTO } from './types';
import { calculateTotalBonus } from './calculateTotalBonus';
import { calculateTotalFine } from './calculateTotalFine';

const get = (response: AxiosResponse<KPIReportDTO>) => {
  return response.data.values.map((item) => {
    const adaptedItem: KPIReportDataItem = {
      ...item,
      id: `${item.operatorId}_${item.weekNumber}`,
      operatorId: String(item.operatorId),
      totalFine: calculateTotalFine(item),
      totalBonus: calculateTotalBonus(item),
    };

    return adaptedItem;
  });
};

export const adapter = {
  get,
};
