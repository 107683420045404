import React, { useCallback } from 'react';

import { buttonUrlOptions } from 'src/components/FlureChat/constants';
import { AutocompleteInput } from 'src/components/common/flure';
import { Resources } from 'src/resources';

import { TextInput } from '../text-input';
import { useStyles } from './styles';

type Props = {
  enabled: boolean;
  buttonTitle: string;
  setButtonTitle: React.Dispatch<React.SetStateAction<string>>;
  selectedAction: string;
  setSelectedAction: React.Dispatch<React.SetStateAction<string>>;
};

export const ButtonWithAction = React.memo(
  ({ enabled, buttonTitle, setButtonTitle, selectedAction, setSelectedAction }: Props) => {
    const classes = useStyles();

    const onTitleChange = useCallback(
      (event) => {
        setButtonTitle(event.target.value);
      },
      [setButtonTitle],
    );

    return (
      <div className={classes.container}>
        <TextInput
          className={classes.buttonLikeInput}
          initialValue={buttonTitle || Resources.strings.add}
          value={buttonTitle}
          onChange={onTitleChange}
          disabled={!enabled}
          maxLength={50}
          multiline
        />
        {enabled && (
          <AutocompleteInput
            disableClearable={false}
            options={buttonUrlOptions}
            onChange={(option) => setSelectedAction(option?.value || '')}
            value={buttonUrlOptions.find((it) => it.value === selectedAction)}
            defaultValue={buttonUrlOptions.find((it) => it.value === selectedAction)}
          />
        )}
      </div>
    );
  },
);
