import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  panel: {
    backgroundColor: 'white',
    borderRadius: '16px',
    padding: '32px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '32px',
    position: 'relative',
    overflow: 'hidden',
  },

  subscriptionIcon: {
    width: '179px',
    height: '147px',
  },

  vibesIcon: {
    width: '127px',
    height: '146px',
  },

  inputsContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    columnGap: '18px',
  },

  input: {
    height: '50px',
    border: '1px solid #000',
    borderRadius: '14px',
    padding: '13px 16px',

    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#101014',
    fontSize: '16px',
    lineHeight: '24px',

    '&::placeholder': {
      color: '#76767A',
    },
  },

  idInput: {
    width: '303px',
    height: '56px',
  },

  amountInput: {
    width: '118px',
    height: '56px',
  },

  errorBorder: {
    borderColor: 'red',
  },

  errorText: {
    color: 'red',
    position: 'absolute',
    bottom: '-20px',
  },
});
