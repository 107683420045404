import React from 'react';
import clsx from 'clsx';

import { LabelText } from 'src/components/common/flure';
import { useStyles } from './styles';

type Props = {
  text: string;
  isActive: boolean;
  disabled?: boolean;
  onClick: () => void;
};

const BubbleCheckBoxView: React.FC<Props> = (props) => {
  const { text, isActive, onClick, disabled } = props;
  const classes = useStyles();

  return (
    <button type="button" className={clsx(classes.container, isActive && classes.active, disabled && classes.defaultCursor)} onClick={onClick} disabled={disabled}>
      <LabelText text={text} />
    </button>
  );
};

export const BubbleCheckBox = React.memo(BubbleCheckBoxView);
