import React from 'react';

import { PromoCodeField } from 'src/components/LocalEvents/types-and-constants';
import { PromoCodeData } from 'src/network/flure-promocodes/types';

import { QRCodeImage } from '../qr-code-image';


type Props = {
  field: PromoCodeField;
  item: PromoCodeData;
};

const fieldsWithTitle = [
  PromoCodeField.id,
  PromoCodeField.eventName,
  PromoCodeField.eventCity,
  PromoCodeField.stageName,
  PromoCodeField.fullLink,
  PromoCodeField.shortLink,
];

export const TableStaticTd = React.memo(({ field, item }: Props) => {
  let tdContent = <>{item[field]}</>;
  const showTitle = fieldsWithTitle.includes(field);

  if (field === PromoCodeField.qr) {
    tdContent = <QRCodeImage value={item.fullLink} itemId={item.id} />;
  }

  if (field === PromoCodeField.fullLink || field === PromoCodeField.shortLink) {
    tdContent = (
      <a href={item[field]} target="_blank">
        {item[field]}
      </a>
    );
  }

  return <td title={showTitle ? String(item[field]) : undefined}>{tdContent}</td>;
});
