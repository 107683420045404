export const columns = [
  {
    field: 'operatorId',
    headerName: 'ID',
    width: 180,
  },
  {
    field: 'agencyName',
    headerName: 'Agency name',
    width: 180,
  },
  {
    field: 'operatorName',
    headerName: 'Operator name',
    width: 180,
  },
  {
    field: 'hoursOnline',
    headerName: 'Online (h)',
    width: 90,
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 140,
  },
  {
    field: 'activityTimes',
    headerName: 'Active Time',
    width: 500,
  },
];
