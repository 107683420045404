import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  buttonsContainer: {
    position: 'relative',
    display: 'flex',
    marginTop: '24px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: `0px 0px 0.8px 0px rgba(0, 0, 0, 0.04), 0px 3px 6px 0px rgba(0, 0, 0, 0.04),
      0px 12px 19px 0px rgba(0, 0, 0, 0.04), 0px 19px 25px 0px rgba(0, 0, 0, 0.04)`,
    padding: '26px 18px',
  },

  btn: {
    margin: '0 8px',
    height: '78px',
    textTransform: 'none',
    fontSize: '24px',
    color: 'white',
    borderRadius: '8px',
  },

  btnApprove: {
    width: '316px',
    backgroundColor: '#00C376',
    '&:hover': {
      backgroundColor: '#00C376',
      opacity: 0.8,
    },
  },

  btnDecline: {
    width: '152px',
    backgroundColor: '#ED654C',
    '&:hover': {
      backgroundColor: '#ED654C',
      opacity: 0.8,
    },
  },

  btnScam: {
    width: '152px',
    backgroundColor: '#878E9C',
    '&:hover': {
      backgroundColor: '#878E9C',
      opacity: 0.8,
    },
  },
});
