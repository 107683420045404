import React, { ChangeEventHandler } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { PromoCodeField } from 'src/components/LocalEvents/types-and-constants';
import { PromoCodeData } from 'src/network/flure-promocodes/types';

import { useStyles } from './styles';

type Props = {
  field: PromoCodeField;
  formData: Partial<PromoCodeData>;
  errors: Partial<Record<keyof PromoCodeData, boolean>>;
  idEditEnabled: boolean;
  handleChange: ChangeEventHandler<HTMLTextAreaElement>;
  handleDateChange: (date: Date, field: PromoCodeField) => void;
};

const maxLength = 255;

export const TableEditableTd = React.memo(
  ({ errors, field, formData, idEditEnabled, handleChange, handleDateChange }: Props) => {
    const classes = useStyles();
    const inputClassName = errors[field] ? classes.errorBorder : '';

    let tdContent = (
      <TextareaAutosize
        className={inputClassName}
        name={field}
        value={formData[field] || ''}
        onChange={handleChange}
        maxLength={maxLength}
        disabled={field === PromoCodeField.id && !idEditEnabled}
      />
    );

    if (field === PromoCodeField.qr) {
      tdContent = <input name="qr" value="-" disabled />;
    }

    if (field === PromoCodeField.activeFrom || field === PromoCodeField.activeUntil) {
      tdContent = (
        <DatePicker
          customInput={<input className={inputClassName} />}
          dateFormat="yyyy-MM-dd"
          selected={formData[field] ? new Date(formData[field]!) : null}
          onChange={(day: Date) => handleDateChange(day, field)}
        />
      );
    }

    return <td>{tdContent}</td>;
  },
);
