import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
  max-width: 40%;
`;

export const InputWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;
