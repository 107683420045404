import React, { useCallback, useMemo, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { animatorRequest, userRequest } from 'src/network';
import { AnimatorActivateFormFields } from 'src/types/animator';
import { Layout } from 'src/components/App/views/Layout';
import { getRealm } from 'src/utils/realm';
import { useAuth } from 'src/services/auth/context';
import { Product } from 'src/types/product';

import { AnimatorActivateFormWithSelect, AnimatorActivateFormWithTextFields } from './ActivateForm';
import * as S from './styled';

export const AnimatorsActivatorScreen = () => {
  const [searchedAnimatorId, setSearchedAnimatorId] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useAuth();
  const isOnceProduct = me?.realm === Product.Once;

  const handleAnimatorIdInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchedAnimatorId(e.target.value),
    [],
  );

  const [animator, searchAnimatorById] = useAsyncFn(
    async (animatorId) => {
      try {
        if (animatorId.length === 0) {
          return null;
        }
        const result = await userRequest.getById(animatorId, true);
        return result;
      } catch (error) {
        enqueueSnackbar(error.toString() || 'Bad request', { variant: 'error' });
        return null;
      }
    },
    [searchedAnimatorId],
  );

  const searchAnimator = useCallback(() => searchAnimatorById(searchedAnimatorId), [
    searchAnimatorById,
    searchedAnimatorId,
  ]);

  const resetSearch = useCallback(() => {
    setSearchedAnimatorId('');
    searchAnimatorById('').then();
  }, [searchAnimatorById]);

  const activateAnimator = useCallback(
    async (values: AnimatorActivateFormFields) => {
      try {
        await animatorRequest.activate(searchedAnimatorId, {
          country: values.country,
          city: values.city,
          realm: getRealm(me?.realm),
        });
        enqueueSnackbar('Activated', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.toString() || 'Bad request', { variant: 'error' });
      }
    },
    [me, enqueueSnackbar, searchedAnimatorId],
  );

  const animatorWasFound = useMemo(() => !!animator.value && !animator.loading, [animator]);

  return (
    <Layout>
      <Typography component="h1" variant="h5">
        Animators activator
      </Typography>
      <S.Container>
        <S.SearchForm>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="outlined-search"
                label="Search animator by Id (siteId)"
                type="number"
                variant="outlined"
                size="small"
                disabled={animatorWasFound}
                value={searchedAnimatorId}
                onChange={handleAnimatorIdInput}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={searchedAnimatorId.length === 0 || animatorWasFound}
                onClick={searchAnimator}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </S.SearchForm>
        {animatorWasFound && (
          <>
            <Button type="button" variant="contained" color="primary" onClick={resetSearch}>
              Reset
            </Button>
            <pre>{JSON.stringify(animator.value, null, 2)}</pre>
            <S.ActivateForm>
              {isOnceProduct ? (
                <AnimatorActivateFormWithSelect onSubmit={activateAnimator} />
              ) : (
                <AnimatorActivateFormWithTextFields onSubmit={activateAnimator} />
              )}
            </S.ActivateForm>
          </>
        )}
      </S.Container>
    </Layout>
  );
};
