import { useEffect, useRef } from 'react';

import { GenderIdentityPreferences } from 'src/types/user/preferences';
import { DEFAULT_GENDER_IDENTITY } from 'src/components/FlureFeed/constants';
import { flureFeedRequest } from 'src/network/flure-feed';

import { LocationOption, allFiltersSearchQuery } from '../utils';
import { useFeedNotifications } from './use-feed-notifications';

type Props = {
  operatorId: string | undefined;
  myLocation: LocationOption | null;
  myLocationOptions: LocationOption[];
  myGenderIdentity: GenderIdentityPreferences;
  isResetPressed: React.MutableRefObject<boolean>;
  fetchLocations: (query: string) => void;
  fetchMyLocations: (substring?: string) => void;
  onMyLocationChange: (location: LocationOption) => void;
  onApply: () => void;
};

export const useBackgroundTasks = (props: Props) => {
  const {
    operatorId,
    myLocation,
    myLocationOptions,
    myGenderIdentity,
    isResetPressed,
    fetchLocations,
    fetchMyLocations,
    onMyLocationChange,
    onApply,
  } = props;
  const { showNoIdErrorNotification } = useFeedNotifications();
  const didMakeFirstRequest = useRef(false);

  useEffect(() => {
    if (operatorId) {
      flureFeedRequest.resetSkippedUsers(operatorId).then(() => {
        fetchLocations(allFiltersSearchQuery());
        fetchMyLocations();
      });
    } else {
      showNoIdErrorNotification();
    }
  }, [fetchLocations, fetchMyLocations, operatorId, showNoIdErrorNotification]);

  useEffect(() => {
    if (!myLocation && myLocationOptions[0]) {
      onMyLocationChange(myLocationOptions[0]);
    }
  }, [myLocation, myLocationOptions, onMyLocationChange]);

  useEffect(() => {
    if (myLocation && !didMakeFirstRequest.current) {
      didMakeFirstRequest.current = true;
      onApply();
    }
  }, [myLocation, onApply]);

  useEffect(() => {
    if (myGenderIdentity === DEFAULT_GENDER_IDENTITY && isResetPressed.current) {
      isResetPressed.current = false;
      onApply();
    }
  }, [isResetPressed, myGenderIdentity, onApply]);
};
