import { format } from 'date-fns';
import React from 'react';

import { ActionType, ActionTypeMapper } from 'src/types/moderation';
import { RAW } from 'src/types/raw';
import { UserRoles } from 'src/types/user/roles';
import { useStyles } from './styles';

type Props = {
  date: Date;
  actionTypes: ActionType[];
  initiator?: RAW.Initiator;
  boldInitiatorRole?: boolean;
};

const roleToLabelMap = {
  [UserRoles.Supervisor]: '(A)',
  [UserRoles.UsersOperator]: '(M)',
};

export const ActionsHistoryLabel = (props: Props) => {
  const { date, actionTypes, initiator, boldInitiatorRole } = props;
  const classes = useStyles();

  return (
    <>
      {format(date, 'dd/MM/yy, HH:mm')} UTC --{' '}
      {actionTypes.map((actionType) => ActionTypeMapper[actionType] || actionType).join(', ')}
      {initiator?.name ? ` by ${initiator.name} ` : ' '}
      {initiator?.role ? (
        <>{boldInitiatorRole ? (
          <span className={classes.role}>{roleToLabelMap[initiator.role]}</span>
        ) : (
          <>{roleToLabelMap[initiator.role]}</>
        )}</>
      ) : null}
    </>
  );
};
