import React, { useCallback, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import { useReportByCommissions } from 'src/services/report/use-report-by-commissions';
import { CommissionsReportDataItem, CommissionsReportFormFields } from 'src/types/report/commissions';
import { ExcelSaverButton } from 'src/components/common/ExcelSaverButton';

import { CommissionsReportTable } from './Table';
import { ReportLayout } from '../common/ReportLayout';
import { ReportForm } from '../common/Form';

export const ReportByCommissionsScreen = () => {
  const {
    reportByCommissionsState,
    fetchReportByCommissions,
    getFileName,
    downloadCommissionsReportXlsx,
  } = useReportByCommissions();
  const { push } = useHistory();
  const [selectedDates, setSelectedDates] = useState<string>();
  const formData = useRef<CommissionsReportFormFields>();

  const submitReportRequest = useCallback(
    (values: CommissionsReportFormFields) => {
      formData.current = values;
      setSelectedDates(values.rangeDate);
      fetchReportByCommissions(values).then();
    },
    [fetchReportByCommissions],
  );

  const moveToReportByMessages = useCallback(
    (operatorId) => {
      if (!selectedDates) {
        return;
      }

      const [from, to] = selectedDates.split(':');

      const params = {
        from,
        to,
        searchMode: 'operator',
        operator: operatorId,
      };

      push({
        pathname: '/report-by-messages',
        search: `?${qs.stringify(params)}`,
      });
    },
    [push, selectedDates],
  );

  return (
    <ReportLayout headerCaption="Report by commissions">
      <ReportForm formType="commissions" onSubmit={submitReportRequest} />
      <CommissionsReportTable data={reportByCommissionsState.value} onSentMessageClick={moveToReportByMessages} />
      <ExcelSaverButton<CommissionsReportDataItem, CommissionsReportFormFields | undefined>
        data={reportByCommissionsState.value}
        download={downloadCommissionsReportXlsx}
        getFileName={getFileName}
        filterData={formData.current}
      />
    </ReportLayout>
  );
};
