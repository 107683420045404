import React from 'react';

import { Resources } from 'src/resources';
import { LabelText, TitleText } from 'src/components/common/flure';
import { BenefitType } from 'src/types/moderation';

import { useStyles } from './styles';

type Props = {
  userId: string;
  type: BenefitType;
  amount: number;
};
export const DoneOverlay = ({ userId, type, amount }: Props) => {
  const classes = useStyles();

  let text = `User ${userId}\nreceived Subscription for ${amount} days!`;

  if (type === BenefitType.Vibes) {
    text = `User ${userId}\nreceived ${amount} Vibes!`;
  }

  return (
    <div className={classes.doneOverlayContainer}>
      <TitleText text={Resources.strings.flureBenefitsManagement.done} />
      <LabelText className={classes.doneSubtitle} text={text} />
    </div>
  );
};
