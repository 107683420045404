import { useMemo } from 'react';

import { DefaultReportSearchMode } from 'src/types/report/common';
import { UserRoles } from 'src/types/user/roles';
import { useAuth } from 'src/services/auth';

import { SelectItem } from '../types';

type SearchModeItem = SelectItem<DefaultReportSearchMode>;

export function useAvailableSearchModes() {
  const { me } = useAuth();

  const availableSearchModes = useMemo(() => {
    const modes: SearchModeItem[] = [
      { value: 'agency', title: 'Agency' },
      { value: 'operator', title: 'Operator' },
    ];

    if (me?.roles?.includes(UserRoles.AgencyAdministrator)) {
      modes.unshift({ value: 'all', title: 'All' }, { value: 'all-without-agency', title: 'All without agency' });
    }

    return modes;
  }, [me?.roles]);

  return availableSearchModes;
}
