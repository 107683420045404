import React from 'react';

import { useStatic, useUserForModeration } from 'src/services/moderation/raw/hooks';
import { useAuth } from 'src/services/auth';
import { UserModerationRAW } from '.';

export const DataRetriever = () => {
  const { me } = useAuth();
  const operatorId = me?.id;

  const { userForModerationState, fetchUserForModeration } = useUserForModeration(operatorId || '');
  useStatic();

  if (!operatorId) return null;

  return (
    <UserModerationRAW
      fetchUserForModeration={fetchUserForModeration}
      userForModerationState={userForModerationState}
      operatorId={operatorId}
    />
  );
};
