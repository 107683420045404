import React from 'react';

import { RAW } from 'src/types/raw';
import { getRAWMediaSourceRAW } from 'src/components/Moderation/utils/image-source';
import { PhotoWrapper } from 'src/components/Moderation/screens/AdminUserModeration/raw/components/media/photo-wrapper';
import { useStyles } from './styles';

type Props = {
  onPhotoPress: (sources: string[]) => void;
  content: RAW.ModerationMedia;
  userId: RAW.UserPreset['profile']['id'];
};

export const RAWSecondaryContent = ({ content, userId, onPhotoPress }: Props) => {
  const classes = useStyles();

  return (
    <>
      {content.approvedRawPhotos.slice(0, 2).map((photo) => (
        <div className={classes.image} key={photo.id}>
          <PhotoWrapper
            photo={photo}
            userId={userId}
            onClick={() => {
              onPhotoPress([
                getRAWMediaSourceRAW(userId, photo.primaryBaseName),
                getRAWMediaSourceRAW(userId, photo.secondaryBaseName),
              ]);
            }}
          />
        </div>
      ))}
    </>
  );
};
