import React, { useEffect } from 'react';
import { Slide } from '@material-ui/core';
import { AlertS } from './styled';

type Props = {
  error: string | null;
  onClose: () => void;
};

export const Error = ({ error, onClose }: Props) => {
  useEffect(() => {
    if (error) {
      const sub = setTimeout(() => onClose(), 4500);
      return () => clearTimeout(sub);
    }
    return undefined;
  }, [onClose, error]);

  return (
    <Slide direction="left" in={Boolean(error)} mountOnEnter unmountOnExit>
      <AlertS variant="outlined" severity="error">
        {error || 'error'}
      </AlertS>
    </Slide>
  );
};
