import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },

  buttonsContainer: {
    backgroundColor: '#fafafa',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '27px 32px 31px',
    borderTop: '1px solid rgba(0, 0, 0, 0.17)',
  },

  rightButtonsContainer: {
    display: 'flex',
    columnGap: '19px',
  },

  btn: {
    height: '48px',
    borderRadius: '24px',
    textTransform: 'none',
    fontSize: '18px',
    lineHeight: '27px',
    color: 'white',
  },

  btnUndo: {
    width: '232px',
    backgroundColor: '#858585',
    '&:hover': {
      backgroundColor: '#858585',
      opacity: 0.8,
    },
  },

  btnApprove: {
    width: '190px',
    backgroundColor: '#00C376',
    '&:hover': {
      backgroundColor: '#00C376',
      opacity: 0.8,
    },
  },

  btnDecline: {
    width: '221px',
    backgroundColor: '#EA4551',
    '&:hover': {
      backgroundColor: '#EA4551',
      opacity: 0.8,
    },
  },
});
