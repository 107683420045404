import React, { useCallback, useMemo } from 'react';
import Button from '@material-ui/core/Button';

import * as S from './styled';

type Props = {
  data: {
    title: string;
    key: string;
    count: number;
  }[];
  onChange: (agencyTechName: string) => void;
};

const columns = ['Agency', 'Agency animators', 'Options'];

export const MultichatAgencyAnimatorsMappingTable = ({ data, onChange: onChangeBase }: Props) => {
  const onChange = useCallback(
    (techName: string) => () => {
      onChangeBase(techName);
    },
    [onChangeBase],
  );

  const rows = useMemo(() => {
    if (data.length === 0) {
      return (
        <tr>
          <td colSpan={3}>Empty</td>
        </tr>
      );
    }

    return data.map((it) => (
      <tr>
        <S.TableBodyCell>{it.title}</S.TableBodyCell>
        <S.TableBodyCell>{it.count}</S.TableBodyCell>
        <S.TableBodyCell>
          <Button type="button" variant="outlined" size="small" onClick={onChange(it.key)}>
            Edit
          </Button>
        </S.TableBodyCell>
      </tr>
    ));
  }, [data, onChange]);

  return (
    <S.Table>
      <thead>
        <tr>
          {columns.map((column) => (
            <S.TableHeadCell key={column}>{column}</S.TableHeadCell>
          ))}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </S.Table>
  );
};
