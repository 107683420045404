import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

import { VerificationHeader } from 'src/components/Verification/views/VerificationHeader';

import { useStyles } from './styles';

type Props = {
  usersInQueue: number;
  handledUsersCount: number;
  isLoading: boolean;
  onRefreshClick: () => void;
};

export const LoadingOrNoContent: React.FC<Props> = (props) => {
  const { usersInQueue, handledUsersCount, isLoading, onRefreshClick } = props;
  const classes = useStyles();

  return (
    <div>
      <VerificationHeader usersInQueue={usersInQueue} handledUsersCount={handledUsersCount} />
      <div className={classes.noContentContainer}>
        <Typography variant="h4">{isLoading ? 'Loading...' : 'No data available'}</Typography>
        {!isLoading && (
          <Box ml={2}>
            <Button onClick={onRefreshClick} color="inherit" variant="outlined" startIcon={<RefreshIcon />}>
              Refresh
            </Button>
          </Box>
        )}
      </div>
    </div>
  );
};
