import { makeStyles } from '@material-ui/core';

const imageHeight = '450px';
const imageWidth = '450px';

export const useStyles = makeStyles({
  verificationCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 5.5px',
    position: 'relative',
  },

  photoWithLoader: {
    position: 'relative',
    height: imageHeight,
    width: imageWidth,
  },

  title: {
    fontSize: 18,
    color: '#7E1BE3',
    fontWeight: 'bold',
    textAlign: 'center',
    position: 'absolute',
    top: -38,
  },

  verificationImageContainer: {
    height: imageHeight,
    width: imageWidth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '0px',
  },

  verificationImage: {
    height: imageHeight,
    width: imageWidth,
    maxWidth: '100%',
    objectFit: 'contain',
    backgroundColor: 'rgba(0,0,0,0.2)',
    border: '8px solid #7E1BE3',
  },

  hidden: {
    visibility: 'hidden',
  },

  loaderContainer: {
    position: 'absolute',
    top: 0,
    height: imageHeight,
    width: imageWidth,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
