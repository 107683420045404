import { useCallback, useEffect, useState } from 'react';

import { useAuth } from 'src/services/auth';
import { useUser } from 'src/services/user/hooks';
import { GenderIdentityPreferences } from 'src/types/user/preferences';
import { Gender, UserLocation } from 'src/types/user';
import { getLocation, locationHasError } from 'src/network/location';
import { userRequest } from 'src/network';
import { getUserBirthday } from 'src/utils/transform';
import { LocationOption } from 'src/components/FlureFeed/utils';
import { DEFAULT_AGE, DEFAULT_GENDER_IDENTITY, DEFAULT_LOCATION } from 'src/components/FlureFeed/constants';

import { useFeedNotifications } from './use-feed-notifications';

export const useUserData = () => {
  const { me } = useAuth();
  const operatorId = me?.id;
  const { fetchUser } = useUser();
  const { showNoIdErrorNotification } = useFeedNotifications();

  const [myAge, setMyAge] = useState<number>(DEFAULT_AGE);
  const [myLocation, setMyLocation] = useState<LocationOption | null>(null);
  const [genderIdentities, setGenderIdentities] = useState<string[]>([DEFAULT_GENDER_IDENTITY]);

  const myGenderIdentity = genderIdentities[0] as GenderIdentityPreferences;

  const onMyLocationChange = useCallback((option: LocationOption | null) => {
    setMyLocation(option);
  }, []);

  const onAgeChange = useCallback((value: number) => {
    setMyAge(value);
  }, []);

  const updateUser = useCallback(async () => {
    const { value: city, country } = myLocation || {};
    let location: UserLocation = DEFAULT_LOCATION;

    if (city && country) {
      const locationData = await getLocation({ city, country });

      if (!locationHasError(locationData)) {
        location = {
          city,
          country,
          latitude: locationData.lat,
          longitude: locationData.lng,
        };
      }
    }

    const gender = myGenderIdentity === GenderIdentityPreferences.Woman ? Gender.Female : Gender.Male;

    return userRequest.update(operatorId!, { birthday: getUserBirthday(myAge), gender, ...location });
  }, [myAge, myGenderIdentity, myLocation, operatorId]);

  useEffect(() => {
    if (operatorId) {
      fetchUser(operatorId);
    } else {
      showNoIdErrorNotification();
    }
  }, [fetchUser, operatorId, showNoIdErrorNotification]);

  return {
    operatorId,
    myAge,
    setMyAge,
    myGenderIdentity,
    genderIdentities,
    setGenderIdentities,
    myLocation,
    onMyLocationChange,
    onAgeChange,
    updateUser,
  };
};
