import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import { Product } from 'src/types/product';
import { Resources } from 'src/resources';
import { ActionButton, LabelText, LabelTextSize, TitleText } from 'src/components/common/flure';
import { useAuth } from 'src/services/auth';
import { moderationRequest, userRequest } from 'src/network';
import { useModerationNotifications } from 'src/components/Moderation/hooks/flure';
import { ActionType, ModerationAction, BenefitType } from 'src/types/moderation';

import { DoneOverlay } from '../done-overlay';
import vipPassIcon from './vip-pass.svg';
import vibesIcon from './vibes.svg';
import { useStyles } from './styles';

const imageMap = {
  [BenefitType.Subscription]: vipPassIcon,
  [BenefitType.Vibes]: vibesIcon,
};

const titleMap = {
  [BenefitType.Subscription]: Resources.strings.flureBenefitsManagement.subscription,
  [BenefitType.Vibes]: Resources.strings.flureBenefitsManagement.vibes,
};

const placeholderMap = {
  [BenefitType.Subscription]: Resources.strings.flureBenefitsManagement.days,
  [BenefitType.Vibes]: Resources.strings.flureBenefitsManagement.amount,
};

type Props = {
  type: BenefitType;
};

const RequiredIdLength = 11;
const MaxVibesLength = 5;
const NumberRegex = /[^0-9]/g;

export const BenefitPanel = ({ type }: Props) => {
  const classes = useStyles();
  const [showDone, setShowDone] = useState(false);
  const [idError, setIdError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [userId, setUserId] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const { showNoIdErrorNotification, showSendActionsErrorNotification } = useModerationNotifications();
  const { me } = useAuth();
  const operatorId = me?.id;

  const buttonDisabled = !userId || !amount || Number(amount) === 0 || idError || amountError || loading;

  const imageStyleMap = {
    [BenefitType.Subscription]: classes.subscriptionIcon,
    [BenefitType.Vibes]: classes.vibesIcon,
  };

  const onUserIdChange = useCallback((event) => {
    const clearedValue = event.target.value.replace(NumberRegex, '');
    setUserId(clearedValue);
    setIdError(false);
  }, []);

  const onAmountChange = useCallback((event) => {
    const clearedValue = event.target.value.replace(NumberRegex, '');
    setAmount(clearedValue);
    setAmountError(false);
  }, []);

  const validateFields = useCallback(() => {
    const isIdError = userId.length !== RequiredIdLength;
    const isAmountError = Number(amount) === 0;

    setIdError(isIdError);
    setAmountError(isAmountError);

    return !isAmountError && !isIdError;
  }, [amount, userId]);

  const sendActions = useCallback(
    async (actions: ModerationAction[]) => {
      try {
        if (operatorId) {
          await moderationRequest.sendAdminModerationActions(operatorId, userId, actions);

          setShowDone(true);
          setLoading(false);
        } else {
          showNoIdErrorNotification();
        }
      } catch (error) {
        showSendActionsErrorNotification(error);
      }
    },
    [operatorId, showNoIdErrorNotification, showSendActionsErrorNotification, userId],
  );

  const onButtonClick = useCallback(async () => {
    setLoading(true);
    if (validateFields()) {
      try {
        const userRealms = (await userRequest.getUserRealms(userId)) || [];

        if (userRealms.includes(Product.Flure)) {
          const actionValue =
            type === BenefitType.Subscription
              ? { [BenefitType.Subscription]: `${amount}.00:00:00` }
              : { [BenefitType.Vibes]: Number(amount) };

          const action: ModerationAction = {
            actionType: ActionType.Benefits,
            actionValue: JSON.stringify(actionValue),
          };

          sendActions([action]);
        } else {
          setIdError(true);
          setLoading(false);
        }
      } catch {
        setIdError(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [amount, sendActions, type, userId, validateFields]);

  useEffect(() => {
    if (showDone) {
      setTimeout(() => {
        setShowDone(false);
        setUserId('');
        setAmount('');
      }, 1000);
    }
  }, [showDone]);

  return (
    <div className={classes.panel}>
      <img className={imageStyleMap[type]} src={imageMap[type]} alt={titleMap[type]} />
      <TitleText text={titleMap[type]} />
      <div className={classes.inputsContainer}>
        <input
          className={clsx(classes.input, classes.idInput, idError && classes.errorBorder)}
          placeholder={Resources.strings.flureBenefitsManagement.userId}
          onChange={onUserIdChange}
          value={userId}
          maxLength={RequiredIdLength}
        />
        {idError && (
          <LabelText
            className={classes.errorText}
            text={Resources.strings.flureBenefitsManagement.idError}
            size={LabelTextSize.Small}
          />
        )}
        <input
          className={clsx(classes.input, classes.amountInput, amountError && classes.errorBorder)}
          placeholder={placeholderMap[type]}
          onChange={onAmountChange}
          value={amount}
          maxLength={MaxVibesLength}
        />
      </div>
      <ActionButton
        title={Resources.strings.flureBenefitsManagement.saveButton}
        onClick={onButtonClick}
        disabled={buttonDisabled}
      />
      {showDone && <DoneOverlay userId={userId} type={type} amount={Number(amount)} />}
    </div>
  );
};
