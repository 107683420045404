import axios, { AxiosInstance } from 'axios';

import { singleton } from 'src/utils/singleton';
import { Product } from 'src/types/product';
import { ServerConfig } from 'src/config';

const authorizationHeader = 'authorization';

const hostsMap: { [key in Product]: string } = {
  [Product.Lovinga]: 'lovingaApiHost',
  [Product.Once]: 'udatesApiHost',
  [Product.Magnet]: 'magnetApiHost',
  [Product.Flure]: 'flureApiHost',
  [Product.RAW]: 'rawApiHost',
};

const mediaHostsMap: { [key in Product]: string } = {
  [Product.Lovinga]: 'lovingaApiHost',
  [Product.Once]: 'udatesApiMediaHost',
  [Product.Magnet]: 'magnetApiHost',
  [Product.Flure]: 'flureApiHost',
  [Product.RAW]: 'rawApiHost',
};

export class Http {
  static shared = singleton(() => new Http());

  public instance: AxiosInstance;

  private mediaHostValue: string | undefined;

  constructor() {
    this.instance = axios.create();
  }

  setHost(config: ServerConfig, realm: Product) {
    return new Promise<void>((resolve) => {
      const hostKey = hostsMap[realm] as keyof ServerConfig;
      const mediaHostKey = mediaHostsMap[realm] as keyof ServerConfig;

      this.mediaHostValue = config[mediaHostKey];
      this.instance.defaults.baseURL = config[hostKey];

      resolve(undefined as void);
    });
  }

  unsetHost() {
    return new Promise<void>((resolve) => {
      delete this.instance.defaults.baseURL;
      this.mediaHostValue = undefined;

      resolve(undefined as void);
    });
  }

  setToken(token: string) {
    return new Promise<void>((resolve) => {
      this.instance.defaults.headers.common[authorizationHeader] = `Token token="${token}"`;
      resolve(undefined as void);
    });
  }

  unsetToken() {
    return new Promise<void>((resolve) => {
      delete this.instance.defaults.headers.common[authorizationHeader];
      resolve(undefined as void);
    });
  }

  get apiHost() {
    return this.instance.defaults.baseURL;
  }

  get mediaHost() {
    return this.mediaHostValue;
  }
}
