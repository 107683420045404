import { AxiosResponse } from 'axios';
import { ActivityReportDataItem } from 'src/types/report/activity';
import moment from 'moment';
import { ActivityReportDTO, TimeItem } from './types';

function convertActivityTimes(activityTimes: TimeItem[]): string {
  return (
    activityTimes
      .map((ti) => ({
        from: moment(ti.from),
        to: moment(ti.to),
      }))
      // according to spec we need only 3 longest intervals. The server returns all
      .sort((TimeRange1, TimeRange2) => {
        const firstRangeLength = TimeRange1.from.diff(TimeRange1.to, 'minutes');
        const secondRangeLength = TimeRange2.from.diff(TimeRange2.to, 'minutes');
        return firstRangeLength - secondRangeLength;
      })
      .slice(0, 3)
      // resort these 3 intervals to order it from the earliest to the latest.
      .sort((TimeRange1, TimeRange2) => {
        return TimeRange1.from.valueOf() - TimeRange2.from.valueOf();
      })
      .map((TimeInterval) => `${TimeInterval.from.format('HH:mm')} - ${TimeInterval.to.format('HH:mm')}`)
      .join('\u2003\u2003\u2003\u2003')
  );
}

const get = (response: AxiosResponse<ActivityReportDTO>) => {
  return response.data.values.map((item) => {
    const adaptedItem: ActivityReportDataItem = {
      ...item,
      id: `${item.operatorId}_${item.date}`,
      operatorId: String(item.operatorId),
      date: moment(item.date).format('YYYY/MM/DD'),
      activityTimes: convertActivityTimes(item.activityTimes),
      hoursOnline: item.minutesOnline ? Math.ceil(item.minutesOnline / 6) / 10 : 0,
    };

    return adaptedItem;
  });
};

export const adapter = {
  get,
};
