import { genderIdentityDict } from 'src/components/FlureFeed/constants';
import { Gender } from 'src/types/user';
import { GenderIdentityPreferences } from 'src/types/user/preferences';

export const formatGenderAndAge = (gender: Gender | undefined, age: number | string | undefined) => {
  if (gender && age) {
    return `${gender === Gender.Female ? 'Female' : 'Male'}, ${age} y.o.`;
  }

  if (gender) {
    return gender === Gender.Female ? 'Female' : 'Male';
  }

  if (age) {
    return `${age} y.o.`;
  }

  return '';
};

export const formatGenderIdentityGenderAndAge = (
  genderIdentity: GenderIdentityPreferences | undefined,
  gender: Gender | undefined,
  age: number | string | undefined,
  withYearsOld = false
) => {
  if (gender && genderIdentity && genderIdentityDict[genderIdentity] && age) {
    return `${gender === Gender.Female ? 'F' : 'M'}, ${genderIdentityDict[genderIdentity]}, ${age}${withYearsOld ? ' y.o.': ''}`;
  }

  if (gender && genderIdentity && genderIdentityDict[genderIdentity]) {
    return `${gender === Gender.Female ? 'F' : 'M'}, ${genderIdentityDict[genderIdentity]}`;
  }

  if (age) {
    return `${age}${withYearsOld ? ' y.o.': ''}`;
  }

  return '';
};
