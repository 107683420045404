import React, { useCallback, useState } from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { useNextUser } from 'src/services/verification/hooks';
import { VerificationTag } from 'src/types/verification';
import { verificationRequest } from 'src/network/verification';
import { BlockUserModal } from 'src/components/Moderation/views/block-user-modal';
import { moderationRequest } from 'src/network';
import { ActionType } from 'src/types/moderation';
import { noop } from 'src/utils/functions';
import { useAuth } from 'src/services/auth';
import { VideoWithLogger } from 'src/components/Moderation/views/video-with-logger';
import { ImageWithLogger } from 'src/components/Moderation/views/image-with-logger';
import { MediaType } from 'src/types/user';

import { ZoomImage } from '../ZoomImage';
import * as S from './styled';

const useStyles = makeStyles({
  media: {
    height: 220,
    maxWidth: '100%',
    objectFit: 'cover',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
  },
  btn: {
    margin: '0 3px',
  },
  btnRight: {
    position: 'absolute',
    right: '12px',
  },
  userInfo: {
    textAlign: 'center',
    marginTop: 5,
    color: '#999',
    fontWeight: 'bold',
    fontSize: 11,
  },
});

export const VerificationWidget: React.FC = () => {
  const { nextUserState, loadNextUser } = useNextUser();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const reasonHeader = { 'X-Reason': `photos=${nextUserState?.value?.media.length || 0}` };
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { me } = useAuth();
  const operatorId = me?.id || '';

  // Верификация - проставление тега юзеру
  const submit = useCallback(
    async (userId: string, tag: VerificationTag, msg: string, headers: Record<string, string>) => {
      try {
        await verificationRequest.sendUserTags(userId, tag, headers);
        enqueueSnackbar(msg, { variant: 'success' });
        await loadNextUser();
      } catch (e) {
        enqueueSnackbar(e.toString() || 'Bad request', { variant: 'error' });
      }
    },
    [],
  ); // eslint-disable-line

  // SCAM - баним юзера, снимая с него все теги
  const scamUser = useCallback(async (userId: string, headers: Record<string, string>) => {
    try {
      await verificationRequest.scamUser(userId, 'Verification photo', headers);
      enqueueSnackbar('User marked as SCAM', { variant: 'success' });
      await verificationRequest.sendUserTags(userId, [], headers);
      await loadNextUser();
    } catch (e) {
      enqueueSnackbar(e.toString() || 'Bad request', { variant: 'error' });
    }
  }, []); // eslint-disable-line

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const blockProfile = useCallback(() => {
    toggleModal();

    const { userId } = nextUserState.value!;

    verificationRequest
      .sendUserTags(userId, VerificationTag.Rejected, reasonHeader)
      .then(() => moderationRequest.sendAdminModerationActions(operatorId, userId, [{ actionType: ActionType.Delete }]))
      .then(() => {
        enqueueSnackbar('User successfully deleted', { variant: 'success' });
      })
      .then(loadNextUser)
      .catch(noop);
  }, [toggleModal, nextUserState.value, reasonHeader, loadNextUser, operatorId, enqueueSnackbar]);

  const renderMedia = useCallback((source: string, mediaType: MediaType) => {
    return mediaType === MediaType.Video ? (
      <VideoWithLogger videoStyle={classes.media} src={source} autoPlay loop />
    ) : (
      <ImageWithLogger imageStyle={classes.media} src={source} alt="Preview" />
    );
  }, []);

  // идет загрузка или нету участника с фотками на оценку
  if (!nextUserState.value || nextUserState.loading) {
    return (
      <S.Controls>
        <Typography variant="h4">{nextUserState.loading ? 'Loading...' : 'No data available'}</Typography>
        {!nextUserState.loading && (
          <Box ml={2}>
            <Button
              onClick={() => loadNextUser()}
              className={classes.btn}
              color="inherit"
              variant="outlined"
              startIcon={<RefreshIcon />}
            >
              Refresh
            </Button>
          </Box>
        )}
      </S.Controls>
    );
  }

  const photoMaxWidth = Math.floor(100 / nextUserState.value.media.length || 1) - 1;

  return (
    <S.Wrapper>
      <BlockUserModal isOpen={isModalOpen} onBlock={blockProfile} onCancel={toggleModal} />
      <S.Cards>
        <S.VerificationCard>
          <ZoomImage src={nextUserState.value.verificationPhoto} mediaType={MediaType.Photo}>
            <img src={nextUserState.value.verificationPhoto} className={classes.media} alt="Verification" />
          </ZoomImage>
          <div className={classes.userInfo}># {nextUserState.value.userId}</div>
        </S.VerificationCard>
        <S.Photos>
          {nextUserState.value.media.map((media) => (
            <S.Photo key={media.baseName} style={{ maxWidth: `${photoMaxWidth}%` }}>
              <ZoomImage src={media.source} mediaType={media.mediaType}>
                {renderMedia(media.source, media.mediaType)}
              </ZoomImage>
            </S.Photo>
          ))}
        </S.Photos>
      </S.Cards>

      <S.Controls>
        <Button
          onClick={() => submit(nextUserState.value!.userId, VerificationTag.Verified, 'User Approved', reasonHeader)}
          className={classes.btn}
          color="primary"
          variant="contained"
        >
          Approve
        </Button>
        <Button
          onClick={() => submit(nextUserState.value!.userId, VerificationTag.Required, 'User Declined', reasonHeader)}
          className={classes.btn}
          color="secondary"
          variant="contained"
        >
          Decline
        </Button>
        <Button
          onClick={() => scamUser(nextUserState.value!.userId, reasonHeader)}
          className={classes.btn}
          color="inherit"
          variant="contained"
        >
          Scam
        </Button>
        <Button
          onClick={() => submit(nextUserState.value!.userId, VerificationTag.Resend, 'User Resend', reasonHeader)}
          className={classes.btn}
          color="inherit"
          variant="contained"
        >
          Resend
        </Button>
        <Button
          onClick={toggleModal}
          className={clsx(classes.btn, classes.btnRight)}
          color="secondary"
          variant="contained"
        >
          Block Profile
        </Button>
      </S.Controls>
    </S.Wrapper>
  );
};
