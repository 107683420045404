import { makeStyles } from '@material-ui/core';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';

const commonStyles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0',
  },
  moderationButton: {
    color: 'white',
    textTransform: 'none' as const,
    textAlign: 'center' as const,
    backgroundColor: '#40A3FF',
    borderRadius: '8px',
    marginInline: '2px',

    '@media (min-width: 1220px)': {
      minWidth: '100px',
      height: '78px',
      fontSize: '20px',
    },
    height: '60px',
    fontSize: '14px',
    minwidth: '60px',
  },
  lightOrangeButton: {
    backgroundColor: '#FEAB30',
    '&:hover': {
      backgroundColor: '#ED654C',
      opacity: 0.8,
    },
  },
  nextButton: {
    width: '246px',
  },
  manPressed: {},
  manUnpressed: {},
  womanPressed: {},
  womanUnpressed: {},
  pressed: {},
  unpressed: {},
};

const useCommonStyles = makeStyles(commonStyles);

const useMagnetStyles = makeStyles(() => {
  const productStyles = {
    nextButton: {
      width: '419px',
    },
    manPressed: {
      backgroundColor: '#40A3FF',
      '&:hover': {
        backgroundColor: '#40A3FF',
        opacity: 0.8,
      },
    },
    manUnpressed: {
      backgroundColor: '#aaaaaa',
    },
    womanPressed: {
      backgroundColor: '#FF6B67',
      '&:hover': {
        backgroundColor: '#FFBBA1',
        opacity: 0.8,
      },
    },
    womanUnpressed: {
      backgroundColor: '#aaaaaa',
    },
  };

  return { ...commonStyles, ...productStyles };
});

const useUdatesStyles = makeStyles(() => {
  const pressed = {
    backgroundColor: '#26334D',
    '&:hover': {
      backgroundColor: '#26334D',
      opacity: 0.8,
    },
  };
  const unpressed = {
    backgroundColor: '#FFFFFF',
    color: '#26334D',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      opacity: 0.8,
    },
  };
  const productStyles = {
    manPressed: pressed,
    manUnpressed: unpressed,
    womanPressed: pressed,
    womanUnpressed: unpressed,
    pressed,
    unpressed,
  };

  return { ...commonStyles, ...productStyles };
});

const useLovingaStyles = makeStyles(() => {
  const pressed = {
    backgroundColor: '#2FE441',
    '&:hover': {
      backgroundColor: '#2FE441',
      opacity: 0.8,
    },
  };
  const unpressed = {
    backgroundColor: '#40A3FF',
    '&:hover': {
      backgroundColor: '#40A3FF',
      opacity: 0.8,
    },
  };
  const productStyles = {
    manPressed: pressed,
    manUnpressed: unpressed,
    womanPressed: pressed,
    womanUnpressed: unpressed,
    pressed,
    unpressed,
  };

  return { ...commonStyles, ...productStyles };
});

export const useProductStyles = () => {
  const { me } = useAuth();
  const magnetClasses = useMagnetStyles();
  const lovingaClasses = useLovingaStyles();
  const udatesClasses = useUdatesStyles();
  const commonClasses = useCommonStyles();

  return (
    (me?.realm === Product.Magnet && magnetClasses) ||
    (me?.realm === Product.Lovinga && lovingaClasses) ||
    ((me?.realm === Product.Once || me?.realm === Product.Flure) && udatesClasses) ||
    commonClasses
  );
};
