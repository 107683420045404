import { useSnackbar } from 'notistack';
import { useAsync } from 'react-use';

import { Logger, LoggerMessages, LoggerServices } from 'src/infrastructure/loggers/datadog';
import { rawModerationRequest } from 'src/network/moderation/raw';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import { RAW } from 'src/types/raw';

const getLog = (operatorId: string) => (payload: {
  reason: string;
  userId?: string;
  isUndoAction?: boolean;
  error?: any;
}) => {
  Logger.log({
    service: LoggerServices.Moderation,
    message: LoggerMessages.MediaForModerationStateIsNull,
    product: Product.RAW,
    payload: {
      message: LoggerMessages.UserForModerationStateIsNull,
      operatorId,
      ...payload,
    },
  });
};

export function useModerationMedia(userId: RAW.User['id']) {
  const { me } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const log = getLog(me?.id || '');

  const media = useAsync(async () => {
    try {
      const result = await rawModerationRequest.getRAWModerationMediaById(userId);

      return result;
    } catch (error: any) {
      if (error?.response?.status !== 404) {
        log({ reason: 'Error is not 404', error: error?.response });
      }
      enqueueSnackbar('Error while fetching actions', { variant: 'error' });
      return null;
    }
  }, [userId]);

  return media;
}
