import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import {
  PhotoTransformation,
  transformations,
  userCachedPhotoSize,
} from 'src/components/Moderation/utils/image-source';
import { ZoomImage } from 'src/components/Verification/views/ZoomImage';
import { MediaTag, MediaType } from 'src/types/user';

import { ImageWithLogger } from 'src/components/Moderation/views/image-with-logger';
import { userRequest, verificationRequest } from 'src/network';
import { useAuth } from 'src/services/auth';
import { useStyles } from './styles';

type Props = {
  userId: string;
  checkIfVerified?: boolean;
  imageContainerStyle?: string;
  imageStyle?: string;
  size?: string;
  transformation?: PhotoTransformation;
  zoomEnabled?: boolean;
  onLoadEnd?: () => void;
};

export const RealPhoto: React.FC<Props> = (props) => {
  const { userId, imageContainerStyle, imageStyle, size, transformation, zoomEnabled, onLoadEnd } = props;
  const classes = useStyles();
  const [mediaSource, setMediaSource] = useState<string | null>(null);

  const { me } = useAuth();

  const renderImage = useCallback(() => {
    return (
      <ImageWithLogger
        className={clsx(classes.realMedia, imageContainerStyle)}
        imageStyle={imageStyle}
        src={mediaSource!}
        alt="realPhoto"
        onLoadEnd={onLoadEnd}
      />
    );
  }, [classes.realMedia, imageContainerStyle, imageStyle, onLoadEnd, mediaSource]);

  useEffect(() => {
    setMediaSource(null);
    verificationRequest.getUserMedia(userId).then((userMedia) => {
      const realPhoto = userMedia.find((media) => media.tags.includes(MediaTag.Hidden));

      if (realPhoto) {
        const token = me?.token;
        const source = userRequest.getUserPhoto(
          userId,
          realPhoto.baseName,
          size || userCachedPhotoSize,
          token,
          transformation ? transformations[transformation] : '',
        );
        setMediaSource(source);
      }
    });
  }, [me?.token, size, transformation, userId]);

  if (!mediaSource) {
    return null;
  }

  if (zoomEnabled) {
    return (
      <ZoomImage src={mediaSource} mediaType={MediaType.Photo}>
        {renderImage()}
      </ZoomImage>
    );
  }

  return renderImage();
};
