/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Box } from '@material-ui/core';
import { CellParams, Columns, DataGrid } from '@material-ui/data-grid';

import { useAutoModerationContent } from 'src/services/moderation/raw/hooks';
import { RAW } from 'src/types/raw';
import { Layout } from 'src/components/App/views/raw/Layout';

import './styles.css';
import { useStyles } from './styles';
import { useMediaFullSizeModal } from '../../views/raw/full-size-media/use-full-size-modal';

const ImageCell = (params: CellParams) => {
  const { modal, openModal } = useMediaFullSizeModal();

  return (
    <>
      <img
        alt="asd"
        src={params.value as string}
        width={100}
        height={150}
        onClick={() => {
          openModal([params.value] as string[]);
        }}
      />
      {modal}
    </>
  );
};
const columns: Columns = [
  {
    field: 'userId',
    headerName: 'User ID',
    width: 200,
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'actionType',
    headerName: 'Action Type',
    width: 200,
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'actionValue',
    headerName: 'Action Value',
    width: 200,
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'reason',
    headerName: 'Reason',
    type: 'number',
    width: 200,
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    description: 'This column has a value getter and is not sortable.',
    filterable: false,
    sortable: false,
    width: 200,
    flex: 1,
  },
  {
    field: 'imageSource',
    headerName: 'Image',
    filterable: false,
    sortable: false,
    width: 100,
    flex: 0.5,
    renderCell: (props) => <ImageCell {...props} />,
  },
];

export const AutoModerationRAWScreen = () => {
  const classes = useStyles();

  const { contentState, fetchContent } = useAutoModerationContent();

  return (
    <>
      <Layout>
        <Box className={classes.mainContainer}>
          <DataGrid
            rows={contentState?.value?.rows || []}
            columns={columns}
            loading={contentState.loading}
            pageSize={50}
            rowCount={contentState?.value?.rowCount}
            rowsPerPageOptions={[50, 100, 200, 500, 1000]}
            paginationMode="server"
            pagination
            rowHeight={150}
            onPageSizeChange={(param) => {
              fetchContent(RAW.AutoModerationContentType.Default, (param.page - 1) * param.pageSize, param.pageSize);
            }}
            onPageChange={(param) => {
              fetchContent(RAW.AutoModerationContentType.Default, (param.page - 1) * param.pageSize, param.pageSize);
            }}
          />
        </Box>
      </Layout>
    </>
  );
};
