import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Resources } from 'src/resources';
import { LabelText, TitleText, TitleTextSize } from 'src/components/common/flure';

import backIcon from './back-icon.png';
import { useStyles } from './styles';

type Props = {
  title: string;
  onGoBack?: () => void;
};

const PageHeaderView: React.FC<Props> = (props) => {
  const { title, onGoBack } = props;
  const classes = useStyles();
  const history = useHistory();

  const onBackButtonClick = useCallback(() => {
    if (onGoBack) {
      onGoBack();
    } else {
      history.goBack();
    }
  }, [history, onGoBack]);

  return (
    <div className={classes.header}>
      <button type="button" className={classes.backButton} onClick={onBackButtonClick}>
        <img className={classes.backIcon} src={backIcon} alt="Back" />
        <LabelText text={Resources.strings.flureChat.back} />
      </button>
      <TitleText text={title} size={TitleTextSize.Large} />
      <div className={classes.backButton} />
    </div>
  );
};

export const PageHeader = React.memo(PageHeaderView);
