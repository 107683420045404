import React, { memo } from 'react';

import { RAW } from 'src/types/raw';
import { useStore } from 'src/context/raw/moderation/hooks';
import { TextContent, ModerationPhoto } from 'src/components/common/raw';

import { useStyles } from './styles';
import { MockSecondaryContentWrapper, SecondaryContentWrapper } from '../secondary-content-wrapper';

type Props = {
  contentType?: RAW.ContentType;
  content: RAW.ModerationContent['content'];
  user: RAW.UserPreset;
  onPhotoPress: (sources: string[]) => void;
  operatorId: string;
  contentCreationTime: string;
  secondaryContent: RAW.SecondaryContentTypeState;
};

const ContentBase = ({
  contentType,
  content,
  user,
  onPhotoPress,
  operatorId,
  contentCreationTime,
  secondaryContent,
}: Props) => {
  const classes = useStyles();
  const { toggleBlockModal } = useStore(operatorId);

  return (
    <div className={classes.wrapper}>
      <SecondaryContentWrapper
        onPhotoPress={onPhotoPress}
        secondaryContent={secondaryContent}
        userId={user.profile.id}
      />
      <div className={classes.container}>
        <ModerationPhoto
          onPhotoPress={onPhotoPress}
          content={content}
          contentType={contentType}
          userId={user.profile.id}
        />
        <div className={classes.textContent}>
          <TextContent
            user={user}
            onBlock={() => toggleBlockModal(true)}
            operatorId={operatorId}
            contentCreationTime={contentCreationTime}
          />
        </div>
      </div>
      <MockSecondaryContentWrapper />
    </div>
  );
};

export const Content = memo(ContentBase);
