import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { ColDef, CellParams } from '@material-ui/data-grid';

export function getUdatesTableColumns(): ColDef[] {
  return [
    {
      field: 'isFreeMember',
      headerName: 'Type',
      width: 60,
      sortable: false,
      renderCell: (params: CellParams) => (
        <Tooltip title={params.getValue('isFreeMember') ? 'Free Member' : 'Operator'}>
          <span>{params.getValue('isFreeMember') ? 'FM' : 'OP'}</span>
        </Tooltip>
      ),
    },
    { field: 'operatorId', headerName: 'Operator', width: 115, sortable: false },
    {
      field: 'operatorName',
      headerName: 'Operator name',
      width: 120,
      sortable: false,
      renderCell: (params: CellParams) => (
        <Tooltip title={params.getValue('operatorName') as string}>
          <span>{params.getValue('operatorName')}</span>
        </Tooltip>
      ),
    },
    {
      field: 'agencyName',
      headerName: 'Agency name',
      width: 120,
      sortable: false,
      renderCell: (params: CellParams) => (
        <Tooltip title={params.getValue('agencyName') as string}>
          <span>{params.getValue('agencyName')}</span>
        </Tooltip>
      ),
    },
    { field: 'animatorId', headerName: 'Animator', width: 115, sortable: false },
    { field: 'clientId', headerName: 'Client', width: 115, sortable: false },
    {
      field: 'prettyDateTime',
      headerName: 'Date',
      width: 135,
      sortable: false,
    },
    {
      field: 'text',
      headerName: 'Text',
      width: 170,
      sortable: false,
      renderCell: (params: CellParams) => (
        <Tooltip title={params.getValue('text') as string}>
          <span>{params.getValue('text')}</span>
        </Tooltip>
      ),
    },
    {
      field: 'sent',
      headerName: 'Status',
      width: 100,
      sortable: false,
      renderCell: (params) =>
        params.getValue('sent') ? <span>Delivered</span> : <span className="delivered-cell--blocked">Blocked</span>,
    },
  ];
}
