import { useAsyncFn } from 'react-use';
import { getMediaSourceRAWForSend } from 'src/components/Moderation/utils/image-source';

import { rawModerationRequest } from 'src/network/moderation/raw';

const getClientIdFromServerUserId = (serverUserId: string) => {
  const num = +serverUserId / 10000000000;

  return `${Math.floor((+serverUserId % 10000000000) * 100 + num)}`;
};

export const useAutoModerationContent = () => {
  const [contentState, fetchContent] = useAsyncFn(
    async (...params: Parameters<typeof rawModerationRequest.getAutoModerationContent>) => {
      try {
        const result = await rawModerationRequest.getAutoModerationContent(...params);

        return {
          rows: result.actions.map((action) => ({
            ...action,
            id: `${action.userId}_${action.actionValue}`,
            imageSource: getMediaSourceRAWForSend(getClientIdFromServerUserId(action.userId), action.actionValue),
          })),
          rowCount: result.totalCount,
        };
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return { contentState, fetchContent };
};
