import styled from 'styled-components';
import { Grid, TextField, Typography } from '@material-ui/core';

export const TextFieldS = styled(TextField)`
  width: 200px;
  margin-right: 10px;
`;

export const GridS = styled(Grid)`
  margin-top: 20px;
`;

export const TypographyS = styled(Typography)`
  margin: 15px;
`;

export const Counter = styled(Typography)`
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
`;
