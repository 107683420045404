import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const InputWrapper = styled.div`
  margin-right: 20px;
`;
