import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import clsx from 'clsx';

import { ActionButton, LabelText } from 'src/components/common/flure';
import { replaceLinks } from 'src/components/FlureChat/utils';
import { useSearchParams } from 'src/components/FlureChat/hooks';
import { MessageComponents, defaultComponentsOrder } from 'src/components/FlureChat/types';

import editIcon from './edit-icon.png';
import { useStyles } from './styles';

type Props = {
  message?: string;
  messageId?: number;
  reference?: string;
  buttonTitle?: string;
  componentsOrder?: MessageComponents[];
  created?: string;
  couldEdit?: boolean;
};

const apiDateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
const localDateFormat = 'DD/MM/YYYY HH:mm';

const MessageItemView: React.FC<Props> = (props) => {
  const {
    message,
    messageId,
    reference,
    buttonTitle,
    componentsOrder = defaultComponentsOrder,
    couldEdit = false,
    created = new Date(),
  } = props;
  const classes = useStyles();
  const messageTime = useMemo(() => moment.utc(created, apiDateFormat).local().format(localDateFormat), [created]);
  const formattedTextWithLinks = message ? replaceLinks(message) : message;
  const history = useHistory();
  const { gendersFromUrl, countriesFromUrl } = useSearchParams();

  const goToEditMessage = useCallback(() => {
    const params = {
      selectedGenders: gendersFromUrl,
      selectedCountries: countriesFromUrl,
    };

    history.push({
      pathname: `/flure-chat/edit/${messageId}`,
      search: `${qs.stringify(params)}`,
    });
  }, [countriesFromUrl, gendersFromUrl, history, messageId]);

  const renderText = useCallback(() => {
    return formattedTextWithLinks ? (
      <LabelText key={formattedTextWithLinks} className={classes.messageText} text={formattedTextWithLinks} isHTML />
    ) : null;
  }, [classes.messageText, formattedTextWithLinks]);

  const renderPhoto = useCallback(() => {
    return reference ? (
      <img
        key={reference}
        className={formattedTextWithLinks || buttonTitle ? classes.messageImageWithText : classes.messageImage}
        src={reference}
        alt="msgImage"
      />
    ) : null;
  }, [buttonTitle, classes.messageImage, classes.messageImageWithText, formattedTextWithLinks, reference]);

  const renderButton = useCallback(
    (index: number) => {
      return buttonTitle ? (
        <div
          key={buttonTitle}
          className={clsx([
            classes.buttonContainer,
            ((componentsOrder[index - 2] === MessageComponents.Text && !reference) ||
              componentsOrder[index - 1] === MessageComponents.Text) &&
              !!formattedTextWithLinks &&
              classes.buttonContainerTextAbove,
            ((componentsOrder[index + 2] === MessageComponents.Text && !reference) ||
              componentsOrder[index + 1] === MessageComponents.Text) &&
              !!formattedTextWithLinks &&
              classes.buttonContainerTextBelow,
          ])}
        >
          <ActionButton className={classes.button} title={buttonTitle} />
        </div>
      ) : null;
    },
    [buttonTitle, classes.buttonContainer, classes.buttonContainerTextAbove, classes.buttonContainerTextBelow, classes.button, componentsOrder, reference, formattedTextWithLinks],
  );

  if (!message && !reference && !buttonTitle) {
    return null;
  }

  return (
    <div className={classes.messageItem}>
      <LabelText className={classes.messageTime} text={messageTime} />
      <div className={classes.contentContainer}>
        <div className={classes.messageWithImageBlock}>
          {componentsOrder.map((it, index) => {
            switch (it) {
              case MessageComponents.Text:
                return renderText();
              case MessageComponents.Photo:
                return renderPhoto();
              case MessageComponents.Button:
                return renderButton(index);
              default:
                return null;
            }
          })}
        </div>
        {couldEdit && (
          <button className={classes.editOnHoverButton} type="button" onClick={goToEditMessage}>
            <img className={classes.editIcon} src={editIcon} alt="Edit" />
          </button>
        )}
      </div>
    </div>
  );
};

export const MessageItem = React.memo(MessageItemView);
