import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },

  buttonLikeInput: {
    height: '50px',
    width: '100%',
    borderRadius: '60px !important',
    backgroundColor: 'black',
    color: 'white !important',
    textAlign: 'center',
    fontFamily: 'Poppins !important',
    fontWeight: ('700 !important' as unknown) as number,
    padding: '13px 15px',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
});
