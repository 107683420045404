import { AxiosResponse } from 'axios';

import { Http } from 'src/network/http';
import { ModerationAction } from 'src/types/moderation';
import { adapter } from './adapter';
import {
  ModerationContentResponse,
  ModerationContentCountResponse,
  ModerationHistoryResponse,
  MediaModerationHistoryResponse,
  CelebritiesResponse,
} from './types';

const sendModerationActions = (operatorId: string, contentId: number, actions: ModerationAction[]) => {
  return Http.shared().instance.post(`/moderation/${operatorId}/content/${contentId}/actions`, { actions });
};

const sendAdminModerationActions = (operatorId: string, userId: string, actions: ModerationAction[]) => {
  return Http.shared().instance.post(`/moderation/${userId}/${operatorId}/actions`, { actions });
};

const getModerationContent = (operatorId: string) => {
  return Http.shared()
    .instance.get<ModerationContentResponse>(`/moderation/${operatorId}/content`)
    .then(adapter.getModerationContent);
};

const getModerationContentCount = () => {
  return Http.shared()
    .instance.get<ModerationContentCountResponse>(`/moderation/content/count`)
    .then(adapter.getModerationContentCount);
};

const getUserModerationHistory = (userId: string, query?: string) => {
  return Http.shared()
    .instance.get<ModerationHistoryResponse>(`/moderation/${userId}/content/actions${query ? `?${query}` : ''}`)
    .then((response: AxiosResponse<ModerationHistoryResponse>) => {
      return response.data;
    });
};

const getMediaModerationHistory = (userId: string) => {
  return Http.shared()
    .instance.get<MediaModerationHistoryResponse>(`/moderation/${userId}/media`)
    .then((response: AxiosResponse<MediaModerationHistoryResponse>) => {
      return response.data;
    });
};

const undoUserModeration = (operatorId: string) => {
  return Http.shared()
    .instance.put<ModerationContentResponse>(`/moderation/${operatorId}/content/undo`)
    .then(adapter.getModerationContent);
};

const detectCelebrityOnPhoto = (baseName: string) => {
  return Http.shared()
    .instance.get<CelebritiesResponse>(`/photos/${baseName}/celebrities`)
    .then((response: AxiosResponse<CelebritiesResponse>) => response.data);
};

export const moderationRequest = {
  detectCelebrityOnPhoto,
  getModerationContent,
  getModerationContentCount,
  getUserModerationHistory,
  getMediaModerationHistory,
  sendAdminModerationActions,
  sendModerationActions,
  undoUserModeration,
};
