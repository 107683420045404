import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { KPIReportDataItem } from 'src/types/report/kpi';
import { TableStretcher, TableMargins } from 'src/components/Report/common/Table/styled';
import { columns } from './columns';

import 'src/components/Report/common/Table/styles.css';

type Props = {
  data?: KPIReportDataItem[];
};

export const KPIReportTable = ({ data = [] }: Props) => {
  return (
    <TableMargins>
      <TableStretcher>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={25}
          headerHeight={30}
          rowHeight={30}
          disableColumnMenu
          disableColumnSelector
          disableColumnFilter
          disableColumnResize
          disableColumnReorder
          disableSelectionOnClick
        />
      </TableStretcher>
    </TableMargins>
  );
};
