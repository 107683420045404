import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: '32px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    textTransform: 'uppercase',
    color: '#FF3B30',
    marginBottom: '20px',
    fontWeight: 800,
    fontFamily: 'Podkova',
    fontSize: '32px',
  },
  button: {
    boxShadow: 'none',
    color: '#FFFFFF',
    fontWeight: 800,
    fontFamily: 'Podkova',
    fontSize: '24px',
    borderRadius: '8px',
    height: 50,
    width: 270,
    textTransform: 'uppercase',
  },
  block: {
    backgroundColor: '#FF3B30',
    marginBottom: '12px',
  },
  cancel: {
    backgroundColor: '#E6E7EC',
    color: '#050505',
  },
});
