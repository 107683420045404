import { makeStyles } from '@material-ui/core';

export const RAW_PHOTO_HEIGHT = 220;
export const RAW_PHOTO_WIDTH = 140;

export const useStyles = makeStyles({
  rawImageWrapper: {
    position: 'relative',
    width: RAW_PHOTO_WIDTH,
    height: RAW_PHOTO_HEIGHT,
  },
  rawMainImage: {
    width: RAW_PHOTO_WIDTH,
    height: RAW_PHOTO_HEIGHT,
    objectFit: 'cover',
  },

  rawSecondaryImage: {
    position: 'absolute',
    top: 8,
    left: 8,
    width: 40,
    height: 54,
    objectFit: 'cover',
    borderRadius: 5,
    borderStyle: 'solid',
    borderWidth: 1,
  },
});
