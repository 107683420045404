import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  filterButtonsContainer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: 'white',
    padding: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '4px',
  },

  filterButton: {
    width: '137px',
    height: '44px',
    borderRadius: '35px',
    backgroundColor: 'white',
    fontFamily: 'Poppins',
    color: 'black',
    fontSize: '16px',
    lineHeight: '24px',
    cursor: 'pointer',
    borderWidth: 0,

    '&:hover': {
      opacity: 0.8,
      backgroundColor: '#eee',
    },
  },

  activeFilterButton: {
    border: '1px solid black',
  },
});
