import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    padding: '15px 20px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
  moderationButton: {
    color: 'white',
    textTransform: 'none' as const,
    backgroundColor: '#40A3FF',
    borderRadius: '8px',
    marginInline: '2px',

    '@media (min-width: 1220px)': {
      minWidth: '100px',
      height: '78px',
      fontSize: '20px',
    },
    height: '60px',
    fontSize: '14px',
    minWidth: '60px',
  },
  largeButton: {
    width: '419px',
  },
  primaryButton: {
    backgroundColor: '#883FFF',
    '&:hover': {
      backgroundColor: '#883FFF',
      opacity: 0.8,
    },
  },
  warningButton: {
    backgroundColor: '#f57c00',
    '&:hover': {
      backgroundColor: '#ff9800',
      opacity: 0.8,
    },
    '&:disabled': {
      backgroundColor: '#ff904f',
      opacity: 0.5,
      color: '#ffffff',
    },
  },
});
