import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    borderRadius: '16px',
    width: '100%',
  },

  topContainer: {
    padding: '24px 24px 8px',
    display: 'flex',
    justifyContent: 'space-between',
  },

  titleWithIcon: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: '16px',
  },

  dragIcon: {
    width: '15px',
    height: '23px',
  },

  childrenContainer: {
    padding: '24px',
  }
});
