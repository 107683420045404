import React from 'react';
import { Button, Chip } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import { useStyles } from './styles';

type Props = {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
};

const TagChip = (props: Props) => {
  const { label, disabled = false, onClick } = props;
  const classes = useStyles();

  return (
    <Chip
      className={classes.chip}
      clickable
      label={<span className={classes.label}>{label}</span>}
      disabled={disabled}
      deleteIcon={<CloseRounded className={classes.deleteIcon} />}
      onClick={onClick}
      onDelete={onClick}
    />
  );
};

export const TagChipWrapper = (props: Props & { className: string }) => {
  const { label, disabled = false, onClick, className } = props;
  const { me } = useAuth();

  if (me?.realm !== Product.Magnet) {
    return (
      <Button variant="contained" className={className} onClick={onClick} disabled={disabled}>
        {label}
      </Button>
    );
  }

  return <TagChip label={label} onClick={onClick} disabled={disabled} />;
};
