import React, { useCallback, useState } from 'react';

import { MediaType } from 'src/types/user';

import { FullSizeMedia } from '.';

export function useMediaFullSizeModal() {
  const [fullSizeSources, setFullSizeSources] = useState<string[]>([]);
  const [isFullSizeOpen, setIsFullSizeOpen] = useState(false);

  const openFullSizeModal = useCallback((sources: string[]) => {
    setFullSizeSources(sources);
    setIsFullSizeOpen(true);
  }, []);

  const modal = fullSizeSources.length ? (
    <FullSizeMedia
      isOpen={isFullSizeOpen}
      setIsOpen={setIsFullSizeOpen}
      sources={fullSizeSources}
      mediaType={MediaType.Photo}
    />
  ) : null;

  return { modal, openModal: openFullSizeModal };
}
