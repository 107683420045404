import { datadogLogs } from '@datadog/browser-logs';
import { Level, Log } from './types';

const clientToken = 'pubbfccd7283fbef542928255b019511286';

export class Datadog {
  static init() {
    datadogLogs.init({
      clientToken,
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });
  }

  static async log(logLevel: Level, data: Log) {
    const payload = {
      service: data.service,
      hostname: `${data.product} Administration`,
      status: logLevel,
      ...(data.payload && { payload: data.payload }),
    };

    datadogLogs.logger[logLevel](data.message, payload);
  }
}
