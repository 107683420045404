import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useSnackbar } from 'notistack';

import { useAuth } from 'src/services/auth';
import { sessionDataStorage } from 'src/utils/session-storage';
import { VerificationContentCountResponse } from 'src/network/verification/types';
import { Layout } from 'src/components/App/views/Layout';
import { useChatModerationContent, useChatModerationContentCount } from 'src/services/flure-chat-moderation/hooks';
import { flureChatModerationRequest } from 'src/network/flure-chat-moderation';
import { ChatModerationAction } from 'src/network/flure-chat-moderation/types';
import { PrevUserId } from 'src/components/common/flure';

import { ActionButtons, AttendeeInfo, ChatModerationListHeader, MessagesList, UserInfo } from './components';
import { useKeyListener } from './hooks';
import { useStyles } from './styles';

const chatModerationHandledUsersCountKey = 'chat-moderation-handled-users-count';
const chatModerationPrevUserIdKey = 'chat-moderation-prev-user-id';

export const FlureChatModerationScreen = () => {
  const { moderationContent, fetchModerationContent } = useChatModerationContent();
  const { contentCountState, fetchContentCount } = useChatModerationContentCount();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useAuth();
  const operatorId = me?.id || '';
  const [handledUsersCount, setHandledUsersCount] = useState<number>(
    Number(sessionDataStorage.get(`${chatModerationHandledUsersCountKey}_${operatorId}`)),
  );
  const [prevUserId, setPrevUserId] = useState<string | null | undefined>(
    sessionDataStorage.get(`${chatModerationPrevUserIdKey}_${operatorId}`),
  );
  const { count: usersInQueue = 0 } = useMemo(
    () => contentCountState?.value || ({} as VerificationContentCountResponse),
    [contentCountState?.value],
  );

  const updateHandledUsersCount = useCallback(() => {
    setHandledUsersCount((prevHandledUsersCount: number) => {
      const newHandledUsersCount = Number(prevHandledUsersCount) + 1;
      sessionDataStorage.set(`${chatModerationHandledUsersCountKey}_${operatorId}`, newHandledUsersCount);
      return newHandledUsersCount;
    });
  }, [operatorId]);

  const updatePrevUserId = useCallback(
    (currentUserId: string | undefined) => {
      sessionDataStorage.set(`${chatModerationPrevUserIdKey}_${operatorId}`, currentUserId || null);
      setPrevUserId(currentUserId);
    },
    [operatorId],
  );

  const submit = useCallback(
    async (userId: string, action: ChatModerationAction, msg: string) => {
      try {
        await flureChatModerationRequest.sendChatModerationAction(operatorId, userId, action);
        enqueueSnackbar(msg, {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        });
        updateHandledUsersCount();
        updatePrevUserId(userId);
        fetchContentCount();
        await fetchModerationContent();
      } catch (e: any) {
        enqueueSnackbar(e.toString() || 'Bad request', {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        });
      }
    },
    [enqueueSnackbar, fetchContentCount, fetchModerationContent, operatorId, updateHandledUsersCount, updatePrevUserId],
  );

  const { senderId: userId } = moderationContent.value || {};

  const approveAction = useCallback(() => {
    if (userId) {
      submit(userId, ChatModerationAction.Approve, 'User Approved');
    }
  }, [userId, submit]);

  const scamAction = useCallback(() => {
    if (userId) {
      submit(userId, ChatModerationAction.Scam, 'User Scammed');
    }
  }, [userId, submit]);

  const onRefreshClick = useCallback(() => {
    fetchContentCount();
    fetchModerationContent();
  }, [fetchContentCount, fetchModerationContent]);

  useEffect(() => {
    fetchContentCount();
    fetchModerationContent();
  }, [fetchContentCount, fetchModerationContent]);

  useKeyListener({
    approveAction,
    scamAction,
  });

  if (!moderationContent.value || moderationContent.loading) {
    return (
      <Layout containerSize="xl">
        <ChatModerationListHeader
          className={classes.header}
          usersInQueue={usersInQueue}
          handledUsersCount={handledUsersCount}
        />
        <div className={classes.loadingContainer}>
          <Typography variant="h4">{moderationContent.loading ? 'Loading...' : 'No data available'}</Typography>
          {!moderationContent.loading && (
            <Box ml={2}>
              <Button onClick={onRefreshClick} color="inherit" variant="outlined" startIcon={<RefreshIcon />}>
                Refresh
              </Button>
            </Box>
          )}
          {!!prevUserId && <PrevUserId userId={prevUserId} />}
        </div>
      </Layout>
    );
  }

  const {
    recipientId,
    keyWordStartIndex,
    keyWordEndIndex,
    messageNumber,
    text,
    senderProfile,
  } = moderationContent.value;

  return (
    <>
      <Layout containerSize="lg">
        <ChatModerationListHeader
          className={classes.header}
          usersInQueue={usersInQueue}
          handledUsersCount={handledUsersCount}
        />
        <div className={classes.wrapper}>
          <UserInfo user={senderProfile} />
          <div className={classes.chatContainer}>
            <AttendeeInfo userId={recipientId} />
            <MessagesList
              senderId={userId!}
              recipientId={recipientId}
              keyWordStartIndex={keyWordStartIndex}
              keyWordEndIndex={keyWordEndIndex}
              messageNumber={messageNumber}
              text={text}
            />
          </div>
        </div>
      </Layout>
      <ActionButtons approveAction={approveAction} scamAction={scamAction} prevUserId={prevUserId} />
    </>
  );
};
