import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs, Tab, Button, Typography } from '@material-ui/core';
import { add } from 'date-fns';

import { Layout } from 'src/components/App/views/Layout';
import { NewQuestion, Question } from 'src/network/dailybuzz/types';

import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useQuestions, useQuestionsCategories, useSaveQuestion } from './hooks';
import { Card } from './card/card';
import { OpenedCard } from './card/opened-card';

import { Error } from './error';
import * as S from './styled';

const emptyQuestion = {
  question: '',
  categoryId: 0,
  date: add(new Date(new Date().setUTCHours(0, 0, 0, 0)), { days: 1 }),
};

export const DailyBuzzScreen = () => {
  const [tab, setTab] = useState('incoming');
  const [openedQuestion, setOpenedQuestion] = useState<number | null>(null);

  const [questions, fetchQuestions] = useQuestions(tab === 'incoming');
  const [categories, fetchCategories] = useQuestionsCategories();
  const saveQuestions = useSaveQuestion();

  const [rootContainerRef] = useAutoAnimate();
  const [cardsContainerRef] = useAutoAnimate();

  const [newQuestion, setNewQuestion] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const sortedQuestions = useMemo(() => {
    let sortFunction = (a: Question, b: Question) => new Date(b.date).getTime() - new Date(a.date).getTime();
    if (tab === 'incoming') {
      sortFunction = (a: Question, b: Question) => new Date(a.date).getTime() - new Date(b.date).getTime();
    }
    return questions.value?.sort(sortFunction);
  }, [questions, tab]);

  useEffect(() => {
    fetchCategories().then();
    fetchQuestions().then();
  }, [fetchCategories, fetchQuestions]);

  const withAddQuestion = useMemo(() => tab === 'incoming', [tab]);

  const onSetTab = useCallback(
    (event: React.ChangeEvent<{}>, value: string) => {
      setTab(value);
      fetchQuestions().then();
    },
    [fetchQuestions],
  );

  const newCard = useCallback(() => {
    setNewQuestion(true);
    setOpenedQuestion(null);
  }, []);

  const closeCard = useCallback(() => {
    setOpenedQuestion(null);
  }, []);

  const closeNewCard = useCallback(() => {
    closeCard();
    setNewQuestion(false);
  }, [closeCard]);

  const openCard = useCallback((id) => {
    setOpenedQuestion(id);
    setNewQuestion(false);
  }, []);

  const onSaveQuestion = useCallback(
    async (question: Question | NewQuestion) => {
      const trimmed = { ...question, question: question.question.trim() };

      try {
        await saveQuestions(trimmed);
        fetchQuestions().then();
        closeNewCard();
      } catch (e) {
        setError(e.response.data);
      }
    },
    [saveQuestions, fetchQuestions, closeNewCard],
  );

  const onCloseError = useCallback(() => {
    setError(null);
  }, []);

  return (
    <Layout>
      <Tabs value={tab} onChange={onSetTab} centered>
        <Tab label="Incoming" value="incoming" />
        <Tab label="Past" value="past" />
      </Tabs>
      <S.ErrorContainer>
        <Error error={error} onClose={onCloseError} />
      </S.ErrorContainer>
      <div ref={rootContainerRef}>
        <S.Header>
          <Button disabled={!withAddQuestion} color="primary" variant="outlined" onClick={newCard}>
            Add a question
          </Button>
          {(questions.loading || categories.loading) && (
            <S.LoaderContainer>
              <Typography component="h1" variant="h5">
                Loading...
              </Typography>
              <S.CircularProgressStyled size={25} />
            </S.LoaderContainer>
          )}
        </S.Header>
        {!questions.loading && !categories.loading && (
          <div ref={cardsContainerRef}>
            {newQuestion && tab !== 'past' && (
              <OpenedCard
                question={(emptyQuestion as unknown) as Question}
                categories={categories.value}
                closeCard={closeNewCard}
                saveQuestion={onSaveQuestion}
              />
            )}
            {sortedQuestions?.map((question) =>
              question.id === openedQuestion ? (
                <OpenedCard
                  key={question.id}
                  question={question}
                  categories={categories.value}
                  closeCard={closeCard}
                  saveQuestion={onSaveQuestion}
                />
              ) : (
                <Card
                  key={question.id}
                  question={question}
                  category={categories.value?.[question.categoryId] || '<-- non -->'}
                  openCard={openCard}
                  withSettings={tab === 'incoming'}
                />
              ),
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};
