import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { useMessages } from 'src/services/flure-chat-moderation/hooks';
import { ApiMessage } from 'src/network/flure-chat-moderation/types';
import { Resources } from 'src/resources';

import { useStyles } from './styles';

type Props = {
  senderId: string;
  recipientId: string;
  keyWordStartIndex: number;
  keyWordEndIndex: number;
  messageNumber: number;
  text: string;
};

const omitDiffValue = 10;

export const MessagesList = ({
  senderId,
  recipientId,
  keyWordStartIndex,
  keyWordEndIndex,
  messageNumber = 10,
  text,
}: Props) => {
  const classes = useStyles();
  const { messagesState, fetchMessages } = useMessages();
  const messagesCount = messagesState.value?.length || 0;

  const renderMessage = ({ message, index }: { message: ApiMessage; index: number }) => {
    return (
      <span
        key={message.id}
        className={clsx(classes.messageContainer, message.sender === senderId && classes.activeMessage)}
      >
        <span className={classes.mediumText}>{messageNumber - messagesCount + index}</span>
        <span className={classes.mediumText}>{message.text || Resources.strings.flureChat.cardGameMessage}</span>
      </span>
    );
  };

  const renderSuspiciousMessage = () => {
    const firstPart = text.slice(0, keyWordStartIndex);
    const suspiciousPart = text.slice(keyWordStartIndex, keyWordEndIndex);
    const lastPart = text.slice(keyWordEndIndex, text.length);

    return (
      <span className={clsx(classes.messageContainer, classes.activeMessage)}>
        <span className={classes.mediumText}>{messageNumber}</span>
        <span className={classes.mediumText}>
          {firstPart}
          <span className={classes.redText}>
            <b>{suspiciousPart}</b>
          </span>
          {lastPart}
        </span>
      </span>
    );
  };

  useEffect(() => {
    if (messageNumber > 1) {
      fetchMessages({
        senderId,
        recipientId,
        select: messageNumber > omitDiffValue ? 9 : messageNumber - 1,
        omit: messageNumber > omitDiffValue ? messageNumber - omitDiffValue : 0,
        sort: 'asc',
      });
    }
  }, [fetchMessages, messageNumber, recipientId, senderId]);

  return (
    <div className={classes.messagesContainer}>
      {!messagesState.loading && messagesState.value?.map((message, index) => renderMessage({ message, index }))}
      {messagesState.loading && (
        <div className={classes.loader}>
          <CircularProgress color="secondary" />
        </div>
      )}
      {renderSuspiciousMessage()}
    </div>
  );
};
