import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
  },
  block: {
    padding: '14px 20px',
    borderRadius: '8px',
    color: '#EF7BF8',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 700,
    display: 'flex',
    border: 'none',
    width: 'fit-content',
    justifyContent: 'center',
    background: 'linear-gradient(141deg, #FFF 0%, #FFF 68.57%, #FFF 100%)',
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.04), 0px 24px 32px 0px rgba(0, 0, 0, 0.04)',
    textTransform: 'none',
  },

  userId: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#000000',
    opacity: 0.5,
  },

  horizontal: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '28px',
  },
});
