import { useAsyncFn } from 'react-use';
import { flureFeedRequest } from 'src/network/flure-feed';
import { useAuth } from 'src/services/auth';

export const useMyLocationsWithUsersCount = () => {
  const { me } = useAuth();
  const operatorId = me?.id;

  const [myLocations, fetchMyLocations] = useAsyncFn(
    async (substring?: string) => {
      try {
        if (operatorId) {
          const result = await flureFeedRequest.getMyLocationsWithUsersCount(operatorId, substring);
          return result;
        }

        return null;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return {
    myLocations: myLocations.value,
    myLocationsLoading: myLocations.loading,
    fetchMyLocations,
  };
};
