import React from 'react';
import Button from '@material-ui/core/Button';
import { CellParams, ColDef } from '@material-ui/data-grid';
import { CommissionsReportDataItem } from 'src/types/report/commissions';

import { TableColumnsProps } from '../types';

const ACCENT_COLOR = '#5A90DC';

export function getUdatesTableColumns({ onSentMessageClick }: TableColumnsProps): ColDef[] {
  return [
    { field: 'operatorId', headerName: 'ID', width: 130, sortable: false },
    { field: 'agencyName', headerName: 'Agency name', width: 130, sortable: false },
    { field: 'operatorName', headerName: 'Operator name', width: 180, sortable: false },
    {
      field: 'messages',
      headerName: 'Sent',
      width: 125,
      sortable: false,
      renderCell: (params: CellParams) => (
        <Button
          type="button"
          size="small"
          style={{ color: ACCENT_COLOR, padding: '0 4px', minWidth: 'auto', fontWeight: 400 }}
          onClick={() => onSentMessageClick(params.getValue('operatorId') as string)}
        >
          {params.getValue('sentMessages')}
        </Button>
      ),
    },
    {
      field: 'receivedData',
      headerName: 'Received',
      description: 'Replies / Follow Up Replies / Viewed Photos / Gifts Reply',
      width: 160,
      sortable: false,
      renderCell: (params: CellParams) => {
        const followUpRepliesCount =
          (params.getValue('answeredFollowUpBonus') as CommissionsReportDataItem['answeredFollowUpBonus'])
            ?.bonusesCount || 0;
        const mediaViewBounusCount =
          (params.getValue('mediaViewBonus') as CommissionsReportDataItem['mediaViewBonus'])?.bonusesCount || 0;
        const giftsReplyCount =
          (params.getValue('giftsReplyBonus') as CommissionsReportDataItem['giftsReplyBonus'])?.bonusesCount || 0;

        return (
          <>
            <span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>
              {params.getValue('receivedMessages')}
            </span>
            /<span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>{followUpRepliesCount}</span>/
            <span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>{mediaViewBounusCount}</span>/
            <span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>{giftsReplyCount}</span>
          </>
        );
      },
    },
    {
      field: 'operatorCommission',
      headerName: 'Op. Commission',
      description: 'Replies / Follow Up Replies / Viewed Photos / Gifts Reply',
      width: 190,
      sortable: false,
      renderCell: (params: CellParams) => {
        const followUpRepliesValue =
          (params.getValue('answeredFollowUpBonus') as CommissionsReportDataItem['answeredFollowUpBonus'])
            ?.totalBonusValue || 0;
        const mediaViewBonusValue =
          (params.getValue('mediaViewBonus') as CommissionsReportDataItem['mediaViewBonus'])?.totalBonusValue || 0;
        const giftsReplyValue =
          (params.getValue('giftsReplyBonus') as CommissionsReportDataItem['giftsReplyBonus'])?.totalBonusValue || 0;

        return (
          <>
            <span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>
              {params.getValue('operatorCommission')}
            </span>
            /<span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>{followUpRepliesValue}</span>/
            <span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>{mediaViewBonusValue}</span>/
            <span style={{ color: '#000', padding: '0 4px', minWidth: 'auto' }}>{giftsReplyValue}</span>
          </>
        );
      },
    },
    {
      field: 'agencyCommission',
      headerName: 'Ag. Commission',
      width: 150,
      sortable: false,
    },
    {
      field: 'totalTrapsPenalty',
      headerName: 'Traps',
      width: 70,
      sortable: false,
    },
    {
      field: 'hoursOnline',
      headerName: 'Online (h)',
      width: 80,
      sortable: false,
    },
    {
      field: 'prettyDateTime',
      headerName: 'Date',
      width: 230,
      sortable: false,
    },
  ];
}
