import React from 'react';
import { Modal } from '@material-ui/core';

import { Resources } from 'src/resources';
import { TitleText, ActionButton, ActionButtonType } from 'src/components/common/flure';

import armIllustration from './arm-illustration.png';
import closeIcon from './close-icon.png';
import { useStyles } from './styles';

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
};

export const DeleteMessageModal = (props: Props) => {
  const { isOpen, onConfirm, onReject } = props;
  const classes = useStyles();

  return (
    <Modal open={isOpen} className={classes.modal}>
      <div className={classes.modalContent}>
        <button className={classes.closeButton} type="button" onClick={onReject}>
          <img className={classes.closeIcon} src={closeIcon} alt="close" />
        </button>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={armIllustration} alt="armIllustration" />
        </div>
        <TitleText className={classes.title} text={Resources.strings.flureChat.deleteMessageModalTitle} isHTML />
        <div className={classes.buttonsContainer}>
          <ActionButton title={Resources.strings.yes} type={ActionButtonType.Default} onClick={onConfirm} />
          <ActionButton title={Resources.strings.no} type={ActionButtonType.Stroke} onClick={onReject} />
        </div>
      </div>
    </Modal>
  );
};
