import React from 'react';
import { TableMargins, TableStretcher } from 'src/components/Report/common/Table/styled';
import 'src/components/Report/common/Table/styles.css';
import { ActivityReportDataItem } from 'src/types/report/activity';
import { DataGrid } from '@material-ui/data-grid';
import { columns } from './columns';

type Props = {
  data?: ActivityReportDataItem[];
};

export const ActivityReportTable = ({ data = [] }: Props) => {
  return (
    <TableMargins>
      <TableStretcher>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={25}
          headerHeight={30}
          rowHeight={30}
          disableColumnMenu
          disableColumnSelector
          disableColumnFilter
          disableColumnResize
          disableColumnReorder
          disableSelectionOnClick
        />
      </TableStretcher>
    </TableMargins>
  );
};
