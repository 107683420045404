import React from 'react';
import clsx from 'clsx';

import { PromoCodeData } from 'src/network/flure-promocodes/types';

import { useStyles } from './styles';

type Props = {
  data: PromoCodeData[];
  buttonDisabled: boolean;
  onButtonPress: () => void;
};

export const TopContainer = React.memo(({ data, buttonDisabled, onButtonPress }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.topContainer}>
      <div className={classes.textContainer}>
        <div>
          <span className={classes.titleText}>Stage Name:&nbsp;</span>
          <span className={classes.valueText}>Local Events</span>
        </div>
        <div>
          <span className={classes.titleText}>Events Number:&nbsp;</span>
          <span className={classes.valueText}>{data.length}</span>
        </div>
      </div>
      <button
        type="button"
        className={clsx(classes.addEventButton, buttonDisabled && classes.disabledButton)}
        onClick={onButtonPress}
        disabled={buttonDisabled}
      >
        Add event +
      </button>
    </div>
  );
});
