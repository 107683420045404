import { useCallback, useEffect, useMemo } from 'react';
import { useAsyncFn } from 'react-use';

import { animatorRequest } from 'src/network/animator';
import { getRealm } from 'src/utils/realm';
import { transformDbIdToFrontId } from 'src/utils/platform/convert-id';
import { useAuth } from '../auth';

export const useAnimators = () => {
  const { me } = useAuth();
  const realm = useMemo(() => getRealm(me?.realm), [me]);
  const [animatorsState, fetchAnimators] = useAsyncFn(
    async () => {
      const result = await animatorRequest.getAll(realm);
      return result;
    },
    [],
    { loading: true },
  );

  useEffect(() => {
    fetchAnimators().then();
  }, [fetchAnimators]);

  const linkOperatorWithAgency = useCallback(
    async (animatorDbId: number, agencyTechName?: string | null) => {
      const animatorId = transformDbIdToFrontId(animatorDbId);
      await animatorRequest.linkWithAgency(animatorId, agencyTechName || null, realm);

      await fetchAnimators();
    },
    [fetchAnimators, realm],
  );

  return {
    animatorsState,
    linkOperatorWithAgency,
  };
};
