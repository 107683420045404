import { makeStyles, withStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  fieldStyle: {
    width: '100%',
  },
});

export const withTextFieldStyles = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'black',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'black',
        },
        '&:hover fieldset': {
          borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'black',
        },
      },
    },
  })