import { AxiosResponse } from 'axios';

import { Operator } from 'src/types/operator';
import { transformDbIdToFrontId } from 'src/utils/platform/convert-id';
import { OperatorsDto } from './types';

const getAll = (response: AxiosResponse<OperatorsDto>): Operator[] => {
  return response.data.operators.map((operator) => {
    const operatorDbId = String(operator.operatorId);

    const adaptedItem: Operator = {
      ...operator,
      operatorId: transformDbIdToFrontId(operatorDbId),
      operatorDbId,
      firstName: operator.firstName || '',
      lastName: operator.lastName || '',
      isReadyForExplicit: operator.isReadyForExplicit || false,
    };

    return adaptedItem;
  });
};

export const adapter = {
  getAll,
};
