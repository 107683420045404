import { MediaModerationReason } from 'src/network/moderation/types';

import { ActionType, UserForModerationStatus } from '../moderation';
import { UserRoles } from '../user/roles';

export type BaseName = string;

type ScamAction = {
  actionType: RAW.Action.ScamUser;
  actionValue: string;
  reason: RAW.Reason.Scam;
};

type ScamPhotoAction = {
  actionType: RAW.Action.Reject;
  actionValue: string;
  reason: RAW.Reason.Scam;
};

type TrashAction = {
  actionType: RAW.Action.Reject;
  actionValue: string;
  reason: RAW.Reason.Trash;
};

type AdultContentAction = {
  actionType: RAW.Action.Reject;
  actionValue: string;
  reason: RAW.Reason.EighteenPlus;
};

type NoUserAction = {
  actionType: RAW.Action.Reject;
  actionValue: string;
  reason: RAW.Reason.NoUser;
};

type ApproveAction = {
  actionType: RAW.Action.Approve;
  actionValue: string;
};

export namespace RAW {
  export enum ModeratedValue {
    Name = 'Nameless',
    Bio = 'Hey there!',
    Work = '',
  }
  export type ResetAction =
    | {
        actionType: 'resetName';
        actionValue: ModeratedValue.Name;
      }
    | {
        actionType: 'resetBio';
        actionValue: ModeratedValue.Bio;
      }
    | {
        actionType: 'resetWork';
        actionValue: ModeratedValue.Work;
      }
    | {
        actionType: 'resetQuestions';
        actionValue: string; // here string is stringified array of { question: string; answer: string }
      }
    | { actionType: 'blockUser'; actionValue?: string };
  export enum ContentType {
    Default = 'default',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    RAW = 'raw',
  }

  export enum BackAction {
    approveRawPhoto = 'approveRawPhoto',
    approveProfilePhoto = 'approveProfilePhoto',
    rejectProfilePhoto = 'rejectProfilePhoto',
    rejectRawPhoto = 'rejectRawPhoto',
    scamUser = 'scamUser',
  }

  export enum Reason {
    EighteenPlus = 'eighteenPlus',
    Trash = 'trash',
    Scam = 'scam',
    NoUser = 'noUser',
  }

  export enum Action {
    Approve = 'approve',
    Reject = 'reject',
    ScamUser = 'scamUser',
  }

  export type ModerationAction =
    | ScamAction
    | ScamPhotoAction
    | TrashAction
    | AdultContentAction
    | NoUserAction
    | ApproveAction
    | ResetAction;
  export type BackModerationAction =
    | {
        actionType: BackAction;
        actionValue?: string;
        reason?: Reason;
      }
    | ResetAction;

  export type ModerationContentCount = {
    profilePhotoCount: number;
    rawPhotoCount: number;
  };

  type CommonContent = {
    id: number;
    userId: string;
    createdAt: string;
  };

  export type DefaultModerationContent = {
    content: CommonContent & {
      mediaId: string;
      type: ContentType.Default;
      primaryBaseName?: undefined;
      secondaryBaseName?: undefined;
    };
  };

  export type RAWModerationContent = {
    content: CommonContent & {
      type: ContentType.RAW;
      primaryBaseName: string;
      secondaryBaseName: string;
      mediaId: string;
    };
  };

  export type TextModerationContent = {
    content: CommonContent & {
      primaryBaseName?: string;
      secondaryBaseName?: string;
      mediaId?: string;

      userName: string;
      userWork: string;
      userBio: string;
      userQuestions: { question: string; answer: string }[];
    };
  };

  export type ModerationContent = DefaultModerationContent | RAWModerationContent; // | TextModerationContent;

  export type UserForModeration = {
    moderationContent: ModerationContent;
    user: UserPreset;
    status: UserForModerationStatus;
  };

  type ISOString = string;

  type Media = {
    baseName: BaseName;
  };

  export type User = {
    id: string;
    name: string;
    birthday: ISOString;
    gender: string;
    basics: {
      work?: string;
      education?: string;
      height?: string;
      drinking?: string;
      smoking?: string;
      lookingFor?: string;
      kids?: string;
      politics?: string;
      religion?: string;
      pronouns: string[];
      languages: string[];
    };
    media: Media[];
    bio?: string;
    interests: string[];
    questions: {
      question: string;
      answer: string;
    }[];
    // location: {
    //   country: Location.Country;
    //   city: Location.City;
    // };
  };

  type RAWPhoto = {
    id: string;
    postedAt: ISOString;
    primary: {
      reference: string;
    };
    secondary: {
      reference: string;
    };
  };

  export type UserPreset = {
    profile: User;
    raws: RAWPhoto[];
  };

  export type ModerationSearch = { userId: User['id'] };

  export type Initiator = {
    id: User['id'];
    name: string;
    role: UserRoles.Supervisor | UserRoles.UsersOperator;
  };

  export type ModerationActions = {
    actions: {
      actionTypes: ActionType[];
      createdAt: string;
      initiator: Initiator;
    }[];
  };

  export enum PhotoAvailabilityStatus {
    Available = 'Available',
    Unlisted = 'Unlisted',
    Deleted = 'Deleted',
  }

  type CommonPhoto = {
    moderatedAt: string;
    availabilityStatus: PhotoAvailabilityStatus;
    initiator: Initiator;
  };

  export type DeclinedPhoto = {
    reason: MediaModerationReason;
  };

  export type ProfilePhoto = CommonPhoto & {
    type: ContentType.Default;
    baseName: string;
  };

  export type RawPhoto = CommonPhoto & {
    type: ContentType.RAW;
    id: string;
    primaryBaseName: string;
    secondaryBaseName: string;
  };

  export type DeclinedProfilePhoto = ProfilePhoto & DeclinedPhoto;

  export type DeclinedRawPhoto = RawPhoto & DeclinedPhoto;

  export type ModerationMediaPhoto = ProfilePhoto | RawPhoto | DeclinedProfilePhoto | DeclinedRawPhoto;
  
  export type ModerationMediaResponse = {
    approvedProfilePhotos: ProfilePhoto[];
    approvedRawPhotos: RawPhoto[];
    declinedProfilePhotos: DeclinedProfilePhoto[];
    declinedRawPhotos: DeclinedRawPhoto[];
  };

  export type ModerationMedia = {
    approvedProfilePhotos: ProfilePhoto[];
    approvedRawPhotos: RawPhoto[];
    declinedPhotos: (RAW.DeclinedProfilePhoto | RAW.DeclinedRawPhoto)[];
  };

  export enum LoadState {
    Initial = 'Initial',
    Pending = 'Pending',
    Fulfilled = 'Fulfilled',
    Rejected = 'Rejected',
  }

  type Prompt = string;
  type Translate = string;

  export type QuestionConfig = Record<Prompt, Translate>;

  export enum StorageKey {
    QueueBlocked = 'queue-blocked',
    StoredContentType = 'stored-content-type',
  }

  export enum MediaType {
    Video = 'video',
    Photo = 'photo',
  }

  export enum AutoModerationContentType {
    Default = 'default',
  }

  export type AutoModerationContent = {
    userId: RAW.User['id'];
    actionType: string;
    actionValue: string;
    reason: string;
    meta: {};
    createdAt: ISOString;
  };

  export type AutoModerationContentResponse = {
    actions: AutoModerationContent[];
    totalCount: number;
  };

  export type ProfilePhotos = BaseName[];

  export enum SecondaryContent {
    ProfileContent = 'ProfileContent',
    RAWContent = 'RAWContent',
  }

  export type SecondaryContentTypeState = {
    loading: boolean;
    value?:
      | {
          secondaryContentType: RAW.SecondaryContent.ProfileContent;
          content: RAW.ProfilePhotos;
        }
      | {
          secondaryContentType: RAW.SecondaryContent.RAWContent;
          content: RAW.ModerationMedia;
        };
  };
}
