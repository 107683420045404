import React from 'react';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';

import { useStyles } from './styles';

import { MessagePreview } from '../../types';
import iphoneStatusBar from './iphone-status-bar.png';
import chatHeader from './chat-header.png';
import { MessageItem } from './components';

type Props = {
  messages: MessagePreview[];
  couldEdit?: boolean;
  withHeader?: boolean;
  loading?: boolean;
};

const PhonePreviewView: React.FC<Props> = (props) => {
  const { messages, couldEdit, withHeader = false, loading = false } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, withHeader && classes.largerHeight)}>
      {withHeader && (
        <div className={classes.topContainer}>
          <img className={classes.statusBar} src={iphoneStatusBar} alt="iphoneStatusBar" />
          <img className={classes.chatHeader} src={chatHeader} alt="chatHeader" />
        </div>
      )}
      <div className={classes.contentContainer}>
        {!loading &&
          messages.map((message, index) => (
            <MessageItem
              // eslint-disable-next-line react/no-array-index-key
              key={`message_${index}`}
              message={message.text}
              reference={message.reference}
              buttonTitle={message?.meta?.buttonTitle}
              componentsOrder={message?.meta?.componentsOrder}
              created={message.created}
              messageId={message.id}
              couldEdit={couldEdit}
            />
          ))}
      </div>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </div>
  );
};

export const PhonePreview = React.memo(PhonePreviewView);
