import { useAsyncFn } from 'react-use';
import moment from 'moment';

import { reportRequest } from 'src/network';
import { GetReportByMessagesRequestParams } from 'src/network/report/messages/types';
import { MessagesReportFormFields, MessageStatus } from 'src/types/report/messages';
import { useAuth } from 'src/services/auth';
import { getRealm, BackendAlias } from 'src/utils/realm';

import { convertBaseFormFieldsToQueryParams } from './helpers';

const messageFieldsStatusesMap: Record<MessageStatus, boolean | null> = {
  all: null,
  delivered: true,
  blocked: false,
};

function convertFormFieldsToQueryParams(
  fields: MessagesReportFormFields,
  realm: BackendAlias,
): GetReportByMessagesRequestParams {
  const [from, to] = fields.rangeDate.split(':');

  const baseParams = convertBaseFormFieldsToQueryParams(fields, realm);

  const params: GetReportByMessagesRequestParams = {
    ...baseParams,
    agencyTechName: fields.agencyTechName || null,
    operator: fields.operatorId ? String(fields.operatorId) : null,
    sent: fields.status ? messageFieldsStatusesMap[fields.status] : null,
    from,
    to: moment(to).add(1, 'day').format('YYYY-MM-DD'),
  };

  return params;
}

export const useReportByMessages = () => {
  const { me } = useAuth();

  const realm = getRealm(me?.realm);

  const [reportByMessagesState, fetchReportByMessages] = useAsyncFn(
    async (params: MessagesReportFormFields) => {
      const queryParams = convertFormFieldsToQueryParams(params, realm);
      return reportRequest.messages.get(queryParams);
    },
    [realm],
  );

  return {
    reportByMessagesState,
    fetchReportByMessages,
  };
};
