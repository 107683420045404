import { Http } from 'src/network/http';
import { AgenciesDto, UserTagsAgencyDto } from './types';
import { adapter } from './adapter';

const getAll = (realm: string) => {
  return Http.shared()
    .instance.get<AgenciesDto>('/multichat/agencies', { params: { realm } })
    .then(adapter.getAll);
};

const getAgencyName = (userId: string) => {
  return Http.shared().instance.get<UserTagsAgencyDto>(`/users/${userId}/tags/agency`).then(adapter.getAgencyName);
};

export const agencyRequest = {
  getAll,
  getAgencyName,
};
