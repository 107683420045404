import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  smallText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
  },

  attendeeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '2px',
    width: '180px',
  },

  mediumText: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '27px',
  },

  link: {
    color: 'black',
  }
});
