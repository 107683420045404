import { useEffect } from 'react';

import { actions, useModerationContext } from 'src/context/raw/moderation';
import prompts from 'src/resources/raw/configs/prompts.json';

export const useStatic = () => {
  const { dispatch } = useModerationContext();

  useEffect(() => {
    try {
      dispatch(actions.loadQuestionConfigPending());
      // network request for config
      // for now config stored in the app
      dispatch(actions.loadQuestionConfigFulFilled(prompts));
    } catch (error) {
      dispatch(actions.loadQuestionConfigRejected());
    }
  }, [dispatch]);
};
