import React, { memo, useState } from 'react';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { Resources } from 'src/resources';
import { VerificationPhoto } from 'src/components/Moderation/views/verification-photo';
import { RealPhoto } from 'src/components/Moderation/views/real-photo';
import { PhotoTransformation } from 'src/components/Moderation/utils/image-source';

import { useStyles } from './styles';

type Props = {
  userId: string;
};

const VerificationMediaView = (props: Props) => {
  const { userId } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Box key="verification" className={classes.verificationCardContainer}>
        <Typography className={classes.title}>{Resources.strings.moderation.media.verification}</Typography>
        <div className={classes.photoWithLoader}>
          <VerificationPhoto
            userId={userId}
            checkIfVerified={false}
            imageContainerStyle={classes.verificationImageContainer}
            imageStyle={clsx(classes.verificationImage, !isLoaded && classes.hidden)}
            transformation={PhotoTransformation.KeepAspectRatio}
            onLoadEnd={() => setIsLoaded(true)}
            zoomEnabled
          />
          {!isLoaded && (
            <Box className={classes.loaderContainer}>
              <CircularProgress />
            </Box>
          )}
        </div>
      </Box>
      <Box key="real" className={classes.verificationCardContainer}>
        <Typography className={classes.title}>{Resources.strings.moderation.media.real}</Typography>
        <div className={classes.photoWithLoader}>
          <RealPhoto
            userId={userId}
            checkIfVerified={false}
            imageContainerStyle={classes.verificationImageContainer}
            imageStyle={clsx(classes.verificationImage, !isLoaded && classes.hidden)}
            transformation={PhotoTransformation.KeepAspectRatio}
            onLoadEnd={() => setIsLoaded(true)}
            zoomEnabled
          />
          {!isLoaded && (
            <Box className={classes.loaderContainer}>
              <CircularProgress />
            </Box>
          )}
        </div>
      </Box>
    </>
  );
};

export const VerificationMedia = memo(VerificationMediaView);
