import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { Slider } from '@material-ui/core';
import { debounce, isEqual } from 'lodash';

import { withSliderStyles } from './styles';

type Props = {
  defaultValue?: number[];
  value?: number[];
  min?: number;
  max?: number;
  onChange?: (value: number[]) => void;
};

const CustomSlider = withSliderStyles(Slider);

const RangeSliderView = (props: Props) => {
  const { defaultValue, min, max, value: valueFromProps, onChange } = props;
  const [value, setValue] = useState(defaultValue);
  const previousValueFromProps = usePrevious(valueFromProps);

  const debouncedChangeHandler = useMemo(() => (onChange ? debounce(onChange, 300) : undefined), [onChange]);

  const handleChange = useCallback(
    (_: React.ChangeEvent<{}>, newValue: number | number[]) => {
      setValue(newValue as number[]);

      if (debouncedChangeHandler) {
        debouncedChangeHandler(newValue as number[]);
      }
    },
    [debouncedChangeHandler],
  );

  const formatValueLabel = useCallback(
    (valueParam: number) => {
      if (valueParam === max) {
        return `${valueParam}+`;
      }

      return valueParam;
    },
    [max],
  );

  useEffect(() => {
    if (!isEqual(previousValueFromProps, valueFromProps) && !isEqual(value, valueFromProps)) {
      setValue(valueFromProps);
    }
  }, [previousValueFromProps, value, valueFromProps]);

  return (
    <CustomSlider
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      valueLabelDisplay="on"
      valueLabelFormat={formatValueLabel}
      aria-labelledby="range-slider"
      min={min}
      max={max}
    />
  );
};

export const RangeSlider = React.memo(RangeSliderView);
