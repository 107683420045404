import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '33px',
  },
  userGenderAndAge: {
    color: '#101014',
    textAlign: 'center',
    fontSize: '29px',
    lineHeight: '18px',
    fontWeight: 700,
    marginBottom: '10px',
  },
  userId: {
    fontSize: 16,
    color: '#020D3D',
    fontWeight: 400,
    opacity: 0.5,
    marginBottom: '20px',
  },
  btn: {
    height: '48px',
    width: '210px',
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: 700,
    color: '#FF4747',
    borderRadius: '23px',
    background: '#FFF',
    boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
  },
});
