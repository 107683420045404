import { AxiosResponse } from 'axios';
import moment from 'moment';

import { BonusesReportDataItem } from 'src/types/report/bonuses';
import { BonusesReportDto } from './types';

const get = (response: AxiosResponse<BonusesReportDto>) => {
  return response.data.map((item, i) => {
    const adaptedItem: BonusesReportDataItem = {
      id: String(item.timestamp) + i,
      operatorId: String(item.operatorId),
      bonusType: String(item.bonusType),
      bonusValue: String(item.bonusValue),
      bonusCount: String(item.bonusCount),
      agencyBonusValue: String(item.agencyBonusValue),
      agencyName: item.agencyName || '-',
      operatorName: item.operatorName || '-',
      timestamp: new Date(item.timestamp),
      prettyDateTime: moment(item.timestamp).format('YYYY/MM/DD HH:mm'),
      allReplies: item.repliesInfo?.AllReplies ? String(item.repliesInfo.AllReplies) : '-',
      weekendReplies: item.repliesInfo?.WeekendReplies ? String(item.repliesInfo.WeekendReplies) : '-',
      replyRate: item.repliesInfo?.ReplyRate ? `${String(item.repliesInfo.ReplyRate)}%` : '-',
    };

    return adaptedItem;
  });
};

export const adapter = {
  get,
};
