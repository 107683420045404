import { Button } from '@material-ui/core';
import React from 'react';

import { ImageWithLogger } from 'src/components/Moderation/views/raw/image-with-logger';
import { getMediaSourceRAW } from 'src/components/Moderation/utils/image-source';
import { RAW } from 'src/types/raw';
import { LargeTooltip } from 'src/components/Moderation/views/tooltip';

import { Label } from '../label';
import { RawPhoto } from '../raw-photo';
import { StatusIcon } from '../status-icon';

import { useStyles } from './styles';
import { TooltipContent } from './tooltip-wrapper';

import PhotoAvailabilityStatus = RAW.PhotoAvailabilityStatus;

type Props = {
  userId: RAW.User['id'];
  photo: RAW.ModerationMediaPhoto;
  onClick?: () => void;
};

const mapPhotoAvailabilityStatusToIconStatus: Record<
  PhotoAvailabilityStatus,
  React.ComponentProps<typeof StatusIcon>['kind'] | undefined
> = {
  [PhotoAvailabilityStatus.Available]: undefined,
  [PhotoAvailabilityStatus.Unlisted]: 'warning',
  [PhotoAvailabilityStatus.Deleted]: 'error',
};

export function PhotoWrapper({ userId, photo, onClick }: Props) {
  const { availabilityStatus } = photo;
  const classes = useStyles();

  const isProfilePhoto = photo.type === RAW.ContentType.Default;
  const isRawPhoto = photo.type === RAW.ContentType.RAW;
  const isDeclined = Object.prototype.hasOwnProperty.call(photo, 'reason');

  const statusIsSuitableForDisplay = availabilityStatus !== RAW.PhotoAvailabilityStatus.Deleted;

  let image;
  if (isProfilePhoto && statusIsSuitableForDisplay) {
    image = (
      <ImageWithLogger
        loading="lazy"
        className={isDeclined ? classes.rawImage : classes.profileImage}
        src={getMediaSourceRAW(userId, (photo as RAW.ProfilePhoto).baseName)}
        alt="profilePhoto"
      />
    );
  } else if (isRawPhoto) {
    image = (
      <RawPhoto
        loading="lazy"
        key={(photo as RAW.RawPhoto).id}
        userId={userId}
        {...(photo as RAW.RawPhoto)}
        availabilityStatus={availabilityStatus}
      />
    );
  }

  return (
    <LargeTooltip
      title={
        <TooltipContent
          declined={isDeclined}
          unlisted={availabilityStatus === PhotoAvailabilityStatus.Unlisted}
          photo={photo}
        />
      }
      arrow
    >
      <Button onClick={onClick} className={isProfilePhoto && !isDeclined ? classes.profileImage : classes.rawImage}>
        {image}
        {isDeclined && isProfilePhoto && <Label reason={(photo as RAW.DeclinedProfilePhoto).reason} />}
        <StatusIcon kind={mapPhotoAvailabilityStatusToIconStatus[availabilityStatus]} />
      </Button>
    </LargeTooltip>
  );
}
