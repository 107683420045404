import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  pushBlockContainer: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '200px',
    marginBottom: '70px',
  },

  justifyStart: {
    width: '910px',
    justifyContent: 'flex-start',
  },

  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '335px',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
  },

  subtitle: {
    display: 'flex',
    justifySelf: 'flex-start',
    marginBottom: '32px',
  },

  checkBoxesContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    marginBottom: '57px',
  },

  pushContentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
  },

  inputsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
    rowGap: '16px',
  },

  rightContainer: {
    width: '375px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '75px',
  },

  zeroPaddingTop: {
    paddingTop: '0px',
  },

  button: {
    width: '420px',
  },
});
