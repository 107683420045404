import React, { useMemo } from 'react';

import { Selector } from 'src/components/common/Selector';
import { Agency } from 'src/types/agency';

type Props = {
  agencies: Agency[];
  value?: string;
  onChange: (value: string) => void;
};

export const AgencySelector = ({ agencies, value, onChange }: Props) => {
  const values = useMemo(() => agencies.map((agency) => ({ key: agency.techName, value: agency.name })), [agencies]);

  return <Selector variants={values} onChange={onChange} emptyPlaceholder="Without agency" value={value} />;
};
