import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
`;

export const SearchForm = styled.div`
  margin-bottom: 20px;
`;

export const ActivateForm = styled.div`
  margin-top: 20px;
`;
