import React from 'react';
import { Paper } from '@material-ui/core';
import { ActionType, ModerationAction, UserForModeration } from 'src/types/moderation';
import { CommonActions } from 'src/components/Moderation/views/actions/common';
import { OnlyGoBack, OnlyUndo } from 'src/components/Moderation/views/actions/common/types';
import { useStyles } from './styles';

type Props = {
  isLoading: boolean;
  undoAction: (actionType: ActionType) => () => void;
  resetQuestions: () => void;
  resetName: () => void;
  isNoUndoUsers: boolean;
  moderationActions: ModerationAction[];
  sendModerationActions: (actions: ModerationAction[]) => Promise<void>;
  moderatedMedia: string;
  isPressed: (actionType: ActionType, actionValue?: string | undefined) => boolean;
  addModerationAction: (actionType: ActionType, actionValue?: string | undefined) => void;
} & (OnlyGoBack | OnlyUndo) &
  Pick<UserForModeration, 'newMediaSource'>;

export const MagnetActions = (props: Props) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.container}>
      <CommonActions
        {...props}
        trashable
        adultContentCensorable
        noUserCensorable
        primaryButtonStyle={classes.primaryButton}
        warningButtonStyle={classes.warningButton}
      />
    </Paper>
  );
};
