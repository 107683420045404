import { Product } from 'src/types/product';
import { DefaultReportFormFields, DefaultReportSearchMode, Reports } from 'src/types/report/common';
import { fillExcelDocument } from 'src/utils/excel';
import { downloadFile } from 'src/utils/files';
import { ActivityReportDataItem } from 'src/types/report/activity';

const convertActivityReport = (json: ActivityReportDataItem[]) => {
  return [
    ['ID', 'Agency name', 'Operator name', 'Online (h)', 'Date', 'Active time'],
    ...json.map((item) => [
      item.operatorId,
      item.agencyName,
      item.operatorName,
      item.hoursOnline,
      item.date,
      item.activityTimes,
    ]),
  ];
};

export const downloadActivityReportXlsx = (payload: ActivityReportDataItem[], fileName: string) => {
  const convertedData = convertActivityReport(payload);
  const document = fillExcelDocument('Report by Activity', convertedData);

  downloadFile(document, `${fileName}.xlsx`);
};

export const getFileName = (realm: Product, filter?: DefaultReportFormFields): string => {
  if (!filter) {
    return 'defaultName';
  }

  const reportName = Reports.activity;
  const formattedDateRange = filter.rangeDate.replace(':', '__');

  const reportMapping: Record<DefaultReportSearchMode, string> = {
    agency: 'agency',
    all: 'all',
    'all-without-agency': 'without-agency',
    operator: 'operator',
  };
  const operator = filter.operatorId ? `-${filter.operatorId}` : '';
  const agency = filter.agencyTechName ? `-${filter.agencyTechName}` : '';

  return `${realm}-${reportName}-${reportMapping[filter.mode]}${agency}${operator}-${formattedDateRange}`;
};
