export function getRandomIndexes<T>(arr: T[], count = 4, alreadyPicked = [] as number[]) {
  const result: number[] = [];
  while (result.length < count) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    if (!result.includes(randomIndex) && !alreadyPicked.includes(randomIndex)) {
      result.push(randomIndex);
    }
  }

  return result;
}
