import { AxiosResponse } from 'axios';

import { Animator } from 'src/types/animator';
import { AnimatorsDto } from './types';

const getAll = (response: AxiosResponse<AnimatorsDto>) => {
  return response.data.animators as Animator[];
};

export const adapter = {
  getAll,
};
