import { useAsyncFn } from 'react-use';

import { UserForModerationStatus } from 'src/types/moderation';
import { Logger, LoggerServices, LoggerMessages } from 'src/infrastructure/loggers/datadog';
import { Product } from 'src/types/product';
import { sessionDataStorage } from 'src/utils/session-storage';
import { rawModerationRequest } from 'src/network/moderation/raw';
import { RAW } from 'src/types/raw';

const moderatedUsersCountKey = 'moderated-users-count';

const getLog = (operatorId: string) => (payload: {
  reason: string;
  userId?: string;
  isUndoAction?: boolean;
  error?: any;
}) => {
  Logger.log({
    service: LoggerServices.Moderation,
    message: LoggerMessages.UserForModerationStateIsNull,
    product: Product.RAW,
    payload: {
      message: LoggerMessages.UserForModerationStateIsNull,
      operatorId,
      moderatedUsersCount: Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)),
      ...payload,
    },
  });
};

const loadUser = (userId: string, logOnError: (error: any) => void) => {
  return rawModerationRequest.getRAWUserPreset(userId).catch((e) => {
    logOnError(e);
    return null;
  });
};

type Props = {
  contentType: RAW.ContentType;
} | void;

export const useUserForModeration = (operatorId: string) => {
  const log = getLog(operatorId);
  const [userForModerationState, fetchUserForModeration] = useAsyncFn(
    async (props: Props) => {
      const isUndoAction = !props?.contentType;
      try {
        const moderationContent = !props?.contentType
          ? await rawModerationRequest.undoRAWUserModeration(operatorId)
          : await rawModerationRequest.getRAWModerationContent(operatorId, props.contentType);

        if (!moderationContent || !moderationContent.content) {
          log({ reason: 'No moderation content' });
          return null;
        }

        const { userId } = moderationContent.content;

        if (!userId) {
          log({ reason: 'No userId', userId });

          return null;
        }

        const user = await loadUser(userId, (error) => log({ error, reason: 'loadUser crush' }));

        if (!user) return null;

        const data: RAW.UserForModeration = { moderationContent, user, status: UserForModerationStatus.Ok };

        return data;
      } catch (error: any) {
        if (error?.response?.status !== 404) {
          log({ reason: 'Error is not 404', isUndoAction, error: error?.response });
        }

        if (error?.response?.status === 404) {
          return { status: UserForModerationStatus.NoUndoUsers } as RAW.UserForModeration;
        }

        return null;
      }
    },
    [],
    { loading: true },
  );

  return { userForModerationState, fetchUserForModeration };
};
