import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';

import { Layout } from 'src/components/App/views/Layout';
import {
  PageHeader,
  PushPreview,
  DeleteMessageModal,
  AudienceSelector,
  MessageWithPreview,
} from 'src/components/FlureChat/components';
import { TitleText, TitleTextSize, ActionButton, ActionButtonType } from 'src/components/common/flure';
import { Resources } from 'src/resources';
import { flureChatRequest } from 'src/network/flure-chat';
import { useAuth } from 'src/services/auth';
import { MessageBody } from 'src/network/flure-chat/types';
import { useSearchParams } from 'src/components/FlureChat/hooks';
import {
  MessageComponents,
  defaultComponentsOrder,
  defaultMessageComponentsState,
} from 'src/components/FlureChat/types';
import { countriesOptions, genderOptions } from 'src/components/FlureChat/constants';

import { useStyles } from './styles';

const messageAndPushMock = {
  pushTitle: Resources.strings.flureChat.pushNotificationDefaultTitle,
  pushSubtitle: Resources.strings.flureChat.pushNotificationDefaultSubtitle,
  pushImage: '',
};

export const EditMessageScreen: React.FC = () => {
  const { gendersFromUrl, countriesFromUrl } = useSearchParams();
  const { messageId } = useParams<{ messageId: string }>();
  const { me } = useAuth();
  const operatorId = me?.id;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { pushTitle, pushSubtitle, pushImage } = messageAndPushMock;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedGenders, setSelectedGenders] = useState<string[]>(gendersFromUrl);
  const [selectedCountries, setSelectedCountries] = useState<string[]>(countriesFromUrl);
  const [messageText, setMessageText] = useState<string | undefined>();
  const [messageInitialText, setMessageInitialText] = useState<string | undefined>();
  const [messageInitialImage, setMessageInitialImage] = useState<string | undefined>();
  const [messageImage, setMessageImage] = useState<string | undefined>();
  const [messageImagePreview, setMessageImagePreview] = useState<string | undefined>();
  const [messageImageFile, setMessageImageFile] = useState<File | undefined>();
  const [buttonTitle, setButtonTitle] = useState(Resources.strings.add);
  const [buttonTitleInitial, setButtonTitleInitial] = useState(Resources.strings.add);
  const [selectedButtonUrl, setSelectedButtonUrl] = useState('');
  const [selectedButtonUrlInitial, setSelectedButtonUrlInitial] = useState('');
  const [messageComponents, setMessageComponents] = useState(defaultComponentsOrder);
  const [messageComponentsInitial, setMessageComponentsInitial] = useState(defaultComponentsOrder);
  const [messageComponentEnabled, setMessageComponentEnabled] = useState(defaultMessageComponentsState);
  const [messageComponentEnabledInitial, setMessageComponentEnabledInitial] = useState(defaultMessageComponentsState);
  const [isModalOpen, setModalOpen] = useState(false);
  const contentDidNotChange =
    messageText === messageInitialText &&
    messageInitialImage === messageImage &&
    buttonTitleInitial === buttonTitle &&
    selectedButtonUrlInitial === selectedButtonUrl &&
    isEqual(messageComponents, messageComponentsInitial) &&
    isEqual(messageComponentEnabled, messageComponentEnabledInitial);
  const isButtonDisabled =
    ((!messageComponentEnabled[MessageComponents.Text] || !messageText) &&
      (!messageComponentEnabled[MessageComponents.Photo] || !messageImage) &&
      (!messageComponentEnabled[MessageComponents.Button] || !selectedButtonUrl || !buttonTitle)) ||
    isLoading ||
    contentDidNotChange;
  const toggleModal = useCallback(() => setModalOpen((isOpen) => !isOpen), []);

  const sendMessage = useCallback(
    (userId: string, messageBody: MessageBody) => {
      flureChatRequest
        .patchMessage(userId, messageId, messageBody)
        .then(() => {
          enqueueSnackbar(Resources.strings.flureChat.messageSent, { variant: 'success' });
          setIsLoading(false);
          history.goBack();
        })
        .catch(() => {
          enqueueSnackbar(Resources.strings.flureChat.sendMessageError, { variant: 'error' });
          setIsLoading(false);
        });
    },
    [enqueueSnackbar, history, messageId],
  );

  const onSendPress = useCallback(() => {
    if (!operatorId) {
      return;
    }

    setIsLoading(true);

    const messageBody: MessageBody = {
      text: messageComponentEnabled[MessageComponents.Text] ? messageText || '' : '',
      reference: messageComponentEnabled[MessageComponents.Photo] ? messageImage || '' : '',
      meta: {
        componentsOrder: messageComponents,
      },
    };

    if (messageComponentEnabled[MessageComponents.Button] && buttonTitle && selectedButtonUrl) {
      messageBody.meta.buttonTitle = buttonTitle;
      messageBody.meta.buttonUrl = selectedButtonUrl;
    }

    if (messageComponentEnabled[MessageComponents.Photo] && messageImageFile) {
      const formData = new FormData();
      formData.append(messageImageFile.name, messageImageFile, messageImageFile.name);

      flureChatRequest.uploadPhotoToChat(operatorId, formData).then((response) => {
        const basename = (response.data as { basename: string })?.basename || (response.data as string[])?.[0];

        if (basename) {
          const imageUri = flureChatRequest.getPhotoUri(operatorId, basename);
          messageBody.reference = imageUri;
          sendMessage(operatorId, messageBody);
        } else if (messageText) {
          sendMessage(operatorId, messageBody);
        } else {
          setIsLoading(false);
          enqueueSnackbar(Resources.strings.flureChat.sendMessageError, { variant: 'error' });
        }
      });
    } else {
      sendMessage(operatorId, messageBody);
    }
  }, [operatorId, messageComponentEnabled, messageText, messageImage, messageComponents, buttonTitle, selectedButtonUrl, messageImageFile, sendMessage, enqueueSnackbar]);

  const deleteMessage = useCallback(() => {
    if (!operatorId || !messageId) {
      return;
    }

    setIsLoading(true);

    flureChatRequest
      .deleteMessage(operatorId, messageId)
      .then(() => {
        enqueueSnackbar(Resources.strings.flureChat.messageDeleted, { variant: 'success' });
        setIsLoading(false);
        history.goBack();
      })
      .catch(() => {
        enqueueSnackbar(Resources.strings.flureChat.deleteMessageError, { variant: 'error' });
        setIsLoading(false);
      });
  }, [enqueueSnackbar, history, messageId, operatorId]);

  useEffect(() => {
    if (messageId && operatorId) {
      setIsLoading(true);
      flureChatRequest
        .getMessagesByIds(operatorId, { ids: [messageId] })
        .then((response) => {
          const message = response.messages[0];
          setMessageComponents(message?.meta?.componentsOrder || defaultComponentsOrder);
          setMessageComponentsInitial(message?.meta?.componentsOrder || defaultComponentsOrder);
          setMessageText(message.text);
          setMessageInitialText(message.text);
          setMessageInitialImage(message.reference || undefined);
          setMessageImage(message.reference || undefined);
          setMessageImagePreview(message.reference);
          setButtonTitle(message?.meta?.buttonTitle || Resources.strings.add);
          setButtonTitleInitial(message?.meta?.buttonTitle || Resources.strings.add);
          setSelectedButtonUrl(message?.meta?.buttonUrl || '');
          setSelectedButtonUrlInitial(message?.meta?.buttonUrl || '');
          setMessageComponentEnabled({
            [MessageComponents.Text]: !!message.text,
            [MessageComponents.Photo]: !!message.reference,
            [MessageComponents.Button]: !!message?.meta?.buttonTitle && !!message?.meta?.buttonUrl,
          });
          setMessageComponentEnabledInitial({
            [MessageComponents.Text]: !!message.text,
            [MessageComponents.Photo]: !!message.reference,
            [MessageComponents.Button]: !!message?.meta?.buttonTitle && !!message?.meta?.buttonUrl,
          });
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [messageId, operatorId]);

  return (
    <Layout containerSize="xl">
      <DeleteMessageModal isOpen={isModalOpen} onConfirm={deleteMessage} onReject={toggleModal} />
      <div className={classes.container}>
        <PageHeader title={Resources.strings.flureChat.editMessage} />

        <div className={classes.audienceContainer}>
          <TitleText
            className={classes.subtitle}
            text={Resources.strings.flureChat.audience}
            size={TitleTextSize.Medium}
          />
          <AudienceSelector
            className={classes.checkBoxesContainer}
            genderOptions={genderOptions}
            countriesOptions={countriesOptions}
            selectedGenders={selectedGenders}
            setSelectedGenders={setSelectedGenders}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            disabled
          />
        </div>

        <MessageWithPreview
          messageTextInitialValue={messageInitialText}
          messageText={messageText}
          setMessageText={setMessageText}
          messageImage={messageImage}
          setMessageImage={setMessageImage}
          setMessageImageFile={setMessageImageFile}
          messageImagePreview={messageImagePreview}
          setMessageImagePreview={setMessageImagePreview}
          buttonTitle={buttonTitle}
          setButtonTitle={setButtonTitle}
          selectedButtonUrl={selectedButtonUrl}
          setSelectedButtonUrl={setSelectedButtonUrl}
          messageComponents={messageComponents}
          setMessageComponents={setMessageComponents}
          messageComponentEnabled={messageComponentEnabled}
          setMessageComponentEnabled={setMessageComponentEnabled}
        />

        <div className={classes.pushContainer}>
          <TitleText
            className={classes.subtitle}
            text={Resources.strings.flureChat.pushNotification}
            size={TitleTextSize.Medium}
          />
          <PushPreview title={pushTitle} subtitle={pushSubtitle} imageSource={pushImage} />
        </div>

        <div className={classes.buttonsContainer}>
          <ActionButton
            className={classes.deleteButton}
            type={ActionButtonType.Default}
            title={Resources.strings.flureChat.deleteMessage}
            onClick={toggleModal}
          />
          <ActionButton
            className={classes.button}
            type={ActionButtonType.Default}
            title={Resources.strings.flureChat.saveAndSend}
            onClick={onSendPress}
            disabled={isButtonDisabled}
          />
        </div>
      </div>
    </Layout>
  );
};
