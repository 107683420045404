import React, { useMemo } from 'react';
import { useMagnetPromptsConfig } from 'src/services/user/hooks';
import { QuestionItem } from 'src/types/user';

type Props = {
  questionItems: Array<QuestionItem> | string;
};

export const QuestionPrompts = (props: Props) => {
  const magnetUserPromptsState = useMagnetPromptsConfig();
  const { questionItems } = props;

  const renderQuestionsField = useMemo(() => {
    if (
      magnetUserPromptsState.loading ||
      !Array.isArray(magnetUserPromptsState.value || !questionItems) ||
      typeof questionItems === 'string'
    ) {
      return null;
    }
    const questions = magnetUserPromptsState.value || [];
    const questionMapper = (answerItem: QuestionItem, index: number) => {
      const question = questions[Number(answerItem.question)]?.trim()?.slice(0, -3) || 'Prompt not found';
      return (
        <span key={answerItem.question}>
          <span>{`${index + 1}. ${question} `}</span>
          <b>
            <i>{answerItem.answer}</i>
          </b>
          <br />
        </span>
      );
    };
    return questionItems.map(questionMapper);
  }, [magnetUserPromptsState.loading, magnetUserPromptsState.value, questionItems]);

  return <>{typeof questionItems === 'string' ? questionItems : renderQuestionsField}</>;
};
