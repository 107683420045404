import { makeStyles } from '@material-ui/core';
import { RAW_PHOTO_WIDTH } from 'src/components/Moderation/screens/AdminUserModeration/raw/components/media/raw-photo/styles';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px'
  },
  mock: {
    width: RAW_PHOTO_WIDTH,
  }
});
