import React, { useMemo } from 'react';

import { MediaType } from 'src/types/user';
import { VideoWithLogger } from 'src/components/Moderation/views/video-with-logger';
import { ImageWithLogger } from 'src/components/Moderation/views/image-with-logger';
import { FullSizePhotoModal } from 'src/components/Moderation/views/full-size-media/modal';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  source: string;
  mediaType: MediaType;
  basename?: string;
};

const maxPhotoSizeMap: { [key in Product]?: number } = {
  [Product.Once]: 2040,
};

export const FullSizeMedia = (props: Props) => {
  const { isOpen, setIsOpen, source, mediaType, basename } = props;
  const { me } = useAuth();
  const sourceWithSize = useMemo(() => {
    const maxPhotoSize = me?.realm && maxPhotoSizeMap[me.realm];

    return maxPhotoSize ? `${source}.x${maxPhotoSize}` : source;
  }, [me, source]);

  if (!source) {
    return null;
  }

  return (
    <FullSizePhotoModal setIsOpen={setIsOpen} isOpen={isOpen}>
      {mediaType === MediaType.Video ? (
        <VideoWithLogger src={source} basename={basename} autoPlay loop />
      ) : (
        <ImageWithLogger
          isFullSize
          src={sourceWithSize}
          basename={basename}
          alt="mainPhoto"
          detectCelebrity={me?.realm !== Product.Flure}
        />
      )}
    </FullSizePhotoModal>
  );
};
