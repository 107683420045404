import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import { Layout } from 'src/components/App/views/Layout';
import { PageHeader, MessageWithPreview, PushWithPreview, AudienceSelector } from 'src/components/FlureChat/components';
import { ActionButton, ActionButtonType, TitleText, TitleTextSize } from 'src/components/common/flure';
import { Resources } from 'src/resources';
import { flureChatRequest } from 'src/network/flure-chat';
import { useAuth } from 'src/services/auth';
import { MessageBody } from 'src/network/flure-chat/types';
import { useSearchParams } from 'src/components/FlureChat/hooks';
import { countriesOptions, genderOptions } from 'src/components/FlureChat/constants';
import {
  defaultMessageComponentsState,
  defaultComponentsOrder,
  MessageComponents,
} from 'src/components/FlureChat/types';

import { useStyles } from './styles';

export const NewMessageScreen: React.FC = () => {
  const classes = useStyles();
  const { gendersFromUrl, countriesFromUrl } = useSearchParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedGenders, setSelectedGenders] = useState<string[]>(gendersFromUrl);
  const [selectedCountries, setSelectedCountries] = useState<string[]>(countriesFromUrl);
  const [messageText, setMessageText] = useState<string | undefined>();
  const [messageImage, setMessageImage] = useState<string | undefined>();
  const [messageImagePreview, setMessageImagePreview] = useState<string | undefined>();
  const [messageImageFile, setMessageImageFile] = useState<File | undefined>();

  const [buttonTitle, setButtonTitle] = useState(Resources.strings.add);
  const [selectedButtonUrl, setSelectedButtonUrl] = useState('');
  const [messageComponents, setMessageComponents] = useState(defaultComponentsOrder);
  const [messageComponentEnabled, setMessageComponentEnabled] = useState(defaultMessageComponentsState);

  const [pushTitle, setPushTitle] = useState<string | undefined>();
  const [pushSubtitle, setPushSubtitle] = useState<string | undefined>();
  const [pushImage, setPushImage] = useState<string | undefined>();
  const { me } = useAuth();
  const operatorId = me?.id;
  const isButtonDisabled =
    isEmpty(selectedGenders) ||
    isEmpty(selectedCountries) ||
    ((!messageComponentEnabled[MessageComponents.Text] || !messageText) &&
      (!messageComponentEnabled[MessageComponents.Photo] || !messageImageFile) &&
      (!messageComponentEnabled[MessageComponents.Button] || !selectedButtonUrl || !buttonTitle)) ||
    !operatorId;

  const resetState = useCallback(() => {
    setMessageText(undefined);
    setMessageImage(undefined);
    setMessageImageFile(undefined);
    setMessageImagePreview(undefined);
    setButtonTitle(Resources.strings.add);
    setSelectedButtonUrl('');
  }, []);

  const sendMessage = useCallback(
    (userId: string, messageBody: MessageBody) => {
      flureChatRequest
        .sendMessageToSelectedAuditory(userId, messageBody)
        .then(() => {
          enqueueSnackbar(Resources.strings.flureChat.messageSent, { variant: 'success' });
          resetState();
        })
        .catch(() => {
          enqueueSnackbar(Resources.strings.flureChat.sendMessageError, { variant: 'error' });
        });
    },
    [enqueueSnackbar, resetState],
  );

  const onSendPress = useCallback(() => {
    if (!operatorId) {
      return;
    }

    const messageBody: MessageBody = {
      text: messageComponentEnabled[MessageComponents.Text] ? messageText || '' : '',
      reference: '',
      auditory: {
        countries: selectedCountries,
        genderIdentities: selectedGenders,
      },
      meta: {
        componentsOrder: messageComponents,
      },
    };

    if (messageComponentEnabled[MessageComponents.Button] && buttonTitle && selectedButtonUrl) {
      messageBody.meta.buttonTitle = buttonTitle;
      messageBody.meta.buttonUrl = selectedButtonUrl;
    }

    if (messageComponentEnabled[MessageComponents.Photo] && messageImageFile) {
      const formData = new FormData();
      formData.append(messageImageFile.name, messageImageFile, messageImageFile.name);

      flureChatRequest.uploadPhotoToChat(operatorId, formData).then((response) => {
        const basename = (response.data as { basename: string })?.basename || (response.data as string[])?.[0];

        if (basename) {
          const imageUri = flureChatRequest.getPhotoUri(operatorId, basename);
          messageBody.reference = imageUri;
          sendMessage(operatorId, messageBody);
        } else if (messageText) {
          sendMessage(operatorId, messageBody);
        } else {
          enqueueSnackbar(Resources.strings.flureChat.sendMessageError, { variant: 'error' });
        }
      });
    } else {
      sendMessage(operatorId, messageBody);
    }
  }, [
    operatorId,
    messageComponentEnabled,
    messageText,
    selectedCountries,
    selectedGenders,
    messageComponents,
    buttonTitle,
    selectedButtonUrl,
    messageImageFile,
    sendMessage,
    enqueueSnackbar,
  ]);

  const goToUrlWithParams = useCallback(
    (pathname: string) => {
      const params = {
        selectedGenders,
        selectedCountries,
      };

      history.replace({
        pathname,
        search: `${qs.stringify(params)}`,
      });
    },
    [history, selectedCountries, selectedGenders],
  );

  const onGoBack = useCallback(() => {
    goToUrlWithParams('/flure-chat');
  }, [goToUrlWithParams]);

  useEffect(() => {
    goToUrlWithParams('/flure-chat/new');
  }, [selectedGenders, selectedCountries, history, goToUrlWithParams]);

  return (
    <Layout containerSize="xl">
      <div className={classes.container}>
        <PageHeader title={Resources.strings.flureChat.newMessage} onGoBack={onGoBack} />

        <div className={classes.audienceContainer}>
          <TitleText
            className={classes.subtitle}
            text={Resources.strings.flureChat.audience}
            size={TitleTextSize.Medium}
          />
          <AudienceSelector
            className={classes.checkBoxesContainer}
            genderOptions={genderOptions}
            countriesOptions={countriesOptions}
            selectedGenders={selectedGenders}
            setSelectedGenders={setSelectedGenders}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            disabled={false}
          />
        </div>

        <MessageWithPreview
          messageText={messageText}
          setMessageText={setMessageText}
          messageImage={messageImage}
          setMessageImage={setMessageImage}
          setMessageImageFile={setMessageImageFile}
          messageImagePreview={messageImagePreview}
          setMessageImagePreview={setMessageImagePreview}
          buttonTitle={buttonTitle}
          setButtonTitle={setButtonTitle}
          selectedButtonUrl={selectedButtonUrl}
          setSelectedButtonUrl={setSelectedButtonUrl}
          messageComponents={messageComponents}
          setMessageComponents={setMessageComponents}
          messageComponentEnabled={messageComponentEnabled}
          setMessageComponentEnabled={setMessageComponentEnabled}
        />

        <PushWithPreview
          pushTitle={pushTitle}
          setPushTitle={setPushTitle}
          pushSubtitle={pushSubtitle}
          setPushSubtitle={setPushSubtitle}
          pushImage={pushImage}
          setPushImage={setPushImage}
          couldCreate={false}
        />

        <ActionButton
          className={classes.button}
          type={ActionButtonType.Default}
          title={Resources.strings.flureChat.saveAndSend}
          onClick={onSendPress}
          disabled={isButtonDisabled}
        />
      </div>
    </Layout>
  );
};
