import { useEffect, useMemo, useCallback, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { ModerationContent, ModerationContentCountResponse } from 'src/network/moderation/types';
import { moderationRequest, userRequest, verificationRequest } from 'src/network';
import { File, FilesData, Media, MediaTag, MediaType } from 'src/types/user';
import { UserForModeration, UserForModerationStatus } from 'src/types/moderation';
import {
  PhotoTransformation,
  transformations,
  useGetMediaSourceAuthed,
} from 'src/components/Moderation/utils/image-source';
import { useAuth } from 'src/services/auth';
import {
  Logger,
  LoggerServices,
  LoggerMessages,
  MaxMediaLoadDurationBeforeLog,
} from 'src/infrastructure/loggers/datadog';
import { Http } from 'src/network/http';
import { Product } from 'src/types/product';
import { sessionDataStorage } from 'src/utils/session-storage';
import { getUserAge } from 'src/utils/transform';
import { ModerationStatus } from './status';

const moderatedUsersCountKey = 'moderated-users-count';

export const useModerationContent = () => {
  const { me } = useAuth();
  const operatorId = useMemo(() => me?.id, [me]);
  const [contentState, fetchContent] = useAsyncFn(
    async () => {
      try {
        const result = operatorId ? await moderationRequest.getModerationContent(operatorId) : null;
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  return { contentState, fetchContent };
};

export const useModerationContentCount = (useService = false, shouldUpdate = true) => {
  const [contentCountState, fetchContentCount] = useAsyncFn(
    async () => {
      try {
        const result = await moderationRequest.getModerationContentCount();
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  const serviceCountData = useModerationContentCountService(useService && shouldUpdate);

  const fakedFetch = useCallback(() => Promise.resolve(), []);

  if (useService) {
    return {
      contentCountState: serviceCountData.contentCountState,
      fetchContentCount: fakedFetch,
    };
  }

  return {
    contentCountState,
    fetchContentCount,
  };
};

export const useModerationContentCountService = (shouldUpdate = true) => {
  const [contentCountState, setContentCount] = useState<{
    value?: ModerationContentCountResponse;
    loading: boolean;
  }>({ loading: true });

  useEffect(() => {
    let unsub: () => void;

    if (shouldUpdate) {
      const update = (countState: ModerationContentCountResponse) => {
        setContentCount({
          value: countState,
          loading: false,
        });
      };
      unsub = ModerationStatus.shared().subscribe(update);
    }

    return () => {
      unsub?.();
    };
  }, [shouldUpdate]);

  return { contentCountState };
};

export const logVerificationImageLoadingDuration = ({
  src,
  startTime,
  endTime,
  operatorId,
  product,
}: {
  src: string;
  startTime: number;
  endTime: number;
  operatorId: string;
  product: string;
}) => {
  const duration = endTime - startTime;

  if (duration > MaxMediaLoadDurationBeforeLog) {
    Logger.log({
      service: LoggerServices.Moderation,
      message: LoggerMessages.VerificationImageLoadDuration,
      product,
      payload: {
        operatorId,
        duration,
        uri: src,
      },
    });
  }
};

type Data<Realm> = Realm extends Product.Lovinga
  ? {
      mentalLevel: any;
      thumbnailMedia: Media;
      mainMediaType: MediaType;
      mainMediaSource: string;
      newMediaBaseName?: string;
    }
  : {
      thumbnailMedia: File | undefined;
      mainMediaType: string;
      mainMediaSource: string;
      photoData: FilesData;
      newMediaBaseName?: string;
    };

class BuildDataForModerationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'BuildDataForModerationError';
  }
}

export const useUserForModeration = () => {
  const { me } = useAuth();
  const getMediaSource = useGetMediaSourceAuthed();
  const operatorId = useMemo(() => me?.id || '', [me]);
  const product = useMemo(() => me?.realm || '', [me]);

  const buildDataForModeration = useCallback(
    async (userId: string, media: Media[], baseName?: string): Promise<[Data<Product>, File[]]> => {
      try {
        if (me?.realm === Product.Once) {
          const { data } = await userRequest.getPhotoTags(userId);
          const thumbnailMedia = data.files.find((el) => el.tags.includes(MediaTag.Thumbnail));

          const mainMediaType = thumbnailMedia?.mediatype.startsWith('video') ? MediaType.Video : MediaType.Photo;
          const mainMediaSource = thumbnailMedia
            ? getMediaSource(userId, thumbnailMedia.basename, thumbnailMedia.mediatype)
            : '';
          const photoData = data;

          const dataForModeration = {
            ...(thumbnailMedia && {
              thumbnailMedia: {
                ...thumbnailMedia,
                baseName: thumbnailMedia.basename,
                mediaType: thumbnailMedia.mediatype,
              },
            }),
            mainMediaType,
            mainMediaSource,
            photoData,
            newMediaBaseName: baseName,
          } as Data<Product.Once>;

          if (thumbnailMedia?.basename && baseName?.startsWith(thumbnailMedia?.basename)) {
            (dataForModeration as Data<Product.Once>).newMediaBaseName = undefined;
          }

          return [dataForModeration, data?.files || []];
        }

        if (me?.realm === Product.Magnet) {
          const { data } = await userRequest.getPhotoTags(userId);
          const files = data?.files || [];
          const { basename, mediatype } = files.find((file) => file.tags.includes(MediaTag.Hidden)) || {};
          const mainMediaSource =
            basename && mediatype && !baseName ? getMediaSource(userId, basename, mediatype, true) : '';

          return [
            {
              mainMediaType: MediaType.Photo,
              mainMediaSource,
              photoData: data,
              newMediaBaseName: baseName,
            } as Data<Product.Magnet>,
            data?.files || [],
          ];
        }

        const mentalLevel = await userRequest.getUserMentalLevel(userId);
        const thumbnailMedia = media?.find((item) => item.tags?.includes(MediaTag.Thumbnail)) || media?.[0];
        const mainMediaType = thumbnailMedia?.mediaType.startsWith('video') ? MediaType.Video : MediaType.Photo;
        const mainMediaSource = thumbnailMedia
          ? getMediaSource(userId, thumbnailMedia.baseName, thumbnailMedia.mediaType)
          : '';

        return [
          {
            mentalLevel,
            thumbnailMedia,
            mainMediaType,
            mainMediaSource,
            newMediaBaseName: baseName,
          } as Data<Product.Lovinga>,
          [],
        ];
      } catch (error) {
        throw new BuildDataForModerationError('Failed to build moderation data');
      }
    },
    [getMediaSource, me?.realm],
  );

  const [userForModerationState, fetchUserForModeration] = useAsyncFn(
    async (isUndoAction = false, realm?: Product) => {
      try {
        const request = isUndoAction ? moderationRequest.undoUserModeration : moderationRequest.getModerationContent;
        const moderationContent = operatorId ? await request(operatorId) : null;

        if (moderationContent) {
          const { content } = moderationContent;
          const { id: contentId, userId, baseName, mediaType, userName, userAbout, userOccupation } =
            content || ({} as ModerationContent);

          if (userId) {
            try {
              const user = await userRequest.getById(userId, true);
              const { name, about, questions, occupation, media, gender, birthday } = user;

              const [dataForModeration, files] = await buildDataForModeration(userId, media, baseName);

              const isPrivate =
                product === Product.Magnet ||
                files.find((el) => baseName?.includes(el.basename))?.tags?.includes(MediaTag.Hidden);
              const newMediaSource =
                baseName && mediaType ? getMediaSource(userId, baseName, mediaType, isPrivate) : '';
              const newMediaType = mediaType?.startsWith('video') ? MediaType.Video : MediaType.Photo;

              return {
                userId,
                contentId,
                newUserName: userName,
                newUserAbout: userAbout,
                newUserOccupation: userOccupation,
                newMediaSource,
                newMediaType,
                name,
                about,
                questions,
                occupation,
                gender,
                userMedia: media,
                status: UserForModerationStatus.Ok,
                age: getUserAge(birthday),
                ...dataForModeration,
              } as UserForModeration;
            } catch (error: any) {
              if (error instanceof BuildDataForModerationError) {
                Logger.log({
                  service: LoggerServices.Moderation,
                  message: error.message,
                  product: realm || '',
                });
                return undefined;
              }

              Logger.log({
                service: LoggerServices.Moderation,
                message: LoggerMessages.GetUserInfoError,
                product: realm || '',
                payload: {
                  userId,
                  operatorId,
                  moderatedUsersCount: Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)),
                  error: error?.response,
                },
              });

              return null;
            }
          } else {
            Logger.log({
              service: LoggerServices.Moderation,
              message: LoggerMessages.UserForModerationStateIsNull,
              product: realm || '',
              payload: {
                message: LoggerMessages.UserForModerationStateIsNull,
                reason: 'No userId',
                userId,
                operatorId,
                moderatedUsersCount: Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)),
              },
            });

            return null;
          }
        } else {
          Logger.log({
            service: LoggerServices.Moderation,
            message: LoggerMessages.UserForModerationStateIsNull,
            product: realm || '',
            payload: {
              message: LoggerMessages.UserForModerationStateIsNull,
              reason: 'No moderation content',
              operatorId,
              moderatedUsersCount: Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)),
            },
          });

          return null;
        }
      } catch (error: any) {
        if (error?.response?.status !== 404) {
          Logger.log({
            service: LoggerServices.Moderation,
            message: LoggerMessages.GetModerationContentError,
            product: realm || '',
            payload: {
              operatorId,
              moderatedUsersCount: Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)),
              isUndoAction,
              error: error?.response,
            },
          });
        }

        if (error?.response?.status === 404) {
          return {
            status: UserForModerationStatus.NoUndoUsers,
          } as UserForModeration;
        }

        return null;
      }
    },
    [],
    { loading: true },
  );

  const fetchVerificationPhoto = useCallback(
    async (userId: string, size?: string, transformation?: PhotoTransformation) => {
      let verificationPhoto;

      try {
        const startTime = Date.now();

        verificationPhoto = await verificationRequest.getVerificationPhoto(
          userId,
          size,
          transformation ? transformations[transformation] : '',
        );

        const endTime = Date.now();
        logVerificationImageLoadingDuration({
          src: `${Http.shared().apiHost}/users/${userId}/verification/photo`,
          startTime,
          endTime,
          operatorId,
          product,
        });
      } catch {
        verificationPhoto = null;
      }

      return verificationPhoto;
    },
    [operatorId, product],
  );

  const fetchVerificationPhotoTag = useCallback(async (userId: string) => {
    let response;

    try {
      response = await verificationRequest.getVerificationPhotoTag(userId);
    } catch {
      response = null;
    }

    return response;
  }, []);

  return { userForModerationState, fetchUserForModeration, fetchVerificationPhoto, fetchVerificationPhotoTag };
};

export const useUserModerationHistory = () => {
  const [moderationHistoryState, fetchModerationHistory] = useAsyncFn(
    async (userId: string, query?: string) => {
      try {
        const result = await moderationRequest.getUserModerationHistory(userId, query);
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return { moderationHistoryState, fetchModerationHistory };
};

export const useUserMediaModerationHistory = () => {
  const [mediaModerationHistoryState, fetchMediaModerationHistory] = useAsyncFn(
    async (userId: string) => {
      try {
        const result = await moderationRequest.getMediaModerationHistory(userId);
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return { mediaModerationHistoryState, fetchMediaModerationHistory };
};
