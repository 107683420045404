import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  image: {
    height: '484px',
    width: '300px',
    objectFit: 'contain',
  },

  topLabelText: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: '#000000',
    marginBottom: 6,
  },


  fullSizeImage: {
    maxWidth: '80vw',
    maxHeight: '70vh',
    width: 'auto',
    height: 'auto',
  },

});
