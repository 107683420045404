import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalContent: {
    backgroundColor: '#F2DAF5',
    display: 'flex',
    alignSelf: 'center',
    justifySelf: 'center',
    flexDirection: 'column',
    padding: '40px 52px',
    borderRadius: '30px',
    position: 'relative',
  },

  closeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',

    position: 'absolute',
    top: '20px',
    right: '20px',

    width: '24px',
    height: '24px',
  },

  closeIcon: {
    width: '24px',
    height: '24px',
    objectFit: 'contain',
  },

  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
  },

  image: {
    width: '129px',
    height: '173px',
    objectFit: 'contain',
  },

  title: {
    fontSize: '26px !important',
    lineHeight: '28px !important',
    textAlign: 'center',
    marginBottom: '24px',
  },

  buttonsContainer: {
    display: 'flex',
    gap: '16px',
  },
});
