import React, { memo, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { Resources } from 'src/resources';
import { VerificationPhoto } from 'src/components/Moderation/views/verification-photo';

import { useStyles } from './styles';

type Props = {
  userId: string;
};

const VerificationMediaItemView = (props: Props) => {
  const { userId } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box key="verification" className={classes.verificationPhotoContainer}>
        <VerificationPhoto
          userId={userId}
          checkIfVerified={false}
          imageContainerStyle={classes.verificationImageContainer}
          imageStyle={clsx(classes.verificationImage, !isLoaded && classes.hidden)}
          onLoadEnd={() => setIsLoaded(true)}
        />
        {!isLoaded && (
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <span className={classes.mediaName}>{Resources.strings.moderation.media.verificationShort}</span>
    </div>
  );
};

export const VerificationMediaItem = memo(VerificationMediaItemView);
