import { makeStyles } from '@material-ui/core';
import {
  RAW_PHOTO_HEIGHT,
  RAW_PHOTO_WIDTH,
} from 'src/components/Moderation/screens/AdminUserModeration/raw/components/media/raw-photo/styles';

export const useStyles = makeStyles({
  mock: {
    width: RAW_PHOTO_WIDTH,
    height: RAW_PHOTO_HEIGHT,
  },
  image: {
    width: RAW_PHOTO_WIDTH,
    height: RAW_PHOTO_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: 15,
  },

  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  loader: {
    width: 48,
    height: 48,
    position: 'absolute',
    border: '5px solid #FFF',
    borderBottomColor: '#EF7BF8',
    borderRadius: '50%',
    display: 'inline-block',
    boxSizing: 'border-box',
    animation: '$rotation  1s linear infinite',
  },
});
