import serverConfig from '../../server/config/config.json';

export type ServerConfig = typeof serverConfig;

export const getServerConfig = async () => {
  const response = await fetch(`/config/config.json`);
  const data: ServerConfig = await response.json();

  return data;
};
