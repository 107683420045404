import { Http } from 'src/network/http';
import { UserRoles } from 'src/types/user/roles';
import { adapter } from './adapter';

type IdentityDto = {
  id: string;
  email: string;
  roles: UserRoles[];
};

const login = ({ email, password }: { email: string; password: string }) => {
  const config = {
    auth: {
      username: email,
      password,
    },
  };

  return Http.shared().instance.get<IdentityDto>('/identity', config).then(adapter.login);
};

const register = ({ email, password }: { email: string; password: string }) => {
  const config = {
    email,
    password,
  };

  return Http.shared().instance.put<IdentityDto>('/identity', config);
};

export const authRequest = {
  login,
  register,
};
