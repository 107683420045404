import React from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { PrevUserId } from 'src/components/common/flure';

import { useStyles } from './styles';

type Props = {
  prevUserId: string | null | undefined;
  approveAction: () => void;
  scamAction: () => void;
};

export const ActionButtons = (props: Props) => {
  const classes = useStyles();
  const { approveAction, scamAction, prevUserId } = props;

  return (
    <Container className={classes.container} maxWidth="lg">
      <div className={classes.buttonsContainer}>
        {prevUserId ? <PrevUserId userId={prevUserId} /> : <div />}
        <div className={classes.rightButtonsContainer}>
          <Button onClick={scamAction} className={clsx(classes.btn, classes.btnDecline)} variant="contained">
            <b>Scam</b>&nbsp;(Esc)
          </Button>
          <Button onClick={approveAction} className={clsx(classes.btn, classes.btnApprove)} variant="contained">
            <b>Ok</b>&nbsp;(Space)
          </Button>
        </div>
      </div>
    </Container>
  );
};
