import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { allCountries, allGenders } from '../constants';

export type SearchParams = {
  selectedGenders?: string[];
  selectedCountries?: string[];
};

export const useSearchParams = () => {
  const { search = '' } = useLocation();
  const {
    selectedGenders: gendersFromUrl = allGenders,
    selectedCountries: countriesFromUrl = allCountries,
  }: SearchParams = qs.parse(search.slice(1));

  return {
    gendersFromUrl,
    countriesFromUrl,
  };
};
