import React from 'react';
import { Gender } from 'src/types/user';

import { formatGenderIdentityGenderAndAge } from 'src/utils/format-gender-and-age';
import { GenderIdentityPreferences } from 'src/types/user/preferences';
import { formatCountryAndCity } from 'src/utils/format-country-and-city';

import { useStyles } from './styles';

type Props = {
  age: number | '';
  gender: Gender;
  genderIdentity: GenderIdentityPreferences;
  country?: string;
  city?: string;
};

export const UserInfo: React.FC<Props> = ({ age, genderIdentity, gender, country, city }) => {
  const classes = useStyles();

  return (
    <div className={classes.infoContainer}>
      <span className={classes.userInfoText}>
        {formatGenderIdentityGenderAndAge(genderIdentity, gender, age, true)}
      </span>
      <span className={classes.userInfoText}>{formatCountryAndCity(country, city)}</span>
    </div>
  );
};
