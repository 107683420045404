import { useMemo } from 'react';
import { ColDef } from '@material-ui/data-grid';

import { useAuth } from 'src/services/auth';

import { getTableColumnsByRealm } from './columns-config';
import './styles.css';

export const useTableColumns = (): ColDef[] => {
  const { me } = useAuth();

  const columns = useMemo(() => {
    return getTableColumnsByRealm({
      realm: me?.realm,
    });
  }, [me]);

  return columns;
};
