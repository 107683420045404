import React, { useCallback, useRef, useMemo, ImgHTMLAttributes, useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import {
  Logger,
  LoggerServices,
  LoggerMessages,
  MaxMediaLoadDurationBeforeLog,
} from 'src/infrastructure/loggers/datadog';
import { useAuth } from 'src/services/auth';
import { moderationRequest } from 'src/network';
import { noop } from 'src/utils/functions';
import { Product } from 'src/types/product';

import { useStyles } from './styles';

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  basename?: string;
  topLabel?: string;
  topLabelColor?: string;
  isFullSize?: boolean;
  imageStyle?: string;
  imageContainerStyle?: string;
  detectCelebrity?: boolean;
  onLoadEnd?: () => void;
};

export const ImageWithLogger: React.FC<Props> = React.memo((props) => {
  const { src, alt, basename, className, topLabel, topLabelColor = '#ED654C', isFullSize, imageStyle, imageContainerStyle, detectCelebrity = true, onLoadEnd: onLoadEndFromProps } = props;
  const classes = useStyles({ topLabelColor });
  const startTime = useRef(Date.now());
  const { me } = useAuth();
  const operatorId = useMemo(() => me?.id || '', [me]);
  const product = useMemo(() => me?.realm || '', [me]);
  const [celebrityName, setCelebrityName] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoadEnd = useCallback(() => {
    const endTime = Date.now();
    const duration = endTime - startTime.current;
    setIsLoaded(true);
    
    if (onLoadEndFromProps) {
      onLoadEndFromProps();
    }

    if (duration > MaxMediaLoadDurationBeforeLog) {
      Logger.log({
        service: LoggerServices.Moderation,
        message: LoggerMessages.ImageLoadDuration,
        product,
        payload: {
          operatorId,
          duration,
          uri: src,
        },
      });
    }
  }, [onLoadEndFromProps, operatorId, product, src]);

  useEffect(() => {
    if (detectCelebrity && basename) {
      moderationRequest
        .detectCelebrityOnPhoto(basename.slice(0, 16))
        .then((data) => {
          if (data?.name) {
            setCelebrityName(data.name);
          }
        })
        .catch(noop);
    }
  }, [basename, detectCelebrity]);

  return (
    <Box className={clsx(className, classes.imageContainer)}>
      <div className={clsx(classes.imageWithLabelContainer, imageContainerStyle)}>
        <img
          src={src}
          alt={alt}
          onLoad={onLoadEnd}
          className={isFullSize ? classes.fullSizeImage : clsx(classes.image, imageStyle)}
        />
        {isLoaded && celebrityName && (
          <Box className={classes.celebrityContainer}>
            <Typography className={classes.celebrityName}>{celebrityName}</Typography>
          </Box>
        )}
        {product !== Product.Magnet && isLoaded && topLabel && (
          <Box className={classes.topLabelContainer}>
            <Typography className={classes.topLabelText}>{topLabel}</Typography>
          </Box>
        )}
      </div>
    </Box>
  );
});
