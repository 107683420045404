import { makeStyles } from '@material-ui/core';

import { RAW_PHOTO_HEIGHT, RAW_PHOTO_WIDTH } from './raw-photo/styles';
import { PROFILE_PHOTO_SIZE } from './photo-wrapper/styles';

export const useStyles = makeStyles({
  title: { fontSize: 18, fontWeight: 700, marginBottom: 16 },

  profilePhotos: {
    width: '100%',
    display: 'grid',
    gap: '16px 16px',
    gridTemplateColumns: `repeat(auto-fill, ${PROFILE_PHOTO_SIZE}px)`,
    gridAutoRows: PROFILE_PHOTO_SIZE,
  },

  rawPhotos: {
    width: '100%',
    display: 'grid',
    gap: '16px 16px',
    position: 'relative',
    gridTemplateColumns: `repeat(auto-fill, ${RAW_PHOTO_WIDTH}px)`,
    gridAutoRows: RAW_PHOTO_HEIGHT,
  },

  separator: {
    width: '100%',
    height: 1,
    backgroundColor: '#EF7BF8',
    marginTop: 24,
    marginBottom: 24,
  },
});
