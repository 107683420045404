import { Product } from '../types/product';

export type BackendAlias = 'locals' | 'magnet' | 'flure';

export function getRealm(realm?: string): BackendAlias {
  switch (realm) {
    case Product.Magnet:
      return 'magnet';
    case Product.Flure:
      return 'flure';
    default:
      return 'locals';
  }
}
