import { RAW } from 'src/types/raw';

import {
  CHANGE_CONTENT_TYPE,
  LOAD_QUESTION_CONFIG_FULFILLED,
  LOAD_QUESTION_CONFIG_PENDING,
  LOAD_QUESTION_CONFIG_REJECTED,
  RESET_MODERATION_ACTIONS,
  SET_IS_SENDING,
  SET_MODERATED_COUNT,
  SET_MODERATION_ACTION,
  SET_MODERATION_ACTIONS,
  TOGGLE_BLOCK_MODAL_STATE,
  SET_QUEUE_BLOCK,
} from './actions';

function changeContentType(contentType: RAW.ContentType) {
  return { type: CHANGE_CONTENT_TYPE, contentType };
}

function setModerationAction(moderatedAction: RAW.ModerationAction) {
  return { type: SET_MODERATION_ACTION, moderatedAction };
}

function setModerationActions(moderatedActions: RAW.ModerationAction[]) {
  return { type: SET_MODERATION_ACTIONS, moderatedActions };
}

function resetModerationActions() {
  return { type: RESET_MODERATION_ACTIONS };
}

function setIsSending(payload: boolean) {
  return { type: SET_IS_SENDING, payload };
}

function setModeratedUsersCount(payload: number) {
  return { type: SET_MODERATED_COUNT, payload };
}

function loadQuestionConfigPending() {
  return { type: LOAD_QUESTION_CONFIG_PENDING };
}

function loadQuestionConfigFulFilled(payload: RAW.QuestionConfig) {
  return { type: LOAD_QUESTION_CONFIG_FULFILLED, payload };
}

function loadQuestionConfigRejected() {
  return { type: LOAD_QUESTION_CONFIG_REJECTED };
}

function toggleBlockModalState(payload: boolean) {
  return { type: TOGGLE_BLOCK_MODAL_STATE, payload };
}

function setQueueBlock(payload: boolean) {
  return { type: SET_QUEUE_BLOCK, payload };
}

export type Actions =
  | ReturnType<typeof changeContentType>
  | ReturnType<typeof setModerationAction>
  | ReturnType<typeof resetModerationActions>
  | ReturnType<typeof setIsSending>
  | ReturnType<typeof setModeratedUsersCount>
  | ReturnType<typeof setModerationActions>
  | ReturnType<typeof loadQuestionConfigPending>
  | ReturnType<typeof loadQuestionConfigFulFilled>
  | ReturnType<typeof loadQuestionConfigRejected>
  | ReturnType<typeof toggleBlockModalState>
  | ReturnType<typeof setQueueBlock>;

export const actions = {
  changeContentType,
  setModerationAction,
  setIsSending,
  setModeratedUsersCount,
  resetModerationActions,
  setModerationActions,
  loadQuestionConfigPending,
  loadQuestionConfigFulFilled,
  loadQuestionConfigRejected,
  toggleBlockModalState,
  setQueueBlock,
};
