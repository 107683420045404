import { ReportLanguage } from 'src/types/report/common';
import { MessageStatus } from 'src/types/report/messages';

import { SelectItem } from './types';

type LanguageItem = SelectItem<ReportLanguage>;

export const languageItems: LanguageItem[] = [
  { title: 'All languages', value: 'all' },
  { title: 'US', value: 'en' },
  { title: 'FR', value: 'fr' },
  { title: 'ES', value: 'es' },
];

export const languageDefaultValue = languageItems[0].value;

export const statusItems: MessageStatus[] = ['all', 'delivered', 'blocked'];

export const statusAllItem = statusItems[0];
