import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '15px',
  },
  primaryButton: {
    backgroundColor: '#883FFF',
    '&:hover': {
      backgroundColor: '#883FFF',
      opacity: 0.8,
    },
  },
  warningButton: {
    backgroundColor: '#f57c00',
    '&:hover': {
      backgroundColor: '#ff9800',
      opacity: 0.8,
    },
  },
});
