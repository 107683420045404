import React from 'react';

import { useStyles } from './styles';

type Props = {
  kind?: 'warning' | 'error';
};

export function StatusIcon({ kind }: Props) {
  const classes = useStyles();

  switch (kind) {
    case 'warning':
      return <span className={classes.icon}>⚠️</span>;
    case 'error':
      return <span className={classes.icon}>🗑️</span>;
    default:
      return null;
  }
}
