import React, { useCallback, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { usePrevious } from 'react-use';

import { useStyles, withTextFieldStyles } from './styles';
import { Option } from '../bubble-checkboxes-list';

type Props = {
  options: Option[];
  defaultValue?: Option;
  value?: Option;
  disableClearable?: boolean;
  onChange?: (value: Option | null) => void;
};

const getOptionLabel = (option: Option) => option.text;

const CustomTextField = withTextFieldStyles(TextField);

const AutocompleteInputView = (props: Props) => {
  const { defaultValue, options, disableClearable = true, value: valueFromProps, onChange } = props;
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);
  const previousValueFromProps = usePrevious(valueFromProps);

  const handleChange = useCallback(
    (_: React.ChangeEvent<{}>, selectedOption: Option | null) => {
      if (onChange) {
        onChange(selectedOption);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (previousValueFromProps !== valueFromProps && value !== valueFromProps) {
      setValue(valueFromProps);
    }
  }, [previousValueFromProps, value, valueFromProps]);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      className={classes.fieldStyle}
      renderInput={(params) => <CustomTextField {...params} variant="outlined" />}
      onChange={handleChange}
      value={value}
      defaultValue={defaultValue}
      disableClearable={disableClearable}
    />
  );
};

export const AutocompleteInput = React.memo(AutocompleteInputView);
