import { AxiosResponse } from 'axios';
import { Question, QuestionCategoriesDto, QuestionsDto } from './types';

export const questionAdapter = (response: AxiosResponse<QuestionsDto>) => {
  return response.data.questions.map(
    (question) =>
      ({
        id: question.id,
        question: question.question,
        categoryId: question.categoryId,
        date: question.date,
      } as Question),
  );
};

export const categoriesAdapter = (response: AxiosResponse<QuestionCategoriesDto>) => {
  return response.data.categories.reduce((acc, category) => {
    if (category.category) {
      acc[category.id] = category.category;
    }
    return acc;
  }, {} as Record<number, string>);
};
