import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  textContentContainer: {
    display: 'flex',
    alignItems: 'baseline',
    columnGap: 20,
  },
  actionBtn: {
    color: '#EF7BF8',
    fontFamily: 'Poppins',
    fontSize: 16,
    margin: 0,
    fontWeight: 600,
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
  },
  title: {
    color: '#000000',
    fontFamily: 'Podkova',
    fontSize: 24,
    opacity: 0.5,
    margin: 0,
    fontWeight: 600,
  },
  name: {
    fontFamily: 'Podkova',
    fontSize: 40,
    color: '#000000',
    fontWeight: 800,
    margin: 0,
  },
  work: {
    fontSize: 24,
  },
  value: {
    marginTop: 12,
  },

  rightLabel: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#000000',
    opacity: 0.5,
    margin: 0,
    marginLeft: 'auto',
  },
});
