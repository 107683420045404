import React from 'react';
import { CircularProgress } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from 'clsx';

import iphoneStatusBar from 'src/components/FlureFeed/assets/iphone-status-bar.png';
import filterIcon from 'src/components/FlureFeed/assets/filter-icon.png';
import invitesIcon from 'src/components/FlureFeed/assets/invites-icon.png';
import { ApiFeedUser, FilterLocation } from 'src/network/flure-feed/types';
import { DEFAULT_LOCATION_NAMES } from 'src/components/FlureFeed/constants';

import { FeedCard } from './components';
import { useStyles } from './styles';

type Props = {
  users: ApiFeedUser[];
  loadMore: () => void;
  loading: boolean;
  showInvites: boolean;
  locationFilter: FilterLocation | string;
};

const PhonePreviewView = (props: Props) => {
  const { users, loading = false, loadMore, locationFilter, showInvites } = props;
  const classes = useStyles();

  const isVirtualOrDefaultLocation =
    locationFilter === FilterLocation.Virtual || DEFAULT_LOCATION_NAMES.includes(locationFilter);
  const title = locationFilter === FilterLocation.Virtual ? 'Virtual Realm' : locationFilter;
  const backgroundClassName = isVirtualOrDefaultLocation ? classes.perfumeBG : classes.whiteBG;

  return (
    <div className={clsx(classes.container, backgroundClassName)}>
      <div className={classes.topContainer}>
        <img className={classes.statusBar} src={iphoneStatusBar} alt="iphoneStatusBar" />
        <div className={classes.screenHeader}>
          {showInvites ? (
            <img className={classes.invitesIcon} src={invitesIcon} alt="invitesIcon" />
          ) : (
            <div className={classes.invitesIcon} />
          )}
          <span className={classes.screenTitle}>{title}</span>
          <img className={classes.filterIcon} src={filterIcon} alt="filterIcon" />
        </div>
      </div>
      <div id="scrollableDiv" className={classes.contentContainer}>
        <InfiniteScroll
          dataLength={users.length}
          next={loadMore}
          className={classes.scrollContent}
          hasMore
          loader={null}
          scrollableTarget="scrollableDiv"
          hasChildren
        >
          {users.map((user) => (
            <FeedCard user={user} key={user.userId} isVirtualLocation={isVirtualOrDefaultLocation} />
          ))}
        </InfiniteScroll>
      </div>
      {loading && users.length === 0 && (
        <div className={classes.loader}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </div>
  );
};

export const PhonePreview = React.memo(PhonePreviewView);
