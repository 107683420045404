import { AxiosResponse } from 'axios';

import { Logger, LoggerServices } from 'src/infrastructure/loggers/datadog';
import { ModerationAction } from 'src/types/moderation';
import { Product } from 'src/types/product';
import { RAW } from 'src/types/raw';

type Payload = {
  keyCodes: string[];
  actions: (ModerationAction | RAW.BackModerationAction)[];
  operatorId: string;
  product: Product | '';
  response: AxiosResponse<unknown>;
  contentId?: number;
  userId?: string;
};

export class ActionsLogger {
  private static payload: Payload = {} as Payload;

  static addKeyCode = (keyCode: string) => {
    if (ActionsLogger.payload.keyCodes) {
      ActionsLogger.payload.keyCodes.push(keyCode);
    } else {
      ActionsLogger.payload.keyCodes = [keyCode];
    }
  };

  static log = (message: string, payload: Partial<Payload>) => {
    Logger.log({
      service: LoggerServices.ModerationActionsLogger,
      message,
      product: payload.product || '',
      payload: { ...ActionsLogger.payload, ...payload },
    });

    ActionsLogger.payload = {} as Payload;
  };
}
