import { KPIReportRecord } from './types';

/*
 * these magic numbers should be implemented on the backend side of the app
 * but we don't have the resources to do it now
 * */
export const calculateTotalBonus = (item: KPIReportRecord) => {
  const { kpiIndicatorsSummary, agencyCommission } = item;

  if (kpiIndicatorsSummary >= 8) {
    return 0.22 * agencyCommission;
  }
  if (kpiIndicatorsSummary >= 6) {
    return 0.15 * agencyCommission;
  }
  if (kpiIndicatorsSummary >= 4) {
    return 0.08 * agencyCommission;
  }
  if (kpiIndicatorsSummary >= 2) {
    return 0.03 * agencyCommission;
  }
  return 0;
};
