import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Button, Box } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useModerationContentCount } from 'src/services/moderation/raw/hooks';
import { Layout } from 'src/components/App/views/Layout';
import { useAuth } from 'src/services/auth';
import { UserRoles } from 'src/types/user/roles';
import { RAW } from 'src/types/raw';
import { rawModerationRequest } from 'src/network/moderation/raw';
import { useKeyboardEventListener } from 'src/components/Moderation/utils/keyboard';

import { useStyles } from './styles';

export const StartRAWModerationProcessScreen = () => {
  const { me } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  const isAdministrator = (me?.roles || []).includes(UserRoles.Supervisor);
  const isOperator = (me?.roles || []).includes(UserRoles.UsersOperator);
  const shouldLoadModerationCount = isOperator || isAdministrator;
  const { contentCountState, fetchContentCount } = useModerationContentCount();
  const data = contentCountState?.value || ({ profilePhotoCount: 0, rawPhotoCount: 0 } as RAW.ModerationContentCount);

  const handleSearch = useCallback(async () => {
    if (!searchValue) {
      return;
    }
    try {
      const { userId } = await rawModerationRequest.getRAWModerationSearch(searchValue);

      history.push(`/moderation/user/${userId}`);
    } catch (e) {
      if (inputRef.current) {
        inputRef.current.innerText = '';
        setSearchValue('');
        enqueueSnackbar('User not found!', { variant: 'error' });
      }
    }
  }, [searchValue, history, enqueueSnackbar]);

  const keyPressListener = useCallback(
    (event: KeyboardEvent) => {
      if (isSearchEnabled && !event.defaultPrevented && event.key === 'Enter') {
        handleSearch();
      }
    },
    [handleSearch, isSearchEnabled],
  );

  useKeyboardEventListener(keyPressListener);

  useEffect(() => {
    if (shouldLoadModerationCount) {
      fetchContentCount();
    }
  }, [shouldLoadModerationCount, fetchContentCount]);

  useEffect(() => {
    if (isSearchEnabled) {
      inputRef.current?.focus();
    }
  }, [isSearchEnabled]);

  const sum = data.profilePhotoCount + data.rawPhotoCount;

  return (
    <Layout containerSize="lg">
      <Box className={classes.container}>
        {(isOperator || isAdministrator) && (
          <Button
            component={Link}
            to="/moderation/content"
            className={classes.button}
            disabled={contentCountState.loading}
          >
            Start moderation ({sum})
          </Button>
        )}

        {isAdministrator && (
          <>
            or
            {isSearchEnabled ? (
              <Box className={classes.findFieldWrapper}>
                <Box onClick={() => inputRef.current?.focus()} className={classes.findField}>
                  <span
                    ref={inputRef}
                    contentEditable
                    onInput={(e) => setSearchValue((e.target as HTMLSpanElement).textContent)}
                    className={classes.textInput}
                  />
                </Box>

                <Button onClick={handleSearch} className={classes.findButton} disabled={!searchValue?.length}>
                  Find
                </Button>
              </Box>
            ) : (
              <Button
                onClick={() => setIsSearchEnabled(true)}
                className={classes.buttonSearch}
                disabled={contentCountState.loading}
              >
                Search by ID or phone number
              </Button>
            )}
          </>
        )}
      </Box>
    </Layout>
  );
};
