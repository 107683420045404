import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { Media, MediaTag, MediaType } from 'src/types/user';
import { FullSizeMedia } from 'src/components/Moderation/views/full-size-media';

import { useStyles } from './styles';
import { MediaItem, UserInfo, VerificationMediaItem } from './components';
import { FullSizeMediaType } from './types';

type Props = {
  userId: string;
  userMedia: Media[];
  newMediaBaseName?: string;
  isPhotoRejected?: boolean;
  showHiddenMedia?: boolean;
  declinedCount?: number;
  mediaTags: Record<string, MediaTag[]>;
  setMediaTags: React.Dispatch<React.SetStateAction<Record<string, MediaTag[]>>>;
};

const emptyFullSizeMedia = {} as FullSizeMediaType;

const MediaContentView = (props: Props) => {
  const {
    userId,
    userMedia,
    newMediaBaseName,
    isPhotoRejected,
    showHiddenMedia = false,
    declinedCount,
    mediaTags,
    setMediaTags,
  } = props;

  const [isFullSizeOpen, setIsFullSizeOpen] = useState<boolean>(false);
  const [fullSizeMedia, setFullSizeMedia] = useState<FullSizeMediaType>(emptyFullSizeMedia);
  const [showNoThumbnailLabel, setShowNoThumbnailLabel] = useState(false);

  const classes = useStyles();
  const sortedUserMedia = useMemo(() => {
    const mainMediaIndex = userMedia.findIndex((it) => it.tags.includes(MediaTag.Thumbnail));
    const newMediaIndex = userMedia.findIndex((it) => it.baseName === newMediaBaseName);
    const isMainAndNewTheSameMedia = mainMediaIndex !== -1 && newMediaIndex !== -1 && mainMediaIndex === newMediaIndex;

    const sortedMedia = userMedia.filter(
      (it, index) =>
        index !== mainMediaIndex && index !== newMediaIndex && (showHiddenMedia || !it.tags.includes(MediaTag.Hidden)),
    );

    if (isMainAndNewTheSameMedia) {
      sortedMedia.unshift(userMedia[newMediaIndex]);
    } else {
      if (newMediaIndex !== -1) {
        sortedMedia.unshift(userMedia[newMediaIndex]);
      }

      if (mainMediaIndex !== -1) {
        sortedMedia.unshift(userMedia[mainMediaIndex]);
      }
    }

    sortedMedia.unshift((undefined as unknown) as Media);

    return sortedMedia;
  }, [newMediaBaseName, showHiddenMedia, userMedia]);
  

  const openFullSizeMedia = useCallback((media: FullSizeMediaType) => {
    setFullSizeMedia(media);
    setIsFullSizeOpen(true);
  }, []);

  useEffect(() => {
    const mediaWithTags = userMedia.reduce<Record<string, MediaTag[]>>((acc, media) => {
      acc[media.baseName] = media.tags;
  
      return acc;
    }, {});

    const hasThumbnailMedia = userMedia.some(media => media.tags.includes(MediaTag.Thumbnail));

    setMediaTags!(mediaWithTags);
    setShowNoThumbnailLabel(!hasThumbnailMedia);
  }, [setMediaTags, userMedia])

  useEffect(() => {
    const hasThumbnailMedia = Object.values<MediaTag[]>(mediaTags).some(tags => tags.includes(MediaTag.Thumbnail));
    setShowNoThumbnailLabel(!hasThumbnailMedia);
  }, [mediaTags]);

  return (
    <Box className={classes.mediaWithInfoContainer}>
      {isFullSizeOpen && (
        <FullSizeMedia
          isOpen={isFullSizeOpen}
          setIsOpen={setIsFullSizeOpen}
          source={fullSizeMedia.source}
          mediaType={MediaType.Photo}
          basename={fullSizeMedia.baseName}
        />
      )}
      <span className={clsx(classes.warningLabel, showNoThumbnailLabel && classes.warningLabelOpacity)}>No thumbnail selected!</span>
      <Box className={classes.mediaBoard}>
        <div className={classes.mediaListContainer}>
          {sortedUserMedia.map((media) =>
            media ? (
              <MediaItem
                key={media.baseName}
                media={media}
                userId={userId}
                newMediaBaseName={newMediaBaseName}
                isPhotoRejected={isPhotoRejected}
                openFullSizeMedia={openFullSizeMedia}
                mediaTags={mediaTags}
                setMediaTags={setMediaTags}
              />
            ) : (
              <VerificationMediaItem key="verification" userId={userId} />
            ),
          )}
        </div>
      </Box>
      <UserInfo userId={userId} declinedCount={declinedCount} />
    </Box>
  );
};

export const MediaContent = memo(MediaContentView);
