import { AxiosResponse } from 'axios';

import { RAW } from 'src/types/raw';
import { convertUtcDateToLocalDate } from 'src/utils/raw/date-utc-to-local-date';

const getResponseData = <T>(response: AxiosResponse<T>) => {
  return response.data;
};

const getModerationContent = getResponseData;

const getModerationActionsById = (response: AxiosResponse<RAW.ModerationActions>) => {
  const { actions } = getResponseData(response);

  return actions.map((action) => ({
    ...action,
    createdAt: convertUtcDateToLocalDate(action.createdAt),
  }));
};

const getModerationMediaById = (response: AxiosResponse<RAW.ModerationMediaResponse>) => {
  const { declinedProfilePhotos, declinedRawPhotos, ...rest } = getResponseData(response);

  return {
    ...rest,
    declinedPhotos: [...declinedProfilePhotos, ...declinedRawPhotos].sort(
      (a, b) => new Date(a.moderatedAt).getTime() - new Date(b.moderatedAt).getTime(),
    ),
  };
};

const getModerationContentCount = getResponseData;

export const adapter = {
  getResponseData,
  getModerationActionsById,
  getModerationMediaById,
  getModerationContent,
  getModerationContentCount,
};
