import React from 'react';
import Typography from '@material-ui/core/Typography';

import { Layout } from 'src/components/App/views/Layout';

export const HomeScreen = () => {
  return (
    <Layout>
      <Typography component="h1" variant="h5">
        Homepage
      </Typography>
    </Layout>
  );
};
