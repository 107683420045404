import { AxiosResponse } from 'axios';

import { UserForWaitingListResponse, WaitingListCountResponse } from './types';

const getUserForWaitingList = (response: AxiosResponse<UserForWaitingListResponse>) => {
  return response.data.userId;
};

const getWaitingListCount = (response: AxiosResponse<WaitingListCountResponse>) => {
  return response.data;
};

export const adapter = {
  getUserForWaitingList,
  getWaitingListCount,
};
