export const makeStorage = () => {
  const storage = {};

  return {
    load: (key) => storage[key],
    store: (key, value) => {
      storage[key] = value;
    },
  };
};
