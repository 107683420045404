import { sessionDataStorage } from 'src/utils/session-storage';
import { RAW } from 'src/types/raw';

import { Props } from './types';

export class Session {
  private static instance: Session;

  public static shared(): Session {
    if (!Session.instance) {
      Session.instance = new Session();
    }

    return Session.instance;
  }

  getIsQueueBlocked = (operatorId: Props['operatorId']) => {
    return sessionDataStorage.get<boolean>(`${RAW.StorageKey.QueueBlocked}_${operatorId}`);
  };

  setIsQueueBlocked = (operatorId: Props['operatorId'], value: boolean) => {
    return sessionDataStorage.set(`${RAW.StorageKey.QueueBlocked}_${operatorId}`, value);
  };

  getContentType = (operatorId: Props['operatorId']) => {
    return sessionDataStorage.get<RAW.ContentType>(`${RAW.StorageKey.StoredContentType}_${operatorId}`);
  };

  setContentType = (operatorId: Props['operatorId'], value: RAW.ContentType) => {
    return sessionDataStorage.set(`${RAW.StorageKey.StoredContentType}_${operatorId}`, value);
  };
}
