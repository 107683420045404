import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  userInfoContainer: {
    marginTop: '20px',
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    position: 'relative',
  },

  userId: {
    fontSize: '16px',
    color: '#020D3D',
    fontWeight: 400,
    opacity: 0.5,
    textAlign: 'center',
  },

  redText: {
    color: '#F05A2A',
    textAlign: 'center',
    fontSize: '25px',
    lineHeight: '17px',
    fontWeight: 700,
    position: 'absolute',
    left: 0,
    right: 0,
  },
});
