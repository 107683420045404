import { DefaultReportSearchMode, Reports } from 'src/types/report/common';
import { CommissionsReportDataItem, CommissionsReportFormFields } from 'src/types/report/commissions';
import { fillExcelDocument } from 'src/utils/excel';
import { downloadFile } from 'src/utils/files';
import { Product } from 'src/types/product';

import { convertReportCommissions } from './convert-report';

export const downloadCommissionsReportXlsxByRealm = (
  realm: Product | undefined,
  payload: CommissionsReportDataItem[],
  fileName: string,
) => {
  const convertedData = convertReportCommissions(realm, payload);

  const document = fillExcelDocument('Commissions by operators', convertedData);

  downloadFile(document, `${fileName}.xlsx`);
};

export const getFileName = (realm: Product, filter?: CommissionsReportFormFields) => {
  if (!filter) {
    return '';
  }

  const reportName = Reports.commissions;
  const formattedDateRange = filter.rangeDate.replace(':', '__');

  const reportMapping: Record<DefaultReportSearchMode, string> = {
    agency: 'agency',
    all: 'all',
    'all-without-agency': 'without-agency',
    operator: 'operator',
  };
  const operator = filter.operatorId ? `-${filter.operatorId}` : '';
  const agency = filter.agencyTechName ? `-${filter.agencyTechName}` : '';
  const split = filter.groupBy === 'day' ? `-split` : '';

  return `${realm}-${reportName}-${reportMapping[filter.mode]}${agency}${operator}${split}-${formattedDateRange}`;
};
