import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './components';

const render = (Component: React.ComponentType) => {
  ReactDOM.render(
    <React.StrictMode>
      <Component />
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./components', () => {
    const ReloadedApp = require('./components').default;
    render(ReloadedApp);
  });
}
