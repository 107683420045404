import { Http } from 'src/network/http';
import { GetReportByKPIRequestParams, KPIReportDTO } from './types';
import { adapter } from './adapter';

const get = (params: GetReportByKPIRequestParams) => {
  return Http.shared()
    .instance.get<KPIReportDTO>(`/reports/commissions/operators/kpi`, { params })
    .then(adapter.get);
};

export const kpiReportRequest = {
  get,
};
