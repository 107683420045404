import { singleton } from 'src/utils/singleton';
import { ModerationContentCountResponse } from 'src/network/moderation/types';
import { moderationRequest } from 'src/network/moderation';

type CountInfoValue = ModerationContentCountResponse | undefined;
export type GetCountSub = (response: ModerationContentCountResponse) => void;
type Unsubscriber = () => void;

export class ModerationStatus {
  static shared = singleton(() => new ModerationStatus());

  static LIMIT = 60 * 1000;

  private countInfo: CountInfoValue;

  private subscriptions: GetCountSub[] = [];

  private timeId!: ReturnType<typeof setTimeout>;

  private lastRequestTimeMs: number | undefined;

  subscribe = (fn: GetCountSub): Unsubscriber => {
    this.subscriptions.push(fn);
    this.update(fn);

    return () => {
      this.subscriptions = this.subscriptions.filter((subFn) => subFn !== fn);
      this.update();
    };
  };

  private update = (fn?: GetCountSub) => {
    clearTimeout(this.timeId);

    if (!this.subscriptions.length) {
      return;
    }

    this.getCount(fn);
  };

  private getCount(fn?: GetCountSub) {
    const lastRequestDelta = this.lastRequestTimeMs ? Date.now() - this.lastRequestTimeMs : Date.now();
    const delta = ModerationStatus.LIMIT - lastRequestDelta;

    if (fn && this.countInfo) {
      fn(this.countInfo);
    }

    if (delta > 0) {
      this.scheduledLoad(delta);
    } else {
      this.load();
    }
  }

  private scheduledLoad = (delta: number) => {
    this.timeId = setTimeout(() => {
      this.load();
    }, delta);
  };

  private load = async () => {
    let result: ModerationContentCountResponse;
    this.lastRequestTimeMs = Date.now();
    const requestTime = this.lastRequestTimeMs;

    try {
      result = await moderationRequest.getModerationContentCount();
    } catch (error) {
      result = { count: 0 };
    }

    if (requestTime !== this.lastRequestTimeMs) {
      return;
    }

    this.countInfo = result;
    this.subscriptions.forEach((fn) => fn(result));

    this.timeId = setTimeout(() => {
      this.load();
    }, ModerationStatus.LIMIT);
  };
}
