import React, { useCallback, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { useReportByMessages } from 'src/services/report/use-report-by-messages';
import { MessagesReportFormFields, MessagesReportDataItem } from 'src/types/report/messages';
import { DefaultReportSearchMode } from 'src/types/report/common';
import { ExcelSaverButton } from 'src/components/common/ExcelSaverButton';
import { downloadMessagesReportXlsx, getFileName } from 'src/services/report/messages';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';

import { ReportLayout } from '../common/ReportLayout';
import { ReportForm } from '../common/Form';
import { MessagesReportTable } from './Table';

const initialValues = {
  rangeDate: ':',
};

export const ReportByMessagesScreen = () => {
  const { search } = useLocation();
  const { reportByMessagesState, fetchReportByMessages } = useReportByMessages();
  const formData = useRef<MessagesReportFormFields>();

  const { me } = useAuth();

  const submitReportRequest = useCallback(
    (values: MessagesReportFormFields) => {
      formData.current = values;
      return fetchReportByMessages(values);
    },
    [fetchReportByMessages],
  );

  const params: Partial<{
    from: string;
    to: string;
    searchMode: DefaultReportSearchMode;
    operator: string;
  }> = useMemo(() => qs.parse(search.slice(1)), [search]);

  const initialFormValues: Partial<MessagesReportFormFields> = useMemo(() => {
    if (!params.operator) {
      return initialValues;
    }

    return {
      rangeDate: [params.from, params.to].join(':'),
      mode: 'operator',
      operatorId: Number(params.operator),
    };
  }, [params]);

  const isOnce = me?.realm === Product.Once;

  return (
    <ReportLayout headerCaption="Report by messages">
      <ReportForm
        formType={isOnce ? 'messages' : 'default'}
        loading={reportByMessagesState.loading}
        onSubmit={submitReportRequest}
        initialValues={initialFormValues}
      />
      <MessagesReportTable data={reportByMessagesState.value} />
      <ExcelSaverButton<MessagesReportDataItem, MessagesReportFormFields | undefined>
        download={downloadMessagesReportXlsx}
        data={reportByMessagesState.value}
        getFileName={getFileName}
        filterData={formData.current}
      />
    </ReportLayout>
  );
};
