import { makeStyles } from '@material-ui/core';

const imageHeight = '210px';
const imageWidth = '122px';


export const useStyles = makeStyles({
  mainPhotoBorder: {
    border: '5px solid black',
  },

  photoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },

  photo: {
    height: imageHeight,
    width: imageWidth,
    maxWidth: '100%',
    objectFit: 'contain',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },

  mediaName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '22px',
    borderRadius: '15px',
    backgroundColor: 'black',
    color: 'white',
    fontFamily: 'Poppins',
    fontSize: '9px',
    lineHeight: '14px',
    position: 'absolute',
    bottom: '-26px',
  },
});
