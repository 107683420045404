import React, { useRef, useCallback, useMemo } from 'react';
import clsx from 'clsx';

import { LabelText, LabelTextType } from 'src/components/common/flure';

import trashCanIcon from './trash-can-icon.png';
import { useStyles } from './styles';

export enum ImageUploadInputSize {
  Small = 'small',
  Large = 'large',
}

type Props = {
  value?: string;
  size?: ImageUploadInputSize;
  onChange?: (source: string | undefined, file?: File) => void;
  className?: string;
  placeholder?: string;
  placeholderClassName?: string;
  imagePreview: string | undefined;
  setImagePreview: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const ImageUploadInputView: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    className,
    placeholder,
    size = ImageUploadInputSize.Small,
    placeholderClassName,
    onChange,
    imagePreview,
    setImagePreview,
  } = props;
  const filePickerRef = useRef(null);

  const { inputClassName, removeButtonClassName } = useMemo(() => {
    const inputStyle = [classes.buttonInput];
    const removeButtonStyle = [classes.removeImageButton];

    if (size === ImageUploadInputSize.Small) {
      inputStyle.push(classes.smallInput);
      removeButtonStyle.push(classes.smallInput);
    }

    if (size === ImageUploadInputSize.Large) {
      inputStyle.push(classes.largeInput);
      removeButtonStyle.push(classes.largeInput);
    }

    return { inputClassName: clsx(...inputStyle, className), removeButtonClassName: clsx(...removeButtonStyle) };
  }, [className, classes.buttonInput, classes.largeInput, classes.removeImageButton, classes.smallInput, size]);

  const previewFile = useCallback(
    (e) => {
      const reader = new FileReader();

      const selectedFile = e.target.files[0];
      if (selectedFile) {
        reader.readAsDataURL(selectedFile);
      }

      reader.onload = (readerEvent) => {
        if (readerEvent.target) {
          setImagePreview(readerEvent.target.result as string);

          if (onChange) {
            onChange(readerEvent.target.result as string, selectedFile);
          }
        }
      };
    },
    [onChange, setImagePreview],
  );

  const removeImage = useCallback(() => {
    setImagePreview(undefined);

    if (onChange) {
      onChange(undefined, undefined);
    }
  }, [onChange, setImagePreview]);

  return (
    <div className={classes.container}>
      <input ref={filePickerRef} accept="image/*" onChange={previewFile} type="file" hidden />
      <input
        type="image"
        className={inputClassName}
        placeholder="Img"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick={() => filePickerRef.current?.click()}
        alt=""
        src={imagePreview}
      />
      {!imagePreview && (
        <LabelText
          className={clsx(classes.placeholderText, placeholderClassName)}
          text={placeholder || 'Img'}
          type={LabelTextType.Info}
        />
      )}
      {!!imagePreview && (
        <button className={removeButtonClassName} type="button" onClick={removeImage}>
          <img className={classes.trashIcon} src={trashCanIcon} alt="Remove" />
        </button>
      )}
    </div>
  );
};

export const ImageUploadInput = React.memo(ImageUploadInputView);
