import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '33px',
  },
  userInfoText: {
    color: '#101014',
    textAlign: 'center',
    fontSize: '29px',
    lineHeight: '18px',
    fontWeight: 700,
    marginBottom: '20px',
  },

});
