import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Cards = styled.div`
  display: flex;
  padding-right: 15px;
  background-color: white;
  border: 1px solid #dedede;
  border-radius: 5px;
`;

export const VerificationCard = styled.div`
  padding: 15px;
  margin-right: 15px;
  border-right: 1px solid #dedede;
  max-width: 250px;
`;

export const Photos = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0 10px;
`;

export const Photo = styled.div`
  margin-right: 10px;
  margin-bottom: 5px;
  max-width: 24%;
`;

export const Controls = styled.div`
  position: relative;
  display: flex;
  margin-top: 25px;
  padding: 15px;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  border: 1px solid #dedede;
  border-radius: 5px;
`;
