import { withStyles } from '@material-ui/core';

export const withSliderStyles = withStyles({
  root: {
    color: '#141414',
    height: 2,
    padding: '15px 0 40px',
  },
  thumb: {
    height: 30,
    width: 30,
    backgroundColor: '#141414',
    marginTop: -15,
    marginLeft: 0,
    boxShadow: 'none',
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 14px)',
    fontFamily: 'Poppins',
    fontSize: '16px',
    top: 40,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 1,
    backgroundColor: '#B4B4B8',
  },
});
