import React, { useCallback, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { yupResolver } from '@hookform/resolvers/yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import { DefaultReportFormFields, DefaultReportSearchMode } from 'src/types/report/common';
import { useAuth } from 'src/services/auth';
import { UserRoles } from 'src/types/user/roles';
import { Product } from 'src/types/product';
import { RangeDatePicker } from 'src/components/common/RangeDatePicker';
import validationSchema from 'src/components/Report/common/Form/schema';
import { SelectWrapper } from 'src/components/Report/common/Form/styled';
import { useAvailableAgencies } from 'src/components/Report/common/Form/hooks';

type Props = {
  initialValues: Partial<DefaultReportFormFields>;
  loading: boolean;
  onSubmit: (values: DefaultReportFormFields) => void;
};

export const BonusesReportForm = ({ initialValues, onSubmit, loading }: Props) => {
  const { me } = useAuth();
  const magnetProduct = me?.realm === Product.Magnet;

  const correctAgencyList = useAvailableAgencies();
  const { control, handleSubmit, errors, watch, formState } = useForm<DefaultReportFormFields>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const modes = useMemo(() => {
    const params: { key: DefaultReportSearchMode; value: string }[] = [{ key: 'operator', value: 'Operator' }];

    if (me?.roles?.includes(UserRoles.AgencyAdministrator)) {
      params.unshift({ key: 'all', value: 'All' });
    }

    return params;
  }, [me?.roles]);

  const magnetModes = useMemo(() => {
    const params: { key: DefaultReportSearchMode; value: string }[] = [
      { key: 'agency', value: 'Agency' },
      { key: 'operator', value: 'Operator' },
    ];

    if (me?.roles?.includes(UserRoles.AgencyAdministrator)) {
      params.unshift({ key: 'all', value: 'All' }, { key: 'all-without-agency', value: 'All without agency' });
    }

    return params;
  }, [me?.roles]);

  const searchModeItems = useMemo(
    () =>
      (magnetProduct ? magnetModes : modes).map((mode) => (
        <MenuItem key={mode.key} value={mode.key}>
          {mode.value}
        </MenuItem>
      )),
    [magnetProduct, magnetModes, modes],
  );

  const agencyItems = useMemo(
    () =>
      correctAgencyList.map((agency) => (
        <MenuItem key={agency.techName} value={agency.techName}>
          {agency.name}
        </MenuItem>
      )),
    [correctAgencyList],
  );

  const onAgencySelectChange = useCallback(
    (saver: (value: string) => void) => (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      saver(e.target.value as string);
    },
    [],
  );

  const onSelectSearchModeChange = useCallback(
    (saver: (value: DefaultReportSearchMode) => void) => (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      saver(e.target.value as DefaultReportSearchMode);
    },
    [],
  );

  const submit = useCallback(
    (values: DefaultReportFormFields) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  const enableSubmit = formState.isValid;

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Controller
            name="rangeDate"
            control={control}
            render={({ onChange, value }) => <RangeDatePicker onChange={onChange} value={value} />}
            defaultValue=":"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="mode"
            control={control}
            defaultValue=""
            render={({ value, onChange }) => (
              <SelectWrapper>
                <FormControl fullWidth variant="outlined" error={!!errors.mode}>
                  <Select displayEmpty value={value} onChange={onSelectSearchModeChange(onChange)}>
                    <MenuItem value="" key="0" disabled>
                      Select search mode
                    </MenuItem>
                    {searchModeItems}
                  </Select>
                  {!!errors.mode && <FormHelperText>{errors.mode?.message}</FormHelperText>}
                </FormControl>
              </SelectWrapper>
            )}
          />
          {magnetProduct && watch('mode') === 'agency' && (
            <Controller
              name="agencyTechName"
              control={control}
              defaultValue=""
              render={({ value, onChange }) => (
                <SelectWrapper>
                  <FormControl fullWidth variant="outlined" error={!!errors.agencyTechName}>
                    <Select displayEmpty value={value} onChange={onAgencySelectChange(onChange)}>
                      <MenuItem value="" key="0" disabled>
                        Select agency
                      </MenuItem>
                      {agencyItems}
                    </Select>
                    {!!errors.agencyTechName && <FormHelperText>{errors.agencyTechName?.message}</FormHelperText>}
                  </FormControl>
                </SelectWrapper>
              )}
            />
          )}
          {watch('mode') === 'operator' && (
            <Controller
              as={TextField}
              name="operatorId"
              control={control}
              label="Operator Id"
              error={!!errors.operatorId}
              helperText={errors.operatorId?.message}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          )}
        </Grid>
      </Grid>
      {loading ? (
        <CircularProgress size="2rem" />
      ) : (
        <Button type="submit" variant="contained" color="primary" disabled={!enableSubmit}>
          Get report
        </Button>
      )}
    </form>
  );
};
