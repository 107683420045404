import { Http } from 'src/network/http';
import { WaitingListActionType } from 'src/types/waiting-list';

import { UserForWaitingListResponse, WaitingListCountResponse } from './types';
import { adapter } from './adapter';

const getUserId = (operatorId: string) =>
  Http.shared()
    .instance.get<UserForWaitingListResponse>(`/moderation/${operatorId}/waitlist`)
    .then(adapter.getUserForWaitingList);

const getWaitingListCount = (operatorId: string) => {
  return Http.shared()
    .instance.get<WaitingListCountResponse>(`/moderation/${operatorId}/waitlist/count`)
    .then(adapter.getWaitingListCount);
};

const getWaitingListUserDeclinedCount = (userId: string) => {
  return Http.shared()
    .instance.get<WaitingListCountResponse>(`/moderation/${userId}/waitlist/declined/count`)
    .then(adapter.getWaitingListCount);
};

const sendAction = (operatorId: string, userId: string, action: WaitingListActionType) =>
  Http.shared().instance.post(`/moderation/waitlist/${operatorId}/${userId}`, { action });

export const waitingListRequest = {
  getUserId,
  getWaitingListCount,
  getWaitingListUserDeclinedCount,
  sendAction,
};
