import { useAsyncFn } from 'react-use';

import { flureChatModerationRequest } from 'src/network/flure-chat-moderation';
import { ChatMessagesResponse, ChatModerationContentResponse } from 'src/network/flure-chat-moderation/types';

import { useAuth } from '../auth';

export const useChatModerationContent = () => {
  const { me } = useAuth();
  const operatorId = me?.id;

  const [moderationContent, fetchModerationContent] = useAsyncFn(
    async () => {
      try {
        const result = operatorId ? await flureChatModerationRequest.getChatModerationContent(operatorId) : {};
        return result as ChatModerationContentResponse;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: false },
  );

  return { moderationContent, fetchModerationContent };
};

export const useMessages = () => {
  const [messagesState, fetchMessages] = useAsyncFn(
    async ({ senderId, recipientId, select = 10, omit = 0, sort = 'desc' }) => {
      try {
        const result = await flureChatModerationRequest.getChatMessages({
          lower: senderId,
          higher: recipientId,
          select,
          omit,
          sort,
        });

        return (result || []) as ChatMessagesResponse;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: false },
  );

  return { messagesState, fetchMessages };
};

export const useChatModerationContentCount = () => {
  const { me } = useAuth();
  const operatorId = me?.id || '';

  const [contentCountState, fetchContentCount] = useAsyncFn(
    async () => {
      try {
        const result = await flureChatModerationRequest.getContentCount(operatorId);
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return {
    contentCountState,
    fetchContentCount,
  };
};

export const useChatModerationMessagesStopWords = () => {
  const [stopWordsState, fetchStopWords] = useAsyncFn(
    async () => {
      try {
        const result = await flureChatModerationRequest.getMessagesStopWords();
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return {
    stopWordsState,
    fetchStopWords,
  };
};
