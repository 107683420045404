import { useCallback } from 'react';

import { useAuth } from 'src/services/auth';
import { Logger, LoggerServices, LoggerMessages } from 'src/infrastructure/loggers/datadog';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';

export const useModerationLogger = () => {
  const { me } = useAuth();
  const operatorId = me?.id;

  const logUpdateThumbnailError = useCallback(
    ({ media, userId, error }) => {
      Logger.log({
        service: LoggerServices.Moderation,
        message: LoggerMessages.UpdateUserThumbnailError,
        product: me?.realm || '',
        payload: {
          operatorId,
          media,
          userId,
          error: error?.response,
        },
      });
    },
    [me?.realm, operatorId],
  );

  const logSendAdminModerationActions = useCallback(
    ({ actions, userId, response }) => {
      ActionsLogger.log(LoggerMessages.SendAdminContentModerationActions, {
        operatorId,
        product: me?.realm || '',
        userId,
        actions,
        response,
      });
    },
    [me?.realm, operatorId],
  );

  const logSendModerationActions = useCallback(
    ({ actions, userId, response, contentId }) => {
      ActionsLogger.log(LoggerMessages.SendUserModerationActions, {
        actions,
        product: me?.realm || '',
        operatorId,
        contentId,
        userId,
        response,
      });
    },
    [me?.realm, operatorId],
  );

  const logSendModerationActionsError = useCallback(
    ({ error, moderatedUsersCount, contentId }) => {
      Logger.log({
        service: LoggerServices.Moderation,
        message: LoggerMessages.SendModerationActionsError,
        product: me?.realm || '',
        payload: {
          operatorId,
          error: error?.response,
          moderatedUsersCount,
          contentId,
        },
      });
    },
    [me?.realm, operatorId],
  );

  return {
    logUpdateThumbnailError,
    logSendAdminModerationActions,
    logSendModerationActions,
    logSendModerationActionsError,
  };
};
