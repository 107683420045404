import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  buttonsContainer: {
    display: 'flex',
    columnGap: '8px',
    paddingLeft: '20px',
  },

  button: {
    width: '39px',
    height: '39px',
    borderRadius: '20px',
    borderWidth: 0,
    backgroundColor: '#E6E6EB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '&:hover': {
        opacity: 0.8,
      },
  },

  saveButton: {
    width: '79px',
    height: '39px',
    borderRadius: '20px',
    backgroundColor: 'black',
    fontFamily: 'Poppins',
    color: 'white',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    borderWidth: 0,
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8,
    },
  },

  trashCanIcon: {
    width: '18px',
    height: '21px',
  },

  editIcon: {
    width: '19px',
    height: '19px',
  },
});
