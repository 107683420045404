import React from 'react';
import clsx from 'clsx';

import { BubbleCheckBoxesList, Option } from 'src/components/common/flure';

import { useStyles } from './styles';

type Props = {
  genderOptions: Option[];
  countriesOptions: Option[];
  selectedGenders: string[];
  setSelectedGenders?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCountries: string[];
  setSelectedCountries?: React.Dispatch<React.SetStateAction<string[]>>;
  className?: string;
  disabled?: boolean;
};

const AudienceSelectorView: React.FC<Props> = (props) => {
  const {
    selectedGenders,
    setSelectedGenders,
    selectedCountries,
    setSelectedCountries,
    className,
    genderOptions,
    countriesOptions,
    disabled,
  } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <BubbleCheckBoxesList options={genderOptions} selected={selectedGenders} setSelected={setSelectedGenders} disabled={disabled} />
      <BubbleCheckBoxesList
        options={countriesOptions}
        selected={selectedCountries}
        setSelected={setSelectedCountries}
        disabled={disabled}
      />
    </div>
  );
};

export const AudienceSelector = React.memo(AudienceSelectorView);
