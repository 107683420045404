import { useCallback, useEffect, useState } from 'react';

import { DEFAULT_LOOKING_FOR, defaultFiltersState } from 'src/components/FlureFeed/constants';
import { Filter, FilterLocation, FiltersState } from 'src/network/flure-feed/types';
import { useFiltersState } from 'src/services/flure-feed/hooks';
import { GenderIdentityPreferences, LookingForPreferences } from 'src/types/user/preferences';
import { LocationOption } from 'src/components/FlureFeed/utils';

export const useFilters = () => {
  const [appliedFilters, setAppliedFilters] = useState<FiltersState>(defaultFiltersState);
  const [tempFilters, setTempFilters] = useState<FiltersState>(defaultFiltersState);
  const [selectedLookingForGenders, setSelectedLookingForGenders] = useState<string[]>([DEFAULT_LOOKING_FOR]);
  const { fetchFiltersState } = useFiltersState();

  const onLocationFilterChange = useCallback((option: LocationOption | null) => {
    setTempFilters((state) => ({
      ...state,
      [Filter.Location]: option?.value || FilterLocation.Virtual,
    }));
  }, []);

  const onAgeFilterChange = useCallback((value: number[]) => {
    setTempFilters((state) => ({
      ...state,
      [Filter.Age]: { min: value[0], max: value[1] },
    }));
  }, []);

  const resetFilters = useCallback(() => {
    setAppliedFilters(defaultFiltersState);
    setTempFilters(defaultFiltersState);
  }, []);

  useEffect(() => {
    fetchFiltersState();
  }, [fetchFiltersState]);

  useEffect(() => {
    setTempFilters((state) => ({
      ...state,
      [Filter.LookingFor]: selectedLookingForGenders as (LookingForPreferences | GenderIdentityPreferences)[],
    }));
  }, [selectedLookingForGenders]);

  return {
    appliedFilters,
    tempFilters,
    selectedLookingForGenders,
    setSelectedLookingForGenders,
    setAppliedFilters,
    onLocationFilterChange,
    onAgeFilterChange,
    resetFilters,
  };
};
