import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import { VerificationTag } from 'src/types/verification';
import { useUserForModeration } from 'src/services/moderation/hooks';
import { PhotoTransformation, verificationPhotoSize } from 'src/components/Moderation/utils/image-source';
import { ZoomImage } from 'src/components/Verification/views/ZoomImage';
import { MediaType } from 'src/types/user';

import { ImageWithLogger } from '../image-with-logger';
import { useStyles } from './styles';

type Props = {
  userId: string;
  checkIfVerified?: boolean;
  imageContainerStyle?: string;
  imageStyle?: string;
  size?: string;
  transformation?: PhotoTransformation;
  zoomEnabled?: boolean;
  onLoadEnd?: () => void;
};

export const VerificationPhoto: React.FC<Props> = (props) => {
  const {
    userId,
    checkIfVerified = true,
    imageContainerStyle,
    imageStyle,
    size,
    transformation,
    zoomEnabled,
    onLoadEnd,
  } = props;
  const classes = useStyles();
  const [verificationMediaSource, setVerificationMediaSource] = useState<string | null>(null);
  const { fetchVerificationPhoto, fetchVerificationPhotoTag } = useUserForModeration();

  const renderImage = useCallback(() => {
    return (
      <ImageWithLogger
        className={clsx(classes.verificationMedia, imageContainerStyle)}
        imageStyle={imageStyle}
        src={verificationMediaSource!}
        alt="verificationPhoto"
        onLoadEnd={onLoadEnd}
      />
    );
  }, [classes.verificationMedia, imageContainerStyle, imageStyle, onLoadEnd, verificationMediaSource]);

  useEffect(() => {
    setVerificationMediaSource(null);

    Promise.all([
      fetchVerificationPhoto(userId, size || verificationPhotoSize, transformation),
      fetchVerificationPhotoTag(userId),
    ]).then(([verificationPhoto, verificationPhotoTag]) => {
      if (checkIfVerified) {
        if (verificationPhotoTag === VerificationTag.Verified && verificationPhoto) {
          setVerificationMediaSource(verificationPhoto);
        }
      } else if (verificationPhoto) {
        setVerificationMediaSource(verificationPhoto);
      }
    });
  }, [checkIfVerified, fetchVerificationPhoto, fetchVerificationPhotoTag, size, transformation, userId]);

  if (!verificationMediaSource) {
    return null;
  }

  if (zoomEnabled) {
    return (
      <ZoomImage src={verificationMediaSource} mediaType={MediaType.Photo}>
        {renderImage()}
      </ZoomImage>
    );
  }

  return renderImage();
};
