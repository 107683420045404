import { Http } from 'src/network/http';
import { MediaTag, MentalLevel, UserDto, UserTagType, FilesData } from 'src/types/user';
import { Cities, Countries } from 'src/types/operator';
import { Product } from 'src/types/product';

import { userRolesRequest } from './roles';
import { userLifeStoryRequest } from './life-story';

const getById = (userId: string, privateMode?: boolean) => {
  return Http.shared()
    .instance.get(`/users/${privateMode ? 'private/' : ''}${userId}`)
    .then((response) => response.data as UserDto);
};

const update = (
  operatorId: string,
  payload: {
    name?: string;
    birthday?: string;
    gender?: string;
    latitude?: number | string;
    longitude?: number | string;
    city?: Cities | string;
    country?: Countries | string;
    about?: string;
    occupation?: string;
    questions?: string;
    languages?: string[];
  },
) =>
  Http.shared()
    .instance.patch(`/users/${operatorId}`, payload)
    .then((response) => response.data as UserDto);

const deleteUser = (userId: string) => {
  return Http.shared().instance.delete(`/users/${userId}`);
};

const getUserPhoto = (userId: string, basename: string, size?: string, token?: string, transformation?: string) => {
  const host = token ? Http.shared().apiHost : Http.shared().mediaHost;
  const authorization = token ? `?authorization=Token%20token="${token}"` : '';

  return basename
    ? `${host}/users/${userId}/photos/${token ? 'private/' : ''}${basename}${size || ''}${
        transformation || ''
      }${authorization}`
    : '';
};

const getUserPhotoFullSize = (userId: string, basename: string, token?: string) => {
  const host = token ? Http.shared().apiHost : Http.shared().mediaHost;
  const authorization = token ? `?authorization=Token%20token="${token}"` : '';

  return basename ? `${host}/users/${userId}/photos/${token ? 'private/' : ''}${basename}${authorization}` : '';
};

const getUserVideo = (userId: string, basename: string) => {
  return basename ? `${Http.shared().apiHost}/users/${userId}/videos/${basename}.swipe` : '';
};

const getUserMentalLevel = (userId: string) => {
  return Http.shared()
    .instance.get(`/users/${userId}/tags/mental.level`)
    .then((response) => response.data && (response.data[0] as string));
};

const getUserPreferences = (userId: string) => {
  return Http.shared()
    .instance.get(`/users/${userId}/preferences`)
    .then((response) => response.data);
};

const getUserTraits = (userId: string) => {
  return Http.shared()
    .instance.get(`/magnet/users/${userId}/traits`)
    .then((response) => response.data);
};

const getUserTurnOns = (userId: string) => {
  return Http.shared()
    .instance.get(`/magnet/users/${userId}/turn-ons`)
    .then((response) => response.data);
};

const getUserTags = (userId: string): Promise<UserTagType[]> => {
  return Http.shared()
    .instance.get(`/users/${userId}/tags`)
    .then((response) => response.data);
};

const getUserRealms = (userId: string): Promise<Product[]> => {
  return Http.shared()
    .instance.get(`/users/${userId}/tags/realms`)
    .then((response) => response.data);
};

const setUserMentalLevel = (userId: string, tag: MentalLevel) => {
  return Http.shared().instance.put(`/users/${userId}/tags/mental.level`, [tag]);
};

const setUserPhotoTag = (userId: string, basename: string, tag: MediaTag) => {
  return Http.shared().instance.put(`/users/${userId}/photos/${basename}/tags/${tag}`);
};

const removeUserPhotoTag = (userId: string, basename: string, tag: MediaTag) => {
  return Http.shared().instance.delete(`/users/${userId}/photos/${basename}/tags/${tag}`);
};

const setUserVideoTag = (userId: string, basename: string, tag: MediaTag) => {
  return Http.shared().instance.put(`/users/${userId}/videos/${basename}/tags/${tag}`);
};

const getPhotoTags = (userId: string) => {
  return Http.shared().instance.get<FilesData>(`/users/${userId}/photos/origin`, {
    headers: {
      accept: 'application/vnd.sdv.filetags+json',
    },
  });
};

const putUserBoostedTag = (userId: string) => {
  return Http.shared().instance.put(`/users/${userId}/tags/users.boosted`, { ttl: '00:00:01' });
};

export const userRequest = {
  deleteUser,
  getById,
  getUserPhoto,
  getUserPhotoFullSize,
  getUserVideo,
  getUserMentalLevel,
  getUserTags,
  getUserTraits,
  getUserTurnOns,
  getUserPreferences,
  update,
  setUserMentalLevel,
  setUserPhotoTag,
  removeUserPhotoTag,
  setUserVideoTag,
  getPhotoTags,
  putUserBoostedTag,
  getUserRealms,
  roles: userRolesRequest,
  lifeStory: userLifeStoryRequest,
};
