import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  mediasContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },

  boxShadow: {
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '30px',
    padding: '16px',
  },

  media: {
    height: '220px',
    width: '105px',
    objectFit: 'cover',
    margin: '8px',
  },

  mediaChunk: {
    display: 'flex',
    flexDirection: 'column',
  },

  mediaChunkTitle: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#020D3D',

    marginBottom: '16px',
  },

  mediaChunksSeparator: {
    width: '100%',
    border: '1px solid #883FFF',
    margin: '24px 0',
  },
});
