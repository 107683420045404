import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { Slider as UISlider } from '@material-ui/core';
import { debounce } from 'lodash';

import { withSliderStyles } from './styles';

type Props = {
  defaultValue?: number;
  min?: number;
  max?: number;
  value?: number;
  onChange?: (value: number) => void;
};

const CustomSlider = withSliderStyles(UISlider);

const SliderView = (props: Props) => {
  const { min, max, defaultValue, value: valueFromProps, onChange } = props;
  const [value, setValue] = useState(defaultValue);
  const previousValueFromProps = usePrevious(valueFromProps);

  const debouncedChangeHandler = useMemo(() => (onChange ? debounce(onChange, 300) : undefined), [onChange]);

  const handleChange = useCallback(
    (_: React.ChangeEvent<{}>, newValue: number | number[]) => {
      setValue(newValue as number);

      if (debouncedChangeHandler) {
        debouncedChangeHandler(newValue as number);
      }
    },
    [debouncedChangeHandler],
  );

  useEffect(() => {
    if (previousValueFromProps !== valueFromProps && value !== valueFromProps) {
      setValue(valueFromProps);
    }
  }, [previousValueFromProps, value, valueFromProps]);

  return (
    <CustomSlider
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      valueLabelDisplay="on"
      aria-labelledby="continuous-slider"
      min={min}
      max={max}
    />
  );
};

export const Slider = React.memo(SliderView);
