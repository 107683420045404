import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  header: {
    marginBottom: 16,
  },

  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: '30vw',
    marginRight: '40px',
  },

  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  fieldName: {
    fontFamily: 'SFCompactDisplay',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#878E9C',
    opacity: 0.5,
    marginBottom: '15px',
  },

  userName: {
    paddingLeft: '15px',
    borderLeft: '7px solid #F31D34',
    fontFamily: 'SFCompactDisplay',
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '47px',
    color: '#26334D',
    marginBottom: '26px',
  },

  userLookingFor: {
    paddingLeft: '15px',
    borderLeft: '7px solid #F31D34',
    fontSize: '28px',
    lineHeight: '33px',
    fontFamily: 'SFCompactDisplay',
    fontWeight: 400,
    color: '#878E9C',
    maxHeight: '260px',
    overflow: 'scroll',
    marginBottom: '26px',
  },

  userFieldContainer: {
    paddingLeft: '15px',
    borderLeft: '7px solid #F31D34',
    marginBottom: '16px',
  },

  location: {
    fontSize: '28px',
    lineHeight: '33px',
    fontFamily: 'SFCompactDisplay',
    fontWeight: 400,
    color: '#878E9C',
  },

  buttonsContainer: {
    position: 'relative',
    display: 'flex',
    marginTop: '30px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  btn: {
    margin: '0 12px',
    height: '78px',
    textTransform: 'none',
    fontSize: '24px',
    lineHeight: '32px',
    color: 'white',
  },

  btnApprove: {
    width: '256px',
    backgroundColor: '#00C376',
    '&:hover': {
      backgroundColor: '#00C376',
      opacity: 0.8,
    },
  },

  btnDecline: {
    width: '173px',
    backgroundColor: '#ED654C',
    '&:hover': {
      backgroundColor: '#ED654C',
      opacity: 0.8,
    },
  },

  preUserIdLoading: {
    position: 'absolute',
    bottom: '-30px',
  },

  prevUserId: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  }
});
