import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

export const useFeedNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showApplyFiltersErrorNotification = useCallback(() => {
    enqueueSnackbar('Could not apply filters. Try to reload page', { variant: 'error' });
  }, [enqueueSnackbar]);

  const showNoIdErrorNotification = useCallback(() => {
    enqueueSnackbar('Cannot get your user id. Try to reload or re-signin', { variant: 'error' });
  }, [enqueueSnackbar]);

  return {
    showApplyFiltersErrorNotification,
    showNoIdErrorNotification,
  };
};
