import React, { useCallback, useMemo } from 'react';

import { Link } from 'react-router-dom';
import { AppBar, Button, IconButton, Menu as MenuBase, MenuItem, Toolbar } from '@material-ui/core';
import { useAuth } from 'src/services/auth';
import { UserRoles } from 'src/types/user/roles';
import { Product } from 'src/types/product';
import { Reports } from 'src/types/report/common';
import { Logo } from 'src/components/common/Logo';

import * as S from './styled';

type Props = {
  keyId: string;
  type: 'icon' | 'text';
  icon?: React.ReactNode;
  title?: string;
  children: React.ReactNode;
};

const MenuButton = ({ keyId, type, icon, title, children }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const button = useMemo(() => {
    if (type === 'icon') {
      return (
        <IconButton
          aria-label="account of current user"
          aria-controls={keyId}
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          {icon}
        </IconButton>
      );
    }

    if (type === 'text') {
      return (
        <Button aria-controls={keyId} aria-haspopup="true" color="inherit" onClick={handleMenu}>
          {title}
        </Button>
      );
    }

    return null;
  }, [icon, keyId, title, type]);

  return (
    <div>
      {button}
      <MenuBase
        id={keyId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
      >
        {children}
      </MenuBase>
    </div>
  );
};

export const Menu = () => {
  const { me, logout } = useAuth();

  const roles = useMemo(() => me?.roles || [], [me]);
  const lovingaProduct = me?.realm === Product.Lovinga;
  const udatesProduct = me?.realm === Product.Once;
  const flureProduct = me?.realm === Product.Flure;
  const magnetProduct = me?.realm === Product.Magnet;
  const rawProduct = me?.realm === Product.RAW;

  const isAgencyAdministrator = useMemo(() => roles.includes(UserRoles.AgencyAdministrator), [roles]);
  const isAgency = useMemo(() => roles.includes(UserRoles.Agency), [roles]);
  const isAdministrator = useMemo(() => roles.includes(UserRoles.Supervisor), [roles]);
  const isOperator = useMemo(() => roles.includes(UserRoles.UsersOperator), [roles]);
  const isCustomerSupportOnly = useMemo(
    () => roles.includes(UserRoles.CustomerSupport) && !isOperator && !isAdministrator,
    [roles, isOperator, isAdministrator],
  );
  const isChatOperator = useMemo(() => roles.includes(UserRoles.ChatOperator), [roles]);
  const isPromoCodesOperator = useMemo(() => roles.includes(UserRoles.PromoCodesOperator), [roles]);

  const onLogoutClick = useCallback(() => logout(), [logout]);

  const renderModerationSection =
    (lovingaProduct || udatesProduct || magnetProduct || flureProduct || rawProduct) &&
    (isOperator || isAdministrator || isCustomerSupportOnly);
  const renderReportByBonusesSection =
    !udatesProduct && !flureProduct && (isAgencyAdministrator || (isAgency && magnetProduct));
  const isFlureOperatorOrAdmin = flureProduct && (isOperator || isAdministrator);
  const renderReportByKPISection = udatesProduct;
  const reportByActivitySection = udatesProduct || magnetProduct;
  const renderFlureChatSection = flureProduct && isChatOperator;

  return (
    <AppBar position="static">
      <Toolbar>
        <Logo />
        {!rawProduct && !isChatOperator && (
          <MenuButton keyId="menu-reports" type="text" title="Reports">
            <MenuItem to={`/report-by-${Reports.commissions}`} component={Link}>
              Report by commissions
            </MenuItem>
            <MenuItem to={`/report-by-${Reports.messages}`} component={Link}>
              Report by messages
            </MenuItem>
            {reportByActivitySection && (
              <MenuItem to={`/report-by-${Reports.activity}`} component={Link}>
                Report by activity
              </MenuItem>
            )}
            {renderReportByKPISection && (
              <MenuItem to={`/report-by-${Reports.kpi}`} component={Link}>
                Report by KPI
              </MenuItem>
            )}
            {renderReportByBonusesSection && (
              <MenuItem to={`/report-by-${Reports.bonuses}`} component={Link}>
                Report by bonuses
              </MenuItem>
            )}
          </MenuButton>
        )}
        {isAgencyAdministrator && (
          <MenuButton keyId="menu-multichat" type="text" title="Multichat">
            <MenuItem to="/multichat/life-stories" component={Link}>
              Life-stories
            </MenuItem>
            <MenuItem to="/multichat/agencies-operators-mapping" component={Link}>
              Agencies-Operators mapping
            </MenuItem>
            <MenuItem to="/multichat/animators-activator" component={Link} key="animators-activator">
              Animators activator
            </MenuItem>
            <MenuItem to="/multichat/agencies-animators-mapping" component={Link} key="agencies-animators-mapping">
              Agencies-Animators mapping
            </MenuItem>
            <MenuItem to="/multichat/operators-managing" component={Link}>
              Operators Managing
            </MenuItem>
          </MenuButton>
        )}
        {flureProduct && isPromoCodesOperator && (
          <Button to="/local-events" component={Link} color="inherit">
            Local Events
          </Button>
        )}
        {flureProduct && isAdministrator && (
          <Button to="/vibes-and-premium-management" component={Link} color="inherit">
            Vibes & Premium Management
          </Button>
        )}
        {(lovingaProduct || flureProduct || magnetProduct) && (isOperator || isAdministrator) && (
          <Button to="/verification" component={Link} color="inherit">
            Verification
          </Button>
        )}
        {renderModerationSection && (
          <Button to="/moderation" component={Link} color="inherit">
            Moderation
          </Button>
        )}
        {rawProduct && (
          <Button to="/moderation/auto" component={Link} color="inherit">
            Auto moderation
          </Button>
        )}
        {renderFlureChatSection && (
          <Button to="/flure-chat-moderation" component={Link} color="inherit">
            Chat Moderation
          </Button>
        )}
        {isFlureOperatorOrAdmin && (
          <Button to="/waiting-list" component={Link} color="inherit">
            Waiting List
          </Button>
        )}
        {renderFlureChatSection && (
          <Button to="/flure-chat" component={Link} color="inherit">
            Flure Bunny
          </Button>
        )}
        {isFlureOperatorOrAdmin && (
          <Button to="/flure-feed" component={Link} color="inherit">
            Feed Preview
          </Button>
        )}
        {magnetProduct && isAdministrator && (
          <MenuButton keyId="menu-dailybuzz" type="text" title="Dailybuzz">
            <MenuItem to="/dailybuzz/questions" component={Link}>
              Questions
            </MenuItem>
            <MenuItem to="/dailybuzz/categories" component={Link}>
              Categories
            </MenuItem>
          </MenuButton>
        )}
        {magnetProduct && isAdministrator && (
          <MenuButton keyId="menu-animators" type="text" title="Animators">
            <MenuItem to="/animators/preload-photos" component={Link}>
              Preload photos
            </MenuItem>
            <MenuItem to="/animators/profile" component={Link}>
              Profile
            </MenuItem>
          </MenuButton>
        )}
        <S.LogoutBox>
          <Button color="inherit" onClick={onLogoutClick}>
            Logout
          </Button>
        </S.LogoutBox>
      </Toolbar>
    </AppBar>
  );
};
