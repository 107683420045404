import React from 'react';
import { Resources } from 'src/resources';
import { ConfirmationModal } from 'src/components/common/confirmation-modal';

type Props = {
  isOpen: boolean;
  onBlock: () => void;
  onCancel: () => void;
  confirmationText?: string;
};

export const BlockUserModal = (props: Props) => {
  const { isOpen, onBlock, onCancel, confirmationText } = props;

  return (
    <ConfirmationModal
      isOpen={isOpen}
      confirmationText={confirmationText || Resources.strings.moderation.blockProfile}
      onConfirm={onBlock}
      rejectionText="Cancel"
      onReject={onCancel}
    />
  );
};
