import React from 'react';
import { Gender } from 'src/types/user';

import { formatGenderAndAge } from 'src/utils/format-gender-and-age';

import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';

type Props = {
  userId: string;
  age: number | '';
  gender: Gender;
  toggleModal: () => void;
};

export const UserInfo: React.FC<Props> = ({ userId, age, gender, toggleModal }) => {
  const classes = useStyles();

  return (
    <div className={classes.infoContainer}>
      <span className={classes.userGenderAndAge}>{formatGenderAndAge(gender, age)}</span>
      <span className={classes.userId}>ID {userId}</span>
      <Button onClick={toggleModal} className={clsx(classes.btn)} variant="contained">
        Delete Profile
      </Button>
    </div>
  );
};
