import React, { useCallback, useMemo } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import 'react-day-picker/lib/style.css';

import './styles.css';
import * as S from './styled';

export interface RangeModifier {
  from?: Date;
  to?: Date;
}

type Props = {
  value: string;
  onChange: (range: string) => void;
};

const maxDate = new Date();

export const RangeDatePicker = ({ value, onChange }: Props) => {
  const range = useMemo(() => {
    const [fromString, toString] = value.split(':');

    return {
      from: fromString ? new Date(fromString) : undefined,
      to: toString ? new Date(toString) : undefined,
    };
  }, [value]);

  const { from, to } = range;

  const modifiers = useMemo(() => ({ start: from, end: to }), [from, to]);

  const reset = useCallback(() => {
    onChange(':');
  }, [onChange]);

  const handleDayClick = useCallback(
    (day) => {
      const updatedRange = DateUtils.addDayToRange(day, range as Required<RangeModifier>);
      const updatedFrom = updatedRange.from ? moment(updatedRange.from).format('YYYY-MM-DD') : undefined;
      const updatedTo = updatedRange.to ? moment(updatedRange.to).format('YYYY-MM-DD') : undefined;
      onChange([updatedFrom, updatedTo].join(':'));
    },
    [range, onChange],
  );

  return (
    <div>
      <S.PickerCaption>
        {!from && !to && 'Please select the first day.'}
        {from && !to && 'Please select the last day.'}
        {from &&
          to &&
          `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{' '}
        {from && to && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="small"
            onClick={reset}
            style={{
              marginLeft: 10,
            }}
          >
            Reset Dates
          </Button>
        )}
      </S.PickerCaption>
      <DayPicker
        className="Selectable"
        numberOfMonths={2}
        selectedDays={[from, { from: from as Date, to: to as Date }]}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        fromMonth={moment().subtract(3, 'months').toDate()}
        toMonth={maxDate}
        disabledDays={[
          {
            after: maxDate,
          },
        ]}
        firstDayOfWeek={1}
      />
    </div>
  );
};
