import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  header: {
    marginBottom: 16,
  },

  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: '110px',
  },

  chatContainer: {
    display: 'flex',
    width: '100%',
    columnGap: '30px',
    padding: '32px 32px 32px 0px',
  },

  buttonsContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '27px 32px 31px',
    borderTop: '1px solid rgba(0, 0, 0, 0.17)',
  },

  loadingContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '27px 32px 31px',
    borderTop: '1px solid rgba(0, 0, 0, 0.17)',
    rowGap: '20px',
  },
});
