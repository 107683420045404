import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';

import { Layout } from 'src/components/App/views/Layout';
import { ActionType, ActionTypeMapper, ActionsRequiredConfirmation, ModerationAction } from 'src/types/moderation';
import { MentalLevel, Gender, MediaType, MediaTag, MediaState } from 'src/types/user';
import { moderationRequest, userRequest } from 'src/network';
import { useModerationContentCount } from 'src/services/moderation/hooks';
import { ModerationContentCountResponse } from 'src/network/moderation/types';
import { useFormattedUser } from 'src/services/user/hooks';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import { Logger, LoggerServices, LoggerMessages } from 'src/infrastructure/loggers/datadog';
import { Resources } from 'src/resources';
import { noop } from 'src/utils/functions';
import {
  useGetMediaSourceAuthed,
  useGetMediaSourceAuthedFullSize,
  userCachedPhotoSize,
} from 'src/components/Moderation/utils/image-source';
import { BlockUserModal } from 'src/components/Moderation/views/block-user-modal';
import { ActionButton } from 'src/components/Moderation/views/action-button';
import { ModerationHeader } from 'src/components/Moderation/views/moderation-header';
import { ImageWithLogger } from 'src/components/Moderation/views/image-with-logger';
import { VideoWithLogger } from 'src/components/Moderation/views/video-with-logger';
import { QuestionPrompts } from 'src/components/Moderation/views/question-prompts';
import { FullSizeMedia } from 'src/components/Moderation/views/full-size-media';
import { getUserAge } from 'src/utils/transform';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';
import { ConfirmationModal } from 'src/components/common/confirmation-modal';
import { VerificationPhoto } from 'src/components/Moderation/views/verification-photo';

import { Actions } from './Actions';
import { useStyles } from './styles';

const { defaultName, defaultAbout, defaultOccupation } = Resources.strings.moderation;

type ConfirmationModalParams = {
  title: string;
  onConfirm: () => void;
};

export const AdminUserContentModerationScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const getMediaSource = useGetMediaSourceAuthed();
  const getMediaSourceFullSize = useGetMediaSourceAuthedFullSize();

  const { userState, fetchFormattedUser } = useFormattedUser();

  const [userGender, setUserGender] = useState<Gender | undefined>();
  const [userMentalLevel, setUserMentalLevel] = useState<MentalLevel | undefined>();
  const [mainUserMediaSource, setUserMainMediaSource] = useState<string | null>(null);
  const [mainUserMediaType, setUserMainMediaType] = useState<MediaType>(MediaType.Photo);

  const [mainUserMediaSourceFullSize, setUserMainMediaSourceFullSize] = useState<string | null>(null);

  const { contentCountState, fetchContentCount } = useModerationContentCount();

  const [moderationActions, setModerationActions] = useState<ModerationAction[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [confirmationModalParams, setConfirmationModalParams] = useState<ConfirmationModalParams | null>(null);
  const [isFullSizeOpen, setIsFullSizeOpen] = useState<boolean>(false);
  const [mediaState, setMediaState] = useState<MediaState>(MediaState.Public);

  const [isSending, setIsSending] = useState(false);
  const [fullSizeMedia, setFullSizeMedia] = useState<string>('');
  const isLoading = useMemo(() => isSending || userState?.loading, [userState, isSending]);

  const { enqueueSnackbar } = useSnackbar();
  const { me } = useAuth();
  const operatorId = me?.id;
  const uDatesProduct = me?.realm === Product.Once;
  const magnetProduct = me?.realm === Product.Magnet;
  const lovingaProduct = me?.realm === Product.Lovinga;
  const showUserAge = uDatesProduct || lovingaProduct;
  const mediaBorder = uDatesProduct ? classes.mediaBorderRed : classes.mediaBorderBlue;
  const actionConfirmationEnabled = false;

  const { userId, baseName: baseNameFromUrl, mediaType: mediaTypeFromUrl } = useParams<{
    userId: string;
    baseName: string;
    mediaType: string;
  }>();
  const isPrivate =
    magnetProduct ||
    userState.value?.photoData?.files
      ?.find((el) => baseNameFromUrl?.includes(el.basename))
      ?.tags?.includes(MediaTag.Hidden);
  const moderationMediaSource = useMemo(() => getMediaSource(userId, baseNameFromUrl, mediaTypeFromUrl, isPrivate), [
    baseNameFromUrl,
    mediaTypeFromUrl,
    getMediaSource,
    userId,
    isPrivate,
  ]);
  const moderationMediaSourceType = mediaTypeFromUrl === 'image' ? MediaType.Photo : MediaType.Video;

  const moderationMediaSourceFullSize = useMemo(
    () => getMediaSourceFullSize(userId, baseNameFromUrl, mediaTypeFromUrl, isPrivate),
    [baseNameFromUrl, mediaTypeFromUrl, getMediaSourceFullSize, userId, isPrivate],
  );

  const {
    name,
    about,
    questions: userQuestions,
    media,
    thumbnail: userThumbnail,
    thumbnailSource: userThumbnailSource,
    occupation,
    photoData,
    birthday,
  } = userState.value || {};
  const age = useMemo(() => getUserAge(birthday || ''), [birthday]);

  const hasThumbnail = useMemo(() => {
    const index = photoData?.files.findIndex((file) => {
      return file.tags.includes(MediaTag.Thumbnail);
    });
    return index !== -1;
  }, [photoData]);

  const hideMainMedia = useMemo(() => {
    const isSameBaseName =
      !!baseNameFromUrl &&
      (baseNameFromUrl === userThumbnail || (!!userThumbnail && baseNameFromUrl.startsWith(userThumbnail)));

    if (uDatesProduct) {
      return !hasThumbnail || isSameBaseName;
    }

    return isSameBaseName || !mainUserMediaSource;
  }, [mainUserMediaSource, uDatesProduct, hasThumbnail, baseNameFromUrl, userThumbnail]);

  const { count: usersInQueue = 0 } = useMemo(
    () => contentCountState?.value || ({} as ModerationContentCountResponse),
    [contentCountState?.value],
  );

  const loadData = useCallback(() => {
    fetchFormattedUser(userId, me?.realm).then(async (user) => {
      if (user) {
        const { thumbnailSource, thumbnailType, gender, photoData: userPhotoData } = user;

        setUserGender(gender);

        if (lovingaProduct) {
          userRequest.getUserMentalLevel(userId).then(setUserMentalLevel).catch(noop);

          setUserMainMediaSource(thumbnailSource);
          setUserMainMediaType(thumbnailType);
        }

        if (uDatesProduct) {
          setUserMainMediaSource(thumbnailSource);
          setUserMainMediaSourceFullSize(thumbnailSource.replace(userCachedPhotoSize, ''));

          setUserMainMediaType(thumbnailType);
          if (uDatesProduct && userPhotoData && baseNameFromUrl) {
            const fileData = userPhotoData.files.find((file) => baseNameFromUrl.startsWith(file.basename));

            if (fileData?.tags.some((value) => value === 'hidden')) {
              setMediaState(MediaState.Private);
            } else {
              setMediaState(MediaState.Public);
            }
          } else {
            setMediaState(MediaState.Public);
          }
        }
      }
    });
  }, [baseNameFromUrl, fetchFormattedUser, lovingaProduct, me?.realm, uDatesProduct, userId]);

  useEffect(() => {
    loadData();
    fetchContentCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserThumbnail = useCallback(() => {
    const newThumbnailMedia = media?.find((mediaItem) => mediaItem.baseName !== userThumbnail);

    if (newThumbnailMedia) {
      const { baseName: newBaseName, mediaType } = newThumbnailMedia;
      const setTagMethod = mediaType.startsWith('video') ? userRequest.setUserVideoTag : userRequest.setUserPhotoTag;

      setTagMethod(userId, newBaseName, MediaTag.Thumbnail).catch((error) => {
        Logger.log({
          service: LoggerServices.Moderation,
          message: LoggerMessages.UpdateUserThumbnailError,
          product: me?.realm || '',
          payload: {
            mediaType,
            userId,
            newBaseName,
            operatorId,
            error: error?.response,
          },
        });
      });
    }
  }, [media, userThumbnail, userId, me?.realm, operatorId]);

  const goBack = useCallback(() => {
    history.replace(`/moderation/user/${userId}`);
  }, [history, userId]);

  const sendModerationActions = useCallback(
    async (actions: ModerationAction[]) => {
      if (operatorId) {
        if (actions.length > 0) {
          const handleSendActions = () => {
            setIsSending(true);

            moderationRequest
              .sendAdminModerationActions(operatorId, userId, actions)
              .then((response) => {
                ActionsLogger.log(LoggerMessages.SendAdminContentModerationActions, {
                  actions,
                  product: me?.realm || '',
                  operatorId,
                  userId,
                  response,
                });

                const needToUpdateUserThumbnail = actions.some(
                  (action) => action.actionValue === userThumbnailSource?.replace('.swipe', ''),
                );

                if (needToUpdateUserThumbnail) {
                  updateUserThumbnail();
                }

                setIsSending(false);

                goBack();
              })
              .catch((error) => {
                setIsSending(false);

                Logger.log({
                  service: LoggerServices.Moderation,
                  message: LoggerMessages.SendModerationActionsError,
                  product: me?.realm || '',
                  payload: {
                    operatorId,
                    error: error?.response,
                  },
                });

                goBack();
              });
          };

          const actionWithConfirmation = actions.find((action) =>
            ActionsRequiredConfirmation.includes(action.actionType),
          );

          if (actionConfirmationEnabled && actionWithConfirmation) {
            setConfirmationModalParams({
              title: ActionTypeMapper[actionWithConfirmation.actionType],
              onConfirm: () => {
                setConfirmationModalParams(null);
                handleSendActions();
              },
            });
          } else {
            handleSendActions();
          }
        }
      } else {
        enqueueSnackbar('Cannot get your user id. Try to reload or re-signin', { variant: 'error' });
      }
    },
    [
      operatorId,
      actionConfirmationEnabled,
      userId,
      me?.realm,
      goBack,
      userThumbnailSource,
      updateUserThumbnail,
      enqueueSnackbar,
    ],
  );

  const addModerationAction = useCallback(
    (actionType: ActionType, actionValue?: string) => {
      setModerationActions([
        ...moderationActions.filter((action) => action.actionType !== actionType),
        actionValue !== undefined
          ? {
              actionType,
              actionValue,
            }
          : { actionType },
      ]);
    },
    [moderationActions],
  );

  const isPressed = useCallback(
    (actionType: ActionType, actionValue?: string) => {
      return (
        moderationActions.some((action) =>
          actionValue
            ? action.actionType === actionType && action.actionValue === actionValue
            : action.actionType === actionType,
        ) ||
        (actionType === ActionType.SetGender && userGender === actionValue) ||
        (actionType === ActionType.SetColor && userMentalLevel === actionValue) ||
        (actionType === ActionType.SetPrivatePhoto && mediaState === actionValue) ||
        (actionType === ActionType.SetPublicPhoto && mediaState === actionValue)
      );
    },
    [mediaState, moderationActions, userGender, userMentalLevel],
  );

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const toggleFullSizeModal = useCallback(
    (mediaSource) => {
      setFullSizeMedia(mediaSource);
      setIsFullSizeOpen(!isFullSizeOpen);
    },
    [isFullSizeOpen],
  );

  const resetName = useCallback(() => {
    addModerationAction(ActionType.ResetName, defaultName);
  }, [addModerationAction]);

  const resetAbout = useCallback(() => {
    addModerationAction(ActionType.ResetAbout, defaultAbout);
  }, [addModerationAction]);

  const resetQuestions = useCallback(() => {
    if (userQuestions) {
      const questions = userQuestions.map(({ question }: { question: string }) => ({
        question,
        answer: '...',
      }));
      const actionValue = JSON.stringify({ questions });
      addModerationAction(ActionType.ResetQuestions, actionValue);
    }
  }, [addModerationAction, userQuestions]);

  const resetOccupation = useCallback(() => {
    addModerationAction(ActionType.ResetOccupation, defaultOccupation);
  }, [addModerationAction]);

  const blockProfile = useCallback(() => {
    toggleModal();
    sendModerationActions([...moderationActions, { actionType: ActionType.Delete }]);
  }, [sendModerationActions, toggleModal, moderationActions]);

  const undoAction = useCallback(
    (actionType: ActionType) => () => {
      setModerationActions([...moderationActions.filter((action) => action.actionType !== actionType)]);
    },
    [moderationActions],
  );

  const mediaClass = uDatesProduct ? classes.uDatesMedia : classes.media;

  const renderMedia = useCallback(
    (source: string, mediaType: MediaType | string, showBorder?: boolean, basename?: string) => {
      return mediaType === MediaType.Video ? (
        <VideoWithLogger
          className={clsx(mediaClass, showBorder && mediaBorder)}
          src={source}
          basename={basename}
          autoPlay
          loop
        />
      ) : (
        <ImageWithLogger
          className={clsx(mediaClass, showBorder && mediaBorder)}
          src={source}
          basename={basename}
          alt="mainPhoto"
        />
      );
    },
    [mediaClass, mediaBorder],
  );

  const mappedAboutField = useMemo(() => {
    if (magnetProduct) {
      return userQuestions && <QuestionPrompts questionItems={userQuestions} />;
    }

    return about;
  }, [about, magnetProduct, userQuestions]);

  const nameActionButtonProps = useMemo(() => {
    if (isPressed(ActionType.ResetName)) {
      return {
        onClick: undoAction(ActionType.ResetName),
        title: Resources.strings.moderation.undoResetNameTitle,
      };
    }

    return {
      onClick: resetName,
      title: Resources.strings.moderation.resetNameTitle,
    };
  }, [isPressed, undoAction, resetName]);

  const renderQuestionsField = useCallback(() => {
    if (isPressed(ActionType.ResetQuestions)) {
      return defaultAbout;
    }
    if (!userQuestions) {
      return null;
    }
    return <QuestionPrompts questionItems={userQuestions} />;
  }, [isPressed, userQuestions]);

  const renderAboutField = useMemo(() => (isPressed(ActionType.ResetAbout) ? defaultAbout : mappedAboutField), [
    isPressed,
    mappedAboutField,
  ]);

  const aboutActionButtonProps = useMemo(() => {
    const actionType = magnetProduct ? ActionType.ResetQuestions : ActionType.ResetAbout;
    if (isPressed(actionType)) {
      return {
        onClick: undoAction(actionType),
        title: uDatesProduct
          ? Resources.strings.moderation.undoResetBioTitleUdates
          : Resources.strings.moderation.undoResetBioTitle,
      };
    }

    const resetAction = magnetProduct ? resetQuestions : resetAbout;
    return {
      onClick: resetAction,
      title: uDatesProduct
        ? Resources.strings.moderation.resetBioTitleUdates
        : Resources.strings.moderation.resetBioTitle,
    };
  }, [magnetProduct, isPressed, resetQuestions, resetAbout, uDatesProduct, undoAction]);

  const nameTitles = useMemo(() => {
    const endingPart = showUserAge && age > 0 ? `, ${age}` : '';

    return {
      defaultName: `${defaultName}${endingPart}`,
      newUserName: `${name}${endingPart}`,
    };
  }, [age, name, showUserAge]);

  if (userState?.value === null) {
    return (
      <Layout containerSize="lg">
        <ModerationHeader usersInQueue={usersInQueue} />
        <Box className={classes.placeholderContainer}>
          <Typography className={classes.placeholderText}>User not found.</Typography>
        </Box>
      </Layout>
    );
  }
  return (
    <Layout containerSize="lg">
      <BlockUserModal isOpen={isModalOpen} onBlock={blockProfile} onCancel={toggleModal} />
      {lovingaProduct && confirmationModalParams ? (
        <ConfirmationModal
          isOpen
          confirmationText={confirmationModalParams.title}
          onConfirm={confirmationModalParams.onConfirm}
          rejectionText="Cancel"
          onReject={() => setConfirmationModalParams(null)}
        />
      ) : (
        <></>
      )}
      {moderationMediaSource && mediaTypeFromUrl ? (
        <FullSizeMedia
          isOpen={isFullSizeOpen}
          setIsOpen={setIsFullSizeOpen}
          source={fullSizeMedia}
          mediaType={mediaTypeFromUrl as MediaType}
          basename={baseNameFromUrl}
        />
      ) : (
        <></>
      )}
      <Box className={classes.mainContainer}>
        <ModerationHeader usersInQueue={usersInQueue} />
        {isLoading && (
          <Box className={classes.placeholderContainer}>
            <CircularProgress />
          </Box>
        )}

        {!isLoading && (
          <Box className={classes.container}>
            <Box className={classes.leftContainer}>
              <Box className={classes.nameWithPhotoContainer}>
                {lovingaProduct && <VerificationPhoto userId={userId} />}
                <Box className={classes.fieldContainer}>
                  <Typography className={classes.name}>
                    {isPressed(ActionType.ResetName) ? nameTitles.defaultName : nameTitles.newUserName}
                  </Typography>
                  <ActionButton {...nameActionButtonProps} disabled={isLoading || name === defaultName} />
                </Box>
              </Box>
              <Box className={clsx(classes.fieldContainer)}>
                <Typography className={classes.bio}>
                  {magnetProduct ? renderQuestionsField() : renderAboutField}
                </Typography>
                <ActionButton {...aboutActionButtonProps} disabled={isLoading} />
              </Box>
              {uDatesProduct && (
                <Box className={clsx(classes.fieldContainer)}>
                  <Typography className={classes.occupation}>
                    {isPressed(ActionType.ResetOccupation) ? defaultOccupation : occupation}
                  </Typography>
                  <ActionButton
                    {...(isPressed(ActionType.ResetOccupation)
                      ? {
                          onClick: undoAction(ActionType.ResetOccupation),
                          title: Resources.strings.moderation.undoResetOccupation,
                        }
                      : {
                          onClick: resetOccupation,
                          title: Resources.strings.moderation.resetOccupation,
                        })}
                    disabled={isLoading || occupation === defaultOccupation}
                  />
                </Box>
              )}
              <Box className={classes.fieldContainer}>
                <ActionButton
                  color="error"
                  onClick={toggleModal}
                  title={Resources.strings.moderation.blockProfile}
                  disabled={isLoading}
                />
              </Box>
            </Box>

            <Box className={classes.rightContainer}>
              <Box className={classes.mediasContainer}>
                {!hideMainMedia && (
                  <Box
                    className={clsx(classes.cursorPointer, classes.mediaContainer)}
                    onClick={() => {
                      toggleFullSizeModal(mainUserMediaSourceFullSize);
                    }}
                  >
                    <Typography className={classes.mediaName}>{Resources.strings.moderation.media.main}</Typography>
                    {mainUserMediaSource ? (
                      renderMedia(mainUserMediaSource, mainUserMediaType)
                    ) : (
                      <Box className={mediaClass}>
                        <CircularProgress />
                      </Box>
                    )}
                  </Box>
                )}
                {baseNameFromUrl && moderationMediaSource && mediaTypeFromUrl && (
                  <Box
                    className={classes.mediaContainer}
                    onClick={() => {
                      toggleFullSizeModal(moderationMediaSourceFullSize);
                    }}
                  >
                    <Typography className={classes.mediaName}>{Resources.strings.moderation.media.new}</Typography>
                    {renderMedia(moderationMediaSource, mediaTypeFromUrl, true, baseNameFromUrl)}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
        <Actions
          goBack={goBack}
          undoAction={undoAction}
          resetAbout={resetAbout}
          resetQuestions={resetQuestions}
          resetName={resetName}
          resetOccupation={resetOccupation}
          moderationActions={moderationActions}
          addModerationAction={addModerationAction}
          setUserMentalLevel={setUserMentalLevel}
          isPressed={isPressed}
          setUserGender={setUserGender}
          newMediaSource={moderationMediaSource}
          newMediaType={moderationMediaSourceType}
          mainMediaSource={userThumbnailSource || ''}
          isLoading={isLoading}
          sendModerationActions={sendModerationActions}
          setMediaState={setMediaState}
          isNoUndoUsers={false}
        />
      </Box>
    </Layout>
  );
};
