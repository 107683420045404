import { useMemo } from 'react';
import { ColDef } from '@material-ui/data-grid';

import { useAuth } from 'src/services/auth';

import { getTableColumnsByRealm } from './columns-config';

export const useTableColumns = (onSentMessageClick: (operatorId: string) => void): ColDef[] => {
  const { me } = useAuth();

  const columns = useMemo(() => {
    return getTableColumnsByRealm({
      realm: me?.realm,
      onSentMessageClick,
    });
  }, [me, onSentMessageClick]);

  return columns;
};
