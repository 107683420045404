import { Product } from 'src/types/product';

import { getDefaultTableColumns } from './default-columns';
import { getUdatesTableColumns } from './udates-columns';

type Props = {
  realm?: Product;
};

export function getTableColumnsByRealm(options: Props) {
  if (!options.realm) {
    return getDefaultTableColumns();
  }

  switch (options.realm) {
    case Product.Once:
      return getUdatesTableColumns();

    default:
      return getDefaultTableColumns();
  }
}
