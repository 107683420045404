import { useAsyncFn } from 'react-use';

import { flureChatRequest } from 'src/network/flure-chat';
import { ChatAuditoryParams } from 'src/network/flure-chat/types';
import { useAuth } from '../auth';

export const useMessages = () => {
  const { me } = useAuth();
  const operatorId = me?.id;

  const [messagesState, fetchMessages] = useAsyncFn(
    async ({ countries, genderIdentities }: ChatAuditoryParams) => {
      try {
        const result = operatorId
          ? await flureChatRequest.getMessagesForSelectedAuditory(operatorId, { countries, genderIdentities })
          : { messages: [] };
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: false },
  );

  return { messagesState, fetchMessages };
};
