import React, { useState, PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';

import { FullSizeMedia } from 'src/components/Moderation/views/full-size-media';
import { MediaType } from 'src/types/user';

type Props = {
  src: string;
  mediaType: MediaType;
  children: JSX.Element;
};

const useStyles = makeStyles({
  imageBox: {
    cursor: 'zoom-in',
  },
});

export const ZoomImage: React.FC<PropsWithChildren<Props>> = ({ src, mediaType, children }) => {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const open = () => setOpened(true);

  return (
    <>
      <span onClick={open} className={classes.imageBox} aria-hidden="true">
        {children}
      </span>
      <FullSizeMedia isOpen={opened} setIsOpen={setOpened} source={src} mediaType={mediaType} />
    </>
  );
};
