import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexShrink: 0,
  },

  progressContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  progressText: {
    fontSize: '18px',
    color: '#020D3D',
    marginRight: '40px',
  },

  iconButton: {
    padding: 10,
  },

  inputContainer: {
    position: 'absolute',
    width: '280px',
    height: '36px',
    borderColor: 'transparent',
    borderRadius: '10px',
    backgroundColor: '#F2F2F2',
    boxShadow: 'none',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    left: 0,
    right: 0,
  },

  input: {
    flex: 1,
  },
});
