import { DependencyList, useEffect, useRef } from 'react';

type EffectCallback = (stopHandle: () => void) => void;

export const useEffectOnce = (func: EffectCallback, deps?: DependencyList) => {
  const didCalled = useRef(false);

  useEffect(() => {
    if (!didCalled.current) {
      const stopEffect = () => {
        didCalled.current = true;
      };

      func(stopEffect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
