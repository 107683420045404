import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '375px',
    height: '812px',
    

    display: 'flex',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },

  perfumeBG: {
    backgroundColor: '#D9BBF9',
  },

  whiteBG: {
    backgroundColor: '#fff',
  },

  topContainer: {
    display: 'flex',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
    flexDirection: 'column',
  },

  statusBar: {
    width: '375px',
    height: '44px',
    objectFit: 'contain',
  },

  screenHeader: {
    width: '375px',
    height: '44px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px 0 16px',
  },

  invitesIcon: {
    width: '24px',
    height: '24px',
  },

  filterIcon: {
    width: '28px',
    height: '28px',
  },

  screenTitle: {
    color: '#101014',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
  },

  contentContainer: {
    padding: '20px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },

  scrollContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    rowGap: '18px',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    overflow: 'unset !important',
  },

  loader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
