import { Media } from 'src/types/user';
import { GenderIdentityPreferences } from 'src/types/user/preferences';

export type MessageSenderProfile = {
  id: string;
  genderIdentity: GenderIdentityPreferences;
  location: {
    city: string;
    country: string;
  };
  name: string;
  birthday: {
    age: string;
  };
  media: Media[];
};

export type ChatModerationContentResponse = {
  senderId: string;
  recipientId: string;
  keyWordStartIndex: number;
  keyWordEndIndex: number;
  messageNumber: number;
  text: string;
  senderProfile: MessageSenderProfile;
};

export enum ChatModerationAction {
  Approve = 'approve',
  Scam = 'scam',
}

export enum ApiMessageDeliveryStatus {
  Unknown = 0,
  Delivered = 1,
  Unpaid = 2,
  Failed = 3,
}

export enum CardGameAction {
  Answer = 'answer',
  AcceptGame = 'acceptGame',
  End = 'end',
  Send = 'send',
  Skip = 'skip',
  Start = 'start',
}

export type CardGameMeta = {
  action?: CardGameAction;
  text?: string;
};

export type ApiMessage = {
  id: number;
  tag: string;
  sender: string;
  recipient: string;
  status: ApiMessageDeliveryStatus;
  timestamp: number;
  read?: boolean;
  text?: string;
  meta?: {
    Auto?: boolean;
    tariffication?: {
      final?: boolean;
      'blurred-without-subscription'?: boolean;
    };
    reference?: string;
    action?: {
      text: string;
      uri: string;
    };
    edited?: boolean;
    sticker?: string;
    'channel-message-id'?: number;
    game?: CardGameMeta;
  };
};

export type ChatMessagesResponse = ApiMessage[];

export type ChatModerationContentCountResponse = {
  count: number;
};

export type ChatModerationMessagesStopWords = {
  moderation: {
    contains: string[];
    match: string[];
  };
  scam: string[];
};
