import { useMemo } from 'react';

import { useModerationMedia } from 'src/services/moderation/raw/hooks';
import { RAW } from 'src/types/raw';

type Props = { contentId: string; contentType: RAW.ContentType; userId: RAW.User['id'] };
type MediaItem = RAW.RawPhoto | RAW.ProfilePhoto;

type Content = {
  type: RAW.ContentType;
  primaryBaseName?: string;
  secondaryBaseName?: string;
  mediaId: string;
};

export function useMediaContent({ contentId, contentType, userId }: Props) {
  const media = useModerationMedia(userId);

  const content = useMemo<Content>(() => {
    const findRawItemCb = (item: MediaItem) => item.type === 'raw' && item.id === contentId;
    const result: Content = { mediaId: contentId, type: contentType };

    if (media.value) {
      const { approvedRawPhotos, declinedPhotos } = media.value;
      if (contentType === RAW.ContentType.RAW) {
        const item = (approvedRawPhotos.find(findRawItemCb) || declinedPhotos.find(findRawItemCb)) as
          | RAW.RawPhoto
          | undefined;

        if (item) {
          result.primaryBaseName = item.primaryBaseName;
          result.secondaryBaseName = item.secondaryBaseName;
        }
      }

      return result;
    }

    return result;
  }, [media.value, contentType, contentId]);

  return content;
}
