import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  messageBlockContainer: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '200px',
    marginBottom: '15px',
  },

  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '335px',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
  },

  subtitle: {
    display: 'flex',
    justifySelf: 'flex-start',
    marginBottom: '24px',
  },

  messageContentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
  },

  inputsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
  },

  messageInput: {
    width: '100%',
  },

  rightContainer: {
    width: '375px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '67px',
  },
});
