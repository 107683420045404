import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  },

  progressContainer: {
    columnGap: '32px',
    display: 'flex',
  },

  progressText: {
    fontSize: '16px',
    fontFamily: 'Poppins',
    cursor: 'pointer',
    userSelect: 'none',
  },

  activeText: {
    color: '#EF7BF8',
  },

  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    opacity: 1,
  },

  checkbox: {
    width: 20,
    height: 20,
    margin: 0,
    marginRight: 8,
    accentColor: '#EF7BF8',
    cursor: 'pointer',
  },
});
