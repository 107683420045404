import { object, string, mixed, bool, TypeOf } from 'yup';

import { BonusCommission, Cities, Commission } from 'src/types/operator';
import { validCommission, validFollowBonusCommission, validStarterBonusCommission } from 'src/services/operator/config';

const validationSchema = object().shape({
  firstName: string().default('').required('Operator first name is required'),
  lastName: string().default('').required('Operator last name is required'),
  commission: mixed<Commission>().default(0.01).oneOf(validCommission).required('Commission is required'),
  starterBonusCommission: mixed<BonusCommission>()
    .default(0.1)
    .oneOf(validStarterBonusCommission)
    .required('Starter bonus commission is required'),
  followBonusCommission: mixed<BonusCommission>()
    .default(0.05)
    .oneOf(validFollowBonusCommission)
    .required('Follow bonus commission is required'),
  isReadyForExplicit: bool().default(false).required('isReadyForExplicit is required'),
  city: mixed<Cities>().default(Cities.Denver).oneOf(Object.values(Cities)).required('City is required'),
  isFreeMember: bool().default(false),
  freeMemberAnimatorClientId: string().when('isFreeMember', {
    is: true,
    then: (schema) => schema.required('Animator ID is required'),
  }),
  animatorCity: string().when('isFreeMember', {
    is: true,
    then: (schema) => schema.required('Animator City is required'),
  }),
  animatorCountry: string().when('isFreeMember', {
    is: true,
    then: (schema) => schema.required('Animator Country is required').length(2, 'Only 2 symbols (only abbreviation)'),
  }),
});

type ExcludeNever<T> = {
  [K in keyof T as T[K] extends never ? never : K]: T[K];
};
export type OperatorForm = ExcludeNever<TypeOf<typeof validationSchema>>;
export type OperatorFormKeys = keyof OperatorForm;

export default validationSchema;
