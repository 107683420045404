import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  header: {
    display: 'flex',
    width: '910px', // left + gap + right
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '30px',
  },

  backButton: {
    display: 'flex',
    alignItems: 'center',

    border: 'none',
    backgroundColor: 'transparent',
    padding: '0px',

    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },

  backIcon: {
    width: '24px',
    height: '24px',
    marginRight: '2px',
  },
});
