import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import { AuthUser } from 'src/types/auth';
import { Product } from 'src/types/product';
import { useAuth } from 'src/services/auth';

import xoxoLogo from './assets/xoxo-logo.svg';
import udatesLogo from './assets/udates-logo.svg';
import flureLogo from './assets/flure-logo.svg';
import magnetLogo from './assets/magnet-logo.svg';

import { useStyles } from './styles';

const getLogo = (me: AuthUser | null) => {
  switch (me?.realm) {
    case Product.Lovinga:
      return xoxoLogo;
    case Product.Once:
      return udatesLogo;
    case Product.Flure:
      return flureLogo;
    case Product.Magnet:
      return magnetLogo;
    default:
      return undefined;
  }
};

export const Logo = () => {
  const classes = useStyles();
  const { me } = useAuth();

  const logoLink = (
    <Link to="/">
      <img src={getLogo(me)} alt="logo" className={classes.logo} />
    </Link>
  );
  const text = `Homepage: ${me?.realm}`;
  const textLink = (
    <Button to="/" component={Link} color="inherit">
      {text}
    </Button>
  );

  return getLogo(me) ? logoLink : textLink;
};
