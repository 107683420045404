import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  topContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px',
  },

  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '14px',
  },

  titleText: {
    fontFamily: 'Poppins',
    color: 'black',
    fontSize: '16px',
    lineHeight: '24px',
    opacity: 0.4,
  },

  valueText: {
    fontFamily: 'Poppins',
    color: 'black',
    fontSize: '16px',
    lineHeight: '24px',
  },

  addEventButton: {
    width: '184px',
    height: '54px',
    borderRadius: '44px',
    backgroundColor: '#F2DAF5',
    fontFamily: 'Poppins',
    color: 'black',
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 500,
    borderWidth: 0,
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8,
    },
  },

  disabledButton: {
    backgroundColor: '#eee',
    cursor: 'not-allowed'
  }
});
