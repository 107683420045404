export type MessagePreview = {
  id?: number;
  text?: string;
  reference?: string;
  created?: string;
  meta: {
    buttonTitle?: string;
    buttonUrl?: string;
    componentsOrder?: MessageComponents[];
  };
};

export enum MessageComponents {
  Text = 'Text',
  Photo = 'Photo',
  Button = 'Button',
}

export const defaultComponentsOrder = Object.values(MessageComponents);

export const defaultMessageComponentsState = {
  [MessageComponents.Text]: false,
  [MessageComponents.Photo]: false,
  [MessageComponents.Button]: false,
};
