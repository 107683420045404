import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  primaryButton: {
    backgroundColor: '#883FFF',
    '&:hover': {
      backgroundColor: '#883FFF',
      opacity: 0.8,
    },
  },
  warningButton: {
    backgroundColor: '#ED654C',
    '&:hover': {
      backgroundColor: '#ED654C',
      opacity: 0.8,
    },
  },
});
