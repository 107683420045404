import { UserTagType } from 'src/types/user';
import { ApiPromoCodeData, PromoCodeData, PromoCodeType } from './types';

function sortObjectsByDateDesc(array: PromoCodeData[]) {
  return array.sort((a, b) => {
      const dateA = new Date(a.activeFrom).getTime();
      const dateB = new Date(b.activeFrom).getTime();

      return dateB - dateA;
  });
}

export const apiPromoCodeAdapter = (apiPromoCode: ApiPromoCodeData): PromoCodeData => {
  const { id, activeUntil = '', activeFrom = '', meta, benefits } = apiPromoCode || {};
  const { eventName, eventCity, stageName, shortLink, fullLink } = meta || {};
  const { ttl } = benefits?.tags?.[0] || {};

  return {
    id,
    eventName,
    eventCity,
    stageName,
    shortLink,
    fullLink,
    subTTL: Number(ttl.split('.')[0]),
    activeUntil: activeUntil.split('T')[0],
    activeFrom: activeFrom.split('T')[0],
  };
};

export const dataToApiPromoCodeAdapter = (promoCodeData: PromoCodeData): ApiPromoCodeData => {
  const { id, eventName, eventCity, stageName, shortLink, fullLink, subTTL, activeUntil, activeFrom } = promoCodeData;

  return {
    id,
    type: PromoCodeType.Many,
    activeUntil,
    activeFrom,
    benefits: {
      tags: [
        {
          tag: UserTagType.Subscription,
          ttl: `${subTTL}.00:00:00`,
        },
      ],
    },
    meta: {
      eventName,
      eventCity,
      stageName,
      shortLink,
      fullLink,
      comment: '',
    },
  };
};

export const apiPromoCodesAdapter = (apiPromoCodes: ApiPromoCodeData[]) => {
  return sortObjectsByDateDesc(apiPromoCodes.map(apiPromoCodeAdapter));
}
