import React from 'react';
import { Button, Typography, Modal, Box } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
};

export const ConfirmationModal = ({ isOpen, onConfirm, onReject }: Props) => {
  const classes = useStyles();

  return (
    <Modal open={isOpen} className={classes.modal}>
      <Box className={classes.modalContent}>
        <Typography className={classes.title}>Are you sure ?</Typography>
        <Button variant="contained" className={clsx(classes.button, classes.block)} onClick={onConfirm}>
          Block this user
        </Button>
        <Button variant="contained" className={clsx(classes.button, classes.cancel)} onClick={onReject}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};
