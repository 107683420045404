import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    gridTemplateColumns: 'repeat(auto-fill, minmax(800px, 1fr))',
  },

  textContentWrapper: { flexGrow: 0.4, flexBasis: '800px' },
  mediaContentWrapper: { flexGrow: 0.6, flexBasis: '500px' },
  textContent: { maxWidth: '80%' },

  separator: {
    height: 0,
    borderWidth: 5,
    borderStyle: 'solid',
    borderColor: '#EF7BF8',
    width: '80%',
    marginTop: 60,
    marginBottom: 32,
  },
});
