import React, { useCallback, useRef } from 'react';
import { useReportByKPI } from 'src/services/report/use-report-by-kpi';
import { KPIReportDataItem } from 'src/types/report/kpi';
import { DefaultReportFormFields } from 'src/types/report/common';
import { ExcelSaverButton } from 'src/components/common/ExcelSaverButton';
import { downloadKPIReportXlsx, getFileName } from 'src/services/report/kpi';
import { ReportForm } from '../common/Form';
import { KPIReportTable } from './Table';
import { ReportLayout } from '../common/ReportLayout';

export const ReportByKPIScreen = () => {
  const { reportByKPIState, fetchReportByKPI } = useReportByKPI();
  const formData = useRef<DefaultReportFormFields>();

  const submitReportRequest = useCallback(
    (values: DefaultReportFormFields) => {
      formData.current = values;
      return fetchReportByKPI(values);
    },
    [fetchReportByKPI],
  );

  return (
    <ReportLayout headerCaption="Report by KPI">
      <ReportForm formType="default" onSubmit={submitReportRequest} />
      <KPIReportTable data={reportByKPIState.value} />
      <ExcelSaverButton<KPIReportDataItem, DefaultReportFormFields | undefined>
        data={reportByKPIState.value}
        download={downloadKPIReportXlsx}
        getFileName={getFileName}
        filterData={formData.current}
      />
    </ReportLayout>
  );
};
