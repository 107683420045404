import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { LoginForm } from 'src/components/Auth/views/LoginForm';
import * as S from './styled';

export const LoginScreen = () => {
  return (
    <S.Wrapper>
      <Container component="main" maxWidth="xs">
        <div>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <LoginForm />
        </div>
      </Container>
    </S.Wrapper>
  );
};
