import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { debounce } from 'lodash';

import { noop } from 'src/utils/functions';

import { useStyles } from './styles';

type Props = {
  initialValue?: string;
  value?: string;
  onChange?: (event: ChangeEvent) => void;
  className?: string;
  multiline?: boolean;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  type?: string;
};

const TextInputView: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    initialValue,
    value,
    multiline = false,
    placeholder = '',
    className: classNameFromProps,
    onChange = noop,
    maxLength,
    disabled = false,
    type = 'text',
  } = props;
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

  const debouncedChangeHandler = useMemo(() => debounce(onChange, 300), [onChange]);

  useEffect(() => {
    if (!value && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [value]);

  useEffect(() => {
    if (initialValue && inputRef.current) {
      inputRef.current.value = initialValue;
    }
  }, [initialValue]);

  if (multiline) {
    return (
      <textarea
        ref={inputRef as React.RefObject<HTMLTextAreaElement>}
        className={clsx(classes.input, classes.textArea, classNameFromProps)}
        defaultValue={initialValue}
        onChange={debouncedChangeHandler}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
      />
    );
  }

  return (
    <input
      ref={inputRef as React.RefObject<HTMLInputElement>}
      type={type}
      className={clsx(classes.input, classNameFromProps)}
      defaultValue={initialValue}
      onChange={debouncedChangeHandler}
      placeholder={placeholder}
      maxLength={maxLength}
      disabled={disabled}
    />
  );
};

export const TextInput = React.memo(TextInputView);
