import { useCallback } from 'react';

import { RAW } from 'src/types/raw';

type Props = {
  moderationActions: RAW.ModerationAction[];
  setModerationActions: (action: RAW.ModerationAction[]) => void;
  setModerationAction: (action: RAW.ModerationAction) => void;
};

export const useActions = ({ moderationActions, setModerationAction, setModerationActions }: Props) => {
  const isPressed = (passedAction: RAW.ModerationAction['actionType']) =>
    moderationActions.some((action) => action.actionType === passedAction);

  const isNameReset = isPressed('resetName');
  const isWorkReset = isPressed('resetWork');
  const isBioReset = isPressed('resetBio');

  const undoAction = useCallback(
    (actionName: RAW.ModerationAction['actionType']) =>
      setModerationActions(moderationActions.filter((action) => action.actionType !== actionName)),
    [moderationActions, setModerationActions],
  );

  const resetNameAction = useCallback(() => {
    if (isNameReset) {
      undoAction('resetName');
    } else {
      setModerationAction({ actionType: 'resetName', actionValue: RAW.ModeratedValue.Name });
    }
  }, [isNameReset, setModerationAction, undoAction]);

  const resetWorkAction = useCallback(() => {
    if (isWorkReset) {
      undoAction('resetWork');
    } else {
      setModerationAction({ actionType: 'resetWork', actionValue: RAW.ModeratedValue.Work });
    }
  }, [isWorkReset, setModerationAction, undoAction]);

  const resetBioAction = useCallback(() => {
    if (isBioReset) {
      undoAction('resetBio');
    } else {
      setModerationAction({ actionType: 'resetBio', actionValue: RAW.ModeratedValue.Bio });
    }
  }, [isBioReset, setModerationAction, undoAction]);

  return {
    resetNameAction,
    resetWorkAction,
    resetBioAction,
    setModerationAction,
    isNameReset,
    isWorkReset,
    isBioReset,
  };
};
