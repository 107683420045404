import { useAsyncFn } from 'react-use';
import { useEffect, useMemo } from 'react';
import { MediaTag, User, UserDto } from 'src/types/user';
import { Http } from 'src/network/http';
import { getUrl } from './preload-photos';

export const useUserMedia = (user: User | UserDto | null | undefined, tags: MediaTag[]) => {
  const [photos, fetchPhotos] = useAsyncFn(async () => {
    if (!user || !user.media) {
      return [];
    }
    return Promise.allSettled(
      user.media
        .filter((el) => tags.some((tag) => el.tags.includes(tag)))
        .map(async (media) => {
          const response = await Http.shared().instance.get(getUrl(user.id, media.baseName), {
            responseType: 'blob',
          });
          return { url: URL.createObjectURL(response.data), tags: media.tags };
        }),
    );
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchPhotos().then();
    }
  }, [fetchPhotos, user]);

  const userMedia = useMemo(() => {
    if (!photos.value) {
      return [];
    }
    return photos.value.reduce((acc, response) => {
      if (response.status === 'fulfilled') {
        return [...acc, response.value];
      }
      return acc;
    }, [] as { url: string; tags: MediaTag[] }[]);
  }, [photos]);

  return { userMedia, isMediaLoading: photos.loading };
};
