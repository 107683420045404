import React from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

const Btn = ({ onClick, label }: { onClick: () => void; label: string }) => {
  const classes = useStyles();

  return (
    <button type="button" className={classes.actionBtn} onClick={onClick}>
      {label}
    </button>
  );
};

type Props = {
  onClick: () => void;
  label: string;
  value?: string;
  btnLabel: string;
  bigValueStyle?: boolean;
  rightLabel?: string;
};

export const TextField = ({ onClick, label, btnLabel, value, bigValueStyle, rightLabel }: Props) => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.textContentContainer}>
        <p className={classes.title}>{label}</p>
        <Btn label={btnLabel} onClick={onClick} />
        <p className={classes.rightLabel}>{rightLabel}</p>
      </Box>
      <p className={clsx(classes.name, classes.value, classes.work, bigValueStyle ? classes.name : undefined)}>
        {value}
      </p>
    </div>
  );
};
