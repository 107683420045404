import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    paddingBottom: '100px',
  },

  table: {
    borderCollapse: 'collapse',
    width: '100%',
    tableLayout: 'fixed',

    '& thead': {
      backgroundColor: '#EDECF0',

      '& th': {
        textAlign: 'left',
        padding: '10px 5px',

        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: 'black',
        opacity: 0.4,

        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },

    '& tbody': {
      '& tr': {
        borderBottom: '1px solid #C5BFD8',
        verticalAlign: 'top',

        '& td': {
          padding: '15px 5px',

          fontFamily: 'Poppins',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: 'black',

          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',

          '& input, & textarea': {
            width: '100%',
            maxWidth: '100%',
            padding: '8px',
            borderRadius: '6px',
            border: '1px solid black',

            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '24px',
            color: 'black',
          },
        },
      },
    },
  },

  loadingTr: {
    height: '80px',
    borderBottom: 'none !important',
  },

  loader: {
    position: 'absolute',
    right: 0,
    left: 0,
    margin: 'auto',
  },
});
