import React, { PropsWithChildren, createContext, useContext, useReducer } from 'react';

import { RAW } from 'src/types/raw';

import { ModerationI, State } from './types';
import { moderationReducer } from './reducer';

const initialState: State = {
  contentType: undefined,
  moderationActions: [],
  isSending: false,
  moderatedUsersCount: 0,
  statics: {
    questions: {
      data: null,
      loadState: RAW.LoadState.Initial,
    },
  },
  isBlockModalOpen: false,
  isQueueBlocked: false,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ModerationContext = createContext<ModerationI>();

function ModerationProvider<T>(props: PropsWithChildren<T>) {
  const [state, dispatch] = useReducer(moderationReducer, initialState);

  const moderationData = { state, dispatch };

  return <ModerationContext.Provider value={moderationData} {...props} />;
}

function useModerationContext() {
  return useContext(ModerationContext);
}

export { ModerationProvider, useModerationContext };
