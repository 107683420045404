import { useCallback } from 'react';

import { Key, KeyTitlesMapper, useKeyboardEventListener } from 'src/components/Moderation/utils/keyboard';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';

type Props = {
  resetNameAction: () => void;
  resetBioAction: () => void;
  resetWorkAction: () => void;
};
export const useKeyListener = ({ resetBioAction, resetNameAction, resetWorkAction }: Props) => {
  const keyPressListener = useCallback(
    (event: KeyboardEvent) => {
      if (!event.defaultPrevented) {
        ActionsLogger.addKeyCode(KeyTitlesMapper[event.keyCode]);

        switch (event.keyCode) {
          case Key.J:
            resetNameAction();
            break;
          case Key.F:
            resetBioAction();
            break;
          case Key.H:
            resetWorkAction();
            break;
          default:
            break;
        }
      }
      return undefined;
    },
    [resetBioAction, resetNameAction, resetWorkAction],
  );

  useKeyboardEventListener(keyPressListener);
};
