import React, { useCallback, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { format } from 'date-fns';

import { Question } from 'src/network/dailybuzz/types';

import { sliceCategory } from '../utils';
import * as S from './styled';

type Props = {
  question: Question;
  openCard: (id: number) => void;
  category?: string;
  withSettings: boolean;
};

export const Card = ({ question, category, openCard, withSettings }: Props) => {
  const onOpenCard = useCallback(() => {
    openCard(question.id);
  }, [openCard, question]);

  const formattedDate = useMemo(() => format(new Date(question.date), 'dd.MM.yyyy'), [question]);

  return (
    <S.Card key={question.id}>
      <S.Container>
        <S.FlexCenter>
          <S.CategoryText align="left">{sliceCategory(category)}</S.CategoryText>
          <S.QuestionText>{question.question}</S.QuestionText>
        </S.FlexCenter>
        <S.FlexCenter>
          <S.DateText>{formattedDate}</S.DateText>

          {withSettings && (
            <IconButton size="small" onClick={onOpenCard}>
              <Settings color="primary" />
            </IconButton>
          )}
        </S.FlexCenter>
      </S.Container>
    </S.Card>
  );
};
