import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { rawModerationRequest } from 'src/network/moderation/raw';
import { RAW } from 'src/types/raw';

type LoadFnProps = {
  contentType: RAW.ContentType.Default;
  userId: string;
};

type HookProps = {
  contentType?: RAW.ContentType;
  userId?: string;
};

const useSecondaryRAWModerationContent = (hookProps: HookProps) => {
  const [secondaryContent, fetchSecondaryContent] = useAsyncFn(
    async (props: LoadFnProps) => {
      try {
        const content = await rawModerationRequest.getRAWModerationMediaById(props.userId);

        return content;
      } catch (error) {
        const emptyObject: RAW.ModerationMedia = {
          approvedProfilePhotos: [],
          approvedRawPhotos: [],
          declinedPhotos: [],
        };

        return emptyObject;
      }
    },
    [hookProps.contentType],
    { loading: true },
  );

  useEffect(() => {
    if (hookProps.userId && hookProps.contentType && hookProps.contentType === RAW.ContentType.Default) {
      fetchSecondaryContent({ contentType: hookProps.contentType, userId: hookProps.userId });
    }
  }, [fetchSecondaryContent, hookProps.contentType, hookProps.userId]);

  return { secondaryContent };
};

type Props = {
  contentType?: RAW.ContentType;
  baseNames?: RAW.ProfilePhotos;
  contentId?: number;
  userDataLoading: boolean;
};

const useSecondaryProfileModerationContent = (props: Props) => {
  const [secondaryContent, setProfileSecondaryContent] = useState<{
    loading: boolean;
    value?: RAW.ProfilePhotos;
    contentId?: number;
  }>({ loading: true, value: undefined });

  const contentId = props?.contentId;

  if (contentId) {
    // если данные о пользователе грузятся
    if (props.userDataLoading) {
      // и текущее состояние === false
      if (secondaryContent.loading === false) {
        // сменить состояние на загружаемое
        setProfileSecondaryContent({ loading: true, value: undefined, contentId: undefined });
      }
    } else if (secondaryContent.contentId !== contentId) {
      setProfileSecondaryContent({ loading: false, value: props.baseNames, contentId });
    }
  }

  return { secondaryContent };
};

type HookProps2 = {
  contentType?: RAW.ContentType;
  userId?: string;
  contentId?: number;
  baseNames?: RAW.ProfilePhotos;
  userDataLoading: boolean;
};

const defaultSecondaryContent = { loading: true, value: undefined };

export const useSecondaryUserContentForModeration = (props: HookProps2): RAW.SecondaryContentTypeState => {
  const { secondaryContent: rawSecondaryContent } = useSecondaryRAWModerationContent(props);
  const { secondaryContent: profileSecondaryContent } = useSecondaryProfileModerationContent(props);

  const rawSecondaryContentValue = rawSecondaryContent.value;
  const profileSecondaryContentValue = profileSecondaryContent.value;

  if (!props.contentType) return defaultSecondaryContent;

  if (
    props.contentType === RAW.ContentType.Default &&
    rawSecondaryContent.loading === false &&
    rawSecondaryContentValue
  ) {
    return {
      loading: false,
      value: { secondaryContentType: RAW.SecondaryContent.RAWContent, content: rawSecondaryContentValue },
    };
  }

  if (
    props.contentType === RAW.ContentType.RAW &&
    profileSecondaryContent.loading === false &&
    profileSecondaryContentValue
  ) {
    return {
      loading: false,
      value: { secondaryContentType: RAW.SecondaryContent.ProfileContent, content: profileSecondaryContentValue },
    };
  }

  return defaultSecondaryContent;
};
