import { AxiosResponse } from 'axios';

import { UserDto } from 'src/types/user';

import { UserForVerificationResponse, VerificationContentCountResponse } from './types';

const getUserForVerification = (response: AxiosResponse<UserForVerificationResponse>) => {
  return response.data.userId;
};

const getVerificationPhoto = (response: AxiosResponse) => {
  const blob = new Blob([response.data], { type: 'image/jpeg' });
  return URL.createObjectURL(blob);
};

const getUserData = (response: AxiosResponse<UserDto>) => {
  return response.data;
};

const getVerificationContentCount = (response: AxiosResponse<VerificationContentCountResponse>) => {
  return response.data;
};

export const adapter = {
  getUserForVerification,
  getVerificationPhoto,
  getUserData,
  getVerificationContentCount,
};
