import { AxiosResponse } from 'axios';
import qs from 'qs';

import { Http } from 'src/network/http';
import {
  ChatModerationContentResponse,
  ChatModerationAction,
  ChatMessagesResponse,
  ChatModerationContentCountResponse,
  ChatModerationMessagesStopWords,
} from './types';

const getResponseData = <T>(response: AxiosResponse<T>) => {
  return response.data;
};

const getChatModerationContent = (operatorId: string) => {
  return Http.shared()
    .instance.get<ChatModerationContentResponse>(`moderation/content/${operatorId}/messages`)
    .then(getResponseData);
};

const getChatMessages = ({
  lower,
  higher,
  omit,
  select,
  sort,
}: {
  lower: string;
  higher: string;
  omit: number;
  select: number;
  sort: 'asc' | 'desc';
}) => {
  return Http.shared()
    .instance.get<ChatMessagesResponse>(
      `dialogs/messages/sender/${lower}/recipient/${higher}?${qs.stringify(
        { omit, select, sort },
        { arrayFormat: 'repeat' },
      )}`,
    )
    .then(getResponseData);
};

const getContentCount = (operatorId: string) => {
  return Http.shared()
    .instance.get<ChatModerationContentCountResponse>(`/moderation/content/${operatorId}/messages/count`)
    .then(getResponseData);
};

const sendChatModerationAction = (operatorId: string, userId: string, action: ChatModerationAction) => {
  return Http.shared().instance.put(`moderation/content/messages/${operatorId}/${userId}`, { action });
};

const getMessagesStopWords = () => {
  return Http.shared()
    .instance.get<ChatModerationMessagesStopWords>(`/moderation/content/messages/stopwords`)
    .then(getResponseData);
};

const updateMessagesStopWords = (body: ChatModerationMessagesStopWords) => {
  return Http.shared().instance.put(`/moderation/content/messages/stopwords`, body);
};

export const flureChatModerationRequest = {
  getChatModerationContent,
  getChatMessages,
  getContentCount,
  sendChatModerationAction,
  getMessagesStopWords,
  updateMessagesStopWords,
};
