import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  subtitle: {
    marginBottom: '20px',
  },

  filterParamContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '28px',
  },
});
