import React, { memo, useMemo, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { Media, MediaTag } from 'src/types/user';
import { ImageWithLogger } from 'src/components/Moderation/views/image-with-logger';
import {
  PhotoTransformation,
  useGetMediaSourceAuthed,
  useGetMediaSourceAuthedFullSize,
} from 'src/components/Moderation/utils/image-source';
import { FullSizeMediaType } from 'src/components/Moderation/views/flure/media-content/types';

import { TagsActionButtons } from '../tags-action-buttons';
import fullScreenIcon from './fullscreen-icon.png';
import { useStyles, mediaItemSize } from './styles';

type Props = {
  media: Media;
  userId: string;
  newMediaBaseName: string | undefined;
  openFullSizeMedia: (media: FullSizeMediaType) => void;
  isPhotoRejected?: boolean;
  mediaTags: Record<string, MediaTag[]>;
  setMediaTags: React.Dispatch<React.SetStateAction<Record<string, MediaTag[]>>>;
};

const MediaItemView = (props: Props) => {
  const {
    media,
    userId,
    newMediaBaseName,
    isPhotoRejected,
    openFullSizeMedia,
    mediaTags,
    setMediaTags,
  } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const classes = useStyles();
  const getMediaSource = useGetMediaSourceAuthed();
  const getMediaSourceFullSize = useGetMediaSourceAuthedFullSize();

  const { mediaSource, mediaSourceFullSize } = useMemo(() => {
    return {
      mediaSource: getMediaSource(
        userId,
        media.baseName,
        media.mediaType,
        true,
        mediaItemSize,
        PhotoTransformation.KeepAspectRatio,
      ),
      mediaSourceFullSize: getMediaSourceFullSize(userId, media.baseName, media.mediaType, true),
    };
  }, [getMediaSource, getMediaSourceFullSize, media.baseName, media.mediaType, userId]);

  const isNewMedia = newMediaBaseName?.startsWith(media?.baseName);
  const isSpicyMedia = mediaTags[media.baseName]?.includes(MediaTag.Spicy);
  const isMainMedia = mediaTags[media.baseName]?.includes(MediaTag.Thumbnail);
  const isRejected = isNewMedia && isPhotoRejected;

  return (
    <div className={classes.container}>
      <Box
        key={media.baseName}
        className={classes.relativeContainer}
      >
        <ImageWithLogger
          className={clsx(classes.smallMedia, isRejected ? classes.cursorDefault : classes.cursorPointer)}
          imageContainerStyle={clsx(
            isNewMedia && classes.mediaImageBorderRed,
            isMainMedia && (isNewMedia ? classes.mediaImageBorderBlackAfter : classes.mediaImageBorderBlackBefore),
            isSpicyMedia && (isNewMedia ? classes.mediaImageBorderYellowAfter : classes.mediaImageBorderYellowBefore),
          )}
          imageStyle={clsx(classes.smallMediaImage, isRejected && classes.rejectedMedia, !isLoaded && classes.hidden)}
          src={mediaSource}
          basename={media.baseName}
          alt="userMedia"
          onLoadEnd={() => setIsLoaded(true)}
          detectCelebrity={false}
        />
        {isLoaded && (
          <button
            type="button"
            className={classes.fullScreenButton}
            onClick={(e) => {
              e.stopPropagation();
              openFullSizeMedia({ source: mediaSourceFullSize, baseName: media.baseName });
            }}
          >
            <img src={fullScreenIcon} className={classes.fullScreenIcon} alt="fullScreenIcon" />
          </button>
        )}
        {!isLoaded && (
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <TagsActionButtons baseName={media.baseName} tags={mediaTags[media.baseName]} setMediaTags={setMediaTags} />
    </div>
  );
};

export const MediaItem = memo(MediaItemView);
