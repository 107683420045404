import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  icon: {
    position: 'absolute',
    top: 5,
    right: 10,
    fontSize: 20,
  },
});
