import { Gender, Media, UserTagType } from 'src/types/user';
import {
  GenderIdentityPreferences,
  LookingForPreferences,
  RelationshipGoalsPreferences,
  RelationshipPreferencePreferences,
  SexualOrientationPreferences,
  UserPreferences,
} from 'src/types/user/preferences';

export enum Filter {
  Age = 'age',
  Desires = 'desires',
  Height = 'height',
  GenderIdentity = 'gender-identity',
  Partner = 'partner',
  SexualOrientation = 'sexual-orientation',
  LookingFor = 'looking-for',
  Location = 'virtualLocation',
  RelationshipPreference = 'relationship-preference',
  RelationshipGoals = 'relationship-goals',
}

export type FilterRange = {
  min: number;
  max: number;
};

export enum FilterLocation {
  Current = 'current',
  Virtual = 'virtual',
}

export type FiltersState = {
  [Filter.Age]?: FilterRange;
  [Filter.Height]?: FilterRange;
  [Filter.LookingFor]?: (LookingForPreferences | GenderIdentityPreferences)[];
  [Filter.RelationshipPreference]?: RelationshipPreferencePreferences[];
  [Filter.RelationshipGoals]?: RelationshipGoalsPreferences[];
  [Filter.Location]?: FilterLocation | string;
};

export type ApiFeedUser = {
  userId: string;
  name: string;
  age: number;
  gender: Gender;
  [UserPreferences.GenderIdentity]: GenderIdentityPreferences;
  [UserPreferences.SexualOrientation]: SexualOrientationPreferences;
  [UserPreferences.SexualIdentity]: string[];
  [UserPreferences.Desires]: string[];
  [UserPreferences.LookingFor]: LookingForPreferences[];
  [UserPreferences.Relationship]: string;
  [UserPreferences.RelationshipPreference]: RelationshipPreferencePreferences;
  [UserPreferences.RelationshipGoals]: RelationshipGoalsPreferences[];
  hasPartner: boolean;
  media: Media[];
  distance: number;
  city: string;
  height: number;
  interests: string[];
  isPopular: boolean;
  isSubscriber: boolean;
  tags?: UserTagType[]; // empty array (security)
  attractiveness?: number;
};

export type LocationWithUsersCount = {
  country: string;
  city: string;
  count: number;
};

export type ApiGetLocations = {
  locations: LocationWithUsersCount[];
};

export enum SearchParam {
  Age = 'age',
  GenderIdentity = 'genderIdentity',
  GenderAtBirth = 'genderAtBirth',
  IsFirst = 'isFirst',
  isOperator = 'isOperator',
  SexualOrientationMale = 'sexualOrientationMal',
  SexualOrientationFemale = 'sexualOrientationFem',
  Partner = 'partner',
  Desires = 'desires',
  Height = 'height',
  PartnerGenderIdentityMan = 'partnerGenderIdentityMan',
  PartnerGenderIdentityWoman = 'partnerGenderIdentityWoman',
  VirtualLocation = 'virtualLocation',
  RelationshipPreferences = 'relationshipPreferences',
  RelationshipGoals = 'relationshipGoals',
}

