import { AxiosResponse } from 'axios';
import moment from 'moment';

import { MessagesReportDataItem } from 'src/types/report/messages';
import { MessagesReportDto } from './types';

const get = (response: AxiosResponse<MessagesReportDto>) => {
  return response.data.messages.map((item) => {
    const adaptedItem: MessagesReportDataItem = {
      ...item,
      id: String(item.timestamp),
      operatorId: String(item.operatorId),
      animatorId: String(item.animatorId),
      clientId: String(item.clientId),
      agencyName: item.agencyName || '-',
      operatorName: item.operatorName || '-',
      timestamp: new Date(item.timestamp),
      prettyDateTime: moment(item.timestamp).format('YYYY/MM/DD HH:mm'),
      sent: item.sent,
    };

    return adaptedItem;
  });
};

export const adapter = {
  get,
};
