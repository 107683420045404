import { AxiosResponse } from 'axios';
import { Http } from 'src/network/http';

import { ApiFeedUser, ApiGetLocations, FiltersState } from './types';

const getLocationsWithUsersCount = (operatorId: string, query: string) =>
  Http.shared()
    .instance.get(`users/${operatorId}/search/flure/locations/count?${query}`)
    .then((response: AxiosResponse<ApiGetLocations>) => response.data?.locations || []);

const getMyLocationsWithUsersCount = (operatorId: string, substring?: string) =>
  Http.shared()
    .instance.get(`users/${operatorId}/search/flure/locations/all`, {
      params: { omit: 0, select: 100, substring },
    })
    .then((response: AxiosResponse<ApiGetLocations>) => response.data?.locations || []);

const getSearchFilters = (operatorId: string) =>
  Http.shared()
    .instance.get(`/users/${operatorId}/search-filters`)
    .then((response: AxiosResponse<FiltersState>) => response.data);

const saveSearchFilters = (operatorId: string, body: FiltersState) =>
  Http.shared().instance.patch(`/users/${operatorId}/search-filters`, body);

const fetchUsers = (operatorId: string, query: string) =>
  Http.shared()
    .instance.get(`users/${operatorId}/search/flure?${query}`)
    .then((response: AxiosResponse<ApiFeedUser[]>) => response.data);

const resetSkippedUsers = (operatorId: string) => Http.shared().instance.delete(`users/${operatorId}/search/flure`);

const skipUser = (operatorId: string, userId: string) =>
  Http.shared().instance.post(`/users/${operatorId}/search/flure`, { skippedUserId: userId });

export const flureFeedRequest = {
  getLocationsWithUsersCount,
  getMyLocationsWithUsersCount,
  getSearchFilters,
  saveSearchFilters,
  fetchUsers,
  resetSkippedUsers,
  skipUser,
};
