import React, { memo } from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

type Props = {
  userId: string;
  declinedCount?: number;
};

const UserInfoView = (props: Props) => {
  const { userId, declinedCount } = props;
  const classes = useStyles();

  return (
    <div className={classes.userInfoContainer}>
      {declinedCount !== undefined && <span className={classes.redText}>{declinedCount + 1} time</span>}
      <Typography className={classes.userId}>ID {userId}</Typography>
    </div>
  );
};

export const UserInfo = memo(UserInfoView);
