import React from 'react';

import { Resources } from 'src/resources';
import {
  DEFAULT_AGE_RANGE,
  MAX_AGE,
  MIN_AGE,
  lookingForWithGenderIdentitiesOptions,
} from 'src/components/FlureFeed/constants';
import {
  TitleText,
  TitleTextSize,
  BubbleCheckBoxesList,
  RangeSlider,
  AutocompleteInput,
} from 'src/components/common/flure';
import { LocationOption } from 'src/components/FlureFeed/utils';
import { Filter, FiltersState } from 'src/network/flure-feed/types';

import { useStyles } from './styles';

type Props = {
  filters: FiltersState;
  selectedLookingForGenders: string[];
  setSelectedLookingForGenders: React.Dispatch<React.SetStateAction<string[]>>;
  locationOptions: LocationOption[];
  onLocationFilterChange: (option: LocationOption | null) => void;
  onAgeFilterChange: (value: number[]) => void;
};

const UserFilterParamsView = (props: Props) => {
  const classes = useStyles();
  const {
    filters,
    locationOptions,
    selectedLookingForGenders,
    setSelectedLookingForGenders,
    onLocationFilterChange,
    onAgeFilterChange,
  } = props;

  return (
    <>
      <div className={classes.filterParamContainer}>
        <TitleText
          className={classes.subtitle}
          text={Resources.strings.flureFeed.locationFilter}
          size={TitleTextSize.Medium}
        />
        <AutocompleteInput
          defaultValue={locationOptions[0]}
          options={locationOptions}
          onChange={onLocationFilterChange}
          value={locationOptions.find((it) => it.value === filters[Filter.Location])}
        />
      </div>
      <div className={classes.filterParamContainer}>
        <TitleText
          className={classes.subtitle}
          text={Resources.strings.flureFeed.lookingForGender}
          size={TitleTextSize.Medium}
        />
        <BubbleCheckBoxesList
          options={lookingForWithGenderIdentitiesOptions}
          selected={selectedLookingForGenders}
          setSelected={setSelectedLookingForGenders}
        />
      </div>
      <div className={classes.filterParamContainer}>
        <TitleText
          className={classes.subtitle}
          text={Resources.strings.flureFeed.lookingForAge}
          size={TitleTextSize.Medium}
        />
        <RangeSlider
          defaultValue={DEFAULT_AGE_RANGE}
          value={[filters[Filter.Age]?.min || MIN_AGE, filters[Filter.Age]?.max || MAX_AGE]}
          min={MIN_AGE}
          max={MAX_AGE}
          onChange={onAgeFilterChange}
        />
      </div>
    </>
  );
};

export const UserFilterParams = React.memo(UserFilterParamsView);
