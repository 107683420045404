import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'grid',
    alignSelf: 'flex-start',
    justifySelf: 'flex: start',
  },

  buttonInput: {
    border: '1px solid #000',
    borderRadius: '14px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    objectFit: 'contain',
  },

  smallInput: {
    height: '110px',
    width: '110px',
  },

  largeInput: {
    height: '192px',
    width: '100%',
    backgroundColor: '#F4F4F4',
  },

  placeholderText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#76767A',
    fontSize: '16px',
    lineHeight: '24px',
    position: 'absolute',
    justifySelf: 'center',
    alignSelf: 'center',
    pointerEvents: 'none',
  },

  removeImageButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    border: '0px',
    borderRadius: '14px',
    cursor: 'pointer',
    transition: 'opacity 0.3s',
    '&:hover': {
      opacity: 1,
    }
  },

  trashIcon: {
    width: '52px',
    height: '52px',
  },
});
