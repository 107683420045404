import React from 'react';
import Container from '@material-ui/core/Container';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTheme } from 'src/services/theme';

import { Menu } from '../Menu';
import * as S from './styled';

type Props = {
  children: JSX.Element | JSX.Element[];
  containerSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
};

export const Layout = ({ children, containerSize = 'md' }: Props) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <S.Wrapper>
        <S.MenuWrapper>
          <Menu />
        </S.MenuWrapper>
        <Container component="main" maxWidth={containerSize}>
          {children}
        </Container>
      </S.Wrapper>
    </ThemeProvider>
  );
};
