import React from 'react';
import Typography from '@material-ui/core/Typography';

import { Layout } from 'src/components/App/views/Layout';
import { VerificationWidget } from 'src/components/Verification/views/VerificationWidget';
import { VerificationWidgetFlure } from 'src/components/Verification/views/VerificationWidgetFlure';
import { VerificationWidgetMagnet } from 'src/components/Verification/views/VerificationWidgetMagnet';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';

export const VerificationScreen = () => {
  const { me } = useAuth();
  const flureProduct = me?.realm === Product.Flure;
  const magnetProduct = me?.realm === Product.Magnet;

  if (flureProduct) {
    return (
      <Layout containerSize="xl">
        <VerificationWidgetFlure />
      </Layout>
    );
  }

  if (magnetProduct) {
    return (
      <Layout containerSize="xl">
        <VerificationWidgetMagnet />
      </Layout>
    );
  }

  return (
    <Layout>
      <Typography component="h1" variant="h5">
        Photo verification
      </Typography>
      <VerificationWidget />
    </Layout>
  );
};
