import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { Button, Paper } from '@material-ui/core';
import { useAuth } from 'src/services/auth';
import { moderationRequest } from 'src/network';
import { noop } from 'src/utils/functions';
import { ActionType } from 'src/types/moderation';
import { ConfirmationModal } from 'src/components/common/confirmation-modal';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';
import { LoggerMessages } from 'src/infrastructure/loggers/datadog';

import { useStyles } from './styles';

type Props = {
  userId: string;
  isScammed?: boolean;
  setIsScammed?: (isScammed: boolean) => void;
};

export const Actions = ({ userId, isScammed, setIsScammed }: Props) => {
  const { me } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const classes = useStyles();

  const toggleModal = useCallback(() => {
    setIsModalOpen((old) => !old);
  }, []);

  const toggleScam = useCallback(() => {
    const operatorId = me?.id || '';
    const scamPromise = isScammed
      ? moderationRequest.sendAdminModerationActions(operatorId, userId, [{ actionType: ActionType.Unscam }])
      : moderationRequest.sendAdminModerationActions(operatorId, userId, [{ actionType: ActionType.Scam }]);
    scamPromise
      .then((response) => {
        ActionsLogger.log(LoggerMessages.SendAdminUserModerationActions, {
          actions: isScammed ? [{ actionType: ActionType.Unscam }] : [{ actionType: ActionType.Scam }],
          product: me?.realm || '',
          operatorId,
          userId,
          response,
        });
        return setIsScammed && setIsScammed(!isScammed);
      })
      .catch(noop)
      .finally(() => {
        toggleModal();
      });
  }, [isScammed, me?.id, me?.realm, setIsScammed, toggleModal, userId]);

  return (
    <Paper elevation={3} className={classes.container}>
      <ConfirmationModal isOpen={isModalOpen} onConfirm={toggleScam} onReject={toggleModal} />
      <Button
        variant="contained"
        className={clsx(classes.moderationButton, classes.warningButton, classes.largeButton)}
        onClick={toggleModal}
        disabled={isScammed}
      >
        Scam
      </Button>
    </Paper>
  );
};
