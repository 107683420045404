import { RAW } from 'src/types/raw';

import { Actions } from './action-creators';
import {
  CHANGE_CONTENT_TYPE,
  LOAD_QUESTION_CONFIG_FULFILLED,
  LOAD_QUESTION_CONFIG_PENDING,
  LOAD_QUESTION_CONFIG_REJECTED,
  RESET_MODERATION_ACTIONS,
  SET_IS_SENDING,
  SET_MODERATED_COUNT,
  SET_MODERATION_ACTION,
  SET_MODERATION_ACTIONS,
  TOGGLE_BLOCK_MODAL_STATE,
  SET_QUEUE_BLOCK,
} from './actions';
import { State } from './types';

export function moderationReducer(state: State, action: Actions): State {
  switch (action.type) {
    case CHANGE_CONTENT_TYPE:
      return { ...state, contentType: action.contentType };
    case SET_MODERATION_ACTION:
      return { ...state, moderationActions: [...state.moderationActions, action.moderatedAction] };
    case SET_MODERATION_ACTIONS:
      return { ...state, moderationActions: action.moderatedActions };
    case RESET_MODERATION_ACTIONS:
      return { ...state, moderationActions: [] };
    case SET_IS_SENDING:
      return { ...state, isSending: action.payload };
    case SET_MODERATED_COUNT:
      return { ...state, moderatedUsersCount: action.payload };

    case LOAD_QUESTION_CONFIG_PENDING:
      return {
        ...state,
        statics: { ...state.statics, questions: { ...state.statics, data: null, loadState: RAW.LoadState.Pending } },
      };
    case LOAD_QUESTION_CONFIG_REJECTED:
      return {
        ...state,
        statics: { ...state.statics, questions: { ...state.statics, data: null, loadState: RAW.LoadState.Rejected } },
      };
    case LOAD_QUESTION_CONFIG_FULFILLED:
      return {
        ...state,
        statics: { ...state.statics, questions: { data: action.payload, loadState: RAW.LoadState.Fulfilled } },
      };
    case TOGGLE_BLOCK_MODAL_STATE:
      return { ...state, isBlockModalOpen: action.payload };
    case SET_QUEUE_BLOCK:
      return { ...state, isQueueBlocked: action.payload };

    default:
      return state;
  }
}
