import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  textCommon: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#101014',
    whiteSpace: 'pre-wrap',

    '& a': {
      color: '#5BADFF',
    },
  },

  smallText: {
    fontSize: '14px',
    lineHeight: '18px',
  },

  mediumText: {
    fontSize: '16px',
    lineHeight: '24px',
  },

  largeText: {
    fontSize: '18px',
    lineHeight: '28px',
  },

  infoText: {
    color: '#76767A',
  },
});
