import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
  },

  progressContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  progressText: {
    fontSize: '18px',
    color: '#020D3D',
    marginRight: '40px',
  },
});
