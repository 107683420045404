import { Http } from 'src/network/http';

type QuestionsConfigDto = {
  onboardSets: Array<{
    language: string;
    questions: Array<string>;
  }>;
};

const getAll = () => {
  return Http.shared()
    .instance.get<QuestionsConfigDto>(`/magnet/questions/config`)
    .then((response) => response.data as QuestionsConfigDto);
};

export const questionsRequest = { getAll };
