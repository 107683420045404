import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  hintsContainer: {
    marginTop: '24px',
    width: '100%',
    padding: '10px 15px',
    border: '1px solid',
    borderRadius: '14px',
    backgroundColor: 'white',

    '& p': {
      fontFamily: 'Poppins',
      fontSize: '16px',
      margin: '5px 0px',
    },
  },
});
