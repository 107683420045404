import { useAsyncFn } from 'react-use';
import { DefaultReportFormFields } from 'src/types/report/common';
import { reportRequest } from 'src/network/report';
import { GetActivityReportRequestParams } from 'src/network/report/activity/types';
import moment from 'moment';
import { Product } from 'src/types/product';
import { useAuth } from '../auth';

import { convertBaseFormFieldsToQueryParams } from './helpers';

function convertFormFieldsToQueryParams(
  fields: DefaultReportFormFields,
  realm: Product,
): GetActivityReportRequestParams {
  const [from, to] = fields.rangeDate.split(':');

  const baseParams = convertBaseFormFieldsToQueryParams(fields, realm);

  return {
    ...baseParams,
    skip: 0 /* hardcoded for now */,
    limit: 1000000 /* hardcoded for now, implemented on the api for the future optimization of reports */,
    from,
    to: moment(to).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    agencyTechName: fields.agencyTechName ? String(fields.agencyTechName) : undefined,
    operatorId: fields.operatorId ? String(fields.operatorId) : undefined,
  };
}

export const useReportByActivity = () => {
  const { me } = useAuth();
  const realm = me?.realm;

  const [reportByActivityState, fetchReportByActivity] = useAsyncFn(
    async (params: DefaultReportFormFields) => {
      const queryParams = convertFormFieldsToQueryParams(params, realm || Product.Once);
      return reportRequest.activity.get(queryParams);
    },
    [realm],
  );

  return { reportByActivityState, fetchReportByActivity };
};
