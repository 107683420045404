import { FilesData, MediaTag, MediaType, User, UserDto } from 'src/types/user';
import { getMediaSource } from 'src/components/Moderation/utils/image-source';

export const udatesUserAdapter = (apiUser: UserDto, photoData: FilesData) => {
  const { id, name, about, tags, media, gender, occupation, birthday } = apiUser;

  const thumbnailMedia = photoData.files.find((el) => el.tags.includes(MediaTag.Thumbnail));
  const thumbnail = thumbnailMedia?.basename;
  const thumbnailType = thumbnailMedia?.mediatype.startsWith('video') ? MediaType.Video : MediaType.Photo;
  const thumbnailSource = thumbnailMedia ? getMediaSource(id, thumbnailMedia.basename, thumbnailMedia.mediatype) : '';

  const user: User = {
    id,
    name: name?.trim() || '',
    about,
    gender,
    tags,
    media,
    thumbnail,
    thumbnailType,
    thumbnailSource,
    photoData,
    occupation,
    birthday,
  };

  return user;
};
