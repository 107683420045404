import React from 'react';
import { Box } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { useStyles } from './styles';
import { getDictionaryValues, interestsDict } from './interests-dictionary';

type Props = {
  interests?: string[];
};

export const UserInterests: React.FC<Props> = (props) => {
  const { interests = [] } = props;
  const classes = useStyles();
  const interestsLabels = getDictionaryValues(interests, interestsDict);

  return (
    <Box className={classes.container}>
      {!isEmpty(interestsLabels) ? (
        interestsLabels.map((interest) => (
          <div key={interest} className={classes.interestBubble}>
            {interest}
          </div>
        ))
      ) : (
        <span className={classes.emptyValue}>-</span>
      )}
    </Box>
  );
};
