import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  smallCell: {
    width: '70px',
  },

  mediumCell: {
    width: '140px',
  },
  
  dateCell: {
    width: '125px',
  },
});
