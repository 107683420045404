import { Http } from 'src/network/http';
import { AnimatorsDto } from './types';
import { adapter } from './adapter';

const getAll = (realm: string) => {
  return Http.shared()
    .instance.get<AnimatorsDto>('/reports/animators', { params: { realm } })
    .then(adapter.getAll);
};

const linkWithAgency = (animatorId: string, agencyTechName: string | null, realm: string) => {
  return Http.shared().instance.put(`/multichat/animators/${animatorId}`, { agencyTechName, realm });
};

const activate = (animatorId: string, { country, city, realm }: { country: string; city: string; realm: string }) => {
  return Http.shared().instance.put(`/multichat/animators/${animatorId}/activate`, {
    country: country.toUpperCase(),
    city,
    realm,
  });
};

export const animatorRequest = {
  getAll,
  linkWithAgency,
  activate,
};
