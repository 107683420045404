import React, { ImgHTMLAttributes } from 'react';
import clsx from 'clsx';

import { RAW } from 'src/types/raw';
import { ImageWithLogger } from 'src/components/Moderation/views/raw/image-with-logger';
import { getMediaSourceRAW, getRAWMediaSourceRAW } from 'src/components/Moderation/utils/image-source';
import { Logger, LoggerServices, RAWLoggerMessages } from 'src/infrastructure/loggers/datadog';
import { Product } from 'src/types/product';

import { useStyles } from './styles';

type Props = {
  contentType?: RAW.ContentType;
  content: Pick<RAW.ModerationContent['content'], 'mediaId' | 'primaryBaseName' | 'secondaryBaseName'>;
  userId: RAW.User['id'];
  onPhotoPress: (sources: string[]) => void;
};

export const PhotoWithWrapper = ({
  onPhotoPress,
  src,
  alt,
  label,
}: ImgHTMLAttributes<HTMLImageElement> & {
  label?: string;
  onPhotoPress: (sources: string[]) => void;
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.wrapper}
      aria-hidden="true"
      onClick={() => {
        if (src) {
          onPhotoPress([src]);
        } else {
          Logger.log({
            service: LoggerServices.Moderation,
            message: RAWLoggerMessages.CouldNotFindSourceForImage,
            product: Product.RAW,
            payload: { uri: src },
          });
        }
      }}
    >
      <ImageWithLogger label={label} src={src} alt={alt} />
    </div>
  );
};

export const ModerationPhoto = ({ contentType, userId, content, onPhotoPress }: Props) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.container,
        contentType === RAW.ContentType.Default ? classes.containerForSinglePhoto : undefined,
      )}
    >
      {contentType === RAW.ContentType.Default ? (
        <PhotoWithWrapper
          onPhotoPress={onPhotoPress}
          src={getMediaSourceRAW(userId, content.mediaId)}
          alt="mainPhoto"
        />
      ) : (
        <>
          <PhotoWithWrapper
            onPhotoPress={onPhotoPress}
            label="Front Photo"
            src={getRAWMediaSourceRAW(userId, content.primaryBaseName!)}
            alt="mainPhoto"
          />
          <PhotoWithWrapper
            onPhotoPress={onPhotoPress}
            label="Back Photo"
            src={getRAWMediaSourceRAW(userId, content.secondaryBaseName!)}
            alt="secondaryPhoto"
          />
        </>
      )}
    </div>
  );
};
