import { useCallback } from 'react';
import { userRequest } from 'src/network';
import { userRAWRequest } from 'src/network/user/raw';
import { useAuth } from 'src/services/auth';

export const userCachedPhotoSize = '.560x1212';
export const verificationPhotoSize = '.346x461';
export const flureModerationPhotoSize = '.252x428';

export enum PhotoTransformation {
  DetectFace = 1,
  KeepAspectRatio = 2,
}

export const transformations = {
  [PhotoTransformation.DetectFace]: '.thumb-fd',
  [PhotoTransformation.KeepAspectRatio]: '.gallery',
} as const;

export const useGetMediaSourceAuthed = () => {
  const { me } = useAuth();
  return useCallback(
    (
      userId: string,
      baseName: string,
      mediaType: string,
      isPrivate?: boolean,
      size?: string,
      transformation?: PhotoTransformation,
    ) => {
      const token = isPrivate ? me?.token : undefined;
      return mediaType?.startsWith('image')
        ? userRequest.getUserPhoto(
            userId,
            baseName,
            size || userCachedPhotoSize,
            token,
            transformation ? transformations[transformation] : '',
          )
        : userRequest.getUserVideo(userId, baseName);
    },
    [me?.token],
  );
};

export const useGetMediaSourceAuthedFullSize = () => {
  const { me } = useAuth();
  return useCallback(
    (userId: string, baseName: string, mediaType: string, isPrivate?: boolean) => {
      const token = isPrivate ? me?.token : undefined;
      return mediaType?.startsWith('image')
        ? userRequest.getUserPhotoFullSize(userId, baseName, token)
        : userRequest.getUserVideo(userId, baseName);
    },
    [me?.token],
  );
};

export const getMediaSourceFullSize = (userId: string, baseName: string, mediaType: string, token?: string) => {
  return mediaType?.startsWith('image')
        ? userRequest.getUserPhotoFullSize(userId, baseName, token)
        : userRequest.getUserVideo(userId, baseName);
};

export const getMediaSource = (userId: string, baseName: string, mediaType: string, token?: string) => {
  return mediaType?.startsWith('image')
    ? userRequest.getUserPhoto(userId, baseName, userCachedPhotoSize, token)
    : userRequest.getUserVideo(userId, baseName);
};

export const getMediaSourceRAW = (userId: string, baseName: string) => {
  return userRAWRequest.getUserPhoto(userId, baseName);
};

export const getMediaSourceRAWForSend = (userId: string, baseName: string) => {
  return userRAWRequest.getUserPhoto(userId, baseName);
};

export const getRAWMediaSourceRAW = (userId: string, partitionId: string) => {
  return userRAWRequest.getRAWPhoto(userId, partitionId);
};
