import { makeStyles } from '@material-ui/core';

const imageHeight = 210;
const imageWidth = 122;
const imageHeightPXs = `${imageHeight}px`;
const smallImageWidth = `${imageWidth}px`;
export const mediaItemSize = `.${2 * imageWidth}x${2 * imageHeight}`;

const commonBorderAfterStyles = {
  content: '""',
  position: 'absolute',
  top: '5px',
  left: '5px',
  right: '5px',
  bottom: '10px',
  zIndex: 1,
};

const commonBorderBeforeStyles = {
  content: '""',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: '5px',
  zIndex: 2,
};

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
  },

  mediaImageBorderRed: {
    '&::before': {
      ...commonBorderBeforeStyles,
      border: '5px solid #F31D34',
    },
  },

  mediaImageBorderBlackAfter: {
    '&::after': {
      ...commonBorderAfterStyles,
      border: '5px solid black',
    },
  },

  mediaImageBorderBlackBefore: {
    '&::after': {
      ...commonBorderBeforeStyles,
      border: '5px solid black',
    },
  },

  mediaImageBorderYellowAfter: {
    '&::after': {
      ...commonBorderAfterStyles,
      border: '5px solid #FBA704',
    },
  },

  mediaImageBorderYellowBefore: {
    '&::after': {
      ...commonBorderBeforeStyles,
      border: '5px solid #FBA704',
    },
  },

  rejectedMedia: {
    opacity: 0.4,
  },

  mediaName: {
    fontFamily: 'SFCompactDisplay',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    textAlign: 'center',
    color: '#020D3D',
    position: 'absolute',
    top: '-27px',
    zIndex: 2,
  },

  cursorPointer: {
    cursor: 'pointer',

    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },

  cursorDefault: {
    cursor: 'default',

    '&:hover': {
      cursor: 'default',
      opacity: 1,
    },
  },

  relativeContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  smallMedia: {
    height: imageHeightPXs,
    width: smallImageWidth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  smallMediaImage: {
    height: imageHeightPXs,
    maxHeight: imageHeightPXs,
    width: smallImageWidth,
    objectFit: 'contain',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },

  hidden: {
    visibility: 'hidden',
  },

  loaderContainer: {
    position: 'absolute',
    marginTop: '-5px',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  fullScreenButton: {
    width: '20px',
    height: '20px',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 2,
    bottom: '11px',
    right: '7px',
    padding: 0,

    '&:hover': {
      opacity: 0.8,
    },
  },

  fullScreenIcon: {
    width: '20px',
    height: '20px',
  },
});
