import React, { useEffect, useMemo } from 'react';
import { Box, Button } from '@material-ui/core';
import moment from 'moment';

import { RAW } from 'src/types/raw';
import { useStore } from 'src/context/raw/moderation/hooks';

import { TextField } from '../text-field';

import { useKeyListener, useActions, useQuestions } from './hooks';
import { useStyles } from './styles';

type Props = {
  user: RAW.UserPreset;
  onBlock: () => void;
  operatorId: string;
  contentCreationTime?: string;
};

export const TextContent = ({ user, onBlock, operatorId, contentCreationTime }: Props) => {
  const classes = useStyles();

  const { moderationActions, setModerationAction, setModerationActions, statics } = useStore(operatorId);

  const { setQuestions, questions, removeQuestion } = useQuestions({
    moderationActions,
    setModerationAction,
    setModerationActions,
  });
  const { resetNameAction, resetWorkAction, resetBioAction, isNameReset, isWorkReset, isBioReset } = useActions({
    moderationActions,
    setModerationAction,
    setModerationActions,
  });

  const age = useMemo(() => {
    try {
      return moment().diff(user.profile.birthday, 'years', false);
    } catch (error) {
      return user.profile.birthday;
    }
  }, [user.profile.birthday]);

  const contentLabel = useMemo(() => {
    try {
      if (!contentCreationTime) return '';

      const minutes = moment().diff(contentCreationTime, 'minutes', false);

      return `Queue time: ${Math.floor(minutes / 60)}h${minutes % 60}m`;
    } catch (error) {
      return '';
    }
  }, [contentCreationTime]);

  useKeyListener({ resetNameAction, resetWorkAction, resetBioAction });

  useEffect(() => {
    setQuestions(user.profile.questions);
  }, [setQuestions, user.profile.questions]);

  return (
    <Box className={classes.wrapper}>
      <TextField
        label="Name"
        btnLabel={isNameReset ? 'Undo reset name (J)' : 'Reset name (J)'}
        onClick={resetNameAction}
        value={`${isNameReset ? RAW.ModeratedValue.Name : user.profile.name}, ${age}`}
        bigValueStyle
        rightLabel={contentLabel}
      />
      <TextField
        label="Work"
        btnLabel={isWorkReset ? 'Undo reset work (H)' : 'Reset work (H)'}
        onClick={resetWorkAction}
        value={isWorkReset ? RAW.ModeratedValue.Work : user.profile.basics.work}
      />
      <TextField
        label="Bio"
        btnLabel={isBioReset ? 'Undo reset bio (F)' : 'Reset bio (F)'}
        onClick={resetBioAction}
        value={isBioReset ? RAW.ModeratedValue.Bio : user.profile.bio}
      />
      {questions.map((el) => (
        <TextField
          key={el.question}
          label={
            statics.questions.loadState === RAW.LoadState.Fulfilled
              ? statics.questions.data?.[el.question]
              : el.question
          }
          btnLabel="Delete"
          onClick={() => removeQuestion(el.question)}
          value={el.answer}
        />
      ))}
      <div className={classes.horizontal}>
        <Button type="button" className={classes.block} onClick={onBlock}>
          Block profile
        </Button>
        <span className={classes.userId}>ID: {user.profile.id}</span>
      </div>
    </Box>
  );
};
