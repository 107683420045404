import { useCallback } from 'react';

import { Key, KeyTitlesMapper, useKeyboardEventListener } from 'src/components/Moderation/utils/keyboard';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';

type Props = {
  nextProfile: () => void;
  resetOccupation?: () => void;
  adultContent: () => Promise<void>;
  noUser?: () => void;
  unApprove?: () => void;
  trash: () => void;
  undo?: () => void;
  setScam: () => void;
};
export const useKeyListener = (props: Props) => {
  const { undo, nextProfile, trash, adultContent, noUser, setScam } = props;
  const keyPressListener = useCallback(
    (event: KeyboardEvent) => {
      if (!event.defaultPrevented) {
        ActionsLogger.addKeyCode(KeyTitlesMapper[event.keyCode]);

        switch (event.keyCode) {
          case Key.Z:
          case Key.Backspace:
            if (undo) {
              undo();
            }
            break;
          case Key.One:
          case Key.Numpad1:
            setScam();
            break;
          case Key.Two:
          case Key.Numpad2:
            if (trash) {
              trash();
            }

            break;
          case Key.Three:
          case Key.Numpad3:
            if (adultContent) {
              adultContent();
            }
            break;
          case Key.Four:
          case Key.Numpad4:
            if (noUser) {
              noUser();
            }
            break;

          case Key.Space:
            nextProfile();
            break;
          default:
            break;
        }
      }
      return undefined;
    },
    [undo, setScam, trash, adultContent, noUser, nextProfile],
  );

  useKeyboardEventListener(keyPressListener);
};
