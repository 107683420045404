import { useCallback } from 'react';
import { useAsyncFn } from 'react-use';
import moment from 'moment';

import { reportRequest } from 'src/network';
import { GetCommissionsReportRequestParams } from 'src/network/report/commissions/types';
import { CommissionsReportFormFields } from 'src/types/report/commissions';
import { useAuth } from 'src/services/auth';
import { getRealm, BackendAlias } from 'src/utils/realm';

import { downloadCommissionsReportXlsxByRealm, getFileName } from './commissions';
import { convertBaseFormFieldsToQueryParams } from './helpers';

function convertFormFieldsToQueryParams(
  fields: CommissionsReportFormFields,
  realm: BackendAlias,
): GetCommissionsReportRequestParams {
  const [from, to] = fields.rangeDate.split(':');

  const baseParams = convertBaseFormFieldsToQueryParams(fields, realm);

  const params: GetCommissionsReportRequestParams = {
    ...baseParams,
    agencyTechName: fields.agencyTechName || null,
    operator: fields.operatorId ? String(fields.operatorId) : null,
    groupBy: fields.groupBy || null,
    from,
    to: moment(to).add(1, 'day').format('YYYY-MM-DD'),
  };

  return params;
}

export const useReportByCommissions = () => {
  const { me } = useAuth();

  const realm = getRealm(me?.realm);

  const [reportByCommissionsState, fetchReportByCommissions] = useAsyncFn(
    async (params: CommissionsReportFormFields) => {
      const queryParams = convertFormFieldsToQueryParams(params, realm);
      return reportRequest.commissions.getByOperators(queryParams);
    },
    [realm],
  );

  const downloadCommissionsReportXlsx = useCallback(
    (payload, fileName) => {
      downloadCommissionsReportXlsxByRealm(me?.realm, payload, fileName);
    },
    [me?.realm],
  );

  return {
    reportByCommissionsState,
    fetchReportByCommissions,
    getFileName,
    downloadCommissionsReportXlsx,
  };
};
