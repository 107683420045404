import React from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

export enum FilterType {
  ActiveEvents = 'ActiveEvents',
  InActiveEvents = 'InActiveEvents',
}

type Props = {
  activeFilterType: FilterType | undefined;
  onFilterPress: (filterType: FilterType) => () => void;
};

export const FilterButtons = React.memo(({ activeFilterType, onFilterPress }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.filterButtonsContainer}>
      <button
        type="button"
        className={clsx(
          classes.filterButton,
          activeFilterType === FilterType.ActiveEvents && classes.activeFilterButton,
        )}
        onClick={onFilterPress(FilterType.ActiveEvents)}
      >
        Active Events
      </button>
      <button
        type="button"
        className={clsx(
          classes.filterButton,
          activeFilterType === FilterType.InActiveEvents && classes.activeFilterButton,
        )}
        onClick={onFilterPress(FilterType.InActiveEvents)}
      >
        Inactive Events
      </button>
    </div>
  );
});
