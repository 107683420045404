import { makeStyles } from '@material-ui/core';

const imageSize = '291px';

export const useStyles = makeStyles({
  verificationPhotoContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 16px',
  },

  mediaName: {
    fontSize: 18,
    color: 'black',
    fontWeight: 'bold',
    marginBottom: 14,
    textAlign: 'center',
  },

  photoWithLoader: {
    position: 'relative',
    height: imageSize,
    width: imageSize,
  },

  verificationImageContainer: {
    height: imageSize,
    width: imageSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginTop: '3px',
    marginRight: '0px',
  },

  verificationImage: {
    height: imageSize,
    maxHeight: imageSize,
    width: imageSize,
    objectFit: 'contain',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },

  hidden: {
    visibility: 'hidden',
  },

  loaderContainer: {
    position: 'absolute',
    top: 0,
    height: imageSize,
    width: imageSize,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
