import { Http } from 'src/network/http';
import { GetCommissionsReportRequestParams, ReportCommissionsByOperatorsDto } from './types';
import { adapter } from './adapter';

const getByOperators = (params: GetCommissionsReportRequestParams) => {
  return Http.shared()
    .instance.get<ReportCommissionsByOperatorsDto>(`/reports/commissions/operators`, { params })
    .then(adapter.getByOperators);
};

export const commissionReportsRequest = {
  getByOperators,
};
