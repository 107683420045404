import { makeStyles } from '@material-ui/core';

const containerHorizontalPadding = 17;
const imageWidth = 122;
const imagesColumnGap = 4;
const smallMediaContainerMinWidth = `${122 + 2 * containerHorizontalPadding}px`;
const smallMediaContainerMaxWidth = `${6 * imageWidth + 5 * imagesColumnGap + 2 * containerHorizontalPadding}px`;

export const useStyles = makeStyles({
  mediaWithInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  warningLabel: {
    display: 'flex',
    alignSelf: 'flex-start',
    padding: '0px 16px',
    borderRadius: '5px',
    marginBottom: '15px',
    backgroundColor: '#EA4551',
    color: 'white',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 500,
    opacity: 0,
  },

  warningLabelOpacity: {
    opacity: 1,
  },

  mediaBoard: {
    minWidth: smallMediaContainerMinWidth,
    width: smallMediaContainerMaxWidth,
    padding: `23px ${containerHorizontalPadding}px 30px`,
    background: 'white',
    boxShadow:
      '0px 18.6951px 24.9268px rgba(0, 0, 0, 0.04), 0px 12.4634px 18.6951px rgba(0, 0, 0, 0.04), 0px 3.11585px 6.23171px rgba(0, 0, 0, 0.04), 0px 0px 0.778964px rgba(0, 0, 0, 0.04)',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'flex-start',
  },

  mediaListContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    columnGap: `${imagesColumnGap}px`,
    rowGap: '24px',
    flexWrap: 'wrap',
  },
});
