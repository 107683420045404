import React from 'react';
import { DataGrid, ColDef } from '@material-ui/data-grid';

import { CommissionsReportDataItem } from 'src/types/report/commissions';
import { TableStretcher, TableMargins } from 'src/components/Report/common/Table/styled';

import { TableColumnsProps } from './types';
import { useTableColumns } from './use-table-columns';

import 'src/components/Report/common/Table/styles.css';

type Props = {
  data?: CommissionsReportDataItem[];
} & TableColumnsProps;

export const CommissionsReportTable = ({ data = [], onSentMessageClick }: Props) => {
  const columns: ColDef[] = useTableColumns(onSentMessageClick);

  return (
    <TableMargins>
      <TableStretcher>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={25}
          headerHeight={30}
          rowHeight={30}
          disableColumnMenu
          disableColumnSelector
          disableColumnFilter
          disableColumnResize
          disableColumnReorder
          disableSelectionOnClick
        />
      </TableStretcher>
    </TableMargins>
  );
};
