import React, { useMemo } from 'react';
import { DataGrid } from '@material-ui/data-grid';

import { MessagesReportDataItem } from 'src/types/report/messages';
import { TableStretcher, TableMargins, Alert } from 'src/components/Report/common/Table/styled';

import { useTableColumns } from './use-table-columns';

import 'src/components/Report/common/Table/styles.css';
import './styles.css';

type Props = {
  data?: MessagesReportDataItem[];
};

const maxCount = 1000;

export const MessagesReportTable = ({ data = [] }: Props) => {
  const rows = useMemo(() => data.slice(0, maxCount), [data]);
  const columns = useTableColumns();

  const showOverflowWarning = data.length > maxCount;

  return (
    <TableMargins>
      {showOverflowWarning && (
        <Alert>
          <b>{maxCount}</b> items are displayed. Total: <b>{data.length}</b>
        </Alert>
      )}
      <TableStretcher>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={25}
          headerHeight={30}
          rowHeight={30}
          disableColumnMenu
          disableColumnSelector
          disableColumnFilter
          disableColumnResize
          disableColumnReorder
          disableSelectionOnClick
        />
      </TableStretcher>
    </TableMargins>
  );
};
