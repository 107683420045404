import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { Product } from 'src/types/product';
import { useAuth } from 'src/services/auth';
import { LoginFormFields } from 'src/types/auth';
import { Resources } from 'src/resources';
import validationSchema from './schema';
import * as S from './styled';

const initialValues = {
  email: '',
  password: '',
  realm: Product.Once,
};

export const LoginForm = () => {
  const history = useHistory();

  const { login } = useAuth();
  const { control, handleSubmit, errors } = useForm<LoginFormFields>({
    resolver: yupResolver(validationSchema),
  });

  const products = useMemo(() => Object.values(Product), []);

  const selectItems = useMemo(
    () =>
      products.map((product, i) => (
        <MenuItem key={product} value={products[i]}>
          {product}
        </MenuItem>
      )),
    [products],
  );

  const onSelectChange = useCallback(
    (saver: (value: Product) => void) => (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      saver(e.target.value as Product);
    },
    [],
  );

  const onSubmit = async (values: LoginFormFields) => {
    await login(values);
    Resources.reCompile();

    history.push('/');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="realm"
        control={control}
        defaultValue={initialValues.realm}
        render={({ value, onChange }) => (
          <S.SelectWrapper>
            <FormControl fullWidth variant="outlined" error={!!errors.realm}>
              <Select displayEmpty value={value} onChange={onSelectChange(onChange)}>
                <MenuItem value="" key="0" disabled>
                  Select product
                </MenuItem>
                {selectItems}
              </Select>
              {!!errors.realm && <FormHelperText>{errors.realm?.message}</FormHelperText>}
            </FormControl>
          </S.SelectWrapper>
        )}
      />
      <Controller
        as={TextField}
        name="email"
        control={control}
        defaultValue={initialValues.email}
        label="Email Address *"
        error={!!errors.email}
        helperText={errors.email?.message}
        fullWidth
        variant="outlined"
        margin="normal"
      />
      <Controller
        as={TextField}
        name="password"
        type="password"
        control={control}
        defaultValue={initialValues.password}
        label="Password *"
        autoComplete="current-password"
        error={!!errors.password}
        helperText={errors.password?.message}
        fullWidth
        variant="outlined"
        margin="normal"
      />
      <S.ButtonWrapper>
        <Button type="submit" fullWidth variant="contained" color="primary">
          Sign In
        </Button>
      </S.ButtonWrapper>
    </form>
  );
};
