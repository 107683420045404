import XLSX from 'xlsx';

export const fillExcelDocument = <T>(tabName: string, data: T[][]) => {
  const excelBook = XLSX.utils.book_new();
  excelBook.SheetNames.push(tabName);

  excelBook.Sheets[tabName] = XLSX.utils.aoa_to_sheet(data);

  return XLSX.write(excelBook, { bookType: 'xlsx', type: 'binary' });
};
