import React, { useCallback } from 'react';
import { Modal } from '@material-ui/core';

import { useStyles } from './styles';

type Props = {
  isOpen: boolean;
  children: React.ReactElement;
  setIsOpen: (isOpen: boolean) => void;
};

export const FullSizePhotoModal = (props: Props) => {
  const { isOpen, setIsOpen, children } = props;

  const classes = useStyles();

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <Modal onClose={closeModal} open={isOpen} className={classes.modal}>
      {children}
    </Modal>
  );
};
