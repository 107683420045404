import React, { PropsWithChildren, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { Layout } from 'src/components/App/views/raw/Layout';
import { ModerationProvider } from 'src/context/raw/moderation';
import { TextContent, ModerationPhoto } from 'src/components/common/raw';
import { BlockUserModal } from 'src/components/Moderation/views/raw/block-user-modal';
import { useAuth } from 'src/services/auth';
import { useSendModerationActions, useStore, useMediaContent, useUserPreset } from 'src/context/raw/moderation/hooks';
import { FakeActions, RawActions } from 'src/components/Moderation/views/raw/actions';
import { RAW } from 'src/types/raw';
import { getMediaSourceRAWForSend } from 'src/components/Moderation/utils/image-source';
import { useMediaFullSizeModal } from 'src/components/Moderation/views/raw/full-size-media/use-full-size-modal';

import { useStyles } from './styles';

const AdminUserContentModerationRAW = () => {
  const { userId, baseName: contentId, mediaType: contentType } = useParams<{
    userId: string;
    baseName: string;
    mediaType: RAW.ContentType;
  }>();
  const { me } = useAuth();
  const operatorId = me?.id || '';
  const { setIsSending, toggleBlockModal, moderationActions } = useStore(operatorId);
  const classes = useStyles();
  const startTime = useRef(Date.now());
  const history = useHistory();
  const content = useMediaContent({ contentId, contentType, userId });
  const { modal, openModal } = useMediaFullSizeModal();

  const sendModerationActionsSuccessFlow = useCallback(() => {
    history.goBack();
  }, [history]);

  const sendModerationActions = useSendModerationActions({
    contentType,
    startTime,
    operatorId,
    setIsSending,
    sendModerationActionsSuccessFlow,
    userId,
  });

  const { userPreset } = useUserPreset(userId);

  const moderatedMedia =
    contentType === RAW.ContentType.Default ? getMediaSourceRAWForSend(userId, content.mediaId) : content.mediaId;

  if (!me) {
    return null;
  }

  return (
    <>
      <Layout>
        <Box className={classes.mainContainer}>
          <BlockUserModal
            moderatedMedia={moderatedMedia}
            actorId={userId}
            operatorId={me.id}
            setIsSending={setIsSending}
          />
          {modal}

          <Box className={classes.contentContainer}>
            {userId && content && (
              <ModerationPhoto onPhotoPress={openModal} content={content} userId={userId} contentType={contentType} />
            )}

            {userPreset.value && (
              <Box className={classes.textContent}>
                <TextContent onBlock={() => toggleBlockModal(true)} user={userPreset.value} operatorId={operatorId} />
              </Box>
            )}
          </Box>
          <FakeActions />
        </Box>
      </Layout>
      <RawActions
        moderationActions={moderationActions}
        moderatedMedia={moderatedMedia}
        sendModerationActions={sendModerationActions}
      />
    </>
  );
};

export const AdminUserContentModerationRAWScreen = (props: PropsWithChildren<any>) => (
  <ModerationProvider>
    <AdminUserContentModerationRAW {...props} />
  </ModerationProvider>
);
