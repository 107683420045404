import moment from 'moment';
import { Product } from 'src/types/product';

import { CommissionsReportDataItem } from 'src/types/report/commissions';

const convertDefaultReportCommissions = (json: CommissionsReportDataItem[]) => {
  const withDate = Boolean(json[0]?.timestamp);

  return [
    [
      'ID',
      'Agency Name',
      'Operator Name',
      'Received Messages',
      'Sent Messages',
      'Operator Commission',
      'Agency Commission',
      'Online (h)',
      'Time since last online (h)',
      ...(withDate ? ['Date'] : []),
    ],
    ...json.map((item) => [
      String(item.operatorId),
      item.agencyName,
      item.operatorName,
      item.receivedMessages,
      item.sentMessages,
      item.operatorCommission,
      item.agencyCommission,
      item.hoursOnline,
      item.hoursSinceLastOnline === null ? '' : item.hoursSinceLastOnline,
      ...(withDate ? [moment(item.timestamp).format('YYYY/MM/DD')] : []),
    ]),
  ];
};

const convertUdatesReportCommissions = (json: CommissionsReportDataItem[]) => {
  const withDate = Boolean(json[0]?.timestamp);

  return [
    [
      'ID',
      'Agency Name',
      'Operator Name',
      'Sent',
      'Received Replies',
      'Received Follow Up Replies',
      'Received Viewed Photos',
      'Received Gifts Reply',
      'Operator Commission Replies',
      'Operator Commission Follow Up Replies',
      'Operator Commission Viewed Photos',
      'Operator Commission Gifts Reply',
      'Agency Commission',
      'Traps',
      'Online (h)',
      'Time since last online (h)',
      ...(withDate ? ['Date'] : []),
    ],
    ...json.map((item) => [
      String(item.operatorId),
      item.agencyName,
      item.operatorName,
      item.sentMessages,
      item.receivedMessages,
      item.answeredFollowUpBonus?.bonusesCount || 0,
      item.mediaViewBonus?.bonusesCount || 0,
      item.giftsReplyBonus?.bonusesCount || 0,
      item.operatorCommission,
      item.answeredFollowUpBonus?.totalBonusValue || 0,
      item.mediaViewBonus?.totalBonusValue || 0,
      item.giftsReplyBonus?.totalBonusValue || 0,
      item.agencyCommission,
      item.totalTrapsPenalty,
      item.hoursOnline,
      item.hoursSinceLastOnline === null ? '' : item.hoursSinceLastOnline,
      ...(withDate ? [moment(item.timestamp).format('YYYY/MM/DD')] : []),
    ]),
  ];
};

const convertMagnetReportCommissions = (json: CommissionsReportDataItem[]) => {
  const withDate = Boolean(json[0]?.timestamp);

  return [
    [
      'ID',
      'Agency Name',
      'Operator Name',
      'Sent',
      'Received Replies',
      'Received Viewed Photos',
      'Operator Commission Replies',
      'Operator Commission Viewed Photos',
      'Agency Commission',
      'Online (h)',
      'Time since last online (h)',
      ...(withDate ? ['Date'] : []),
    ],
    ...json.map((item) => [
      String(item.operatorId),
      item.agencyName,
      item.operatorName,
      item.sentMessages,
      item.receivedMessages,
      item.mediaViewBonus?.bonusesCount || 0,
      item.operatorCommission,
      item.mediaViewBonus?.totalBonusValue || 0,
      item.agencyCommission,
      item.hoursOnline,
      item.hoursSinceLastOnline === null ? '' : item.hoursSinceLastOnline,
      ...(withDate ? [moment(item.timestamp).format('YYYY/MM/DD')] : []),
    ]),
  ];
};

export const convertReportCommissions = (realm: Product | undefined, json: CommissionsReportDataItem[]) => {
  switch (realm) {
    case Product.Once:
      return convertUdatesReportCommissions(json);
    case Product.Magnet:
      return convertMagnetReportCommissions(json);

    default:
      return convertDefaultReportCommissions(json);
  }
};
