import { useEffect, useRef } from 'react';

export const useKeyboardEventListener = (eventHandler: (event: KeyboardEvent) => void) => {
  const savedHandler = useRef<(event: KeyboardEvent) => void>();

  useEffect(() => {
    savedHandler.current = eventHandler;
  }, [eventHandler]);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => savedHandler.current && savedHandler.current(event);

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);
};
