import { UserTagType } from "src/types/user";

export enum PromoCodeType {
  One = 'one',
  Many = 'many',
}

export type ApiPromoCodeData = {
  id: string;
  type: PromoCodeType;
  activeUntil: string;
  activeFrom: string;
  benefits: {
    tags: [
      {
        tag: UserTagType;
        ttl: string;
      },
    ];
  };
  meta: {
    eventName: string;
    eventCity: string;
    stageName: string;
    shortLink: string;
    fullLink: string;
    comment: string;
  };
};

export type ApiGetPromoCodes = { promocodes: ApiPromoCodeData[] };

export type PromoCodeData = {
  id: string;
  eventName: string;
  eventCity: string;
  stageName: string;
  shortLink: string;
  fullLink: string;
  subTTL: number;
  activeUntil: string;
  activeFrom: string;
  qr?: string
};
