import React from 'react';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AuthProvider } from '../services/auth';

export const AppProviders: React.FC = ({ children }) => {
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={6000}>
      <AuthProvider>
        <CssBaseline />
        {children}
      </AuthProvider>
    </SnackbarProvider>
  );
};
