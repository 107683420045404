import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    justifyContent: 'center',
  },
  primaryButton: {
    backgroundColor: '#00C376',
    margin: '0 12px',
    '&:hover': {
      backgroundColor: '#00C376',
      opacity: 0.8,
    },
  },
  warningButton: {
    backgroundColor: '#ED654C',
    margin: '0 12px',
    '&:hover': {
      backgroundColor: '#ED654C',
      opacity: 0.8,
    },
  },
  undoBackButton: {
    backgroundColor: '#878E9C',
    margin: '0 12px',
    '&:hover': {
      backgroundColor: '#878E9C',
      opacity: 0.8,
    },
  },
});
