import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  smallText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: 'rgba(0, 0, 0, 0.5)',
  },

  link: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
});
