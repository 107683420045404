import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import validationSchema from './schema';
import * as S from './styled';

type Props = {
  onSubmit: (id: string) => void;
};

export const LifeStoriesSearchForm = (props: Props) => {
  const { control, handleSubmit, errors } = useForm<{ userId: number }>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: { userId: number }) => {
    props.onSubmit(String(values.userId));
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.InputWrapper>
        <Controller
          as={TextField}
          name="userId"
          type="number"
          control={control}
          label="Animator Id *"
          error={!!errors.userId}
          variant="outlined"
          size="small"
        />
      </S.InputWrapper>
      <Button type="submit" variant="contained" color="primary">
        Search
      </Button>
    </S.Form>
  );
};
