import React from 'react';
import Container from '@material-ui/core/Container';
import { ThemeProvider } from '@material-ui/core/styles';

import { useTheme } from 'src/services/theme';
import { layoutMaxWidth } from 'src/constants';
import { Menu } from 'src/components/App/views/Menu';


import * as S from './styled';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const Layout = ({ children }: Props) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <S.Wrapper>
        <S.MenuWrapper>
          <Menu />
        </S.MenuWrapper>
        <Container
          component="main"
          style={{
            height: '100%',
            maxWidth: layoutMaxWidth,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </Container>
      </S.Wrapper>
    </ThemeProvider>
  );
};
