import React from 'react';
import QRCode from 'react-qr-code';

import { useStyles } from './styles';

type Props = {
  value: string;
  itemId: string;
};

const downloadQR = (itemId: string) => {
  const svg = document.getElementById(`qr_${itemId}`);
  const svgData = new XMLSerializer().serializeToString(svg!);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx!.drawImage(img, 0, 0);
    const pngFile = canvas.toDataURL('image/png');

    const downloadLink = document.createElement('a');
    downloadLink.download = `qr_${itemId}`;
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

export const QRCodeImage = ({ value, itemId }: Props) => {
  const classes = useStyles();

  if (!value) {
    return null;
  }

  return (
    <button className={classes.button} type="button" onClick={() => downloadQR(itemId)}>
      <QRCode size={40} value={value} viewBox="0 0 256 256" />
      <QRCode className={classes.hiddenQr} id={`qr_${itemId}`} size={400} value={value} viewBox="0 0 256 256" />
    </button>
  );
};
