import { Http } from 'src/network/http';
import { BackendAlias } from 'src/utils/realm';
import { BonusCommission, Cities, Commission, Countries } from 'src/types/operator';

import { OperatorsDto } from './types';
import { adapter } from './adapter';

const getAll = (realm: string) => {
  return Http.shared()
    .instance.get<OperatorsDto>('/multichat/operators', { params: { realm } })
    .then(adapter.getAll);
};

const update = (
  operatorId: string,
  payload: { firstName: string; lastName: string; agencyTechName: string | null; realm: string },
) => {
  return Http.shared().instance.put(`/multichat/operators/${operatorId}`, payload);
};

const activate = (
  operatorId: string,
  payload: {
    firstName: string;
    lastName: string;
    commission: Commission;
    isReadyForExplicit: boolean;
    realm: BackendAlias;
    country: Countries;
    city: Cities;
    scheduleType?: 'FlexibleSchedule' | 'TwoInTwo' | 'Agency';
    bonuses?: { bonus: string; value: BonusCommission }[];
    isFreeMember?: boolean;
    freeMemberAnimatorClientId?: string;
  },
) => Http.shared().instance.put<{ Success: boolean }>(`/multichat/operators/${operatorId}/activate`, payload);

export const operatorRequest = {
  getAll,
  update,
  activate,
};
