import { Box } from '@material-ui/core';
import React from 'react';

import { ImageWithLogger } from 'src/components/Moderation/views/raw/image-with-logger';
import { getRAWMediaSourceRAW } from 'src/components/Moderation/utils/image-source';
import { MediaModerationReason } from 'src/network/moderation/types';
import { RAW } from 'src/types/raw';

import { Label } from '../label';

import { useStyles } from './styles';

type Props = {
  userId: RAW.User['id'];
  reason?: MediaModerationReason;
  loading?: "eager" | "lazy";
} & RAW.RawPhoto;

export function RawPhoto(props: Props) {
  const { userId, primaryBaseName, secondaryBaseName, availabilityStatus, reason, loading } = props;
  const classes = useStyles();

  const statusIsSuitableForDisplay = availabilityStatus !== RAW.PhotoAvailabilityStatus.Deleted;

  return (
    <Box className={classes.rawImageWrapper}>
      {statusIsSuitableForDisplay ? (<>
        <ImageWithLogger
          loading={loading}
          className={classes.rawMainImage}
          src={getRAWMediaSourceRAW(userId, primaryBaseName!)}
          alt="mainPhoto"
        />
        <ImageWithLogger
          loading={loading}
          className={classes.rawSecondaryImage}
          src={getRAWMediaSourceRAW(userId, secondaryBaseName!)}
          alt="secondaryPhoto"
        />
      </>) : null}
      {reason && <Label reason={reason} />}
    </Box>
  );
}
