import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '343px',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
  },

  subtitle: {
    marginBottom: '20px',
  },

  filterParamContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '28px',
  },
});
