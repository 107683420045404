import React, { useCallback, useEffect, useState } from 'react';

import { useNextUserMagnet } from 'src/services/verification/hooks';

import { Typography } from '@material-ui/core';
import { Resources } from 'src/resources';
import { VerificationTag } from 'src/types/verification';
import { verificationRequest } from 'src/network/verification';
import { BlockUserModal } from 'src/components/Moderation/views/block-user-modal';
import { moderationRequest } from 'src/network';
import { ActionType } from 'src/types/moderation';
import { noop } from 'src/utils/functions';
import { useAuth } from 'src/services/auth';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';
import verificationImage from './verification-example.png';
import { useUsersCount } from './hooks';
import { LoadingOrNoContent, UserInfo, VerificationActionButtons, VerificationMedia } from './components';
import { VerificationHeader } from '../VerificationHeader';

export const VerificationWidgetMagnet: React.FC = () => {
  const { nextUserState, loadNextUser } = useNextUserMagnet();
  const { fetchContentCount, handledUsersCount, usersInQueue, updateHandledUsersCount } = useUsersCount();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useAuth();
  const operatorId = me?.id || '';

  const classes = useStyles();

  const toggleModal = useCallback(() => {
    setIsModalOpen((modalOpen) => !modalOpen);
  }, []);

  const blockProfile = useCallback(() => {
    toggleModal();

    const { userId } = nextUserState.value!;

    verificationRequest
      .sendUserTags(userId, VerificationTag.Rejected, {
        'X-Reason': `photos=${nextUserState?.value?.media.length || 0}`,
      })
      .then(() => moderationRequest.sendAdminModerationActions(operatorId, userId, [{ actionType: ActionType.Delete }]))
      .then(() => {
        enqueueSnackbar('User successfully deleted', { variant: 'success' });
      })
      .then(loadNextUser)
      .catch(noop);
  }, [toggleModal, nextUserState.value, loadNextUser, operatorId, enqueueSnackbar]);

  const onRefreshClick = useCallback(() => {
    fetchContentCount();
    loadNextUser();
  }, [fetchContentCount, loadNextUser]);

  useEffect(() => {
    fetchContentCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!nextUserState.value || nextUserState.loading) {
    return (
      <LoadingOrNoContent
        usersInQueue={usersInQueue}
        handledUsersCount={handledUsersCount}
        isLoading={nextUserState.loading}
        onRefreshClick={onRefreshClick}
      />
    );
  }

  const { age, gender, mediaCount, userId } = nextUserState.value || {};

  return (
    <div>
      <BlockUserModal isOpen={isModalOpen} onBlock={blockProfile} onCancel={toggleModal} />
      <VerificationHeader usersInQueue={usersInQueue} handledUsersCount={handledUsersCount} />
      <div className={classes.container}>
        <div className={classes.example}>
          <Typography className={classes.exampleTitle}>{Resources.strings.moderation.media.example}</Typography>
          <img className={classes.examplePhoto} src={verificationImage} alt="verification example" />
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.photosContainer}>
            <VerificationMedia userId={userId} />
          </div>
          <UserInfo userId={userId} age={age} gender={gender} toggleModal={toggleModal} />
          <VerificationActionButtons
            userId={userId}
            mediaCount={mediaCount}
            updateHandledUsersCount={updateHandledUsersCount}
            fetchContentCount={fetchContentCount}
            loadNextUser={loadNextUser}
          />
        </div>
      </div>
    </div>
  );
};
