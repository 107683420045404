import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

export enum ActionButtonType {
  Default = 'default',
  Stroke = 'stroke',
  Pink = 'pink',
}

type Props = {
  title: string;
  onClick?: () => void;
  type?: ActionButtonType;
  disabled?: boolean;
  className?: string;
  titleClassName?: string;
};

const ActionButtonView: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    title,
    onClick,
    disabled,
    type = ActionButtonType.Default,
    className: classNameFromProps,
    titleClassName: titleClassNameFromProps,
  } = props;

  const buttonClassName = useMemo(() => {
    const buttonStyles = [classes.buttonCommon, classes.titleCommon];

    if (type === ActionButtonType.Default) {
      buttonStyles.push(classes.defaultButton);
    }

    if (type === ActionButtonType.Stroke) {
      buttonStyles.push(classes.strokeButton);
    }

    if (type === ActionButtonType.Pink) {
      buttonStyles.push(classes.pinkButton);
    }

    return clsx(...buttonStyles, classNameFromProps, titleClassNameFromProps);
  }, [
    classNameFromProps,
    classes.buttonCommon,
    classes.defaultButton,
    classes.pinkButton,
    classes.strokeButton,
    classes.titleCommon,
    titleClassNameFromProps,
    type,
  ]);

  return (
    <Button className={buttonClassName} onClick={onClick} disabled={disabled}>
      {title}
    </Button>
  );
};

export const ActionButton = React.memo(ActionButtonView);
