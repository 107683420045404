import React, { useCallback, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';

import { Agency } from 'src/types/agency';
import { Animator } from 'src/types/animator';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import * as S from './styled';

type Props = {
  agency: Agency;
  animators: Animator[];
  onClose: () => void;
  onLinkAnimator: (animatorId: number, agencyTechName?: string) => void;
};

export const AgencyAnimatorsFillingForm = ({ agency, animators, onClose, onLinkAnimator }: Props) => {
  const assignedAnimators = useMemo(() => animators.filter((animator) => animator.agencyTechName === agency.techName), [
    animators,
    agency,
  ]);

  const unlinkAnimatorAndAgency = useCallback(
    (animatorId: number) => () => {
      onLinkAnimator(animatorId);
    },
    [onLinkAnimator],
  );

  const linkAnimatorAndAgency = useCallback(
    (animatorId: number) => () => {
      onLinkAnimator(animatorId, agency.techName);
    },
    [agency.techName, onLinkAnimator],
  );

  const assignedAnimatorsComponent = useMemo(() => {
    if (assignedAnimators.length === 0) {
      return 'Empty';
    }

    return (
      <ul>
        {assignedAnimators.map((animator) => {
          return (
            <S.ListItem key={animator.id}>
              <S.ListItemInner>
                <span>
                  {animator.name} ({animator.id})
                </span>{' '}
                <S.Button type="button" onClick={unlinkAnimatorAndAgency(animator.id)}>
                  x
                </S.Button>
              </S.ListItemInner>
            </S.ListItem>
          );
        })}
      </ul>
    );
  }, [assignedAnimators, unlinkAnimatorAndAgency]);

  const [searchedPhrase, setSearchedPhrase] = useState('');
  const search = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchedPhrase(e.target.value.toLowerCase()),
    [],
  );

  const freeAnimators = useMemo(() => {
    return animators.filter(
      (animator) =>
        !animator.agencyTechName &&
        ((animator.id || '').toString().toLowerCase().includes(searchedPhrase) ||
          (animator.name || '').toLowerCase().includes(searchedPhrase)),
    );
  }, [animators, searchedPhrase]);

  const freeAnimatorsComponent = useMemo(
    () =>
      searchedPhrase &&
      freeAnimators.map((animator) => (
        <S.SearchedListItem key={animator.id}>
          {animator.name} ({animator.id}){' '}
          <S.Button type="button" onClick={linkAnimatorAndAgency(animator.id)}>
            +
          </S.Button>
        </S.SearchedListItem>
      )),
    [freeAnimators, linkAnimatorAndAgency, searchedPhrase],
  );

  return (
    <div>
      <S.Header>
        <S.Title>{agency.name}</S.Title>
        <Button type="button" variant="outlined" size="small" onClick={onClose}>
          Close
        </Button>
      </S.Header>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {assignedAnimatorsComponent}
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Animators search (dbId/name)"
            type="search"
            variant="outlined"
            size="small"
            onChange={search}
          />
          <div>{freeAnimatorsComponent}</div>
        </Grid>
      </Grid>
    </div>
  );
};
