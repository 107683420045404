import { CellValue } from '@material-ui/data-grid';

export function roundCellValue(num: CellValue, dec: number) {
  if (!num) {
    return 0;
  }
  const roundedValue = Math.floor(+num * 10 ** dec) / 10 ** dec;

  // we need rounding to be consistent with the multichat UI, so the actual rounding is performed with Math.floor
  // toFixed is used here not for the actual rounding but to pad the result with trailing zeroes.
  return roundedValue.toFixed(dec);
}
