import { makeStyles } from '@material-ui/core';

import { layoutMaxWidth } from 'src/constants';

export const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexBasis: '100%',
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    maxWidth: layoutMaxWidth,
    '@media (max-width: 1200px)': {
      columnGap: '10px',
    },
    flex: 1,
  },

  textContent: { width: '40%' },

  separator: {
    height: 0,
    borderWidth: 5,
    borderStyle: 'solid',
    borderColor: '#EF7BF8',
    width: '50%',
    marginTop: 60,
    marginBottom: 32,
  },
});
