import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  ListItem,
  ListItemIcon,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import DatePicker from 'react-datepicker';
import { useAsyncFn } from 'react-use';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import random from 'lodash/random';
import EmojiPicker from 'emoji-picker-react';
import {
  EmojiEmotionsRounded,
  RemoveOutlined,
  AddCircleOutlineOutlined,
  CheckCircleOutline,
  Cancel,
} from '@material-ui/icons';
import { useAutoAnimate } from '@formkit/auto-animate/react';

import { Http } from 'src/network/http';
import { intersection } from 'src/utils/intersection';
import { Gender, MediaTag, QuestionItem, User as UserType } from 'src/types/user';
import { useSnackbar } from 'notistack';
import * as S from 'src/components/DailyBuzz/card/styled';
import { dayStartToIsoFormat } from 'src/utils/day-start-to-iso-format';
import { clientConfig } from 'src/config';

import {
  AddQuestionContainer,
  CardLeft,
  CardRight,
  Circular,
  CustomPickerHeader,
  Image,
  ImageContainer,
  ImageInput,
  CheckListContainer,
  TopMarginContainer,
  NameInput,
  PickerContainer,
  QuestionContainer,
  QuestionInput,
  SaveContainer,
  UploadingBox,
  UserProfileCard,
  CheckboxList,
} from '../styled';
import { useUserMedia } from '../hooks';

type Dist = { [x: string]: string };

type Props = {
  mode: 'create' | 'edit';
  questionsPool: string[];
  traitsPool: Dist;
  turnOnsPool: Dist;
  lookingForPool: Dist;
  interestsPool: Dist;
  user: UserType;
  onSaveEnd: () => void;
};

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

const years = range(1920, new Date().getFullYear() - 17, 1);
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const maxCountInterests = 10;

type ListId = 'traits' | 'turn-ons' | 'interests';

const CustomInput = forwardRef<unknown, { value?: string; onClick?: () => void }>(({ value, onClick }, ref) => (
  <S.DateButton variant="outlined" color="primary" onClick={onClick} ref={ref as React.RefObject<HTMLButtonElement>}>
    {value}
  </S.DateButton>
));

const mirrorAi = {
  host: 'https://public-api.mirror-ai.net',
  corsHost: 'https://mirror-ai.nptest.org',
};

const maxPromptLength = 70;
export const User = ({
  questionsPool,
  traitsPool,
  lookingForPool,
  interestsPool,
  turnOnsPool,
  user,
  mode,
  onSaveEnd,
}: Props) => {
  const [isSaving, setIsSaving] = useState(false);

  const { userMedia, isMediaLoading } = useUserMedia(user, [MediaTag.Hidden]);
  const { userMedia: userThumbnailMedia, isMediaLoading: isMediaThumbnailLoading } = useUserMedia(user, [
    MediaTag.Thumbnail,
  ]);
  const { userMedia: userThumbnailBodyMedia, isMediaLoading: isMediaThumbnailBodyLoading } = useUserMedia(user, [
    MediaTag.ThumbnailBody,
  ]);

  const { enqueueSnackbar } = useSnackbar();

  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState([false, false, false, false]);
  const emojisRef = useRef<SVGSVGElement[]>([]);

  const [newName, setNewName] = useState<string>('');
  const [newLocation, setNewLocation] = useState<string>('');
  const [newDate, setNewDate] = useState<Date>(new Date());
  const [lookingFor, setLookingFor] = useState<string>('relationship');

  const [gender, setGender] = useState<Gender>(Gender.Female);

  const [newImageFile, setNewImageFile] = useState<FileList | null>();
  const [newImageUrl, setNewImageUrl] = useState<string>();

  const [interests, setInterests] = useState<string[]>([]);
  const [traits, setTraits] = useState<string[]>([]);
  const [turnOns, setTurnOns] = useState<string[]>([]);

  const [newQuestions, setNewQuestions] = useState<QuestionItem[]>([]);

  const [newQuestionIndex, setNewQuestionIndex] = useState<number | ''>('');

  const inputRef = useRef<HTMLInputElement>(null);
  const [cardRightRef] = useAutoAnimate();

  const [bodiesIdsPool, fetchBodiesIds] = useAsyncFn(async () => {
    const response = await Http.shared().instance.get('magnet/profile-details/full-bodies-ids-list');
    return response.data as { male: string[]; female: string[] };
  });

  useEffect(() => {
    fetchBodiesIds().then();
  }, [fetchBodiesIds]);

  useEffect(() => {
    if (user) {
      if (user.gender) {
        setGender(user.gender);
      }
      setNewDate(new Date(user.birthday || 0));
      if (Array.isArray(user.interests) && user.interests?.length > 0) {
        setInterests(cloneDeep(user.interests));
      } else {
        setInterests([]);
      }
      setNewName(user.name);
      if (user.city && user.country) {
        setNewLocation(`${user.city}, ${user.country}`);
      } else {
        setNewLocation('');
      }

      if (user.lookingFor) {
        setLookingFor(user.lookingFor);
      }

      if (user.questions) {
        setNewQuestions(cloneDeep(user.questions));
      }

      if (user.turnOns) {
        setTurnOns(cloneDeep(user.turnOns || []));
      }

      if (user.traits) {
        setTraits(cloneDeep(user.traits || []));
      }
    }
  }, [user, questionsPool, interestsPool]);

  useEffect(() => {
    setNewImageFile(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, [user]);

  useEffect(() => {
    if (!newImageFile || newImageFile.length === 0) {
      return undefined;
    }
    const imageUrl = URL.createObjectURL(newImageFile[0]);
    setNewImageUrl(imageUrl);

    return () => {
      URL.revokeObjectURL(imageUrl);
      setNewImageUrl(undefined);
    };
  }, [newImageFile]);

  const interestsErrors = useMemo(() => {
    return interests.length > maxCountInterests || interests.length === 0;
  }, [interests]);

  const answersErrors = useMemo(() => {
    return newQuestions.map(({ answer }) => answer.length > maxPromptLength || answer.length === 0);
  }, [newQuestions]);

  const nameError = useMemo(() => newName.length === 0 || newName.length > 15, [newName]);

  const canBeSaved = useMemo(() => {
    return (
      !interestsErrors &&
      !answersErrors.some((error) => error) &&
      !nameError &&
      Boolean(lookingFor) &&
      turnOns.length > 0 &&
      traits.length > 0
    );
  }, [interestsErrors, answersErrors, nameError, lookingFor, turnOns, traits]);

  const questionsPoolAvailable = useMemo(() => {
    return (
      questionsPool?.reduce((acc, text, i) => {
        if (!newQuestions.some((newQuestion) => newQuestion.question === String(i))) {
          acc.push({ text, i });
        }
        return acc;
      }, [] as { text: string; i: number }[]) || []
    );
  }, [questionsPool, newQuestions]);

  const onDayClick = useCallback((day) => {
    setNewDate(day);
  }, []);

  const onAnswerChange = useCallback(
    (event, i) => {
      const { value } = event.target;
      const answers = [...newQuestions];
      answers[i].answer = value;
      setNewQuestions(answers);
    },
    [newQuestions],
  );

  const onRemoveQuestionClick = useCallback(
    (i: number) => {
      const withoutI = newQuestions.filter((_, index) => index !== i);
      setNewQuestions(withoutI);
    },
    [newQuestions],
  );

  const onAddQuestionClick = useCallback(() => {
    if (newQuestionIndex === '') {
      return;
    }
    setNewQuestions([...newQuestions, { question: newQuestionIndex.toString(), answer: '' }]);
    setNewQuestionIndex('');
  }, [newQuestions, newQuestionIndex]);

  const onEmojiPick = useCallback(
    (emoji: { emoji: string }, i: number) => {
      const answers = [...newQuestions];
      answers[i].answer += emoji.emoji;
      setNewQuestions(answers);
    },
    [newQuestions],
  );

  const onEmojiOpenClick = useCallback((i: number) => {
    const newState = [false, false, false, false];
    newState[i] = true;
    setIsEmojiPickerOpen(newState);
  }, []);

  const closeEmojiPicker = useCallback(() => {
    setIsEmojiPickerOpen([false, false, false, false]);
  }, []);

  const onSaveBody = useCallback(
    async (mirrorAiPhotoId) => {
      const responseAttrs = await axios.get(
        `${mirrorAi.host}/v2/attrs?${new URLSearchParams({ face_id: mirrorAiPhotoId }).toString()}`,
        {
          headers: {
            'x-token': clientConfig.magnetMirrorAiToken,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      let fullBodyBasename = '';

      if (responseAttrs.data.ok && responseAttrs.data.attrs.gender) {
        const mirrorAIGender: 'male' | 'female' = responseAttrs.data.attrs.gender;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const bodyIds: string = bodiesIdsPool.value[mirrorAIGender];
        const bodyId: string = bodyIds[random(0, bodyIds.length - 1)];

        const responseSticker = await axios.get(
          `${mirrorAi.host}/v2/sticker?${new URLSearchParams({
            face_id: mirrorAiPhotoId,
            loc: 'en',
            sticker: bodyId,
          }).toString()}`,
          {
            headers: {
              'x-token': clientConfig.magnetMirrorAiToken,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        );

        if (responseSticker.data.ok) {
          const urlSticker = responseSticker.data.url;
          const corsUrlSticker = `${mirrorAi.corsHost}/${urlSticker.split('.net')[1]}?sz=1080`;
          const mirrorAiSticker = await axios.get(corsUrlSticker, {
            responseType: 'blob',
          });
          const mirrorAiStickerFormData = new FormData();
          mirrorAiStickerFormData.append('file', mirrorAiSticker.data, 'image.png');
          const mirrorAiStickerUploadResponse = await Http.shared().instance.post(
            `/users/${user.id}/photos`,
            mirrorAiStickerFormData,
            {
              params: {
                tags: `thumbnail-body,serviceId:${mirrorAiPhotoId},type_body:${bodyId}`,
              },
            },
          );
          fullBodyBasename = mirrorAiStickerUploadResponse.data.basename || mirrorAiStickerUploadResponse.data[0];
        }
      }
      return fullBodyBasename;
    },
    [bodiesIdsPool.value, user.id],
  );

  const onSaveClick = useCallback(async () => {
    try {
      setIsSaving(true);
      const isNewUser = mode === 'create';

      const [city, country] = newLocation.split(',').map((s) => s.trim());

      const shouldUpdateUser =
        user.name !== newName ||
        user.birthday !== newDate.toISOString() ||
        user.city !== city ||
        user.country !== country;

      if (isNewUser || shouldUpdateUser) {
        const beginningOfDay = dayStartToIsoFormat(newDate);

        await Http.shared().instance.patch(`/users/${user.id}`, {
          name: newName,
          birthday: beginningOfDay,
          city,
          country,
          gender,
        });
      }
      if (isNewUser || user.lookingFor !== lookingFor) {
        await Http.shared().instance.put(`/users/${user.id}/preferences`, {
          'looking-for': lookingFor,
        });
      }

      await Http.shared().instance.put(`/users/${user.id}/interests`, interests, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      await Http.shared().instance.put(
        `/magnet/users/${user.id}/turn-ons`,
        { 'turn-ons': turnOns },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      await Http.shared().instance.put(
        `/magnet/users/${user.id}/traits`,
        { traits },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const shouldUpdateQuestions =
        intersection(
          user.questions?.map((question) => question.answer),
          newQuestions.map((question) => question.answer),
        ).length !== user.questions?.length || user.questions?.length !== newQuestions.length;

      if (isNewUser || shouldUpdateQuestions) {
        await Http.shared().instance.put(`users/${user.id}/questions`, { questions: newQuestions });
      }

      if (newImageFile && newImageFile[0]) {
        const formData = new FormData();
        formData.append('photo', newImageFile[0], 'image.jpg');
        const response = await axios.post(`${mirrorAi.host}/v2/generate?style=anime`, formData, {
          headers: {
            'x-token': clientConfig.magnetMirrorAiToken,
          },
        });
        if (response.data.ok) {
          const { url, id: mirrorAiPhotoId } = response.data.face;
          const corsUrl = `${mirrorAi.corsHost}/${url.split('.net')[1]}`;
          const mirrorAiPhoto = await axios.get(corsUrl, {
            responseType: 'blob',
          });

          const avatarFormData = new FormData();
          avatarFormData.append('file', newImageFile[0], 'image.jpg');
          const avatarPhotoUploadResponse = await Http.shared().instance.post(
            `/users/${user.id}/photos`,
            avatarFormData,
          );
          const avatarBasename = avatarPhotoUploadResponse.data.basename || avatarPhotoUploadResponse.data[0];

          const mirrorAiFormData = new FormData();
          mirrorAiFormData.append('file', mirrorAiPhoto.data, 'image.png');

          const avatarColors = ['8D2929', '9F3907', '937400', '069143', '29748D', '29498D', '58298D'];
          const avatarColor = avatarColors[random(0, avatarColors.length - 1)];

          const mirrorAiPhotoUploadResponse = await Http.shared().instance.post(
            `/users/${user.id}/photos`,
            mirrorAiFormData,
            {
              params: {
                tags: `thumbnail,color:${avatarColor},serviceId:${mirrorAiPhotoId}`,
              },
            },
          );
          const mirrorAiBasename = mirrorAiPhotoUploadResponse.data.basename || mirrorAiPhotoUploadResponse.data[0];

          await Http.shared().instance.put(`/users/${user.id}/photos/${avatarBasename}/tags/hidden`);

          await axios.post(`${mirrorAi.corsHost}/v2/add_face`, new URLSearchParams({ face_id: mirrorAiPhotoId }), {
            headers: {
              'x-token': clientConfig.magnetMirrorAiToken,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });

          const fullBodyBasename = await onSaveBody(mirrorAiPhotoId);

          const oldPhotosWithoutThumbnailAndHidden =
            user.media
              ?.filter(
                (photo) =>
                  !photo.tags.includes(MediaTag.Thumbnail) &&
                  !photo.tags.includes(MediaTag.Hidden) &&
                  !photo.tags.includes(MediaTag.ThumbnailBody),
              )
              .map((el) => el.baseName) || [];

          await Http.shared().instance.put(
            `/users/${user.id}/photos`,
            [...oldPhotosWithoutThumbnailAndHidden, avatarBasename, mirrorAiBasename, fullBodyBasename].filter(Boolean),
          );
        }
      } else if (!userThumbnailBodyMedia[0]?.url) {
        const data = user.media?.find((photo) => photo.tags.includes(MediaTag.Thumbnail));
        const serviceID = data && data.tags.find((el) => el.includes('serviceId:'))?.split(':')?.[1];
        if (serviceID && user.media) {
          const fullBodyBasename = await onSaveBody(serviceID);
          const allMedia = user.media.map((el) => el.baseName) || [];

          await Http.shared().instance.put(`/users/${user.id}/photos`, [...allMedia, fullBodyBasename].filter(Boolean));
        }
      }
      onSaveEnd();
    } catch (error) {
      const errorMessage = error?.response?.data?.error ?? error.message;

      if (errorMessage.startsWith('photo-too-big')) {
        enqueueSnackbar(
          `${errorMessage} The maximum side of the image in pixels should not exceed 2340. The maximum multiplication of the width and height of the image in pixels should not exceed 5,000,000.`,
          { variant: 'error' },
        );
        return;
      }

      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setIsSaving(false);

      setNewImageFile(null);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [
    mode,
    newLocation,
    user,
    newName,
    newDate,
    lookingFor,
    interests,
    turnOns,
    traits,
    newQuestions,
    newImageFile,
    userThumbnailBodyMedia,
    onSaveEnd,
    gender,
    onSaveBody,
    enqueueSnackbar,
  ]);

  const handleToggle = useCallback(
    (idList: ListId, value: string) => () => {
      let checked;
      let setChecked;

      switch (idList) {
        case 'interests': {
          checked = interests;
          setChecked = setInterests;
          break;
        }
        case 'turn-ons': {
          checked = turnOns;
          setChecked = setTurnOns;
          break;
        }
        default:
          checked = traits;
          setChecked = setTraits;
      }

      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      if (idList === 'interests' && newChecked.length > maxCountInterests) {
        return;
      }

      setChecked(newChecked);
    },
    [interests, traits, turnOns],
  );

  const customList = (idList: ListId, items: { [K in keyof Dist]: [K, Dist[K]] }[keyof Dist][], checked: string[]) => (
    <Card>
      <CardHeader
        avatar={
          <Checkbox
            checked={checked.length !== 0 && (idList !== 'interests' || checked.length < 11)}
            disabled
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={idList}
        subheader={`${checked.length} selected${idList === 'interests' ? `. Max=${maxCountInterests}` : ''}`}
      />
      <Divider />
      <CheckboxList dense role="list">
        {items.map(([value, text]) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(idList, value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={text} />
            </ListItem>
          );
        })}
        <ListItem />
      </CheckboxList>
    </Card>
  );

  return (
    <Box>
      <UserProfileCard variant="outlined">
        <Box display="flex">
          <CardLeft>
            {!isMediaThumbnailLoading && (
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row">
                <Typography>Thumbnail: </Typography>
                {userThumbnailMedia[0]?.url ? (
                  <CheckCircleOutline fontSize="small" />
                ) : (
                  <Cancel fontSize="small" color="error" />
                )}
              </Box>
            )}
            {!isMediaThumbnailBodyLoading && (
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row">
                <Typography>Thumbnail Body: </Typography>
                {userThumbnailBodyMedia[0]?.url ? (
                  <CheckCircleOutline fontSize="small" />
                ) : (
                  <Cancel fontSize="small" color="error" />
                )}
              </Box>
            )}
            <Box>
              {(isMediaLoading || !userMedia[0]) && !newImageUrl && (
                <ImageContainer display="flex" justifyContent="center" alignItems="center">
                  Image placeholder
                </ImageContainer>
              )}
              {!isMediaLoading && (newImageUrl || userMedia[0]?.url) && (
                <Box>
                  <Image src={newImageUrl || userMedia[0]?.url} alt="avatar" />
                </Box>
              )}
            </Box>
            <Box>
              <ImageInput
                ref={inputRef}
                onChange={(e) => setNewImageFile(e.target.files)}
                type="file"
                accept="image/png, image/jpeg"
              />
            </Box>
            <TopMarginContainer>
              <NameInput
                error={nameError}
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                variant="filled"
                color="primary"
                placeholder="Name"
              />
            </TopMarginContainer>
            <Box>
              <TextField
                value={newLocation}
                onChange={(e) => setNewLocation(e.target.value)}
                variant="filled"
                color="primary"
                placeholder="City, Country"
              />
            </Box>
            <TopMarginContainer>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={gender}
                  onChange={(event) => setGender(event.target.value as Gender)}
                >
                  <FormControlLabel value={Gender.Female} control={<Radio />} label="Female" />
                  <FormControlLabel value={Gender.Male} control={<Radio />} label="Male" />
                </RadioGroup>
              </FormControl>
            </TopMarginContainer>
            {lookingForPool && (
              <TopMarginContainer>
                <FormControl>
                  <FormLabel id="looking-for-controlled-radio-buttons-group">Looking For</FormLabel>
                  <RadioGroup
                    aria-labelledby="looking-for-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={lookingFor}
                    onChange={(event) => setLookingFor(event.target.value as string)}
                  >
                    {getEntries(lookingForPool!).map(([key, value]) => (
                      <FormControlLabel key={key} value={key} control={<Radio />} label={value} />
                    ))}
                  </RadioGroup>
                </FormControl>
              </TopMarginContainer>
            )}
            <PickerContainer>
              <DatePicker
                customInput={<CustomInput />}
                dateFormat="dd/MM/yyyy"
                selected={newDate}
                onChange={onDayClick}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <CustomPickerHeader>
                    <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      {'<'}
                    </button>
                    <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(Number(value))}>
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={months[date.getMonth()]}
                      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      {'>'}
                    </button>
                  </CustomPickerHeader>
                )}
              />
            </PickerContainer>
          </CardLeft>
          <CardRight ref={cardRightRef} variant="elevation">
            <CheckListContainer display="flex" justifyContent="space-between" flexDirection="row">
              {interests && interestsPool && (
                <CheckListContainer display="flex" justifyContent="space-between">
                  {customList('interests', getEntries(interestsPool), interests)}
                </CheckListContainer>
              )}
              {traits && traitsPool && (
                <CheckListContainer display="flex" justifyContent="space-between">
                  {customList('traits', getEntries(traitsPool), traits)}
                </CheckListContainer>
              )}
              {turnOns && traitsPool && (
                <CheckListContainer display="flex" justifyContent="space-between">
                  {customList('turn-ons', getEntries(turnOnsPool), turnOns)}
                </CheckListContainer>
              )}
            </CheckListContainer>
            {newQuestions &&
              questionsPool &&
              newQuestions.map((question, i) => (
                <QuestionContainer key={question.question}>
                  <Typography variant="subtitle1" key={question.question}>
                    {questionsPool?.[Number(question.question)]}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <IconButton disabled={newQuestions.length < 2} onClick={() => onRemoveQuestionClick(i)}>
                      <RemoveOutlined color={newQuestions.length < 2 ? 'disabled' : 'primary'} />
                    </IconButton>
                    <QuestionInput
                      variant="outlined"
                      value={newQuestions?.[i].answer}
                      onChange={(event) => onAnswerChange(event, i)}
                      placeholder="Type answer here"
                    />
                    <IconButton onClick={() => onEmojiOpenClick(i)}>
                      <EmojiEmotionsRounded
                        ref={(r) => {
                          emojisRef.current[i] = r as SVGSVGElement;
                        }}
                        color="primary"
                      />
                    </IconButton>
                    <Popover
                      open={isEmojiPickerOpen[i]}
                      anchorEl={emojisRef.current[i]}
                      onClose={closeEmojiPicker}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <EmojiPicker onEmojiClick={(emoji) => onEmojiPick(emoji, i)} />
                    </Popover>
                    <Typography color={answersErrors[i] ? 'error' : 'textPrimary'} variant="subtitle1">
                      {`${newQuestions[i].answer.length}/${maxPromptLength}`}
                    </Typography>
                  </Box>
                </QuestionContainer>
              ))}
            {newQuestions.length < 4 && (
              <AddQuestionContainer>
                <Select
                  value={newQuestionIndex}
                  onChange={(event) => {
                    setNewQuestionIndex(event.target.value as number);
                  }}
                >
                  {questionsPool &&
                    questionsPoolAvailable.map((el) => (
                      <MenuItem key={el.i} value={el.i}>
                        {el.text}
                      </MenuItem>
                    ))}
                </Select>
                <IconButton disabled={newQuestionIndex === ''} onClick={onAddQuestionClick}>
                  <AddCircleOutlineOutlined color={newQuestionIndex === '' ? 'disabled' : 'primary'} />
                </IconButton>
              </AddQuestionContainer>
            )}
          </CardRight>
        </Box>
      </UserProfileCard>
      <SaveContainer display="flex" alignItems="center">
        <Button onClick={onSaveClick} disabled={!canBeSaved || isSaving} variant="contained" color="primary">
          Save
        </Button>
        {isSaving && (
          <UploadingBox>
            <Typography>Saving...</Typography>
            <Circular size="2rem" />
          </UploadingBox>
        )}
      </SaveContainer>
    </Box>
  );
};
