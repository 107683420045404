import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { AgencySelector } from 'src/components/Multichat/views/Agency/Selector';
import { ScheduleSelector } from 'src/components/Multichat/views/Schedule/Selector';
import { Agency } from 'src/types/agency';
import { OperatorAgencyMappingFormFields } from 'src/types/multichat/operator-agency-mapping';
import validationSchema from './schema';
import * as S from './styled';

type Props = {
  agencies: Agency[];
  onSubmit: (values: OperatorAgencyMappingFormFields) => void;
  onCancel: () => void;
};

export const MultichatAgencyOperatorsMappingFillingForm = ({ agencies, onSubmit: onSubmitBase, onCancel }: Props) => {
  const { control, handleSubmit } = useForm<OperatorAgencyMappingFormFields>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    (values) => {
      onSubmitBase(values);
    },
    [onSubmitBase],
  );

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.InputWrapper>
        <Controller
          control={control}
          as={TextField}
          fullWidth
          type="text"
          variant="outlined"
          size="small"
          name="operatorDbId"
          label="Operator DB Id"
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <Controller
          control={control}
          as={TextField}
          fullWidth
          type="text"
          variant="outlined"
          size="small"
          name="firstName"
          label="First name"
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <Controller
          name="isReadyForExplicit"
          control={control}
          render={(props) => (
            <FormControlLabel
              control={<Checkbox {...props} checked={props.value} onChange={(e) => props.onChange(e.target.checked)} />}
              label="is ready for explicit"
            />
          )}
          defaultValue={false}
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <Controller
          control={control}
          as={TextField}
          fullWidth
          type="text"
          variant="outlined"
          size="small"
          name="lastName"
          label="Last name"
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <Controller
          name="agencyTechName"
          control={control}
          render={({ value, onChange }) => <AgencySelector agencies={agencies} value={value} onChange={onChange} />}
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <Controller
          name="scheduleType"
          control={control}
          render={({ value, onChange }) => <ScheduleSelector value={value} onChange={onChange} />}
        />
      </S.InputWrapper>

      <Button type="submit" variant="contained" color="primary" size="small">
        Add operator to mapping
      </Button>
      {` `}
      <Button type="button" variant="outlined" size="small" onClick={onCancel}>
        Cancel
      </Button>
    </S.Form>
  );
};
