import React, { useState, useCallback } from 'react';
import { Box, Paper, InputBase, IconButton, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';

type Props = {
  usersInQueue?: number;
  moderatedUsersCount?: number;
  showInput?: boolean;
};

export const ModerationHeader: React.FC<Props> = (props) => {
  const { usersInQueue, moderatedUsersCount, showInput } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const history = useHistory();

  const onTextChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  const onSubmit = useCallback(() => {
    if (searchText) {
      history.push(`/moderation/user/${searchText}`);
    }
  }, [history, searchText]);

  return (
    <Box className={classes.topContainer}>
      {showInput && (
        <Paper component="form" className={classes.inputContainer} onSubmit={onSubmit}>
          <IconButton type="submit" className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            placeholder="Search"
            inputProps={{ 'aria-label': 'search' }}
            value={searchText}
            onChange={onTextChange}
          />
        </Paper>
      )}
      <Box className={classes.progressContainer}>
        {usersInQueue !== undefined && (
          <Typography className={classes.progressText}>
            👨 <b>{usersInQueue}</b> users in the queue
          </Typography>
        )}
        {moderatedUsersCount !== undefined && (
          <Typography className={classes.progressText}>
            ✅ You&apos;ve done <b>{moderatedUsersCount}</b>
          </Typography>
        )}
      </Box>
    </Box>
  );
};
