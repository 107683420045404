import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useUser } from 'src/services/user/hooks';
import { getUserAge } from 'src/utils/transform';
import { Gender } from 'src/types/user';

import { useStyles } from './styles';

type Props = {
  userId: string;
};

// TODO: maybe add some loader
export const AttendeeInfo = ({ userId }: Props) => {
  const classes = useStyles();
  const { userState, fetchUser } = useUser();

  useEffect(() => {
    fetchUser(userId);
  }, [fetchUser, userId]);

  const { id, name = '', gender, birthday = '' } = userState.value || {};
  const userDeleted = !userState.loading && !id;

  const renderTextInfo = () => {
    if (userDeleted) {
      return '(DELETED)';
    }

    return `${name}, ${gender === Gender.Male ? 'M' : 'F'}, ${getUserAge(birthday)}`;
  };

  return (
    <div className={classes.attendeeInfoContainer}>
      <span className={classes.mediumText}>to {userState.loading ? 'Loading...' : <b>{renderTextInfo()}</b>}</span>
      <span className={classes.smallText}>
        User&nbsp;
        <Link to={`/moderation/user/${userId}`} target="_blank" className={classes.link}>
          <u>{userId}</u>
        </Link>
      </span>
    </div>
  );
};
