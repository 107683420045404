import { ModerationHistoryMedia } from 'src/network/moderation/types';

export enum ChunkType {
  Moderation = 1,
  Approved = 2,
  Declined = 3,
}

export type MediaChunks = {
  [ChunkType.Moderation]?: ModerationHistoryMedia[];
  [ChunkType.Approved]?: ModerationHistoryMedia[];
  [ChunkType.Declined]?: ModerationHistoryMedia[];
};

export const ChunkTypeToTitleMap = {
  [ChunkType.Moderation]: '🔄  Moderation',
  [ChunkType.Approved]: '✅  Approved',
  [ChunkType.Declined]: '❌ Declined',
};
