import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    columnGap: '16px',
    padding: '14px 24px',
    paddingBottom: 24,
    background: 'white',
    boxShadow:
      '0px 18.6951px 24.9268px rgba(0, 0, 0, 0.04), 0px 12.4634px 18.6951px rgba(0, 0, 0, 0.04), 0px 3.11585px 6.23171px rgba(0, 0, 0, 0.04), 0px 0px 0.778964px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-start'
  },

  containerForSinglePhoto: {
    padding: '37px 0px',
    justifyContent: 'center',
    minWidth: 558,
  },

  wrapper: {
    cursor: 'pointer',
  },
});
