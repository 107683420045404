import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    alignSelf: 'start',
    justifySelf: 'start',
  },
});
