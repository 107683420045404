import { AxiosResponse } from 'axios';

import { UserRoles } from 'src/types/user/roles';

type IdentityDto = {
  id: string;
  email: string;
  roles: UserRoles[];
};

const login = (response: AxiosResponse<IdentityDto>) => {
  const token = (response.request as XMLHttpRequest).getResponseHeader('X-Token');

  if (!token) {
    return Promise.reject(new Error('Token not exist'));
  }

  return {
    ...response.data,
    token,
  };
};

export const adapter = {
  login,
};
