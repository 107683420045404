import { useMemo } from 'react';

import { useAgencies, useAgencyTechName } from 'src/services/agency/hooks';
import { Agency } from 'src/types/agency';
import { UserRoles } from 'src/types/user/roles';
import { useAuth } from 'src/services/auth';

export function useAvailableAgencies(): Agency[] {
  const allAgenciesState = useAgencies();
  const agencyTechNameState = useAgencyTechName();
  const { me } = useAuth();

  const availableAgencies = useMemo(() => {
    if (me?.roles?.includes(UserRoles.AgencyAdministrator)) {
      return allAgenciesState.value || [];
    }

    if (me?.roles?.includes(UserRoles.Agency)) {
      return (allAgenciesState.value || []).filter((agency) => agency.techName === agencyTechNameState.value);
    }

    return [];
  }, [allAgenciesState, me, agencyTechNameState]);

  return availableAgencies;
}
