import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  header: {
    marginBottom: 16,
  },

  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    columnGap: '100px',
    paddingBottom: '110px',
  },

  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '20px',
  },

  wordsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },

  wordRow: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },

  wordNumber: {
    textAlign: 'right',
    minWidth: '40px',
  },

  wordInput: {
    width: '250px',
    height: '32px',
    border: '1px solid #000',
    borderRadius: '8px',
    padding: '0 16px',

    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#101014',
    fontSize: '16px',
    lineHeight: '24px',

    '&::placeholder': {
      color: '#76767A',
    },
  },

  removeImageButton: {
    width: '32px',
    height: '32px',
    border: '1px solid black',
    borderRadius: '8px',
    cursor: 'pointer',
    padding: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      opacity: 0.5,
    },
  },

  trashIcon: {
    width: '60px',
    height: '60px',
  },
});
