export type DictType = {
  [key: string]: string;
};

export const interestsDict: DictType = {
  music: 'Music',
  sports: 'Sports',
  gaming: 'Gaming',
  reading: 'Reading',
  cooking: 'Cooking',
  fitness: 'Fitness',
  art: 'Art',
  movies: 'Movies',
  crafting: 'Crafting',
  dance: 'Dance',
  writing: 'Writing',
  knitting: 'Knitting',
  travel: 'Travel',
  running: 'Running',
  'outdoor-activity': 'Outdoor activity',
  hiking: 'Hiking',
  photography: 'Photography',
  shopping: 'Shopping',
  food: 'Food',
};

export const getDictionaryValues = (ids: string[], dictionary: DictType) => {
    return ids ? ids.map(it => dictionary[it]).filter(it => !!it) : [];
};
