import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {},

  historyContainer: {
    width: '100%',
    paddingTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },

  historyItem: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#020D3D',
    marginBottom: '12px',
  },

  historyTitle: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '36px',
  },
});
