import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { Product } from 'src/types/product';
import { useAuth } from 'src/services/auth';

const commonTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#F6F6F6',
    },
  },
};

const magnetTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#6C20B9',
    },
  },
};

const rawTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#EF7BF8',
    },
  },
};

const flureTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#F2DAF5',
    },
  },
};

const AppTheme = {
  [Product.Lovinga]: commonTheme,
  [Product.Once]: commonTheme,
  [Product.Flure]: flureTheme,
  [Product.Magnet]: magnetTheme,
  [Product.RAW]: rawTheme,
};

export const useTheme = () => {
  const { me } = useAuth();

  return me?.realm ? createMuiTheme(AppTheme[me.realm]) : {};
};
