import React, { PropsWithChildren } from 'react';
import { Layout } from 'src/components/App/views/Layout';
import Typography from '@material-ui/core/Typography';

type Props = {
  headerCaption: string;
};

export function ReportLayout(props: PropsWithChildren<Props>) {
  const { headerCaption, children } = props;

  return (
    <Layout containerSize="lg">
      <Typography component="h1" variant="h5">
        {headerCaption}
      </Typography>
      <>{children}</>
    </Layout>
  );
}
