import { makeStyles } from '@material-ui/core';

import { layoutMaxWidth } from 'src/constants';

const useCommonStyles 
= makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 24px',
    maxWidth: layoutMaxWidth,
    margin: 'auto',
  },

  wrapper: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
  },

  fakeContainer: {
    position: 'relative',
    margin: 0,
  },
  moderationButton: {
    color: 'white',
    textTransform: 'none' as const,
    textAlign: 'center' as const,
    borderRadius: '8px',
    marginInline: '2px',
    fontFamily: 'Podkova',
    fontWeight: 600,

    '@media (min-width: 1220px)': {
      minWidth: '100px',
      height: '78px',
    },
    fontSize: '24px',
    height: '78px',
    minWidth: '108px',
  },

  undo: {
    backgroundColor: '#40A3FF',
  },
  reject: {
    backgroundColor: '#FF3B30',
  },
  approve: {
    backgroundColor: '#EF7BF8',
    width: '400px',
  },
});

export const useProductStyles = () => {
  const commonClasses = useCommonStyles();

  return commonClasses;
};
