import React, { useCallback, useRef, useMemo, useState, useEffect } from 'react';
import { Typography, Box } from '@material-ui/core';
import clsx from 'clsx';

import {
  Logger,
  LoggerServices,
  LoggerMessages,
  MaxMediaLoadDurationBeforeLog,
} from 'src/infrastructure/loggers/datadog';
import { useAuth } from 'src/services/auth';
import { moderationRequest } from 'src/network';
import { noop } from 'src/utils/functions';

import { useStyles } from './styles';

type Props = {
  src: string;
  className?: string;
  autoPlay?: boolean;
  loop?: boolean;
  topLabel?: string;
  topLabelColor?: string;
  basename?: string;
  videoStyle?: string;
};

export const VideoWithLogger: React.FC<Props> = (props) => {
  const { basename, src, className, autoPlay, loop, topLabel, topLabelColor = '#ED654C', videoStyle } = props;
  const classes = useStyles({ topLabelColor });
  const startTime = useRef(0);
  const { me } = useAuth();
  const isLoaded = useRef(false);
  const operatorId = useMemo(() => me?.id || '', [me]);
  const product = useMemo(() => me?.realm || '', [me]);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [celebrityName, setCelebrityName] = useState('');

  const onLoadStart = useCallback(() => {
    startTime.current = Date.now();
  }, []);

  const onLoadEnd = useCallback(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      setIsVideoLoaded(true);
      const endTime = Date.now();
      const duration = endTime - startTime.current;

      if (duration > MaxMediaLoadDurationBeforeLog) {
        Logger.log({
          service: LoggerServices.Moderation,
          message: LoggerMessages.VideoLoadDuration,
          product,
          payload: {
            operatorId,
            duration,
            uri: src,
          },
        });
      }
    }
  }, [operatorId, product, src]);

  useEffect(() => {
    if (basename) {
      moderationRequest
        .detectCelebrityOnPhoto(basename.slice(0, 16))
        .then((data) => {
          if (data?.name) {
            setCelebrityName(data.name);
          }
        })
        .catch(noop);
    }
  }, [basename]);

  return (
    <Box className={clsx(className, classes.videoContainer)}>
      <div className={classes.videoWithLabelContainer}>
        <video
          className={clsx(classes.video, videoStyle)}
          autoPlay={autoPlay}
          loop={loop}
          muted
          onCanPlayThrough={onLoadEnd}
          onLoadStart={onLoadStart}
        >
          <source src={src} />
          Your browser doesn&lsquo;t support video
        </video>
        {isVideoLoaded && celebrityName && (
          <Box className={classes.celebrityContainer}>
            <Typography className={classes.celebrityName}>{celebrityName}</Typography>
          </Box>
        )}
        {isVideoLoaded && topLabel && (
          <Box className={classes.topLabelContainer}>
            <Typography className={classes.topLabelText}>{topLabel}</Typography>
          </Box>
        )}
      </div>
    </Box>
  );
};
