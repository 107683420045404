import React, { useMemo } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { useAuth } from 'src/services/auth';

type PrivateRouteProps = {
  component: React.FC<RouteComponentProps<{}>>;
  path: string;
  exact?: boolean;
  condition?: boolean;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, condition, ...rest }) => {
  const { me } = useAuth();

  const tokenExist = !!me?.token;

  const needToRender = useMemo(() => (typeof condition === 'boolean' ? condition : true), [condition]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return tokenExist && needToRender ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />
        );
      }}
    />
  );
};
