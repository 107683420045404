import React from 'react';

import { Layout } from 'src/components/App/views/Layout';
import { BenefitType } from 'src/types/moderation';

import { useStyles } from './styles';
import { BenefitPanel } from './components';

export const VibesAndPremiumManagementScreen = () => {
  const classes = useStyles();

  return (
    <Layout containerSize="xl">
      <div className={classes.container}>
        <BenefitPanel type={BenefitType.Subscription} />
        <BenefitPanel type={BenefitType.Vibes} />
      </div>
    </Layout>
  );
};
