import React from 'react';
import { Link } from 'react-router-dom';

import { formatCountryAndCity } from 'src/utils/format-country-and-city';
import { MediaTag } from 'src/types/user';
import { MessageSenderProfile } from 'src/network/flure-chat-moderation/types';
import { useGetMediaSourceAuthed } from 'src/components/Moderation/utils/image-source';
import { genderIdentityDict } from 'src/components/FlureFeed/constants';

import { useStyles } from './styles';

type Props = {
  user: MessageSenderProfile;
};

const emptyObject = {};

export const UserInfo = ({ user }: Props) => {
  const getMediaSource = useGetMediaSourceAuthed();
  const classes = useStyles();

  const { genderIdentity, birthday, media = [], name, id: userId, location } = user || emptyObject;
  const { country, city } = location || emptyObject;

  const thumbnailMedia = media?.find((el) => el.tags.includes(MediaTag.Thumbnail)) || media[0];
  const thumbnailMediaSource =
    thumbnailMedia && userId ? getMediaSource(userId, thumbnailMedia.baseName, thumbnailMedia.mediaType, true) : '';

  return (
    <div className={classes.userInfoContainer}>
      <img className={classes.userThumbnail} src={thumbnailMediaSource} alt="thumbnail" />
      <div className={classes.textsContainer}>
        <span className={classes.nameText}>
          {name}, {birthday?.age}
        </span>
        {genderIdentity && <span className={classes.smallText}>{genderIdentityDict[genderIdentity]}</span>}
        {(country || city) && <span className={classes.smallText}>{formatCountryAndCity(country, city)}</span>}
        <span className={classes.smallText}>
          User&nbsp;
          <Link to={`/moderation/user/${userId}`} target="_blank" className={classes.link}>
            <u>{userId}</u>
          </Link>
        </span>
      </div>
    </div>
  );
};
