import { Http } from 'src/network/http';

type UserLifeStoryDto = {
  story: string;
};

const get = (ownerId: string) => {
  return Http.shared()
    .instance.get<UserLifeStoryDto>(`/users/${ownerId}/lifestyle`)
    .then((response) => response.data as UserLifeStoryDto)
    .then((response) => response.story);
};

const save = (ownerId: string, story: string) => {
  return Http.shared().instance.put(`/users/${ownerId}/lifestyle`, { story });
};

export const userLifeStoryRequest = {
  get,
  save,
};
