export enum Level {
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
}

export type Log = {
  service: string;
  message: string;
  product: string;
  payload?: Record<string, any>;
};
