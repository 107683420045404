import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { Layout } from 'src/components/App/views/Layout';
import { ModerationProvider } from 'src/context/raw/moderation';
import { TextContent } from 'src/components/common/raw';
import { BlockUserModal } from 'src/components/Moderation/views/raw/block-user-modal';
import { useAuth } from 'src/services/auth';
import { MaxOnScreenPresenceTime, useStore, useUserPreset } from 'src/context/raw/moderation/hooks';
import { rawModerationRequest } from 'src/network/moderation/raw';
import { RAW } from 'src/types/raw';
import { useRefCallback } from 'src/hooks/raw';

import { ActionsHistory } from './components/actions-history';
import { Media } from './components/media';
import { useStyles } from './styles';

const AdminUserModerationRAW = () => {
  const { userId } = useParams<{ userId: string }>();
  const { me } = useAuth();
  const operatorId = me?.id || '';
  const { setIsSending, toggleBlockModal, setModerationActions, moderationActions } = useStore(operatorId);
  const classes = useStyles();
  const timer = useRef<number>();
  const { userPreset, fetchUserPreset } = useUserPreset(userId);
  const resetAction = useRefCallback((shouldFetch?: boolean) => {
    if (me && moderationActions.length) {
      clearTimeout(timer.current);
      rawModerationRequest.sendRAWModerationActionsForUser(me.id, userId, moderationActions as RAW.ResetAction[]);

      setModerationActions([]);
      if (shouldFetch) {
        fetchUserPreset();
      }
    }
  });

  useEffect(() => {
    setModerationActions([]);
  }, [setModerationActions]);

  useEffect(() => {
    if (moderationActions.length) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => resetAction.current(true), MaxOnScreenPresenceTime);
    }
  }, [moderationActions, me, userId, resetAction]);

  useEffect(() => {
    const resetActionCb = () => resetAction.current();

    return resetActionCb;
  }, [resetAction]);

  if (!me) {
    return null;
  }

  return (
    <Layout containerSize="xl">
      <Box className={classes.mainContainer}>
        <BlockUserModal actorId={userId} operatorId={me?.id} setIsSending={setIsSending} />
        <Box className={classes.textContentWrapper}>
          {userPreset.value && (
            <Box className={classes.textContent}>
              <TextContent onBlock={() => toggleBlockModal(true)} user={userPreset.value} operatorId={operatorId} />
            </Box>
          )}
          <Box className={classes.separator} />
          {userId && <ActionsHistory userId={userId} />}
        </Box>

        <Box className={classes.mediaContentWrapper}>{userId && <Media userId={userId} />}</Box>
      </Box>
    </Layout>
  );
};

export const AdminUserModerationRAWScreen = (props: PropsWithChildren<any>) => (
  <ModerationProvider>
    <AdminUserModerationRAW {...props} />
  </ModerationProvider>
);
