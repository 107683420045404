import React from 'react';

import { RAW } from 'src/types/raw';
import { getMediaSourceRAW } from 'src/components/Moderation/utils/image-source';
import { ImageWithLogger } from 'src/components/Moderation/views/raw/image-with-logger';

import { useStyles } from './styles';

type Props = {
  content: RAW.ProfilePhotos;
  userId: RAW.UserPreset['profile']['id'];
  onPhotoPress: (sources: string[]) => void;
};

export const ProfileSecondaryContent = ({ content, onPhotoPress, userId }: Props) => {
  const classes = useStyles();

  return (
    <>
      {content.slice(0, 2).map((photo) => (
        <ImageWithLogger
          key={photo}
          onClick={() => onPhotoPress([getMediaSourceRAW(userId, photo)])}
          loading="lazy"
          className={classes.image}
          src={getMediaSourceRAW(userId, photo)}
          alt="profilePhoto"
        />
      ))}
    </>
  );
};
