import React from 'react';

import { useStyles } from './styles';

export const TextStylingHints = React.memo(() => {
  const classes = useStyles();

  return (
    <div className={classes.hintsContainer}>
      <p>
        <b>Text Styling Hints:</b>
      </p>
      <p>
        - Use{' '}
        <b>
          {'{'}Name{'}'}
        </b>{' '}
        syntax for name display
      </p>
      <p>
        - Use <b>{'<b>'}</b>text<b>{'</b>'}</b> tags to make text <b>Bold</b>
      </p>
      <p>
        - Use <b>{'<i>'}</b>text<b>{'</i>'}</b> tags to make text <i>Italic</i>
      </p>
      <p>
        - Use <b>[link=https://...]</b>display link text<b>[/link]</b> syntax for links formatting
      </p>
    </div>
  );
});
