import styled from 'styled-components';

export const Header = styled.div`
  margin: 20px 0;
  position: relative;
`;

export const HeaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(250, 250, 250, 0.82);
  cursor: not-allowed;
  z-index: 100;
`;
