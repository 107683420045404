import { useCallback, useState } from 'react';

import { userRequest } from 'src/network';

export const useUserLifeStory = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const [lifeStory, setLifeStory] = useState<string | null>(null);

  const fetchLifeStory = useCallback(async (id: string) => {
    setUserId(id);
    setLifeStory(null);

    const response = await userRequest.lifeStory.get(id);

    setLifeStory(response);
  }, []);

  const updateLifeStory = useCallback(
    async (story: string) => {
      if (!userId) {
        return;
      }

      await userRequest.lifeStory.save(userId, story);
      setLifeStory(null);
    },
    [userId],
  );

  return {
    lifeStory,
    fetchLifeStory,
    updateLifeStory,
  };
};
