import React, { useMemo } from 'react';

import { ActionType, ModerationAction, UserForModeration } from 'src/types/moderation';
import { FlureActions } from 'src/components/Moderation/views/actions/Flure';

type Props = {
  isPressed: (actionType: ActionType, actionValue?: string | undefined) => boolean;
  undoAction: (actionType: ActionType) => () => void;
  undo: () => void;
  resetAbout: () => void;
  resetName: () => void;
  moderationActions: ModerationAction[];
  isLoading: boolean;
  sendModerationActions: (actions: ModerationAction[]) => Promise<void>;
  isNoUndoUsers: boolean;
  addModerationAction: (actionType: ActionType, actionValue?: string | undefined) => void;
} & Pick<UserForModeration, 'newMediaSource' | 'mainMediaSource' | 'newMediaType'>;

export const Actions = ({
  undoAction,
  undo,
  addModerationAction,
  resetAbout,
  resetName,
  moderationActions,
  isPressed,
  newMediaSource,
  mainMediaSource,
  isLoading,
  sendModerationActions,
  isNoUndoUsers,
  newMediaType,
}: Props) => {
  const moderatedMedia = useMemo(() => newMediaSource || mainMediaSource, [newMediaSource, mainMediaSource]);

  return (
    <FlureActions
      moderationActions={moderationActions}
      undo={undo}
      newMediaSource={newMediaSource}
      isNoUndoUsers={isNoUndoUsers}
      isLoading={isLoading}
      addModerationAction={addModerationAction}
      sendModerationActions={sendModerationActions}
      isPressed={isPressed}
      undoAction={undoAction}
      resetName={resetName}
      moderatedMedia={moderatedMedia}
      resetAbout={resetAbout}
      newMediaType={newMediaType}
    />
  );
};
