import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

export const useModerationNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showReloadNotification = useCallback(() => {
    enqueueSnackbar('The wait was too long, please reload your screen', { variant: 'error' });
  }, [enqueueSnackbar]);

  const showSendActionsErrorNotification = useCallback(
    (error) => {
      enqueueSnackbar(`Send moderation action:${error?.response ? String(error?.response) : String(error)}`, {
        variant: 'error',
      });
    },
    [enqueueSnackbar],
  );

  const showNoIdErrorNotification = useCallback(() => {
    enqueueSnackbar('Cannot get your user id. Try to reload or re-signin', { variant: 'error' });
  }, [enqueueSnackbar]);

  return {
    showReloadNotification,
    showSendActionsErrorNotification,
    showNoIdErrorNotification,
  };
};
