import { makeStyles } from '@material-ui/core';

const inputTextStyle = {
  fontFamily: 'Poppins',
  fontWeight: 400,
  color: '#101014',
  fontSize: '16px',
  lineHeight: '24px',
};

export const useStyles = makeStyles({
  header: {
    marginBottom: 16,
  },

  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    columnGap: '100px',
    paddingBottom: '110px',
  },

  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '20px',
  },

  wordsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },

  wordRow: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },

  alignEnd: {
    alignItems: 'flex-end',
  },

  wordNumber: {
    textAlign: 'right',
    minWidth: '40px',
  },

  wordInput: {
    width: '250px',
    height: '32px',
    border: '1px solid #000',
    borderRadius: '8px',
    padding: '0 16px',

    '&::placeholder': {
      color: '#76767A',
    },

    ...inputTextStyle,
  },

  textArea: {
    marginLeft: '48px',
    width: '250px',
    minWidth: '250px',
    maxWidth: '250px',
    minHeight: '64px',
    border: '1px solid #000',
    borderRadius: '8px',
    padding: '8px 16px',

    ...inputTextStyle,
  },

  actionButton: {
    width: '32px',
    height: '32px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    padding: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    '-webkit-box-shadow': '0px 0px 4px 0px rgba(0,0,0,0.54)',
    '-moz-box-shadow': '0px 0px 4px 0px rgba(0,0,0,0.54)',
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.54)',

    '&:hover': {
      opacity: 0.5,
    },
  },

  deleteButton: {
    backgroundColor: '#EA4551',

    '& svg': {
      color: 'white',
    },
  },

  applyButton: {
    backgroundColor: '#00C376',

    '& svg': {
      color: 'white',
    },
  },

  trashIcon: {
    width: '60px',
    height: '60px',
  },

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '8px',
  },
});
