import React from 'react';

import { Resources } from 'src/resources';
import { DEFAULT_AGE, MAX_AGE, MIN_AGE, genderIdentityOptions } from 'src/components/FlureFeed/constants';
import { TitleText, TitleTextSize, BubbleCheckBoxesList, AutocompleteSearchableInput } from 'src/components/common/flure';
import { Slider } from 'src/components/common/flure/';
import { LocationOption } from 'src/components/FlureFeed/utils';

import { useStyles } from './styles';

type Props = {
  age: number;
  genderIdentities: string[];
  setGenderIdentities: React.Dispatch<React.SetStateAction<string[]>>;
  myLocationOptions: LocationOption[];
  onMyLocationChange: (option: LocationOption | null) => void;
  onAgeChange: (age: number) => void;
  myLocation?: string;
  searchLocations: (substring?: string) => void;
};

const UserProfileParamsView = (props: Props) => {
  const classes = useStyles();
  const {
    age,
    genderIdentities,
    setGenderIdentities,
    myLocationOptions,
    onMyLocationChange,
    onAgeChange,
    myLocation,
    searchLocations,
  } = props;

  return (
    <div className={classes.container}>
      <div className={classes.filterParamContainer}>
        <TitleText
          className={classes.subtitle}
          text={Resources.strings.flureFeed.myLocation}
          size={TitleTextSize.Medium}
        />
        <AutocompleteSearchableInput
          defaultValue={myLocationOptions[0]}
          options={myLocationOptions}
          onChange={onMyLocationChange}
          value={myLocationOptions.find((it) => it.value === myLocation)}
          searchLocations={searchLocations}
        />
      </div>
      <div className={classes.filterParamContainer}>
        <TitleText
          className={classes.subtitle}
          text={Resources.strings.flureFeed.myGender}
          size={TitleTextSize.Medium}
        />
        <BubbleCheckBoxesList
          options={genderIdentityOptions}
          selected={genderIdentities}
          setSelected={setGenderIdentities}
          isMultiple={false}
        />
      </div>
      <div className={classes.filterParamContainer}>
        <TitleText className={classes.subtitle} text={Resources.strings.flureFeed.myAge} size={TitleTextSize.Medium} />
        <Slider defaultValue={DEFAULT_AGE} min={MIN_AGE} max={MAX_AGE} onChange={onAgeChange} value={age} />
      </div>
    </div>
  );
};

export const UserProfileParams = React.memo(UserProfileParamsView);
