import React from 'react';
import Typography from '@material-ui/core/Typography';

import { Layout } from 'src/components/App/views/Layout';
import { useManagingNewOperator } from 'src/services/operator/hooks';

import { OperatorsManagingForm } from './CreationForm';

export const OperatorsManagingScreen = () => {
  const { manageOperator, operatorsData } = useManagingNewOperator();

  return (
    <Layout>
      <Typography component="h1" variant="h5">
        Operators Managing
      </Typography>
      <OperatorsManagingForm requestError={operatorsData.error} onSubmit={manageOperator} />
    </Layout>
  );
};
