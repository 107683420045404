import React from 'react';

import { useStyles } from './styles';

type Props = {
  loading: boolean;
};

export const MockSecondaryContent = ({ loading }: Props) => {
  const classes = useStyles();

  if (!loading) {
    return <div className={classes.mock} />;
  }

  return <div className={classes.image}>{loading && <span className={classes.loader} />}</div>;
};
