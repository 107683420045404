import React, { useCallback, useMemo } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

type Props = {
  variants: { key: string; value: string }[];
  value?: string;
  emptyPlaceholder?: string;
  onChange: (value: string) => void;
};

export const Selector = ({ variants, value = 'null', emptyPlaceholder = 'Nothing', onChange: onChangeBase }: Props) => {
  const agencyItems = useMemo(
    () =>
      variants.map((variant) => (
        <MenuItem key={variant.key} value={variant.key}>
          {variant.value}
        </MenuItem>
      )),
    [variants],
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      onChangeBase(event.target.value as string);
    },
    [onChangeBase],
  );

  return (
    <Select fullWidth displayEmpty={false} value={value} onChange={onChange}>
      <MenuItem value="null" key="0">
        {emptyPlaceholder}
      </MenuItem>
      {agencyItems}
    </Select>
  );
};
