import { addDays, format } from 'date-fns';
import { PromoCodeData } from 'src/network/flure-promocodes/types';

export enum PromoCodeField {
  id = 'id',
  eventName = 'eventName',
  activeFrom = 'activeFrom',
  activeUntil = 'activeUntil',
  subTTL = 'subTTL',
  eventCity = 'eventCity',
  stageName = 'stageName',
  qr = 'qr',
  fullLink = 'fullLink',
  shortLink = 'shortLink',
}

export const promoCodeFields = Object.values(PromoCodeField);

export const fieldToTitleMap = {
  [PromoCodeField.id]: 'ID (unique)',
  [PromoCodeField.eventName]: 'EventName',
  [PromoCodeField.activeFrom]: 'ActiveFrom',
  [PromoCodeField.activeUntil]: 'ActiveUntil',
  [PromoCodeField.subTTL]: 'Sub.TTL',
  [PromoCodeField.eventCity]: 'EventCity',
  [PromoCodeField.stageName]: 'Camp.Name',
  [PromoCodeField.qr]: 'QR',
  [PromoCodeField.fullLink]: 'LinkToFlureApp',
  [PromoCodeField.shortLink]: 'LinkAppShort',
};

export const fieldToTooltipTextMap = {
  [PromoCodeField.id]: 'The unique code for each event. Example: ny_event_1',
  [PromoCodeField.eventName]: 'The name of the Event. Example: The White House Event',
  [PromoCodeField.activeFrom]: 'The date when the event starts. Example: 2024-05-01',
  [PromoCodeField.activeUntil]: 'The expiration date when the link stops give Free Premium. Example: 2024-05-15',
  [PromoCodeField.subTTL]: 'The period in days for Free Premium access. Example: 7',
  [PromoCodeField.eventCity]: 'The city of the event. Example: New York',
  [PromoCodeField.stageName]:
    'Parameter from Appsflyer One Link Management. Need to be taken from "Campaign name" parameter. Example: ny_campaign_1',
  [PromoCodeField.qr]: 'Generated automatically from LinkToFlureApp',
  [PromoCodeField.fullLink]:
    'Parameter from Appsflyer One Link Management. Need to be taken from "Long Url" parameter. Example: https://flure.onelink.me/wUIC?af_xp=custom&pid=Local_Events&c=ny_campaign_1&deep_link_value=ny_campaign_1&af_dp=flure%3A%2F%2F&event_name=NY%20Campaign',
  [PromoCodeField.shortLink]:
    'Parameter from Appsflyer One Link Management. Need to be taken from "Short Url" parameter. Example: https://flure.onelink.me/wUIC/7r3wr5aa',
};

export const dateFormat = 'yyyy-MM-dd';
export const defaultTTLDays = 7;

export const emptyRow: PromoCodeData = {
  id: 'new_event',
  eventName: '',
  activeFrom: format(new Date(), dateFormat),
  activeUntil: format(addDays(new Date(), defaultTTLDays), dateFormat),
  subTTL: defaultTTLDays,
  eventCity: '',
  stageName: '',
  fullLink: '',
  shortLink: '',
};
