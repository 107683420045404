import React from 'react';
import { Button, Typography, Modal, Box } from '@material-ui/core';
import clsx from 'clsx';
import { Resources } from 'src/resources';

import { useProductStyles } from './styles';

type Props = {
  isOpen: boolean;
  confirmationText?: string;
  rejectionText?: string;
  onConfirm: () => void;
  onReject: () => void;
};

export const ConfirmationModal = (props: Props) => {
  const {
    isOpen,
    confirmationText = Resources.strings.yes,
    rejectionText = Resources.strings.no,
    onConfirm,
    onReject,
  } = props;
  const classes = useProductStyles();

  return (
    <Modal open={isOpen} className={classes.modal}>
      <Box className={classes.modalContent}>
        <Typography className={classes.title}>Are you sure ?</Typography>
        <Button variant="contained" className={clsx(classes.button, classes.block)} onClick={onConfirm}>
          {confirmationText}
        </Button>
        <Button variant="contained" className={clsx(classes.button, classes.cancel)} onClick={onReject}>
          {rejectionText}
        </Button>
      </Box>
    </Modal>
  );
};
