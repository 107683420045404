import React, { memo, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { Resources } from 'src/resources';
import { VerificationPhoto } from 'src/components/Moderation/views/verification-photo';
import { PhotoTransformation } from 'src/components/Moderation/utils/image-source';

import { useStyles } from './styles';

type Props = {
  userId: string;
};

const VerificationMediaView = (props: Props) => {
  const { userId } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const classes = useStyles();

  return (
    <Box key="verification" className={classes.verificationCardContainer}>
      <div className={classes.photoWithLoader}>
        <VerificationPhoto
          userId={userId}
          checkIfVerified={false}
          imageContainerStyle={classes.verificationImageContainer}
          imageStyle={clsx(classes.verificationImage, !isLoaded && classes.hidden)}
          transformation={PhotoTransformation.KeepAspectRatio}
          onLoadEnd={() => setIsLoaded(true)}
          zoomEnabled
        />
        {!isLoaded && (
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        )}
      </div>
      <span className={classes.mediaName}>{Resources.strings.moderation.media.verificationShort}</span>
    </Box>
  );
};

export const VerificationMedia = memo(VerificationMediaView);
