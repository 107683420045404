import React, { FC } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Controller, Control } from 'react-hook-form';

type Props = {
  name: string;
  label: string;
  control: Control<Record<string, unknown>>;
  defaultValue?: unknown;
  id?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  size?: 'small' | 'medium';
  error: boolean;
};

const ReactHookFormSelect: FC<Props> = ({ error, name, label, control, defaultValue, children, ...props }) => {
  const labelId = `${name}-label`;

  return (
    <FormControl {...props} style={{ width: '100%' }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        as={
          <Select labelId={labelId} label={label}>
            {children}
          </Select>
        }
        name={name}
        error={error}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};
export default ReactHookFormSelect;
