import { string, object } from 'yup';

import { validateToContainTwoDates } from 'src/utils/string';

const validationSchema = object().shape({
  rangeDate: string()
    .test('values', 'Select dates', (value) => {
      if (!value) {
        return false;
      }

      return validateToContainTwoDates(value);
    })
    .required('Dates range is required'),
  mode: string().required('Mode is required'),
});

export default validationSchema;
