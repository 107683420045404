import merge from 'merge';
import { Product } from 'src/types/product';
import { storage } from 'src/utils/storage';
import commonStrings from './common.json';
import magnetStrings from './magnet.json';
import udatesStrings from './udates.json';
import flureStrings from './flure.json';

const stringsToMerge: Array<{}> = [commonStrings];

type ResourceStrings = typeof commonStrings;

export const compileStrings = (): ResourceStrings => {
  const realm = storage.get<{ realm: Product }>('me')?.realm;
  switch (realm) {
    case Product.Magnet:
      stringsToMerge.push(magnetStrings);
      break;
    case Product.Once:
      stringsToMerge.push(udatesStrings);
      break;
    case Product.Flure:
      stringsToMerge.push(flureStrings);
      break;
    default:
  }

  return merge.recursive(true, ...stringsToMerge);
};
