import { useAsyncFn } from 'react-use';
import moment from 'moment';

import { reportRequest } from 'src/network';
import { GetReportByKPIRequestParams } from 'src/network/report/kpi/types';
import { DefaultReportFormFields } from 'src/types/report/common';

import { convertBaseFormFieldsToQueryParams } from './helpers';

function convertFormFieldsToQueryParams(fields: DefaultReportFormFields): GetReportByKPIRequestParams {
  const [from, to] = fields.rangeDate.split(':');

  const baseParams = convertBaseFormFieldsToQueryParams(fields, 'locals');
  const params: GetReportByKPIRequestParams = {
    ...baseParams,
    skip: 0 /* hardcoded for now */,
    limit: 1000000 /* hardcoded for now, implemented on the api for the future optimization of reports */,
    from,
    to: moment(to).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    agencyTechName: fields.agencyTechName ? String(fields.agencyTechName) : undefined,
    operatorId: fields.operatorId ? String(fields.operatorId) : undefined,
  };

  return params;
}

export const useReportByKPI = () => {
  const [reportByKPIState, fetchReportByKPI] = useAsyncFn(async (params: DefaultReportFormFields) => {
    const queryParams = convertFormFieldsToQueryParams(params);
    return reportRequest.kpi.get(queryParams);
  }, []);

  return { reportByKPIState, fetchReportByKPI };
};
