import { useCallback } from 'react';

import { Session } from 'src/services/raw/session';

import { actions, useModerationContext } from '../index';

export const useStore = (operatorId: string) => {
  const {
    dispatch,
    state: {
      contentType,
      moderationActions,
      isSending,
      moderatedUsersCount,
      statics,
      isBlockModalOpen,
      isQueueBlocked,
    },
  } = useModerationContext();

  const setModerationAction = useCallback(
    (action: Parameters<typeof actions.setModerationAction>[0]) => dispatch(actions.setModerationAction(action)),
    [dispatch],
  );
  const setModerationActions = useCallback(
    (action: Parameters<typeof actions.setModerationActions>[0]) => dispatch(actions.setModerationActions(action)),
    [dispatch],
  );
  const resetModerationActions = useCallback(() => dispatch(actions.resetModerationActions()), [dispatch]);
  const setIsSending = useCallback(
    (val: Parameters<typeof actions.setIsSending>[0]) => dispatch(actions.setIsSending(val)),
    [dispatch],
  );
  const changeContentType = useCallback(
    (val: Parameters<typeof actions.changeContentType>[0], forceUpdateByUserClick?: boolean) => {
      if (!isQueueBlocked || forceUpdateByUserClick) {
        dispatch(actions.changeContentType(val));
        Session.shared().setContentType(operatorId, val);

        return val;
      }

      return contentType;
    },
    [dispatch, isQueueBlocked, operatorId, contentType],
  );
  const setModeratedUsersCount = useCallback(
    (val: Parameters<typeof actions.setModeratedUsersCount>[0]) => dispatch(actions.setModeratedUsersCount(val)),
    [dispatch],
  );
  const toggleBlockModal = useCallback(
    (val: Parameters<typeof actions.toggleBlockModalState>[0]) => dispatch(actions.toggleBlockModalState(val)),
    [dispatch],
  );
  const setQueueBlock = useCallback(
    (val: Parameters<typeof actions.setQueueBlock>[0]) => {
      dispatch(actions.setQueueBlock(val));
      Session.shared().setIsQueueBlocked(operatorId, val);
    },
    [dispatch, operatorId],
  );

  return {
    contentType,
    moderationActions,
    isSending,
    moderatedUsersCount,
    statics,
    isBlockModalOpen,
    isQueueBlocked,
    setModerationAction,
    setModerationActions,
    setIsSending,
    changeContentType,
    setModeratedUsersCount,
    resetModerationActions,
    toggleBlockModal,
    setQueueBlock,
  };
};
