import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  textCommon: {
    fontFamily: 'PlayfairDisplay',
    fontWeight: 700,
    color: '#101014',
  },

  smallText: {
    fontSize: '20px',
    lineHeight: '24px',
  },

  mediumText: {
    fontSize: '32px',
    lineHeight: '43px',
  },

  largeText: {
    fontSize: '40px',
    lineHeight: '47px',
  },
});
