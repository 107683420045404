import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '200px',
    paddingTop: '10px',
  },

  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '335px',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
  },

  title: {
    marginBottom: '32px',
  },

  subtitle: {
    marginBottom: '25px',
  },

  checkBoxesContainer: {
    marginBottom: '32px',
  },

  rightContainer: {
    width: '375px',
    display: 'flex',
    flexDirection: 'column',
  },
});
