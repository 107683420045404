import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '6px',
    flexWrap: 'wrap',
    rowGap: '8px',
  },

  interestBubble: {
    display: 'flex',
    border: '1px solid #101014',
    padding: '6px 14px',
    borderRadius: '100px',
    backgroundColor: 'white',
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '21px',
  },

  emptyValue: {
    fontFamily: 'SFCompactDisplay',
    fontSize: '28px',
    lineHeight: '33px',
    fontWeight: 400,
    color: '#878E9C',
  }
});
