import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  noContentContainer: {
    position: 'relative',
    display: 'flex',
    paddingTop: '52px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
