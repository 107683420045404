import React, { useMemo } from 'react';
import { Operator, UpdatedOperatorState } from 'src/types/operator';
import { Agency } from 'src/types/agency';
import { MultichatAgencyOperatorsMappingTableRow } from './Row';
import * as S from './styled';

type Props = {
  operators: Operator[];
  agencies: Agency[];
  searchedPhrase: string;
  onSave: (operatorId: string, data: UpdatedOperatorState) => void;
};

const COLUMN_IDS = {
  OPERATOR: 'Operator ID',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EXPLICIT: 'Explicit',
  SCHEDULE: 'Schedule',
  AGENCY: 'Agency',
  OPTIONS: 'Options',
};

const COLUMNS = [
  COLUMN_IDS.OPERATOR,
  COLUMN_IDS.FIRST_NAME,
  COLUMN_IDS.LAST_NAME,
  COLUMN_IDS.EXPLICIT,
  COLUMN_IDS.SCHEDULE,
  COLUMN_IDS.AGENCY,
  COLUMN_IDS.OPTIONS,
];

const COLUMN_PARAMS = {
  [COLUMN_IDS.EXPLICIT]: { width: 60 },
};

export const MultichatAgencyOperatorsMappingTable = ({
  operators: fetchedOperators,
  agencies,
  searchedPhrase,
  onSave,
}: Props) => {
  const operators = useMemo(
    () =>
      fetchedOperators.filter(
        (operator) =>
          operator.operatorId.toString().toLowerCase().includes(searchedPhrase) ||
          operator.operatorDbId.toString().toLowerCase().includes(searchedPhrase) ||
          operator.firstName.toLowerCase().includes(searchedPhrase) ||
          operator.lastName.toLowerCase().includes(searchedPhrase),
      ),
    [fetchedOperators, searchedPhrase],
  );

  const columns = useMemo(
    () =>
      COLUMNS.map((id) => ({
        name: id,
        params: COLUMN_PARAMS[id],
      })),
    [],
  );

  const rows = useMemo(() => {
    if (operators.length === 0) {
      return (
        <tr>
          <td colSpan={5}>Empty</td>
        </tr>
      );
    }

    return operators.map((operator) => (
      <MultichatAgencyOperatorsMappingTableRow
        key={operator.operatorId}
        {...operator}
        agencies={agencies}
        onSave={onSave}
      />
    ));
  }, [agencies, onSave, operators]);

  return (
    <S.Table>
      <thead>
        <tr>
          {columns.map((column) => (
            <S.TableHeadCell key={column.name} {...{ width: column?.params?.width }}>
              {column.name}
            </S.TableHeadCell>
          ))}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </S.Table>
  );
};
