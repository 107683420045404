import React, { useRef, ImgHTMLAttributes } from 'react';
import { Typography, Box } from '@material-ui/core';
import clsx from 'clsx';

import { Logger, LoggerServices, LoggerMessages } from 'src/infrastructure/loggers/datadog';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';

import { useStyles } from './styles';

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  label?: string;
  isFullSize?: boolean;
};

export const ImageWithLogger = (props: Props) => {
  const { src, alt, label, loading, className, isFullSize, onClick } = props;

  const classes = useStyles();
  const startTime = useRef(Date.now());
  const { me } = useAuth();
  const operatorId = me?.id || '';

  const onLoadEnd = () => {
    const endTime = Date.now();
    const duration = endTime - startTime.current;
    Logger.log({
      service: LoggerServices.Moderation,
      message: LoggerMessages.ImageLoadDuration,
      product: Product.RAW,
      payload: { operatorId, duration, uri: src },
    });
  };

  return (
    <Box className={classes.imageContainer} onClick={onClick}>
      {label && <Typography className={classes.topLabelText}>{label}</Typography>}
      <img
        loading={loading}
        src={src}
        alt={alt}
        onLoad={onLoadEnd}
        onError={(error) => {
          Logger.log({
            service: LoggerServices.Moderation,
            message: LoggerMessages.LoadImageError,
            product: Product.RAW,
            payload: { operatorId, error, uri: src },
          });
        }}
        className={
          isFullSize
            ? classes.fullSizeImage
            : clsx({ [classes.image]: !className!, [className || '']: Boolean(className) })
        }
      />
    </Box>
  );
};

export const ImageWithLoggerWithRef = React.forwardRef(ImageWithLogger);
