import React from 'react';
import { useStyles } from './styles';

import trashCanIcon from './trash-can-icon.webp';
import editIcon from './edit-icon.webp';

type Props = {
  onSave?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

export const ActionButtons = ({ onSave, onEdit, onDelete }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonsContainer}>
      {onDelete && (
        <button type="button" className={classes.button} onClick={onDelete}>
          <img className={classes.trashCanIcon} src={trashCanIcon} alt="Delete" />
        </button>
      )}
      {onEdit && (
        <button type="button" className={classes.button} onClick={onEdit}>
          <img className={classes.editIcon} src={editIcon} alt="Edit" />
        </button>
      )}
      {onSave && (
        <button type="button" className={classes.saveButton} onClick={onSave}>
          Save
        </button>
      )}
    </div>
  );
};
