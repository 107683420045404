import { useCallback, useState } from 'react';

import { ActionType, ModerationAction } from 'src/types/moderation';
import { MediaTag, Media } from 'src/types/user';
import { userRequest } from 'src/network';
import { Resources } from 'src/resources';
import { noop } from 'src/utils/functions';

import { useModerationLogger } from './use-moderation-logger';

type Props = {
  userMedia: Media[];
  userId: string;
};

const { defaultName, defaultAbout } = Resources.strings.moderation;

export const useCommonModerationActions = (props: Props) => {
  const { userMedia, userId } = props;
  const [moderationActions, setModerationActions] = useState<ModerationAction[]>([]);
  const { logUpdateThumbnailError } = useModerationLogger();
  const [mediaTags, setMediaTags] = useState<Record<string, MediaTag[]>>({});

  const boostUser = useCallback(() => {
    userRequest.putUserBoostedTag(userId).catch(noop);
  }, [userId]);

  const checkIfNeedUpdateMediaTags = useCallback((actions: ModerationAction[]) => {
    return actions.some(
      (action) => action.actionType === ActionType.Approve || action.actionType === ActionType.Reject,
    );
  }, []);

  const updateMediaTags = useCallback(
    (shouldBoost = true) => {
      const initialMediaTags = userMedia.reduce<Record<string, MediaTag[]>>((acc, media) => {
        acc[media.baseName] = media.tags;

        return acc;
      }, {});

      Object.keys(mediaTags).forEach((baseName) => {
        const initialTags = initialMediaTags[baseName] || [];
        const finalTags = mediaTags[baseName];

        finalTags.forEach((tag) => {
          if (!initialTags.includes(tag)) {
            userRequest.setUserPhotoTag(userId, baseName, tag).catch((error) => {
              logUpdateThumbnailError({ userId, media: baseName, error });
            });

            if (shouldBoost && tag === MediaTag.Thumbnail) {
              boostUser();
            }
          }
        });

        initialTags.forEach((tag) => {
          if (!finalTags.includes(tag)) {
            userRequest.removeUserPhotoTag(userId, baseName, tag).catch((error) => {
              logUpdateThumbnailError({ userId, media: baseName, error });
            });
          }
        });
      });
    },
    [boostUser, logUpdateThumbnailError, mediaTags, userId, userMedia],
  );

  const addModerationAction = useCallback(
    (actionType: ActionType, actionValue?: string) => {
      setModerationActions([
        ...moderationActions.filter((action) => action.actionType !== actionType),
        {
          actionType,
          ...(actionValue !== undefined && { actionValue }),
        },
      ]);
    },
    [moderationActions],
  );

  const isPressed = useCallback(
    (actionType: ActionType, actionValue?: string) => {
      return moderationActions.some((action) =>
        actionValue
          ? action.actionType === actionType && action.actionValue === actionValue
          : action.actionType === actionType,
      );
    },
    [moderationActions],
  );

  const resetName = useCallback(() => {
    addModerationAction(ActionType.ResetName, defaultName);
  }, [addModerationAction]);

  const resetAbout = useCallback(() => {
    addModerationAction(ActionType.ResetAbout, defaultAbout);
  }, [addModerationAction]);

  const undoAction = useCallback(
    (actionType: ActionType) => () => {
      setModerationActions([...moderationActions.filter((action) => action.actionType !== actionType)]);
    },
    [moderationActions],
  );

  return {
    moderationActions,
    addModerationAction,
    resetName,
    resetAbout,
    undoAction,
    isPressed,
    setModerationActions,
    mediaTags,
    setMediaTags,
    checkIfNeedUpdateMediaTags,
    updateMediaTags,
  };
};
