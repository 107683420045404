import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { useStyles } from './styles';

type Props = {
  userId: string;
  className?: string;
};

export const PrevUserId = ({ userId, className }: Props) => {
  const classes = useStyles();

  return (
    <span className={clsx([classes.smallText, className])}>
      Previous user ID:&nbsp;
      <Link to={`/moderation/user/${userId}`} target="_blank" className={classes.link}>
        <u>{userId}</u>
      </Link>
    </span>
  );
};
