import styled from 'styled-components';
import { TextField, CircularProgress, Card, Box, Typography, List } from '@material-ui/core';

export const TextFieldS = styled(TextField)`
  margin: 12px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InputS = styled.input`
  border: 1px solid rgba(108, 32, 185, 0.5);
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  margin-left: 32px;
  margin-right: 8px;
`;

export const Circular = styled(CircularProgress)`
  margin-left: 8px;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 200px;
  height: 250px;
`;

export const UploadingBox = styled.div`
  margin-left: 12px;
  display: flex;
  align-items: center;
`;

export const LoadingPhotos = styled.div`
  padding: 5px 15px;
  margin-bottom: 16px;
`;

export const UserProfileContainer = styled.div`
  padding: 10px;
`;

export const UserProfileCard = styled(Card)`
  padding: 10px;
  width: fit-content;
  border: 1px solid #6c20b9;
`;

export const CardLeft = styled(Box)`
  margin-right: 32px;
`;

export const CardRight = styled(Card)`
  padding: 10px;
`;

export const ImageContainer = styled(Box)`
  width: 200px;
  height: 252px;
  outline: 1px solid black;
`;

export const ImageInput = styled.input`
  margin-top: 8px;
  width: 200px;
`;

export const TopMarginContainer = styled(Box)`
  margin-top: 16px;
`;

export const NameInput = styled(TextField)`
  margin: 10px 0;
`;

export const PickerContainer = styled(Box)`
  margin-top: 32px;
`;

export const CheckListContainer = styled(Box)`
  margin-bottom: 32px;
  margin-left: 5px;
  margin-right: 5px;
`;

export const QuestionContainer = styled(Box)`
  margin: 10px 0;
`;

export const QuestionInput = styled(TextField)`
  margin-right: 8px;
  width: 400px;
`;

export const SaveContainer = styled(Box)`
  margin-top: 16px;
`;

export const ImageTitle = styled(Typography)`
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  width: 80px;
  border: 1px solid #661b95;
  border-radius: 5px;
  background-color: #661b95;
  color: white;
  text-align: center;
  padding: 1px 5px;
`;

export const IdTitle = styled(Typography)`
  margin-left: 12px;
`;

export const CustomPickerHeader = styled.div`
  display: flex;
  margin: 10px;
  justify-content: center;
`;

export const AddQuestionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckboxList = styled(List)`
  width: 200px;
  height: 230px;
  overflow: auto;
`
