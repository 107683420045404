import React, { useCallback, useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';

import { Layout } from 'src/components/App/views/Layout';
import { useAgencies } from 'src/services/agency/hooks';
import { useAnimators } from 'src/services/animator/hooks';
import { MultichatAgencyAnimatorsMappingTable } from './Table';
import * as S from './styled';
import { AgencyAnimatorsFillingForm } from './FillingForm';

export const MultichatAgencyAnimatorsMappingScreen = () => {
  const { animatorsState, linkOperatorWithAgency } = useAnimators();
  const agenciesState = useAgencies();

  const [editableAgencyTechName, setEditableAgencyTechName] = useState<string | null>(null);

  const editableAgency = useMemo(() => {
    if (!agenciesState.value || !editableAgencyTechName) {
      return null;
    }

    const foundAgency = agenciesState.value.find((agency) => agency.techName === editableAgencyTechName);
    if (!foundAgency) {
      return null;
    }

    return foundAgency;
  }, [agenciesState.value, editableAgencyTechName]);

  const dataForTable = useMemo(() => {
    if (!agenciesState.value || !animatorsState.value) {
      return [];
    }

    const linkedAnimatorsCounts = animatorsState.value.reduce((acc, animator) => {
      if (!animator.agencyTechName) {
        return acc;
      }

      const count = acc[animator.agencyTechName] || 0;

      return { ...acc, [animator.agencyTechName]: count + 1 };
    }, {} as { [agencyTechName: string]: number });

    return agenciesState.value.map((agency) => ({
      title: agency.name,
      key: agency.techName,
      count: linkedAnimatorsCounts[agency.techName] || 0,
    }));
  }, [agenciesState.value, animatorsState.value]);

  const onEdit = useCallback((agencyTechName: string) => {
    setEditableAgencyTechName(agencyTechName);
  }, []);

  const onClose = useCallback(() => {
    setEditableAgencyTechName(null);
  }, []);

  return (
    <Layout>
      <Typography component="h1" variant="h5">
        Agencies-Animators mapping
      </Typography>
      <S.Content>
        {animatorsState.value && agenciesState.value && !editableAgencyTechName && (
          <MultichatAgencyAnimatorsMappingTable data={dataForTable} onChange={onEdit} />
        )}
        {editableAgency && animatorsState.value && (
          <AgencyAnimatorsFillingForm
            agency={editableAgency}
            animators={animatorsState.value}
            onClose={onClose}
            onLinkAnimator={linkOperatorWithAgency}
          />
        )}
      </S.Content>
    </Layout>
  );
};
