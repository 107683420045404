import { makeStyles } from '@material-ui/core';
import {
  RAW_PHOTO_HEIGHT,
  RAW_PHOTO_WIDTH,
} from 'src/components/Moderation/screens/AdminUserModeration/raw/components/media/raw-photo/styles';

export const useStyles = makeStyles({
  image: {
    width: RAW_PHOTO_WIDTH,
    height: RAW_PHOTO_HEIGHT,
    cursor: 'pointer',
    borderRadius: 15,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    objectFit: 'cover',
  },
});
