import React from 'react';

import { RAW } from 'src/types/raw';
import { MockSecondaryContent } from '../mock-secondary-content';
import { RAWSecondaryContent } from '../raw-secondary-content';
import { ProfileSecondaryContent } from '../profile-secondary-content';
import { useStyles } from './styles';

type Props = {
  secondaryContent: RAW.SecondaryContentTypeState;
  userId: string;
  onPhotoPress: (sources: string[]) => void;
};

export const SecondaryContentWrapper = ({ secondaryContent, userId, onPhotoPress }: Props) => {
  const classes = useStyles();

  const renderContent = () => {
    if (secondaryContent.loading) {
      return <MockSecondaryContent loading />;
    }

    if (!secondaryContent.value) {
      return <MockSecondaryContent loading={false} />;
    }

    if (secondaryContent.value.secondaryContentType === RAW.SecondaryContent.RAWContent) {
      if (secondaryContent.value.content.approvedRawPhotos.length === 0) {
        return <MockSecondaryContent loading={false} />;
      }

      return (
        <RAWSecondaryContent content={secondaryContent.value.content} userId={userId} onPhotoPress={onPhotoPress} />
      );
    }

    if (secondaryContent.value.content.length === 0) {
      return <MockSecondaryContent loading={false} />;
    }

    return (
      <ProfileSecondaryContent content={secondaryContent.value.content} userId={userId} onPhotoPress={onPhotoPress} />
    );
  };

  return <div className={classes.wrapper}>{renderContent()}</div>;
};

export const MockSecondaryContentWrapper = () => {
  const classes = useStyles();

  return <div className={classes.mock} />;
};
