import React from 'react';
import Typography from '@material-ui/core/Typography';

import { Layout } from 'src/components/App/views/Layout';
import { useUserLifeStory } from 'src/services/user/life-story';
import { LifeStoriesSearchForm } from './SearchForm';
import { LifeStoryEditor } from './Editor';

export const MultichatLifeStoriesScreen = () => {
  const { lifeStory, fetchLifeStory, updateLifeStory } = useUserLifeStory();

  return (
    <Layout>
      <Typography component="h1" variant="h5">
        Multichat stories
      </Typography>
      <LifeStoriesSearchForm onSubmit={fetchLifeStory} />
      {lifeStory !== null ? <LifeStoryEditor story={lifeStory || ''} onSubmit={updateLifeStory} /> : <></>}
    </Layout>
  );
};
