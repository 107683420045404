import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { verificationRequest } from 'src/network/verification';
import { waitingListRequest } from 'src/network/waiting-list';
import { getUserAge } from 'src/utils/transform';
import { useAuth } from 'src/services/auth';

export const useNextUser = () => {
  const { me } = useAuth();
  const operatorId = me?.id || '';

  const [nextUserState, loadNextUser] = useAsyncFn(
    async () => {
      const userId = await waitingListRequest.getUserId(operatorId);

      if (userId) {
        try {
          const user = await verificationRequest.getUser(userId);
          const declinedCount = await waitingListRequest.getWaitingListUserDeclinedCount(userId);

          return {
            userId,
            gender: user?.gender,
            age: getUserAge(user?.birthday),
            name: user?.name,
            desiredRelationship: user?.preferences?.['desired-relationship'],
            genderIdentity: user?.preferences?.['gender-identity'],
            mainMedia: user?.media?.[0],
            interests: user?.interests,
            country: user?.country,
            city: user?.city,
            declinedCount: declinedCount.count,
            media: user?.media,
          };
        } catch (e) {} // eslint-disable-line
      }

      return null;
    },
    [],
    { loading: true },
  );

  useEffect(() => {
    loadNextUser().then();
  }, [loadNextUser]);

  return {
    nextUserState,
    loadNextUser,
  };
};

export const useWaitingListCount = () => {
  const { me } = useAuth();
  const operatorId = me?.id || '';

  const [contentCountState, fetchContentCount] = useAsyncFn(
    async () => {
      try {
        const result = await waitingListRequest.getWaitingListCount(operatorId);
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return {
    contentCountState,
    fetchContentCount,
  };
};
